import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ApplicationFileService } from '@core/services/application-file.service';
import { SpecialHandlingService } from '@core/services/special-handling.service';
import { ValidatorsService } from '@core/services/validators.service';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { BaseApplicationForLogic, ComponentTabIndexMap, FormAudience, FormComponentGroup, FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { InKindService } from '@features/in-kind/in-kind.service';
import { LogicState } from '@features/logic-builder/logic-builder.typing';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { YcFile } from '@yourcause/common/files';
import { isUndefined, uniq } from 'lodash';
import { ComponentHelperService } from '../component-helper/component-helper.service';
import { FormLogicService } from '../form-logic/form-logic.service';

@Injectable({ providedIn: 'root' })
export class FormValidationService {

  constructor (
    private componentHelper: ComponentHelperService,
    private formBuilder: TypeSafeFormBuilder,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private validatorService: ValidatorsService,
    private applicationFileService: ApplicationFileService,
    private specialHandlingService: SpecialHandlingService,
    private formLogicService: FormLogicService,
    private inKindService: InKindService
  ) { }

  /**
   * Get the Form Component Group
   *
   * @param tab: Tab
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param readOnly: Is the form read only?
   * @param onManagerForm: Is this a manager form?
   * @param isForConfigModalPreview: Are we in the config modal?
   * @param isForSetValue: Is this for set value logic?
   * @returns the form group control map used to render the form
   */
  getFormComponentMap (
    tab: FormDefinitionForUi,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    readOnly: boolean,
    onManagerForm: boolean,
    isForConfigModalPreview: boolean,
    isForSetValue: boolean
  ): FormComponentGroup {
    const components = this.componentHelper.getAllComponents([tab]);
    const formGroupBase = this.formBuilder.group({});
    components.forEach((component) => {
      const compKey = this.componentHelper.getAdaptedKeyFromComponentKey(component.key, isForSetValue, isForConfigModalPreview);
      const isRefField = this.componentHelper.isReferenceFieldComp(component.type);
      const isStandardField = this.componentHelper.isStandardComponent(component.type);
      const isEmployeeSsoField = this.componentHelper.isEmployeeSsoComponent(component.type);
      const isReportField = this.componentHelper.isReportFieldComp(component.type);
      if (isRefField) {
        this.addControlForRefFieldComponent(
          formGroupBase,
          component,
          translations,
          inFormBuilder,
          readOnly,
          onManagerForm,
          compKey
        );
      } else if (isStandardField) {
        this.addControlForStandardComponent(
          formGroupBase,
          component,
          translations,
          inFormBuilder,
          compKey
        );
      } else if (isEmployeeSsoField) {
        this.addControlForEmployeeSsoComponent(
          formGroupBase,
          component,
          inFormBuilder,
          compKey
        );
      } else if (isReportField) {
        this.addControlForReportComponent(
          formGroupBase,
          component,
          inFormBuilder,
          compKey
        );
      }
    });
  
    return formGroupBase;
  }

  /**
   * Add Custom Validator to Array
   *
   * @param inFormBuilder: Are we in the form builder?
   * @param component: Component
   * @param validators: Validators
   * @returns the adapted validators
   */
  addCustomValidatorToArray (
    inFormBuilder: boolean,
    component: FormDefinitionComponent,
    validators: ValidatorFn[]
  ) {
    if (
      !inFormBuilder &&
      (component.customValidation || !!component.validate?.custom)
    ) {
      validators = [
        ...validators,
        this.customValidator(component)
      ];
    }

    return validators;
  }

  /**
   * Custom Validator for Advanced Validation
   *
   * @param component: Component
   * @returns the custom validator
   */
  customValidator (component: FormDefinitionComponent) {
    return () => {
      const customValidationResult = component?.validate?.validationResult;
      const customValidationMessage = component?.validate?.customMessage;
      const hasValidationResult = !isUndefined(this.formLogicService.customValidationMap[component.key]);
      const hasCustomValidationError = hasValidationResult ?
        !this.formLogicService.customValidationMap[component.key] :
        false;
      if (hasCustomValidationError) {
        if (!!customValidationResult) {
          return {
            customValidationError: {
              errorMessage: customValidationMessage || customValidationResult
            }
          };
        } else {
          return {
            customValidationError: {
              errorMessage: component?.customValidation?.result ??
                customValidationMessage
            }
          };
        }
      }

      return null;
    };
  }

  /**
   * Adds the control for the reference field component
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param readOnly: Is this form read (view) only?
   * @param onManagerForm: Is this a manager form?
   * @param compKey: Components'key
   */
  addControlForRefFieldComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    readOnly: boolean,
    onManagerForm: boolean,
    compKey: string
  ) {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
    let validators: ValidatorFn[] = [];
    if (!inFormBuilder) {
      validators = this.validatorService.getValidatorsForReferenceFieldComponent(
        component,
        field,
        this.formFieldTableAndSubsetService.dataPointsMap,
        translations
      );
    }
    let controlValue: any = component.value;
    if (field.isRichText && field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea) {
      controlValue = {
        value: component.value,
        disabled: this.isCompDisabled(component, readOnly, field.formAudience, onManagerForm)
      };
    }
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(controlValue, this.addCustomValidatorToArray(inFormBuilder, component, validators))
    );
  }

  /**
   * Is the component disabled?
   *
   * @param component: Component
   * @param readOnly: Is this form read (view)  only?
   * @param fieldAudience: Field Audience
   * @param onManagerForm: Is this a manager form?
   * @returns 
   */
  isCompDisabled (
    component: FormDefinitionComponent,
    readOnly: boolean,
    fieldAudience: FormAudience,
    onManagerForm: boolean
  ) {
    if (readOnly === true) {
      return readOnly;
    } else {
      return (
          onManagerForm &&
          fieldAudience === FormAudience.APPLICANT
        ) ||
        this.componentHelper.isCompDisabled(component) ||
        // disable if manager field is on applicant form
        (
          !onManagerForm &&
          fieldAudience === FormAudience.MANAGER
        );
    }
  }

  /**
   * Adds the control for a standard component
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForStandardComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    compKey: string
  ) {
    let value: any = component.value;
    let validators: ValidatorFn[] = [];
    switch (component.type) {
      case 'amountRequested':
        if (!inFormBuilder) {
          validators = this.validatorService.getValidatorsForSimpleComponent(
            component,
            true,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'inKindItems':
        if (!inFormBuilder) {
          const foundItem = this.inKindService.allItems?.find((item) => {
            return item.identification === component.validationItem;
          });
          validators = this.validatorService.getValidatorsForInKindComponent(
            component,
            foundItem?.name ?? component.validationItem ?? '',
            translations
          );
        }
        break;
      case 'designation':
        if (!inFormBuilder) {
          validators = this.validatorService.getValidatorsForSimpleComponent(
            component,
            false,
            false,
            false,
            translations,
            true
          );
        }
        break;
      case 'decision':
        if (!inFormBuilder) {
          validators = this.validatorService.getValidatorsForSimpleComponent(
            component,
            false,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'reviewerRecommendedFundingAmount':
        if (!inFormBuilder) {
          validators = this.validatorService.getValidatorsForSimpleComponent(
            component,
            true,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'specialHandling':
        const specialHandling = (value ?? {}) as SpecialHandling;
        let formControlFile: YcFile<File>[] = [];
        const file = this.applicationFileService.breakDownloadUrlDownToObject(
          specialHandling.fileUrl
        );
        if (!!file) {
          formControlFile = [new YcFile(
            file.fileName,
            null,
            specialHandling.fileUrl,
            +file.fileId
          )];
        }
        value = this.formBuilder.group({
          handlingName: specialHandling.name,
          address1: specialHandling.address1 || '',
          address2: specialHandling.address2 || '',
          city: specialHandling.city || '',
          stateProvRegCode: specialHandling.state || '',
          countryCode: specialHandling.country || '',
          postalCode: specialHandling.postalCode || '',
          file: [formControlFile],
          reason: specialHandling.reason || '',
          notes: specialHandling.notes || '',
          handlingOn: this.specialHandlingService.parentHasHandling(specialHandling)
        });
        break;
    }
  
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        value,
        this.addCustomValidatorToArray(inFormBuilder, component, validators)
      )
    );
  }

  /**
   * Adds the control for employee SSO components
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForEmployeeSsoComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    inFormBuilder: boolean,
    compKey: string
  ) {
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        component.value,
        this.addCustomValidatorToArray(inFormBuilder, component, [])
      )
    );
  }

  /**
   * Adds the control for report components
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForReportComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    inFormBuilder: boolean,
    compKey: string
  ) {
    const isNominationField = this.componentHelper.isReportNominationField(
      component.reportFieldDataOptions?.reportFieldObject
    );
    if (isNominationField) {
      compKey = this.componentHelper.getNominatorReportKey(component.reportFieldDataOptions.reportFieldDisplay);
    }
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        component.value,
        this.addCustomValidatorToArray(inFormBuilder, component, [])
      )
    );
  }

  /**
   * Sets the Validation Map on Init
   * 
   * @param formDefinition: Form Definition
   */
  setValidationMapOnInit (formDefinition: FormDefinitionForUi[]) {
    const allComps = this.componentHelper.getAllComponents(formDefinition);
    // All comps start off valid, until the validation is run and evaluated
    const validityMap = allComps.reduce((acc, comp) => {
      return {
        ...acc,
        [comp.key]: true
      };
    }, {} as Record<string, boolean>);
    this.formLogicService.setCustomValidationMap(validityMap);
    this.formLogicService.setFinalValidationMap(validityMap);
  }

  /**
   * Sets the Curent Validity State
   *
   * @param validityState: Validity State to Set
   */
  setCurrentValidityState (
    validityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    this.formLogicService.setCurrentValidityState(validityState);
  }

  /**
   * Sets the Form Validation Map
   *
   * @param formGroupMap: Form Group Map
   * @returns the validity changes
   */
  setFormValidationMap (
    formGroupMap: Record<number, FormComponentGroup>
  ) {
    const validationMap: Record<string, boolean> = {};
    let tabValidityChanges: number[] = [];
    Object.keys(formGroupMap).forEach((index) => {
      const group = formGroupMap[+index];
      for (const key in group.controls) {
        const control = group.controls[key];
        const previousValidity = this.formLogicService.finalValidationMap[key];
        const currentValidity = !control.invalid;
        if (previousValidity !== currentValidity) {
          tabValidityChanges.push(+index);
        }
        validationMap[key] = currentValidity;
      }
    });
    this.formLogicService.setFinalValidationMap(validationMap);

    return uniq(tabValidityChanges);
  }

  /**
   * Force Validation Updates
   *
   * @param compKeyValidityChanges: Comp Keys where validity has changed
   * @param componentTabIndexMap: Component tab index map
   * @param formGroupMap: Form group map
   */
  handleForcingValidationUpdates (
    compKeyValidityChanges: string[],
    componentTabIndexMap: ComponentTabIndexMap,
    formGroupMap: Record<number, FormComponentGroup>
  ) {
    if (compKeyValidityChanges.length > 0) {
      compKeyValidityChanges.forEach((compKey) => {
        const tabIndex = componentTabIndexMap[compKey].tabIndex;
        const control = formGroupMap[tabIndex].get(compKey);
        if (!!control) {
          control.updateValueAndValidity();
        }
      });
    }
  }
}

