import { Injectable } from '@angular/core';
import { differenceInSeconds } from 'date-fns';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';

@Injectable({ providedIn: 'root' })
export class TokenTimeoutService {
  private loopDuration = 500;
  private _timeRemaining$ = new BehaviorSubject<number>(Infinity);
  private interval: NodeJS.Timer|number;
  timeRemaining$: Observable<number> = this._timeRemaining$;
  showTimeout$ = this.timeRemaining$.pipe(map(time => {
    return time <= this.timeoutStart;
  }));

  readonly timeoutStart = 30;

  constructor (
    private tokenStorage: TokenStorageService
  ) { }

  start () {
    this.calculateTimeRemaining();
    this.interval = setInterval(() => this.calculateTimeRemaining(), this.loopDuration);
  }

  stop () {
    this._timeRemaining$.next(Infinity);
    if (this.interval) {
      clearInterval(this.interval as number);
    }
  }

  private calculateTimeRemaining () {
    const token = this.tokenStorage.jwt;
    const timeRemaining = token ?
      differenceInSeconds(new Date(token.refreshTokenExpiration), new Date()) :
      0;

    this._timeRemaining$.next(timeRemaining);
  }
}
