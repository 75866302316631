import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CacheBusterInterceptor implements HttpInterceptor {
  intercept (request: HttpRequest<any>, next: HttpHandler) {
    if (request.method.toLowerCase() === 'get' && !request.url.includes('_')) {
      let url = request.urlWithParams;
      url += `${url.includes('?') ? '&' : '?'}_=${Date.now()}`;

      request = request.clone({
        url
      });
    }

    return next.handle(request);
  }
}
