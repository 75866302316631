
<div *ngIf="editable &&
  component.type !== 'button'" 
  class="actions" 
  [class.active]="editable"
  [class.visible]="hovered"
  [class.toolbox-open]="toolboxOpen">
  <gc-form-builder-component-actions
    [componentKey]="component.key"
    [allowCopy]="!isBBGM"
    [allowEditFormField]="!isBBGM"
    [componentType]="component.type"
    (actionClick)="componentActionClick.emit({
      action: $event,
      component: component
    })">
  </gc-form-builder-component-actions>
</div>

<div [ycHidden]="!editable && isHidden && !inConfigModal"
  [ngClass]="'apiKey_' + component.key"
  class="mb-4">
  <div [ngSwitch]="type"
    [class.mb-4]="!lastCompOnPage">
    <!-- Reference Fields -->
    <gc-form-reference-field *ngSwitchCase="'referenceField'"
      [notAutoSave]="notAutoSave"
      [formGroup]="formGroup"
      [inConfigModal]="inConfigModal"
      [isForSetValue]="isForSetValue"
      [formComponent]="component"
      [data]="valueForField"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [masked]="masked"
      [label]="component.label"
      [hideLabel]="component.hideLabel"
      [placeholder]="component.placeholder"
      [disabledOverride]="disabledOverride"
      [parentFields]="parentFields"
      [translations]="translations"
      [isManagerForm]="isManagerForm"
      [inline]="component.inline"
      [defaultVal]="component.defaultVal"
      [prefix]="component.prefix"
      [suffix]="component.suffix"
      [isFormBuilderView]="isFormBuilderView"
      [currencyOptions]="currencyOptions"
      [requireDecimal]="component.requireDecimal"
      [decimalLimit]="component.decimalLimit"
      [showWordCount]="component.showWordCount"
      [showCharCount]="component.showCharCount"
      [inputMask]="component.inputMask"
      [rows]="component.rows | ycAs: $number"
      [tooltipText]="component.tooltip"
      [tabIndex]="component.tabIndex"
      [description]="component.description"
      [emitInitialValidity]="emitInitialValidity"
      [hideWithoutParentVal]="component.hideWithoutParentVal"
      [itemsShownBeforeScroll]="component.itemsShownBeforeScroll" 
      [displayType]="component.displayType"
      [relatedComponent]="component.relatedComponent"
      [visibleToApplicants]="component.visibleToApplicants"
      [visibleToManagers]="component.visibleToManagers"
      [dataUpdates]="component.dataUpdates"
      [apiConfig]="component.apiConfig"
      [useCustomCurrency]="component.useCustomCurrency"
      [customCurrency]="component.customCurrency"
      [required]="component.required"
      [showFieldChange]="showFieldChange"
      [min]="component.validate?.min"
      [max]="component.validate?.max"
      [maxFileSizeInMb]="component.maxFileSizeInMb"
      [acceptedFileTypes]="component.acceptedFileTypes"
      (onValueChange)="valueChanged($event)">
    </gc-form-reference-field>

    <!-- Amount Requested -->
    <gc-form-amount-requested *ngSwitchCase="'amountRequested'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [translations]="translations"
      [inConfigModal]="inConfigModal"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [isForSetValue]="isForSetValue"
      [data]="valueForField | ycAs: $currencyValue"
      [currencyOptions]="currencyOptions"
      [canToggleCurrency]="canToggleCurrency"
      [forceDefaultCurrency]="forceDefaultCurrency"
      [label]="component.label"
      [isFormBuilderView]="isFormBuilderView"
      [disabled]="component.disabled"
      [disabledOverride]="disabledOverride"
      [hideLabel]="component.hideLabel"
      [description]="component.description"
      [defaultVal]="component.defaultVal"
      [tabIndex]="component.tabIndex"
      [useCustomCurrency]="component.useCustomCurrency"
      [showFieldChange]="showFieldChange"
      (onValueChange)="valueChanged($event)">
    </gc-form-amount-requested>
    
    <!-- Attention -->
    <gc-form-attention *ngSwitchCase="'careOf'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [inConfigModal]="inConfigModal"
      [parentFields]="parentFields"
      [translations]="translations"
      [data]="valueForField | ycAs: $string"
      [disabledOverride]="disabledOverride"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [label]="component.label"
      [tabIndex]="component.tabIndex"
      [description]="component.description"
      [showFieldChange]="showFieldChange"
      [isFormBuilderView]="isFormBuilderView"
      (onValueChange)="valueChanged($event)">
    </gc-form-attention>

    <!-- Decision -->
    <gc-form-decision *ngSwitchCase="'decision'"
      [formComponent]="component"
      [parentFields]="parentFields"
      [translations]="translations"
      [data]="valueForField | ycAs: $decisionType"
      [showErrorSummary]="showErrorSummary"
      [formGroup]="formGroup"
      [inConfigModal]="inConfigModal"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [truthyValue]="component.truthyValue"
      [falsyValue]="component.falsyValue"
      [recuseValue]="component.recuseValue"
      [isFormBuilderView]="isFormBuilderView"
      [label]="component.label"
      [disabledOverride]="disabledOverride"
      [description]="component.description"
      [tabIndex]="component.tabIndex"
      [placeholder]="component.placeholder"
      [allowRecused]="component.allowRecused"
      (onValueChange)="valueChanged($event)">
    </gc-form-decision>

    <!-- Designation -->
    <gc-form-designation *ngSwitchCase="'designation'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [translations]="translations"
      [inConfigModal]="inConfigModal"
      [data]="valueForField | ycAs: $string"
      [hideLabel]="component.hideLabel"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [disabled]="component.disabled"
      [disabledOverride]="disabledOverride"
      [tabIndex]="component.tabIndex"
      [isFormBuilderView]="isFormBuilderView"
      [label]="component.label"
      [placeholder]="component.placeholder"
      [description]="component.description"
      [showFieldChange]="showFieldChange"
      (onValueChange)="valueChanged($event)">
    </gc-form-designation>

    <!-- Recommended Funding Amount -->
    <gc-form-recommended-funding-amount *ngSwitchCase="'reviewerRecommendedFundingAmount'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [translations]="translations"
      [inConfigModal]="inConfigModal"
      [data]="valueForField | ycAs: $currencyValue"
      [label]="component.label"
      [isFormBuilderView]="isFormBuilderView"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [hideLabel]="component.hideLabel"
      [description]="component.description"
      [disabledOverride]="disabledOverride"
      [isForSetValue]="isForSetValue"
      [defaultVal]="component.defaultVal"
      [tabIndex]="component.tabIndex"
      [showFieldChange]="showFieldChange"
      (onValueChange)="valueChanged($event)">
    </gc-form-recommended-funding-amount>

    <!-- Special Handling -->
    <gc-form-special-handling *ngSwitchCase="'specialHandling'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [inConfigModal]="inConfigModal"
      [translations]="translations"
      [isFormBuilderView]="isFormBuilderView"
      [data]="valueForField | ycAs: $specialHandling"
      [label]="component.label"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [disabled]="component.disabled"
      [disabledOverride]="disabledOverride"
      [requireFileUpload]="component.requireFileUpload"
      [requireReason]="component.requireReason"
      [specialHandlingInstructions]="component.specialHandlingInstructions"
      [tabIndex]="component.tabIndex"
      [showFieldChange]="showFieldChange"
      (onValueChange)="valueChanged($event)">
    </gc-form-special-handling>

    <!-- In Kind -->
    <gc-form-in-kind-amount-requested *ngSwitchCase="'inKindItems'"
      [formComponent]="component"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [translations]="translations"
      [inConfigModal]="inConfigModal"
      [data]="valueForField | ycAs: $inKindItems"
      [items]="component.items"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages | ycFilterByAttr: 'componentKey': [component.key]"
      [description]="component.description"
      [disabledOverride]="disabledOverride"
      [tabIndex]="component.tabIndex"
      [disabled]="component.disabled"
      [isFormBuilderView]="isFormBuilderView"
      [label]="component.label"
      [displayInKindValues]="component.displayInKindValues"
      [hideLabel]="component.hideLabel"
      [maxItems]="component.maxItems"
      [allowMultiple]="component.allowMultiple"
      [validationErrorMessage]="component.validationErrorMessage"
      [validationType]="component.validationType"
      [willBeValid]="component.willBeValid"
      [validationAmount]="component.validationAmount"
      [validationItem]="component.validationItem"
      [displayInKindValues]="component.displayInKindValues"
      [showCategory]="component.showCategory"
      [showFieldChange]="showFieldChange"
      (onValueChange)="valueChanged($event)">
    </gc-form-in-kind-amount-requested>

    <!-- Employee SSO Field -->
    <gc-form-employee-sso-field *ngSwitchCase="'employeeSSO'"
      [formComponent]="component"
      [inConfigModal]="inConfigModal"
      [formGroup]="formGroup"
      [parentFields]="parentFields"
      [disabledOverride]="disabledOverride"
      [isFormBuilderView]="isFormBuilderView"
      [translations]="translations"
      [data]="valueForField | ycAs: $string"
      [label]="component.label"
      [description]="component.description">
    </gc-form-employee-sso-field>

    <!-- Report Field -->
    <gc-form-report-field *ngSwitchCase="'reportField'"
      [formComponent]="component"
      [inConfigModal]="inConfigModal"
      [formGroup]="formGroup"
      [data]="valueForField"
      [disabledOverride]="disabledOverride"
      [isFormBuilderView]="isFormBuilderView"
      [translations]="translations"
      [notAutoSave]="notAutoSave"
      [isManagerForm]="isManagerForm"
      [masked]="masked"
      [editable]="editable"
      [parentFields]="parentFields"
      [reportFieldDataOptions]="component.reportFieldDataOptions"
      [displayType]="component.displayType"
      [label]="component.label"
      [hideLabel]="component.hideLabel"
      [description]="component.description">
    </gc-form-report-field>

    <!-- Content -->
    <gc-form-content *ngSwitchCase="'content'"
      [formComponent]="component"
      [disabled]="disabledOverride"
      [isFormBuilderView]="isFormBuilderView"
      [isContentEditMode]="inConfigModal"
      [html]="component.html || component.content"
      (onValueChange)="valueChanged($event)">
    </gc-form-content>

    <!-- Columns -->
    <gc-form-columns *ngSwitchCase="'columns'"
      [depth]="depth + 1"
      [formGroup]="formGroup"
      [editable]="editable"
      [formComponent]="component"
      [translations]="translations"
      [notAutoSave]="notAutoSave"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [isFormBuilderView]="isFormBuilderView"
      [inConfigModal]="inConfigModal"
      [disabledOverride]="disabledOverride"
      [parentFields]="parentFields"
      [masked]="masked"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      (onValueChange)="onValueChange.emit($event)"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-columns>

    <!-- Well -->
    <gc-form-well *ngSwitchCase="'well'"
      [depth]="depth + 1"
      [formGroup]="formGroup"
      [editable]="editable"
      [formComponent]="component"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [notAutoSave]="notAutoSave"
      [translations]="translations"
      [isFormBuilderView]="isFormBuilderView"
      [inConfigModal]="inConfigModal"
      [parentFields]="parentFields"
      [disabledOverride]="disabledOverride"
      [masked]="masked"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      (onValueChange)="onValueChange.emit($event)"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-well>

    <!-- Grid -->
    <gc-form-grid *ngSwitchCase="'table'"
      [depth]="depth + 1"
      [formGroup]="formGroup"
      [editable]="editable"
      [formComponent]="component"
      [notAutoSave]="notAutoSave"
      [isFormBuilderView]="isFormBuilderView"
      [translations]="translations"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [disabledOverride]="disabledOverride"
      [parentFields]="parentFields"
      [inConfigModal]="inConfigModal"
      [masked]="masked"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      (onValueChange)="onValueChange.emit($event)"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-grid>

    <!-- Panel -->
    <gc-form-panel *ngSwitchCase="'panel'"
      [depth]="depth + 1"
      [formGroup]="formGroup"
      [editable]="editable"
      [formComponent]="component"
      [notAutoSave]="notAutoSave"
      [translations]="translations"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [disabledOverride]="disabledOverride"
      [parentFields]="parentFields"
      [isFormBuilderView]="isFormBuilderView"
      [inConfigModal]="inConfigModal"
      [masked]="masked"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      (onValueChange)="onValueChange.emit($event)"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-panel>

    <!-- Field Set -->
    <gc-form-field-set *ngSwitchCase="'fieldset'"
      [depth]="depth + 1"
      [formGroup]="formGroup"
      [editable]="editable"
      [formComponent]="component"
      [notAutoSave]="notAutoSave"
      [translations]="translations"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [disabledOverride]="disabledOverride"
      [parentFields]="parentFields"
      [isFormBuilderView]="isFormBuilderView"
      [inConfigModal]="inConfigModal"
      [masked]="masked"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      (onValueChange)="onValueChange.emit($event)"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-field-set>

    <div *ngSwitchDefault>
      <ng-container *ngIf="!(component.type | gcIsInvalidComp)">
        UNKNOWN COMPONENT:
        {{ component.type }}
      </ng-container>
    </div>

  </div>
</div>
