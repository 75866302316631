<div>
  <div *ngFor="let comp of components"
    class="mb-4"
    [class.mt-4]="comp.type === 'checkbox'">

    <div [ngSwitch]="comp.type">

      <!-- Text -->
      <div *ngSwitchCase="'text'"
        class="mb-4">
        <yc-input
          [name]="comp.key"
          [label]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [disabled]="isViewOnly"
          [addRequiredAsterisk]="comp.required"
          [customRegexPattern]="comp.customRegexPattern"
          [value]="comp.value | ycAs: $string"
          (onChange)="onConfigChange(comp, $event)">
        </yc-input>
      </div>

      <!-- Number -->
      <div *ngSwitchCase="'number'"
        class="mb-4">
        <yc-input
          [name]="comp.key"
          [isNumber]="true"
          [disabled]="isViewOnly"
          [label]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [addRequiredAsterisk]="comp.required"
          [value]="comp.value | ycAs: $number"
          (onChange)="onConfigChange(comp, $event)">
        </yc-input>
      </div>

      <!-- Text Area -->
      <div *ngSwitchCase="'textarea'"
        class="mb-4">
        <yc-textarea
          [name]="comp.key"
          [disabled]="isViewOnly"
          [label]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [addRequiredAsterisk]="comp.required"
          [value]="comp.value | ycAs: $string"
          (onChange)="onConfigChange(comp, $event)">
        </yc-textarea>
      </div>

      <!-- Select -->
      <div *ngSwitchCase="'select'"
        class="mb-4">
        <yc-typeahead-select
          [name]="comp.key"
          [disabled]="isViewOnly"
          [showClear]="false"
          [label]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [addRequiredAsterisk]="comp.required"
          [items]="comp.typeaheadOptions"
          [value]="comp.value"
          (onChange)="onConfigChange(comp, $event)">
        </yc-typeahead-select>
      </div>

      <!-- Checkbox -->
      <div *ngSwitchCase="'checkbox'"
        class="mb-4">
        <yc-checkbox
          [name]="comp.key"
          [disabled]="isViewOnly"
          [optionLabel]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [addRequiredAsterisk]="comp.required"
          [value]="comp.value | ycAs: $boolean"
          (onChange)="onConfigChange(comp, $event)">
        </yc-checkbox>
      </div>

      <!-- Date -->
      <div *ngSwitchCase="'date'"
        class="mb-4">
        <yc-datepicker
          [name]="comp.key"
          [disabled]="isViewOnly"
          [label]="comp.i18nKey ?
            (comp.i18nKey | ycI18n: comp.i18nDefault) :
            ''"
          [help]="comp.i18nDescKey ?
            (comp.i18nDescKey | ycI18n: comp.i18nDescDefault: comp.i18nDescContext) :
            ''"
          [addRequiredAsterisk]="comp.required"
          [value]="comp.value | ycAs: $string"
          (onChange)="onConfigChange(comp, $event)">
        </yc-datepicker>
      </div>

      <!-- In Kind Validation -->
      <div *ngSwitchCase="'inKindValidationWidget'"
        class="mb-4">
        <gc-in-kind-validation-settings
          [willBeValid]="component.willBeValid"
          [isViewOnly]="isViewOnly"
          [validationAmount]="component.validationAmount"
          [validationType]="component.validationType"
          [validationItem]="component.validationItem"
          [validationErrorMessage]="component.validationErrorMessage"
          (onChange)="inKindValidationChange($event)">
        </gc-in-kind-validation-settings>
      </div>

      <!-- In Kind Data Settings -->
      <div *ngSwitchCase="'inKindDataWidget'"
        class="mb-4">
        <gc-in-kind-data-settings
          [isViewOnly]="isViewOnly"
          [allowMultiple]="component.allowMultiple"
          [maxItems]="component.maxItems"
          [showCategory]="component.showCategory"
          [items]="component.items"
          [displayInKindValues]="component.displayInKindValues"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="inKindDataSettingsChange($event)">
        </gc-in-kind-data-settings>
      </div>

      <!-- Decision Recused Settings -->
      <div *ngSwitchCase="'decisionRecusedWidget'"
        class="mb-4">
        <gc-decision-recused-settings
          [isViewOnly]="isViewOnly"
          [allowRecused]="component.allowRecused"
          [recuseValue]="component.recuseValue"
          (onChange)="decisionRecusedChange($event)">
        </gc-decision-recused-settings>
      </div>

      <!-- Report Field Data Options -->
      <div *ngSwitchCase="'reportFieldDataWidget'"
        class="mb-4">
        <gc-report-field-data-options-widget
          [isViewOnly]="isViewOnly"
          [reportFieldDataOptions]="component.reportFieldDataOptions"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="reportFieldDataOptionsChange($event)">
        </gc-report-field-data-options-widget>
      </div>

      <!-- Regular Expression Pattern -->
      <div *ngSwitchCase="'regularExpressionSetting'"
        class="mb-4">
        <gc-regex-validation-setting
          [isViewOnly]="isViewOnly"
          [pattern]="component.validate.pattern"
          [compType]="component.type"
          (onChange)="onConfigChange(comp, $event)">
        </gc-regex-validation-setting>
      </div>
    
      <!-- Dependent Picklist Options  -->
      <div *ngSwitchCase="'dependentPicklistSettings'"
        class="mb-4">
        <gc-dependent-picklist-options-settings
          [isViewOnly]="isViewOnly"
          [hideWithoutParentVal]="component.hideWithoutParentVal"
          [compType]="component.type"
          (onChange)="onConfigChange(comp, $event)">
        </gc-dependent-picklist-options-settings>
      </div>

      <!-- External API Config -->
      <div *ngSwitchCase="'externalApiSettings'" 
        class="mb-4">
        <gc-external-api-selector-settings
          [isViewOnly]="isViewOnly"
          [apiConfig]="component.apiConfig"
          (onChange)="onConfigChange(comp, $event)">
        </gc-external-api-selector-settings>
      </div>

      <!-- External API Form Field Source -->
      <div *ngSwitchCase="'externalApiFormFieldSource'" 
        class="mb-4">
        <gc-external-api-form-field-settings
          [isViewOnly]="isViewOnly"
          [component]="component"
          [relatedComponent]="component.relatedComponent"
          [formDefinition]="formDefinition"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-external-api-form-field-settings>
      </div>

      <!-- Custom Currency -->
      <div *ngSwitchCase="'customCurrencyWidget'"
        class="mb-4">
        <gc-currency-settings
          [isViewOnly]="isViewOnly"
          [useCustomCurrency]="component.useCustomCurrency"
          [customCurrency]="component.customCurrency"
          (onChange)="currencyChange($event)">
        </gc-currency-settings>
      </div>

      <!-- Calculated Value -->
      <div *ngSwitchCase="'formulaBuilderManager'"
        class="mb-4">
        <gc-formula-builder-manager
          [isViewOnly]="isViewOnly"
          [formula]="component.formula"
          [component]="component"
          [conditionalValue]="component.conditionalValue"
          (onModalOpenOrClose)="onModalOpenOrClose.emit($event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-formula-builder-manager>
      </div>

      <!-- Conditional Logic  -->
      <div *ngSwitchCase="'logicFormBuilderWidget'"
        class="mb-4">
        <gc-conditional-logic-form-builder-settings
          [isViewOnly]="isViewOnly"
          [logicGroups]="component.conditionalLogic"
          [component]="component"
          (onModalOpenOrClose)="onModalOpenOrClose.emit($event)"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-conditional-logic-form-builder-settings>
      </div>

      <!-- Set Value  -->
      <div *ngSwitchCase="'logicValueFormBuilderWidget'"
        class="mb-4">
        <gc-conditional-value-form-builder-settings
          [isViewOnly]="isViewOnly"
          [logicGroups]="component.conditionalValue"
          [component]="component"
          (onModalOpenOrClose)="onModalOpenOrClose.emit($event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-conditional-value-form-builder-settings>
      </div>

      <!-- Columns -->
      <div *ngSwitchCase="'columnsWidget'"
        class="mb-4">
        <gc-column-properties
          [isViewOnly]="isViewOnly"
          [columns]="component.columns"
          (onChange)="onConfigChange(comp, $event)"
          (isValidChange)="updateCustomValidity(comp, $event)">
        </gc-column-properties>
      </div>

      <!-- Custom Validation -->
      <div *ngSwitchCase="'customJavascript'"
        class="mb-4">
        <gc-custom-javascript-settings
          [isViewOnly]="isViewOnly"
          [component]="comp"
          (onChange)="onConfigChange(comp, $event)">
        </gc-custom-javascript-settings>
      </div>

      <!-- Advanced Validation -->
      <div *ngSwitchCase="'validationBuilder'"
        class="mb-4">
        <gc-validation-builder-manager
          [isViewOnly]="isViewOnly"
          [customValidation]="component.customValidation"
          [label]="component.label"
          [component]="component"
          (onModalOpenOrClose)="onModalOpenOrClose.emit($event)"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-validation-builder-manager>
      </div>

      <!-- Visible Table Columns -->
      <div *ngSwitchCase="'tableComponentVisibleColumns'"
        class="mb-4">
        <gc-table-component-visible-columns
          [isViewOnly]="isViewOnly"
          [compType]="component.type"
          [hiddenTableColumnKeys]="component.hiddenTableColumnKeys"
          [labelOverrideMap]="component.labelOverrideMap"
          [requiredOverrideKeys]="component.requiredOverrideKeys"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onTableColumnsConfigChange($event)">
        </gc-table-component-visible-columns>
      </div>

      <!-- Visible CDT Options -->
      <div *ngSwitchCase="'cdtFieldVisibleOptions'"
        class="mb-4">
        <gc-cdt-field-visible-options
          [isViewOnly]="isViewOnly"
          [compType]="component.type"
          [hiddenCdtKeys]="component.hiddenCdtKeys"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-cdt-field-visible-options>
      </div>

      <!-- Max File Size -->
      <div *ngSwitchCase="'maxFileSizeInMb'"
        class="mb-4">
        <gc-max-file-size
          [isViewOnly]="isViewOnly"
          [compType]="component.type"
          [maxFileSizeInMb]="component.maxFileSizeInMb"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-max-file-size>
      </div>

      <!-- Accepted File Types -->
      <div *ngSwitchCase="'acceptedFileTypes'"
        class="mb-4">
        <gc-accepted-file-types
          [isViewOnly]="isViewOnly"
          [acceptedFileTypes]="component.acceptedFileTypes"
          (isValidChange)="updateCustomValidity(comp, $event)"
          (onChange)="onConfigChange(comp, $event)">
        </gc-accepted-file-types>
      </div>
    </div>
  </div>
</div>