import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { PaginatedResponse } from '@yourcause/common';
import { GCDashboards } from './dashboards.typing';

@Injectable({ providedIn: 'root' })
export class DashboardsResources {

  constructor (
    private http: HttpRestService
  ) { }

  getChartData (
    endpoint: string,
    id: number,
    refreshChart: boolean
  ): Promise<GCDashboards.DashboardDataResults> {
    return this.http.post('/api/manager/dashboard/' + endpoint, {
      id,
      refreshChart
    });
  }

  getAllWidgets (): Promise<GCDashboards.SimpleWidgetConfigFromApi[]> {
    const endpoint = 'api/manager/dashboard/GetAllWidgetsForUser';

    return this.http.get(endpoint);
  }

  getWidgetDetail (widgetId: number): Promise<GCDashboards.WidgetConfigFromApi> {
    const endpoint = `api/manager/dashboard/GetWidgetDetail/${widgetId}`;

    return this.http.get(endpoint);
  }

  createWidget (
    widget: GCDashboards.CreateWidgetPayload
  ): Promise<number> {
    return this.http.post('/api/manager/dashboard/InsertWidget', widget);
  }

  updateWidget (
    widget: GCDashboards.UpdateWidgetPayload
  ): Promise<number> {
    return this.http.post('/api/manager/dashboard/UpdateWidget', widget);
  }

  removeWidget (
    widgetId: number,
    dashboardId: number,
    keepAsTemplate: boolean
  ): Promise<void> {
    return this.http.post('/api/manager/dashboard/DeleteWidget', {
      widgetId,
      dashboardId,
      keepAsTemplate
    });
  }

  getDashboardTabs (): Promise<GCDashboards.DashboardTab[]> {
    const endpoint = 'api/manager/dashboard/GetUserDashboards';

    return this.http.get(endpoint);
  }

  getDashboardDetail (dashboardId: number): Promise<GCDashboards.DashboardDetail> {
    return this.http.get(`/api/manager/dashboard/GetDashboardDetail/${dashboardId}`);
  }

  createDashboard (
    name: string,
    order: number
  ): Promise<number> {
    const endpoint = 'api/manager/dashboard/CreateDashboard';

    return this.http.post(endpoint, {
      name,
      order
    });
  }

  updateDashboardTab (
    dashboardId: number,
    name: string,
    order: number
  ) {
    const endpoint = 'api/manager/dashboard/UpdateDashboard';

    return this.http.post(endpoint, {
      dashboardId,
      name,
      order
    });
  }

  deleteDashboardTab (dashboardId: number) {
    const endpoint = 'api/manager/dashboard/DeleteDashboard';

    return this.http.post(endpoint, {
      dashboardId
    });
  }

  getReportRowsForTable (
    paginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any>,
    endpoint: string,
    formIds: number[],
    formDataFields: string[],
    userSavedReportColumnList: AdHocReportingAPI.UserSavedReportColumn[] = [],
    baseFormId: number,
    referenceFieldIds: number[],
    referenceFieldTableIds: number[],
    extraPostOptions: Record<string, any> = {}
  ) {
    return this.http.post<PaginatedResponse<any>>(
      'api/manager/dashboard/' + endpoint,
      {
        ...extraPostOptions,
        paginationOptions,
        formIds,
        formDataFields,
        userSavedReportColumnList,
        baseFormId,
        referenceFieldIds,
        referenceFieldTableIds
      }
    );
  }

  getPreviewDashboardData (
    previewPayload: GCDashboards.PreviewDataPayload,
    api: string
  ): Promise<GCDashboards.DashboardDataResults> {
    const endpoint = 'api/manager/dashboard/' + api;

    return this.http.post(
      endpoint,
      previewPayload
    );
  }

  moveLockedDashboard (payload: {
    dashboardId: number;
    order: number;
  }) {
    const endpoint = 'api/manager/dashboard/UpdateLockedDashboardOrder';

    return this.http.post(endpoint, payload);
  }

  updateDashboardVisibility (
    payload: {
      dashboardId: number;
      isHidden: boolean;
    }
  ) {
    const enpdoint = 'api/manager/dashboard/UpdateDashboardVisibility';

    return this.http.post<void>(
      enpdoint,
      payload
    );
  }

  getDashboardSharedUsers (
    dashboardId: number
  ): Promise<GCDashboards.DashboardSharedUserInfo[]> {
    const endpoint = `api/manager/dashboard/GetDashboardSharedUsersInfo/${dashboardId}`;

    return this.http.get(endpoint);
  }

  setSharedDashboardForUsers (payload: GCDashboards.ShareDashboardUserPayload) {
    const endpoint = 'api/manager/dashboard/ShareDashboard';

    return this.http.post(endpoint, payload);
  }

  revokeDashboardForUsers (payload: GCDashboards.RevokeDashboardUserPayload) {
    const endpoint = 'api/manager/dashboard/RevokeDashboard';

    return this.http.post(endpoint, payload);
  }
}
