import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { FileTypeService } from '@yourcause/common/files';
import { MinMaxArrayValidator } from '@yourcause/common/form-control-validation';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
interface AcceptedFileTypesGroup {
  acceptedFileTypes: string[];
}

@Component({
  selector: 'gc-accepted-file-types',
  templateUrl: './accepted-file-types.component.html',
  styleUrls: ['./accepted-file-types.component.scss']
})
export class AcceptedFileTypesComponent implements OnInit, OnDestroy {
  @Input() acceptedFileTypes: string[];
  @Input() isViewOnly = false;
  @Output() onChange = new EventEmitter<string[]>();
  @Output() isValidChange = new EventEmitter<boolean>();

  formGroup: TypeSafeFormGroup<AcceptedFileTypesGroup>;
  sub = new Subscription();
  acceptedFileTypeOptions = this.fileTypeService.getAcceptedFileTypeOptions();

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private fileTypeService: FileTypeService,
    private i18n: I18nService
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group<AcceptedFileTypesGroup>({
      acceptedFileTypes: [
        this.acceptedFileTypes ?? this.fileTypeService.getAllPermittedExtensions(),
        MinMaxArrayValidator(
          'min',
          1,
          this.i18n.translate('common:textMustSelectAtLeasOneFileType', {}, 'Must select at least one file type'),
          ''
        )
      ]
    });
    this.emitValidity();
    this.sub.add(this.formGroup.statusChanges.subscribe(() => {
      this.emitValidity();
    }));
  }


  emitValidity () {
    this.isValidChange.emit(!this.formGroup.invalid);
  }
 
  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
