
<div [formGroup]="formGroup">
  <yc-checkbox-group
    [disabled]="isViewOnly"
    [showSelectAllOptions]="true"
    [useDependentValues]="true"
    [maxFilteredResults]="0"
    [name]="'acceptedFileTypes'"
    [useCdkVirtualScroll]="true"
    [options]="acceptedFileTypeOptions"
    (onChange)="onChange.emit($event)">
  </yc-checkbox-group>
</div>
