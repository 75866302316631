<div *ngIf="formGroup"
  [formGroup]="formGroup">
  <!-- Masked View -->
  <yc-input *ngIf="isMaskedView && !isFileUpload"
    [label]="label"
    [value]="formFieldMask"
    [disabled]="true">
  </yc-input>

  <yc-input *ngIf="isMaskedView && isFileUpload"
    [label]="label"
    [value]="maskedFileUploads"
    [disabled]="true">
  </yc-input>

  <div [ycHidden]="isMaskedView">
    <ng-container [ngSwitch]="field.type">
      <!-- Text Field -->
      <ng-container *ngSwitchCase="FieldTypes.TextField">
        <!-- Not Multiple -->
        <yc-input *ngIf="!field.supportsMultiple"
          [disabled]="isDisabled"
          [name]="compKey"
          [tooltipText]="tooltipText"
          [showQuestionMarkForTooltip]="true"
          [prefix]="prefix"
          [suffix]="suffix"
          [prefixSuffixFlexBasis]="'5%'"
          [showWordCount]="showWordCount"
          [showCharCount]="showCharCount"
          [customMaskPattern]="inputMask"
          [label]="label"
          [description]="description"
          [srOnlyLabel]="hideLabel"
          [tabIndex]="tabIndex"
          [addRequiredAsterisk]="addRequiredAsterisk"
          [placeholder]="placeholder"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (onChange)="dataChanged($event)">
        </yc-input>

        <!-- Multiple -->
        <div *ngIf="field.supportsMultiple && multiTextAnswers">
          <ng-template 
            *ngTemplateOutlet="multiTextFieldOrArea">   
          </ng-template>
        </div>
      </ng-container>

      <!-- Textarea Not Multiple -->
      <ng-container *ngSwitchCase="FieldTypes.TextArea">
        <!-- Standard -->
        <yc-textarea *ngIf="!field.supportsMultiple && !field.isRichText"
          [disabled]="isDisabled"
          [name]="compKey"
          [maxLength]="67000000"
          [label]="label"
          [description]="description"
          [srOnlyLabel]="hideLabel"
          [tooltipText]="tooltipText"
          [showQuestionMarkForTooltip]="true"
          [showWordCount]="showWordCount"
          [showCharCount]="showCharCount"
          [tabIndex]="tabIndex"
          [rows]="rows"
          [addRequiredAsterisk]="addRequiredAsterisk"
          [placeholder]="placeholder"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (onChange)="dataChanged($event)">
        </yc-textarea>

        <!-- Rich Text -->
        <yc-rich-text-editor *ngIf="!field.supportsMultiple && field.isRichText"
          [name]="compKey"
          [label]="label"
          [description]="description"
          [srOnlyLabel]="hideLabel"
          [tooltipText]="tooltipText"
          [showQuestionMarkForTooltip]="true"
          [showWordCount]="showWordCount"
          [showCharCount]="showCharCount"
          [tabIndex]="tabIndex"
          [adjustHeightWhenDisabled]="true"
          [addRequiredAsterisk]="addRequiredAsterisk"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (onChange)="dataChanged($event)">
        </yc-rich-text-editor>

        <!-- Multiple -->
        <div *ngIf="field.supportsMultiple && multiTextAnswers">
          <ng-template 
            *ngTemplateOutlet="multiTextFieldOrArea">   
          </ng-template>
        </div>
      </ng-container>

      <!-- Number -->
      <yc-input *ngSwitchCase="FieldTypes.Number"
        [disabled]="isDisabled"
        type="text"
        [isNumber]="true"
        [name]="compKey"
        [tabIndex]="tabIndex"
        [prefix]="prefix"
        [suffix]="suffix"
        [prefixSuffixFlexBasis]="'5%'"
        [numberOfDecimals]="decimalLimit"
        [requireDecimal]="requireDecimal"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [addRequiredAsterisk]="addRequiredAsterisk"
        [placeholder]="placeholder"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        (onChange)="dataChanged($event)">
      </yc-input>

      <!-- Address -->
      <gc-address-form-field *ngSwitchCase="FieldTypes.Address"
        [data]="data  | ycAs: $address"
        [disabled]="isDisabled"
        [compKey]="compKey"
        [tooltipText]="tooltipText"
        [placeholder]="placeholder"
        [label]="label"
        [description]="description"
        [formIsReadOnly]="readOnly"
        [tabIndex]="tabIndex"
        [showExtendedAddressInfo]="field.captureExtendedAddressInfo"
        [addRequiredAsterisk]="addRequiredAsterisk"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        (onChange)="dataChanged($event)">
      </gc-address-form-field>

      <!-- Checkbox -->
      <yc-checkbox *ngSwitchCase="FieldTypes.Checkbox"
        [name]="compKey"
        [disabled]="isDisabled"
        [srOnlyLabel]="true"
        [optionLabel]="label"
        [help]="description"
        [tabIndex]="tabIndex"
        [addRequiredAsterisk]="addRequiredAsterisk"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        (onChange)="dataChanged($event)">
      </yc-checkbox>

      <!-- Date -->
      <yc-datepicker *ngSwitchCase="FieldTypes.Date"
        [name]="compKey"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [disabled]="isDisabled"
        [hideDateIcon]="isDisabled || readOnly"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [tabIndex]="tabIndex"
        [placeholder]="placeholder"
        [addRequiredAsterisk]="addRequiredAsterisk"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        (onChange)="dataChanged($event)">
      </yc-datepicker>

      <!-- File upload -->
      <yc-multi-dropzone-input *ngSwitchCase="FieldTypes.FileUpload"
        [name]="compKey"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [disabled]="isDisabled"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [placeholder]="placeholder"
        [maxFiles]="maxFiles"
        [maxFileSize]="maxFileSize"
        [acceptedFileTypes]="acceptedFileTypes"
        [tabIndex]="tabIndex"
        [addRequiredAsterisk]="addRequiredAsterisk"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        [uploadFunc]="notAutoSave ? undefined : uploadFunc"
        [fileDownloadFunc]="handleDownloadFile"
        (onChange)="filesChanged($event)">
      </yc-multi-dropzone-input>

      <!-- Custom Data Table / Radio Buttons / Selectboxes -->
      <gc-form-custom-data-table *ngSwitchDefault
        [parentFormGroup]="formGroup"
        [comp]="comp"
        [inputType]="field.type"
        [isFormBuilderView]="isFormBuilderView"
        [supportsMultiple]="field.supportsMultiple"
        [disabled]="isDisabled"
        [requiredOverride]="isRequired"
        [inline]="inline"
        [hiddenCdtKeys]="comp.hiddenCdtKeys"
        [parentFields]="parentFields"
        [parentReferenceFieldId]="field.parentReferenceFieldId"
        [defaultVal]="defaultVal"
        [compKey]="compKey"
        [label]="label"
        [description]="description"
        [hideLabel]="hideLabel"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [hideWithoutParentVal]="hideWithoutParentVal"
        [placeholder]="placeholder"
        [tabIndex]="tabIndex"
        [data]="data | ycAs: $stringOrStringArray"
        [selectedCustomDataTable]="field.customDataTableGuid"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        (onDataChanged)="dataChanged($event)">
      </gc-form-custom-data-table>

      <!-- External API -->
      <ng-container *ngSwitchCase="FieldTypes.ExternalAPI">
        <gc-form-external-api-field
          [apiConfig]="apiConfig"
          [data]="data | ycAs: $string"
          [label]="label"
          [description]="description"
          [placeholder]="placeholder"
          [displayType]="displayType"
          [relatedComponent]="relatedComponent"
          [visibleToApplicants]="visibleToApplicants"
          [parentFields]="parentFields"
          [visibleToManagers]="visibleToManagers"
          [dataUpdates]="dataUpdates"
          [required]="required"
          [component]="comp"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (dataChange)="dataChange.emit($event)">
        </gc-form-external-api-field>
      </ng-container>

      <!-- Tables -->
      <ng-container *ngSwitchCase="FieldTypes.Table">
        <gc-form-table-field
          [component]="comp"
          [notAutoSave]="notAutoSave"
          [field]="field"
          [disabled]="isDisabled"
          [isFormBuilderView]="isFormBuilderView"
          [data]="data | ycAs: $tableResponseRowArray"
          [translations]="translations"
          [tooltipText]="tooltipText"
          [label]="label"
          [description]="description"
          [srOnlyLabel]="hideLabel"
          [parentFields]="parentFields"
          [tabIndex]="tabIndex"
          [errorMessages]="errorMessages"
          [showErrorSummary]="showErrorSummary"
          [hiddenTableColumnKeys]="comp.hiddenTableColumnKeys"
          [labelOverrideMap]="comp.labelOverrideMap"
          [requiredOverrideKeys]="comp.requiredOverrideKeys"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (onDataChanged)="dataChanged($event)">
        </gc-form-table-field>
      </ng-container>
 
      <!-- Data sets -->
      <ng-container *ngSwitchCase="FieldTypes.Subset">
        <gc-form-data-set-field
          [component]="comp"
          [data]="data | ycAs: $tableResponseRowArray"
          [field]="field"
          [disabled]="isDisabled"
          [hideLabel]="hideLabel"
          [itemsShownBeforeScroll]="itemsShownBeforeScroll"
          [tooltipText]="tooltipText"
          [translations]="translations"
          [parentFields]="parentFields"
          [label]="label"
          [description]="description"
          [srOnlyLabel]="hideLabel"
          [tabIndex]="tabIndex"
          [errorMessages]="errorMessages"
          [showErrorSummary]="showErrorSummary"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (onDataChanged)="dataChanged($event)">
        </gc-form-data-set-field>
      </ng-container>

      <!-- Aggregate -->
      <yc-input *ngSwitchCase="FieldTypes.Aggregate"
        [disabled]="true"
        [name]="compKey"
        [tabIndex]="tabIndex"
        [isNumber]="true"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [label]="label"
        [description]="description"
        [srOnlyLabel]="hideLabel"
        [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
        [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
        [addRequiredAsterisk]="addRequiredAsterisk">
      </yc-input>

      <!-- Currency -->
      <div *ngSwitchCase="FieldTypes.Currency">
        <yc-currency
          [amount]="(data | ycAs: $currencyValue).amountForControl"
          [currencyOptions]="currencyOptions"
          [label]="label"
          [description]="description"
          [isRequired]="isRequired"
          [hideLabel]="hideLabel"
          [disabled]="isDisabled"
          [tabIndex]="tabIndex"
          [currency]="(data | ycAs: $currencyValue).currency"
          [canToggle]="false"
          [errors]="control.errors"
          [isDirtyOverride]="control.dirty"
          [currencyLocked]="useCustomCurrency === CurrencyRadioOptions.USE_ONE_CURRENCY"
          [formattingData]="formattingData"
          [customLabelIcon]="showFieldChange ? customLabelIcon : ''"
          [customLabelIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
          [customLabelIconClass]="showFieldChange ? customLabelIconClass : ''"
          (amountChange)="onCurrencyAmountChange($event)"
          (currencyChange)="onCurrencyChange($event)">
        </yc-currency>
      </div>
    </ng-container>
  </div>


  <!-- Multi Text Field or Text Area Template -->
  <ng-template #multiTextFieldOrArea>
    <div class="mb-2">
      <yc-form-label *ngIf="!hideLabel"
        [label]="label"
        [description]="description"
        [name]="compKey"
        [tooltipText]="tooltipText"
        [showQuestionMarkForTooltip]="true"
        [asteriskForRequired]="isRequired"
        [customIcon]="showFieldChange ? customLabelIcon : ''"
        [customIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
        [customIconClass]="showFieldChange ? customLabelIconClass : ''">
      </yc-form-label>
    </div>
    <div *ngFor="let answer of multiTextAnswers; let index = index; trackBy: trackBy"
      class="d-flex justify-content-beween align-items-center">
      <div class="flex-grow-1">
        <!-- Multi Text Field -->
        <yc-input *ngIf="field.type === FieldTypes.TextField"
          [name]="compKey + index"
          [isDirtyOverride]="control.dirty"
          [prefix]="prefix"
          [errors]="control.errors"
          [label]="('common:textInput' | ycI18n: 'Input') + (index + 1)"
          [srOnlyLabel]="true"
          [suffix]="suffix"
          [prefixSuffixFlexBasis]="'5%'"
          [showWordCount]="showWordCount"
          [showCharCount]="showCharCount"
          [disabled]="isDisabled"
          [placeholder]="placeholder"
          [tabIndex]="tabIndex"
          [customMaskPattern]="inputMask"
          [(value)]="multiTextAnswers[index]"
          (valueChange)="multiTextAnswerChanged()">
        </yc-input>

        <!-- Mult Text Area -->
        <yc-textarea *ngIf="field.type === FieldTypes.TextArea"
          [name]="compKey + index"
          [isDirtyOverride]="control.dirty"
          [maxLength]="67000000"
          [label]="('common:textInput' | ycI18n: 'Input') + (index + 1)"
          [srOnlyLabel]="true"
          [errors]="control.errors"
          [showWordCount]="showWordCount"
          [showCharCount]="showCharCount"
          [disabled]="isDisabled"
          [tabIndex]="tabIndex"
          [rows]="rows"
          [addRequiredAsterisk]="addRequiredAsterisk"
          [placeholder]="placeholder"
          [(value)]="multiTextAnswers[index]"
          (valueChange)="multiTextAnswerChanged()">
        </yc-textarea>
      </div>
      <!-- Remove -->
      <div *ngIf="multiTextAnswers.length > 1 &&
        !readOnly &&
        !isDisabled"
        class="ms-2 d-flex justify-content-center">
        <yc-button
          [icon]="true"
          [fontAwesomeIcon]="'times'"
          [textDanger]="true"
          [value]="'common:textRemove' | ycI18n: 'Remove'"
          (onClick)="removeTextRow(index)">
        </yc-button>
      </div>
    </div>
    <!-- Add Another -->
    <div class="mb-3">
      <yc-button *ngIf="!readOnly &&
        !isDisabled"
        class="d-flex justify-content-end"
        [link]="true"
        [langKey]="'common:textAddAnother'"
        [defaultText]="'Add another'"
        (onClick)="addTextRow()">
      </yc-button>
    </div>
  </ng-template>

</div>