import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { maxFileSizeDefaultInMb } from '@yourcause/common/files';
import { Subscription } from 'rxjs';
interface MaxFileSizeGroup {
  maxFileSizeInMb: number;
}

@Component({
  selector: 'gc-max-file-size',
  templateUrl: './max-file-size.component.html',
  styleUrls: ['./max-file-size.component.scss']
})
export class MaxFileSizeComponent implements OnInit, OnDestroy {
  @Input() compType: string;
  @Input() maxFileSizeInMb: number;
  @Input() isViewOnly = false;
  @Output() onChange = new EventEmitter<number>();
  @Output() isValidChange = new EventEmitter<boolean>();

  formGroup: TypeSafeFormGroup<MaxFileSizeGroup>;
  sub = new Subscription();
  Default_Max_File_Size = maxFileSizeDefaultInMb;
  isMultiUploadField = false;

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private formFieldHelperService: FormFieldHelperService
  ) { }

  ngOnInit () {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(this.compType);
    if (!!field) {
      this.isMultiUploadField = field.supportsMultiple;
    }
    this.formGroup = this.formBuilder.group<MaxFileSizeGroup>({
      maxFileSizeInMb: [
        this.maxFileSizeInMb || maxFileSizeDefaultInMb,
        [Validators.max(maxFileSizeDefaultInMb), Validators.min(0.1)]
      ]
    });
    this.emitValidity();
    this.sub.add(this.formGroup.statusChanges.subscribe(() => {
      this.emitValidity();
    }));
  }

  emitValidity () {
    this.isValidChange.emit(!this.formGroup.invalid);
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
