<gc-form-renderer
  [showTabs]="false"
  [tabIndex]="tabIndex"
  [editable]="true"
  [formType]="form.formType"
  [isFormBuilderView]="true"
  [hideSubmitButton]="true"
  [formId]="form.id"
  [defaultFormLang]="form.defaultLanguageId"
  [formRevisionId]="formRevisionId"
  [notAutoSave]="true"
  [externalFields]="sampleExternalFields"
  [form]="form.formDefinition"
  (componentActionClick)="handleComponentAction($event)"
  (formChange)="handleFormChange($event)"
  (dropListsChanged)="handleDropListsChanged($event)"
  (onTabIndexChangedForGoToComp)="onTabIndexChangedForGoToComp.emit($event)"
  (onHandleComponentAction)="handleComponentAction($event)"
  (onUpdateTabIndex)="onUpdateTabIndex.emit($event)">
</gc-form-renderer>

<yc-expandable-toolbox
  [open]="toolboxOpen"
  [isDragAndDropPanels]="true"
  [supportsSearch]="true"
  [searchPlaceholder]="searchPlaceholder"
  [topLevelFilters]="topLevelFilters"
  [position]="ToolboxPosition.BOTTOM_RIGHT_ON_ACTION_FOOTER"
  [headerStyleClass]="'gcm-client-color-bg-primary'"
  [headerText]="'common:hdrFormBuilder' | ycI18n : 'Form Builder'"
  [dropListId]="dragBucketId"
  [dropListConnectedTo]="allDropLists"
  [panelSections]="toolboxPanelSections | ycAs: $panelSections"
  [panelStyle]="PanelStyles.Accordion"
  [noResultsOptions]="noResultsOptions"
  (openChanged)="toolboxOpen = $event"
  (dropListReady)="handleBucketReady($event)">
</yc-expandable-toolbox>

<yc-custom-footer
  [sidebarClosed]="sidebarClosed"
  [hideSidebar]="isBBGM">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center justify-content-end">
        <!-- Save-->
        <yc-button
          class="me-2"
          [primary]="true"
          [disabled]="savingForm"
          [isFooterButton]="true"
          [isDropdownButton]="true"
          [dropdownActions]="saveActions"
          [langKey]="'common:btnSave'"
          [defaultText]="'Save'">
        </yc-button>

        <!-- Form Detail -->
        <yc-button
          class="me-2"
          [secondary]="true"
          [disabled]="savingForm"
          [langKey]="'common:textFormDetail'"
          [defaultText]="'Form detail'"
          (onClick)="formDetailClicked.emit()">
        </yc-button>


        <!-- Add Form Field -->
        <yc-button *ngIf="!isBBGM"
          class="me-2"
          [secondary]="true"
          [disabled]="savingForm" 
          [langKey]="'common:textAddNewFormFieldToPage'"
          [defaultText]="'Add new form field to page'"
          (onClick)="addFormField()">
        </yc-button>

        <!-- Restore Deleted Components -->
        <yc-button *ngIf="removedCompsWithinSession.length > 0"
          class="me-2"
          [link]="true"
          [disabled]="savingForm" 
          [langKey]="'common:textRestoreDeletedComponent'"
          [defaultText]="'Restore deleted components'"
          (onClick)="restoreDeletedComponents()">
        </yc-button>

        <!-- Spell Check -->
        <yc-button *ngIf="showSpellCheck"
          class="me-2"
          [link]="true"
          [disabled]="savingForm" 
          [langKey]="'common:textPerformSpellCheck'"
          [defaultText]="'Perform spell check'"
          (onClick)="spellCheck()">
        </yc-button>
  
        <!-- Info Text -->
        <div *ngIf="enforceMaxComps">
          <yc-i18n
            [key]="'common:textComponentsOnPage'"
            [context]="{
              numberOnPage: numberOfCompsTracker[this.tabIndex] || 0,
              numberAllowed: MaxCompsPerPage
            }"
            [defaultValue]="'Components on page: __numberOnPage__ of __numberAllowed__'">
          </yc-i18n>
          <yc-fa
            [icon]="'question-circle'"
            class="text-link ms-1"
            [class.text-danger]="(numberOfCompsTracker[this.tabIndex] || 0) > MaxCompsPerPage"
            [light]="true"
            [tooltip]="'common:textMaxComponentsPerPageTooltip2' | ycI18n: maxCompsPerPageDefaultText: {
              numberAllowed: MaxCompsPerPage
            }">
          </yc-fa>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column">
      <!-- Cancel -->
      <yc-button
        [link]="true"
        [borderOnHover]="true"
        [langKey]="'common:btnCancel'"
        [defaultText]="'Cancel'"
        (onClick)="cancelClicked.emit()">
      </yc-button>

      <div>
        <gc-copyright-block></gc-copyright-block>
      </div>
    </div>
  </div>
</yc-custom-footer>
