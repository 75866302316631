<div class="yc-layout-table pb-0"
  [class.mb-4]="!(showErrorSummary && errorMessages.length > 0)"
  [class.mb-0]="showErrorSummary && errorMessages.length > 0">

  <!-- Label / Description -->
  <div class="d-flex align-items-center justify-content-between">
    <!-- Left Side (Label / Description) -->
    <div>
      <div class="font-550">
        <!-- Label -->
        <yc-form-label
          [id]="component.key"
          [label]="label"
          [srOnly]="srOnlyLabel"
          [asteriskForRequired]="!!component.validate.min"
          [customIcon]="customLabelIcon"
          [customIconTooltip]="customLabelIconTooltip"
          [customIconClass]="customLabelIconClass"
          [tooltipText]="tooltipText"
          [showQuestionMarkForTooltip]="true">
        </yc-form-label>
      </div>
  
      <!-- Description -->
      <small class="d-block"
        [innerHTML]="description">
      </small>
      <!-- Minimum Records Message -->
      <small *ngIf="!!component.validate?.min"
        class="d-block">
        <yc-i18n
          key="common:textMinimumNumberResponses"
          defaultValue="Minimum number of responses">
        </yc-i18n>: {{ component.validate.min }}
      </small>
    </div>

    <!-- Right Side (Actions) -->
    <ul class="list-unstyled d-flex">
    
      <!-- Add New -->
      <li>
        <yc-button
          class="me-3"
          [link]="true"
          [fontAwesomeIcon]="'plus'"
          [langKey]="'GLOBAL:textAddNew'"
          [defaultText]="'Add new'"
          [disabled]="isFormBuilderView || disabled"
          (onClick)="addNewRecord()">
        </yc-button>
      </li>

      <!-- Import -->
      <li>
        <yc-button *ngIf="field.tableAllowsImport && !notAutoSave"
          [link]="true"
          class="me-3"
          [fontAwesomeIcon]="'upload'"
          [langKey]="'GLOBAL:btnImport'"
          [defaultText]="'Import'"
          [disabled]="isFormBuilderView || disabled"
          (onClick)="importToTable()">
        </yc-button>
      </li>

      <!-- Download -->
      <li>
        <yc-download-format-dropdown *ngIf="rowsForTable?.length > 0"
          [buttonText]="'common:textDownload' | ycI18n: 'Download'"
          [link]="true"
          [disabled]="isFormBuilderView"
          (onOptionSelect)="downloadTable($event)">
        </yc-download-format-dropdown>
      </li>
    </ul>
  </div>


  <!-- Table -->
  <yc-table *ngIf="visibleColumns"
    [rows]="rowsForTable"
    [ariaDescribedBy]="component.key"
    [rowsPerPage]="rowsPerPage || 15"
    [tableDescription]="label"
    [simple]="true"
    [tabIndex]="tabIndex"
    [includeRecordCount]="true"
    [showNoRecordsMessage]="false">
    <ng-container *ngFor="let column of visibleColumns">
      <ng-container *ycTableColumn="'';
        display: translations[column.label] || column.label;
        prop: 'responses.' + column.referenceField.key,
        isTotaled: column.summarizeData,
        currency: column.referenceField.type === FieldTypes.Currency ?
          defaultCurrency :
          '',
        filterType: column | getFilterTypeFromTableColumn,
        let row;">

        <ng-container *ngIf="column.referenceField.type !== FieldTypes.FileUpload">
          {{
            row | gcGetFormattedTableResponse:
              column:
              row.responses[column.referenceField.key]
          }}
        </ng-container>

        <ng-container *ngIf="column.referenceField.type === FieldTypes.FileUpload">
          <a *ngFor="let fileUrl of (
            (row | gcGetFormattedTableResponse:
              column:
              row.responses[column.referenceField.key]) | ycAs: $stringArray
            )"
            class="wrap-words anchor-tag"
            href="javascript:void(0)"
            (click)="handleOpenFile(fileUrl)">
            {{ fileUrl | ycGetFileNameFromUrl }}
          </a>
        </ng-container>
        
      </ng-container>
    </ng-container>

    <!-- Actions -->
    <ng-container *ycTableColumn="'common:textActions'
      let row;
      let index = index;
      prop: '',
      filterType: '',
      class: 'actions',
      locked: true">
      <div>

        <!-- Edit -->
        <yc-button *ngIf="!disabled"
          [icon]="true"
          [fontAwesomeIcon]="'pencil'"
          [tooltipText]="'common:textEdit' | ycI18n: 'Edit'"
          (onClick)="viewOrEditRecord(row, index, false)"> 
        </yc-button>

        <!-- View -->
        <yc-button *ngIf="disabled"
          [icon]="true"
          [fontAwesomeIcon]="'eye'"
          [tooltipText]="'common:textView' | ycI18n: 'View'"
          (onClick)="viewOrEditRecord(row, index, true)"> 
        </yc-button>

        <!-- Delete -->
        <yc-button *ngIf="!disabled"
          [icon]="true"
          [fontAwesomeIcon]="'times'"
          [textDanger]="true"
          [tooltipText]="'common:btnDelete' | ycI18n: 'Delete'"
          [popoverTemplate]="confirmChangePopoverTemplate"
          [placement]="'left'"
          [popoverContext]="{
            index: index,
            row: row
          }"
          (onPopoverReady)="onPopoverReady(row, $event)">
        </yc-button>
      </div>
    </ng-container>
  </yc-table>
</div>

<div *ngIf="showErrorSummary && errorMessages.length > 0"
  class="mb-4 text-danger">
  {{ errorMessages[0].errorMessage }}
</div>



<!-- Delete Row Popover -->
<ng-template #confirmChangePopoverTemplate
  let-row="row"
  let-index="index">
  <strong class="mb-3 d-block">
    <yc-i18n
      key="common:hdrDeleteRow"
      defaultValue="Delete Row">
    </yc-i18n>
  </strong>
  <span>
    <yc-i18n
      key="common:textDeleteRowConfirm"
      defaultValue="Are you sure you want to delete this row? This action cannot be undone.">
    </yc-i18n>
  </span>
  <div class="d-flex justify-content-end align-items-center">
    <!-- No -->
    <yc-button
      class="me-3"
      [link]="true"
      [langKey]="'common:textNo'"
      [defaultText]="'No'"
      (onClick)="row.popover?.hide()">
    </yc-button>

    <!-- Yes -->
    <yc-button
      [primary]="true"
      [langKey]="'common:textYes'"
      [defaultText]="'Yes'"
      (onClick)="deleteRecord(index)">
    </yc-button>
  </div>
</ng-template>