import { Injectable } from '@angular/core';
import { User } from '@core/typings/client-user.typing';
import { UserService } from '@features/users/user.service';

@Injectable({ providedIn: 'root' })
export class PolicyService {

  constructor (
    private  userService: UserService
  ) { }

  system = {
    permissionSetType: 1,
    isAuthorized: this.isAuthorized,
    allPermissionType: 1,
    canManageUsers: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        2
      );
    },
    canManageClientSettings: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        3
      );
    },
    canManageWorkflows: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        4
      );
    },
    canManageFundingSources: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        5
      );
    },
    canManageBudgets: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        6
      );
    },
    canManageForms: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        7
      );
    },
    canManageOrganizationCRM: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        8
      );
    },
    canManageEmails: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        9
      );
    },
    canManageTags: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        10
      );
    },
    canManageWorkflowAutomation: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        11
      );
    },
    canManageInKind: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        12
      );
    },
    canManageInvitations: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        13
      );
    },
    canManageDocumentTemplates: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        14
      );
    },
    canViewResourceCenterLinks: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        15
      );
    },
    canManageSFTPConnections: () => {
      return this.isAuthorized(
        this.system.permissionSetType,
        this.system.allPermissionType,
        16
      );
    },
    canDownloadDataFeeds: () => {
      // TODO: wire up or remove pending implementation of YC SFTP
      return false;
    }
  };

  insights = {
    permissionSetType: 6,
    allPermissionType: 0, // There is no all permission type for insights
    canViewPrograms: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        1
      );
    },
    canViewOrganizations: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        4
      );
    },
    canViewApplicants: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        5
      );
    },
    canViewBudgets: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        2
      );
    },
    canViewFundingSources: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        3
      );
    },
    canViewAwards: () => {
      return this.isAuthorized(
        this.insights.permissionSetType,
        this.insights.allPermissionType,
        6
      );
    }
  };

  grantProgram = {
    permissionSetType: 2,
    allPermissionType: 1,
    canManagePrograms: () => {
      return this.isAuthorized(
        this.grantProgram.permissionSetType,
        this.grantProgram.allPermissionType,
        2
      );
    }
  };

  processing = {
    permissionSetType: 5,
    allPermissionType: 1,
    canManageProcessing: () => {
      return this.isAuthorized(
        this.processing.permissionSetType,
        this.processing.allPermissionType,
        1
      );
    },
    canViewDisbursementReport: () => {
      return this.isAuthorized(
        this.processing.permissionSetType,
        null, // the All permission doesn't count towards this b/c of segmentation
        2
      );
    }
  };

  grantApplication = {
    permissionSetType: 3,
    allPermissionType: 1,
    canManageAllApplications: () => {
      return this.isAuthorized(
        this.grantApplication.permissionSetType,
        this.grantApplication.allPermissionType,
        1
      );
    },
    canManageApplicationsInWorkFlows: () => {
      return this.userService.user &&
        this.userService.user.workflowManager;
    },
    canCreateOrEditApplications: () => {
      return this.isAuthorized(
        this.grantApplication.permissionSetType,
        this.grantApplication.allPermissionType,
        3
      );
    },
    canSeeMaskedApplicants: () => {
      return this.isAuthorized(
        this.grantApplication.permissionSetType,
        this.grantApplication.allPermissionType,
        4
      );
    },
    canTakeActionsOnAllApps: () => {
      return this.isAuthorized(
        this.grantApplication.permissionSetType,
        this.grantApplication.allPermissionType,
        6
      );
    },
    canAccessApplicationManager: () => {
      return this.grantApplication.canManageAllApplications() ||
        this.grantApplication.canManageApplicationsInWorkFlows() ||
        this.grantApplication.canTakeActionsOnAllApps();
    }
  };

  dataExport = {
    permissionSetType: 4,
    allPermissionType: 1,
    canPullProgramSummaryExport: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        2
      );
    },
    canPullPaymentExport: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        3
      );
    },
    canPullBudgetExport: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        4
      );
    },
    canPullFormExport: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        5
      );
    },
    canPullApplicationDetails: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        6
      );
    },
    canPullAwardExport: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        7
      );
    },
    canExportAllData: () => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        8
      );
    },
    canManageAdHoc: (
      user: Partial<User> = this.userService.user
    ) => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        9,
        false,
        user
      );
    },
    canManageDashboards: (
      user: Partial<User> = this.userService.user
    ) => {
      return this.isAuthorized(
        this.dataExport.permissionSetType,
        this.dataExport.allPermissionType,
        10,
        false,
        user
      );
    }
  };

  canManageGrantApplications () {
    return this.grantApplication.canManageAllApplications() ||
      this.grantApplication.canTakeActionsOnAllApps();
  }

  private isAuthorized (
    permissionSetType: number,
    allPermissionType: number,
    permissionType: number,
    permissionTypeOnly = false, // Only check if they have permissionType, not all or root user, like view only applications.,
    user: Partial<User> = this.userService.user
  ) {
    if (user && user.isRootUser && !permissionTypeOnly) {
      return true;
    }
    if (user && (user.roles == null || user.roles.length === 0)) {
      return false;
    }

    const rolePolicies = this.getPoliciesBy(user, permissionSetType);
    let isValid = false;
    rolePolicies.forEach(rolePolicy => {
      // for each of the group of role policies
      rolePolicy.policies.forEach((policy: any) => {
        // for each of the policies in each group
        if (permissionTypeOnly) {
          // if we are only checking permissionType
          if (
            policy.permissionSetType === permissionSetType &&
            policy.permissionType === permissionType &&
            policy.allow &&
            !this.hasDenyPolicy(
              rolePolicy.policies,
              permissionSetType,
              permissionType
            )
          ) {
            isValid = true;
          }
        } else {
          if (
            // if the user has all permission type, we need to check against denied policies
            policy.permissionSetType === permissionSetType &&
            policy.permissionType === allPermissionType &&
            policy.allow === true &&
            !this.hasDenyPolicy(
              rolePolicy.policies,
              permissionSetType,
              permissionType
            )
          ) {
            isValid = true;

            return;
          }
          if (
            policy.permissionSetType === permissionSetType &&
            policy.permissionType === permissionType &&
            policy.allow === true
          ) {
            isValid = true;

            return;
          }
        }
      });
    });

    return isValid;
  }

  private getPoliciesBy (user: any, permissionSetType: any) {
    const rolePolicies = [];
    if (user && user.roles) {
      for (const role of user.roles) {
        // for each of the roles a user has
        let isPermissionSetTypeInPolicy = false;
        role.policies.forEach((policy: any) => {
          // for each of the policies within that role
          if (policy.permissionSetType === permissionSetType) {
            // if the policy permission set type matches what we've passed in
            isPermissionSetTypeInPolicy = true;
            // the permission set type is in the policy
          }
        });
        if (isPermissionSetTypeInPolicy) {
          // if it's in the policy
          rolePolicies.push(role);
          // we push it into an aray of role policies
        }
      }
    }

    return rolePolicies;
  }

  private hasDenyPolicy (
    policies: any[],
    permissionSetType: number,
    permissionType: number
  ) {
    for (const policy of policies) {
      if (
        !policy.allow &&
        policy.permissionSetType === permissionSetType &&
        policy.permissionType === permissionType
      ) {
        return true;
      }
    }

    return false;
  }
}
