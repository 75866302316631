export default [
  {
    "countryId": 1,
    "countryCode": "AF",
    "countryName": "Afghanistan",
    "regions": [
      {
        "regionId": 17,
        "regionName": "Badakhshan"
      },
      {
        "regionId": 18,
        "regionName": "Badgis"
      },
      {
        "regionId": 19,
        "regionName": "Baglan"
      },
      {
        "regionId": 20,
        "regionName": "Balkh"
      },
      {
        "regionId": 21,
        "regionName": "Bamiyan"
      },
      {
        "regionId": 22,
        "regionName": "Farah"
      },
      {
        "regionId": 23,
        "regionName": "Faryab"
      },
      {
        "regionId": 24,
        "regionName": "Gawr"
      },
      {
        "regionId": 25,
        "regionName": "Gazni"
      },
      {
        "regionId": 26,
        "regionName": "Herat"
      },
      {
        "regionId": 27,
        "regionName": "Hilmand"
      },
      {
        "regionId": 28,
        "regionName": "Jawzjan"
      },
      {
        "regionId": 29,
        "regionName": "Kabul"
      },
      {
        "regionId": 30,
        "regionName": "Kapisa"
      },
      {
        "regionId": 31,
        "regionName": "Khawst"
      },
      {
        "regionId": 32,
        "regionName": "Kunar"
      },
      {
        "regionId": 33,
        "regionName": "Lagman"
      },
      {
        "regionId": 34,
        "regionName": "Lawghar"
      },
      {
        "regionId": 35,
        "regionName": "Nangarhar"
      },
      {
        "regionId": 36,
        "regionName": "Nimruz"
      },
      {
        "regionId": 37,
        "regionName": "Nuristan"
      },
      {
        "regionId": 38,
        "regionName": "Paktika"
      },
      {
        "regionId": 39,
        "regionName": "Paktiya"
      },
      {
        "regionId": 40,
        "regionName": "Parwan"
      },
      {
        "regionId": 41,
        "regionName": "Qandahar"
      },
      {
        "regionId": 42,
        "regionName": "Qunduz"
      },
      {
        "regionId": 43,
        "regionName": "Samangan"
      },
      {
        "regionId": 44,
        "regionName": "Sar-e Pul"
      },
      {
        "regionId": 45,
        "regionName": "Takhar"
      },
      {
        "regionId": 46,
        "regionName": "Uruzgan"
      },
      {
        "regionId": 47,
        "regionName": "Wardag"
      },
      {
        "regionId": 48,
        "regionName": "Zabul"
      }
    ]
  },
  {
    "countryId": 2,
    "countryCode": "AX",
    "countryName": "Aland",
    "regions": []
  },
  {
    "countryId": 3,
    "countryCode": "AL",
    "countryName": "Albania",
    "regions": [
      {
        "regionId": 57,
        "regionName": "Berat"
      },
      {
        "regionId": 58,
        "regionName": "Bulqize"
      },
      {
        "regionId": 59,
        "regionName": "Delvine"
      },
      {
        "regionId": 60,
        "regionName": "Devoll"
      },
      {
        "regionId": 61,
        "regionName": "Dibre"
      },
      {
        "regionId": 62,
        "regionName": "Durres"
      },
      {
        "regionId": 63,
        "regionName": "Elbasan"
      },
      {
        "regionId": 64,
        "regionName": "Fier"
      },
      {
        "regionId": 65,
        "regionName": "Gjirokaster"
      },
      {
        "regionId": 66,
        "regionName": "Gramsh"
      },
      {
        "regionId": 67,
        "regionName": "Has"
      },
      {
        "regionId": 68,
        "regionName": "Kavaje"
      },
      {
        "regionId": 69,
        "regionName": "Kolonje"
      },
      {
        "regionId": 70,
        "regionName": "Korce"
      },
      {
        "regionId": 71,
        "regionName": "Kruje"
      },
      {
        "regionId": 72,
        "regionName": "Kucove"
      },
      {
        "regionId": 73,
        "regionName": "Kukes"
      },
      {
        "regionId": 74,
        "regionName": "Kurbin"
      },
      {
        "regionId": 75,
        "regionName": "Lezhe"
      },
      {
        "regionId": 76,
        "regionName": "Librazhd"
      },
      {
        "regionId": 77,
        "regionName": "Lushnje"
      },
      {
        "regionId": 78,
        "regionName": "Mallakaster"
      },
      {
        "regionId": 79,
        "regionName": "Malsi e Madhe"
      },
      {
        "regionId": 80,
        "regionName": "Mat"
      },
      {
        "regionId": 81,
        "regionName": "Mirdite"
      },
      {
        "regionId": 82,
        "regionName": "Peqin"
      },
      {
        "regionId": 83,
        "regionName": "Permet"
      },
      {
        "regionId": 84,
        "regionName": "Pogradec"
      },
      {
        "regionId": 85,
        "regionName": "Puke"
      },
      {
        "regionId": 86,
        "regionName": "Sarande"
      },
      {
        "regionId": 87,
        "regionName": "Shkoder"
      },
      {
        "regionId": 88,
        "regionName": "Skrapar"
      },
      {
        "regionId": 89,
        "regionName": "Tepelene"
      },
      {
        "regionId": 90,
        "regionName": "Tirane"
      },
      {
        "regionId": 91,
        "regionName": "Tropoje"
      },
      {
        "regionId": 92,
        "regionName": "Vlore"
      }
    ]
  },
  {
    "countryId": 4,
    "countryCode": "DZ",
    "countryName": "Algeria",
    "regions": [
      {
        "regionId": 931,
        "regionName": "Adrar"
      },
      {
        "regionId": 932,
        "regionName": "al-Aghwat"
      },
      {
        "regionId": 933,
        "regionName": "al-Bayadh"
      },
      {
        "regionId": 934,
        "regionName": "Algiers"
      },
      {
        "regionId": 935,
        "regionName": "al-Jaza'ir"
      },
      {
        "regionId": 936,
        "regionName": "al-Wad"
      },
      {
        "regionId": 937,
        "regionName": "Annabah"
      },
      {
        "regionId": 938,
        "regionName": "ash-Shalif"
      },
      {
        "regionId": 939,
        "regionName": "at-Tarif"
      },
      {
        "regionId": 940,
        "regionName": "'Ayn Daflah"
      },
      {
        "regionId": 941,
        "regionName": "'Ayn Tamushanat"
      },
      {
        "regionId": 942,
        "regionName": "Bashshar"
      },
      {
        "regionId": 943,
        "regionName": "Batnah"
      },
      {
        "regionId": 944,
        "regionName": "Bijayah"
      },
      {
        "regionId": 945,
        "regionName": "Biskrah"
      },
      {
        "regionId": 946,
        "regionName": "Blidah"
      },
      {
        "regionId": 947,
        "regionName": "Buirah"
      },
      {
        "regionId": 948,
        "regionName": "Bumardas"
      },
      {
        "regionId": 949,
        "regionName": "Burj Bu Arririj"
      },
      {
        "regionId": 950,
        "regionName": "Ghalizan"
      },
      {
        "regionId": 951,
        "regionName": "Ghardayah"
      },
      {
        "regionId": 952,
        "regionName": "Ilizi"
      },
      {
        "regionId": 953,
        "regionName": "Jijili"
      },
      {
        "regionId": 954,
        "regionName": "Jilfah"
      },
      {
        "regionId": 955,
        "regionName": "Khanshalah"
      },
      {
        "regionId": 956,
        "regionName": "Masilah"
      },
      {
        "regionId": 957,
        "regionName": "Midyah"
      },
      {
        "regionId": 958,
        "regionName": "Milah"
      },
      {
        "regionId": 959,
        "regionName": "Muaskar"
      },
      {
        "regionId": 960,
        "regionName": "Mustaghanam"
      },
      {
        "regionId": 961,
        "regionName": "Naama"
      },
      {
        "regionId": 962,
        "regionName": "Oran"
      },
      {
        "regionId": 963,
        "regionName": "Ouargla"
      },
      {
        "regionId": 964,
        "regionName": "Qalmah"
      },
      {
        "regionId": 965,
        "regionName": "Qustantinah"
      },
      {
        "regionId": 966,
        "regionName": "Sakikdah"
      },
      {
        "regionId": 967,
        "regionName": "Satif"
      },
      {
        "regionId": 968,
        "regionName": "Sayda'"
      },
      {
        "regionId": 969,
        "regionName": "Sidi ban-al-'Abbas"
      },
      {
        "regionId": 970,
        "regionName": "Suq Ahras"
      },
      {
        "regionId": 971,
        "regionName": "Tamanghasat"
      },
      {
        "regionId": 972,
        "regionName": "Tibazah"
      },
      {
        "regionId": 973,
        "regionName": "Tibissah"
      },
      {
        "regionId": 974,
        "regionName": "Tilimsan"
      },
      {
        "regionId": 975,
        "regionName": "Tinduf"
      },
      {
        "regionId": 976,
        "regionName": "Tisamsilt"
      },
      {
        "regionId": 977,
        "regionName": "Tiyarat"
      },
      {
        "regionId": 978,
        "regionName": "Tizi Wazu"
      },
      {
        "regionId": 979,
        "regionName": "Umm-al-Bawaghi"
      },
      {
        "regionId": 980,
        "regionName": "Wahran"
      },
      {
        "regionId": 981,
        "regionName": "Warqla"
      },
      {
        "regionId": 982,
        "regionName": "Wilaya d Alger"
      },
      {
        "regionId": 983,
        "regionName": "Wilaya de Bejaia"
      },
      {
        "regionId": 984,
        "regionName": "Wilaya de Constantine"
      }
    ]
  },
  {
    "countryId": 5,
    "countryCode": "AS",
    "countryName": "American Samoa",
    "regions": [
      {
        "regionId": 159,
        "regionName": "Eastern"
      },
      {
        "regionId": 160,
        "regionName": "Manu'a"
      },
      {
        "regionId": 161,
        "regionName": "Swains Island"
      },
      {
        "regionId": 162,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 6,
    "countryCode": "AD",
    "countryName": "Andorra",
    "regions": [
      {
        "regionId": 1,
        "regionCode": "07",
        "regionName": "Andorra la Vella"
      },
      {
        "regionId": 2,
        "regionCode": "02",
        "regionName": "Canillo"
      },
      {
        "regionId": 3,
        "regionCode": "03",
        "regionName": "Encamp"
      },
      {
        "regionId": 4,
        "regionCode": "04",
        "regionName": "La Massana"
      },
      {
        "regionId": 5,
        "regionName": "Les Escaldes"
      },
      {
        "regionId": 6,
        "regionCode": "05",
        "regionName": "Ordino"
      },
      {
        "regionId": 7,
        "regionCode": "06",
        "regionName": "Sant Julia de Loria"
      }
    ]
  },
  {
    "countryId": 7,
    "countryCode": "AO",
    "countryName": "Angola",
    "regions": [
      {
        "regionId": 110,
        "regionName": "Bengo"
      },
      {
        "regionId": 111,
        "regionName": "Benguela"
      },
      {
        "regionId": 112,
        "regionName": "Bie"
      },
      {
        "regionId": 113,
        "regionName": "Cabinda"
      },
      {
        "regionId": 114,
        "regionName": "Cunene"
      },
      {
        "regionId": 115,
        "regionName": "Huambo"
      },
      {
        "regionId": 116,
        "regionName": "Huila"
      },
      {
        "regionId": 117,
        "regionName": "Kuando-Kubango"
      },
      {
        "regionId": 118,
        "regionName": "Kwanza Norte"
      },
      {
        "regionId": 119,
        "regionName": "Kwanza Sul"
      },
      {
        "regionId": 120,
        "regionName": "Luanda"
      },
      {
        "regionId": 121,
        "regionName": "Lunda Norte"
      },
      {
        "regionId": 122,
        "regionName": "Lunda Sul"
      },
      {
        "regionId": 123,
        "regionName": "Malanje"
      },
      {
        "regionId": 124,
        "regionName": "Moxico"
      },
      {
        "regionId": 125,
        "regionName": "Namibe"
      },
      {
        "regionId": 126,
        "regionName": "Uige"
      },
      {
        "regionId": 127,
        "regionName": "Zaire"
      }
    ]
  },
  {
    "countryId": 8,
    "countryCode": "AI",
    "countryName": "Anguilla",
    "regions": [
      {
        "regionId": 56,
        "regionName": "Other Provinces"
      }
    ]
  },
  {
    "countryId": 9,
    "countryCode": "AQ",
    "countryName": "Antarctica",
    "regions": [
      {
        "regionId": 128,
        "regionName": "Sector claimed by Argentina/Ch"
      },
      {
        "regionId": 129,
        "regionName": "Sector claimed by Argentina/UK"
      },
      {
        "regionId": 130,
        "regionName": "Sector claimed by Australia"
      },
      {
        "regionId": 131,
        "regionName": "Sector claimed by France"
      },
      {
        "regionId": 132,
        "regionName": "Sector claimed by New Zealand"
      },
      {
        "regionId": 133,
        "regionName": "Sector claimed by Norway"
      },
      {
        "regionId": 134,
        "regionName": "Unclaimed Sector"
      }
    ]
  },
  {
    "countryId": 10,
    "countryCode": "AG",
    "countryName": "Antigua",
    "regions": [
      {
        "regionId": 49,
        "regionName": "Barbuda"
      },
      {
        "regionId": 50,
        "regionName": "Saint George"
      },
      {
        "regionId": 51,
        "regionName": "Saint John"
      },
      {
        "regionId": 52,
        "regionName": "Saint Mary"
      },
      {
        "regionId": 53,
        "regionName": "Saint Paul"
      },
      {
        "regionId": 54,
        "regionName": "Saint Peter"
      },
      {
        "regionId": 55,
        "regionName": "Saint Philip"
      }
    ]
  },
  {
    "countryId": 11,
    "countryCode": "AR",
    "countryName": "Argentina",
    "regions": [
      {
        "regionId": 135,
        "regionCode": "01",
        "regionName": "Buenos Aires"
      },
      {
        "regionId": 136,
        "regionCode": "02",
        "regionName": "Catamarca"
      },
      {
        "regionId": 137,
        "regionCode": "16",
        "regionName": "Chaco"
      },
      {
        "regionId": 138,
        "regionCode": "17",
        "regionName": "Chubut"
      },
      {
        "regionId": 139,
        "regionCode": "X",
        "regionName": "Cordoba"
      },
      {
        "regionId": 140,
        "regionCode": "04",
        "regionName": "Corrientes"
      },
      {
        "regionId": 141,
        "regionName": "Distrito Federal"
      },
      {
        "regionId": 142,
        "regionCode": "05",
        "regionName": "Entre Rios"
      },
      {
        "regionId": 143,
        "regionCode": "18",
        "regionName": "Formosa"
      },
      {
        "regionId": 144,
        "regionCode": "06",
        "regionName": "Jujuy"
      },
      {
        "regionId": 145,
        "regionCode": "21",
        "regionName": "La Pampa"
      },
      {
        "regionId": 146,
        "regionCode": "08",
        "regionName": "La Rioja"
      },
      {
        "regionId": 147,
        "regionCode": "07",
        "regionName": "Mendoza"
      },
      {
        "regionId": 148,
        "regionCode": "19",
        "regionName": "Misiones"
      },
      {
        "regionId": 149,
        "regionCode": "20",
        "regionName": "Neuquen"
      },
      {
        "regionId": 150,
        "regionCode": "22",
        "regionName": "Rio Negro"
      },
      {
        "regionId": 151,
        "regionCode": "09",
        "regionName": "Salta"
      },
      {
        "regionId": 152,
        "regionCode": "10",
        "regionName": "San Juan"
      },
      {
        "regionId": 153,
        "regionCode": "11",
        "regionName": "San Luis"
      },
      {
        "regionId": 154,
        "regionCode": "23",
        "regionName": "Santa Cruz"
      },
      {
        "regionId": 155,
        "regionCode": "S",
        "regionName": "Santa Fe"
      },
      {
        "regionId": 156,
        "regionCode": "13",
        "regionName": "Santiago del Estero"
      },
      {
        "regionId": 157,
        "regionCode": "V",
        "regionName": "Tierra del Fuego"
      },
      {
        "regionId": 158,
        "regionCode": "T",
        "regionName": "Tucuman"
      }
    ]
  },
  {
    "countryId": 12,
    "countryCode": "AM",
    "countryName": "Armenia",
    "regions": [
      {
        "regionId": 93,
        "regionCode": "AG",
        "regionName": "Aragatsotn"
      },
      {
        "regionId": 94,
        "regionCode": "AR",
        "regionName": "Ararat"
      },
      {
        "regionId": 95,
        "regionCode": "AV",
        "regionName": "Armavir"
      },
      {
        "regionId": 96,
        "regionCode": "GR",
        "regionName": "Gegharkunik"
      },
      {
        "regionId": 97,
        "regionName": "Kotaik"
      },
      {
        "regionId": 98,
        "regionCode": "LO",
        "regionName": "Lori"
      },
      {
        "regionId": 99,
        "regionCode": "SH",
        "regionName": "Shirak"
      },
      {
        "regionId": 100,
        "regionName": "Stepanakert"
      },
      {
        "regionId": 101,
        "regionCode": "SU",
        "regionName": "Syunik"
      },
      {
        "regionId": 102,
        "regionCode": "TV",
        "regionName": "Tavush"
      },
      {
        "regionId": 103,
        "regionCode": "VD",
        "regionName": "Vayots Dzor"
      },
      {
        "regionId": 104,
        "regionCode": "ER",
        "regionName": "Yerevan"
      }
    ]
  },
  {
    "countryId": 13,
    "countryCode": "AW",
    "countryName": "Aruba",
    "regions": [
      {
        "regionId": 213,
        "regionName": "Aruba"
      }
    ]
  },
  {
    "countryId": 14,
    "countryCode": "AC",
    "countryName": "Ascension",
    "regions": []
  },
  {
    "countryId": 15,
    "countryCode": "AU",
    "countryName": "Australia",
    "regions": [
      {
        "regionId": 182,
        "regionCode": "ACT",
        "regionName": "Australian Capital Territory"
      },
      {
        "regionId": 202,
        "regionCode": "NSW",
        "regionName": "New South Wales"
      },
      {
        "regionId": 203,
        "regionCode": "NT",
        "regionName": "Northern Territory"
      },
      {
        "regionId": 205,
        "regionCode": "QLD",
        "regionName": "Queensland"
      },
      {
        "regionId": 206,
        "regionCode": "SA",
        "regionName": "South Australia"
      },
      {
        "regionId": 207,
        "regionCode": "TAS",
        "regionName": "Tasmania"
      },
      {
        "regionId": 209,
        "regionCode": "VIC",
        "regionName": "Victoria"
      },
      {
        "regionId": 211,
        "regionCode": "WA",
        "regionName": "Western Australia"
      }
    ]
  },
  {
    "countryId": 16,
    "countryCode": "AT",
    "countryName": "Austria",
    "regions": [
      {
        "regionId": 163,
        "regionName": "Bundesland Salzburg"
      },
      {
        "regionId": 164,
        "regionName": "Bundesland Steiermark"
      },
      {
        "regionId": 165,
        "regionName": "Bundesland Tirol"
      },
      {
        "regionId": 166,
        "regionCode": "B",
        "regionName": "Burgenland"
      },
      {
        "regionId": 167,
        "regionCode": "K",
        "regionName": "Carinthia"
      },
      {
        "regionId": 168,
        "regionName": "Karnten"
      },
      {
        "regionId": 169,
        "regionName": "Liezen"
      },
      {
        "regionId": 170,
        "regionCode": "NÖ",
        "regionName": "Lower Austria"
      },
      {
        "regionId": 171,
        "regionName": "Niederosterreich"
      },
      {
        "regionId": 172,
        "regionName": "Oberosterreich"
      },
      {
        "regionId": 173,
        "regionCode": "S",
        "regionName": "Salzburg"
      },
      {
        "regionId": 174,
        "regionName": "Schleswig-Holstein"
      },
      {
        "regionId": 175,
        "regionName": "Steiermark"
      },
      {
        "regionId": 176,
        "regionCode": "ST",
        "regionName": "Styria"
      },
      {
        "regionId": 177,
        "regionName": "Tirol"
      },
      {
        "regionId": 178,
        "regionCode": "OÖ",
        "regionName": "Upper Austria"
      },
      {
        "regionId": 179,
        "regionCode": "V",
        "regionName": "Vorarlberg"
      },
      {
        "regionId": 180,
        "regionName": "Wien"
      }
    ]
  },
  {
    "countryId": 17,
    "countryCode": "AZ",
    "countryName": "Azerbaijan",
    "regions": [
      {
        "regionId": 214,
        "regionName": "Abseron"
      },
      {
        "regionId": 215,
        "regionName": "Baki Sahari"
      },
      {
        "regionId": 216,
        "regionName": "Ganca"
      },
      {
        "regionId": 217,
        "regionName": "Ganja"
      },
      {
        "regionId": 218,
        "regionName": "Kalbacar"
      },
      {
        "regionId": 219,
        "regionName": "Lankaran"
      },
      {
        "regionId": 220,
        "regionName": "Mil-Qarabax"
      },
      {
        "regionId": 221,
        "regionName": "Mugan-Salyan"
      },
      {
        "regionId": 222,
        "regionName": "Nagorni-Qarabax"
      },
      {
        "regionId": 223,
        "regionName": "Naxcivan"
      },
      {
        "regionId": 224,
        "regionName": "Priaraks"
      },
      {
        "regionId": 225,
        "regionName": "Qazax"
      },
      {
        "regionId": 226,
        "regionName": "Saki"
      },
      {
        "regionId": 227,
        "regionName": "Sirvan"
      },
      {
        "regionId": 228,
        "regionName": "Xacmaz"
      }
    ]
  },
  {
    "countryId": 18,
    "countryCode": "BS",
    "countryName": "Bahamas",
    "regions": [
      {
        "regionId": 473,
        "regionName": "Abaco"
      },
      {
        "regionId": 474,
        "regionName": "Acklins Island"
      },
      {
        "regionId": 475,
        "regionName": "Andros"
      },
      {
        "regionId": 476,
        "regionCode": "BY",
        "regionName": "Berry Islands"
      },
      {
        "regionId": 477,
        "regionName": "Biminis"
      },
      {
        "regionId": 478,
        "regionCode": "CI",
        "regionName": "Cat Island"
      },
      {
        "regionId": 479,
        "regionName": "Crooked Island"
      },
      {
        "regionId": 480,
        "regionName": "Eleuthera"
      },
      {
        "regionId": 481,
        "regionCode": "EX",
        "regionName": "Exuma and Cays"
      },
      {
        "regionId": 482,
        "regionName": "Grand Bahama"
      },
      {
        "regionId": 483,
        "regionName": "Inagua Islands"
      },
      {
        "regionId": 484,
        "regionCode": "LI",
        "regionName": "Long Island"
      },
      {
        "regionId": 485,
        "regionCode": "MG",
        "regionName": "Mayaguana"
      },
      {
        "regionId": 486,
        "regionName": "New Providence"
      },
      {
        "regionId": 487,
        "regionCode": "RI",
        "regionName": "Ragged Island"
      },
      {
        "regionId": 488,
        "regionCode": "RC",
        "regionName": "Rum Cay"
      },
      {
        "regionId": 489,
        "regionCode": "SS",
        "regionName": "San Salvador"
      }
    ]
  },
  {
    "countryId": 19,
    "countryCode": "BH",
    "countryName": "Bahrain",
    "regions": [
      {
        "regionId": 393,
        "regionName": "al-Manamah"
      },
      {
        "regionId": 394,
        "regionName": "al-Muharraq"
      },
      {
        "regionId": 395,
        "regionName": "ar-Rifa'a"
      },
      {
        "regionId": 396,
        "regionName": "Badiyah"
      },
      {
        "regionId": 397,
        "regionName": "Hidd"
      },
      {
        "regionId": 398,
        "regionName": "'Isa"
      },
      {
        "regionId": 399,
        "regionName": "Jidd Hafs"
      },
      {
        "regionId": 400,
        "regionName": "Mahama"
      },
      {
        "regionId": 401,
        "regionName": "Manama"
      },
      {
        "regionId": 402,
        "regionName": "Sitrah"
      }
    ]
  },
  {
    "countryId": 20,
    "countryCode": "BD",
    "countryName": "Bangladesh",
    "regions": [
      {
        "regionId": 242,
        "regionName": "Bagar Hat"
      },
      {
        "regionId": 243,
        "regionName": "Bandarban"
      },
      {
        "regionId": 244,
        "regionName": "Barguna"
      },
      {
        "regionId": 245,
        "regionName": "Barisal"
      },
      {
        "regionId": 246,
        "regionName": "Bhola"
      },
      {
        "regionId": 247,
        "regionName": "Bogora"
      },
      {
        "regionId": 248,
        "regionName": "Brahman Bariya"
      },
      {
        "regionId": 249,
        "regionName": "Chandpur"
      },
      {
        "regionId": 250,
        "regionName": "Chattagam"
      },
      {
        "regionId": 251,
        "regionName": "Chittagong Division"
      },
      {
        "regionId": 252,
        "regionName": "Chuadanga"
      },
      {
        "regionId": 253,
        "regionName": "Dhaka"
      },
      {
        "regionId": 254,
        "regionName": "Dinajpur"
      },
      {
        "regionId": 255,
        "regionName": "Faridpur"
      },
      {
        "regionId": 256,
        "regionName": "Feni"
      },
      {
        "regionId": 257,
        "regionName": "Gaybanda"
      },
      {
        "regionId": 258,
        "regionName": "Gazipur"
      },
      {
        "regionId": 259,
        "regionName": "Gopalganj"
      },
      {
        "regionId": 260,
        "regionName": "Habiganj"
      },
      {
        "regionId": 261,
        "regionName": "Jaipur Hat"
      },
      {
        "regionId": 262,
        "regionName": "Jamalpur"
      },
      {
        "regionId": 263,
        "regionName": "Jessor"
      },
      {
        "regionId": 264,
        "regionName": "Jhalakati"
      },
      {
        "regionId": 265,
        "regionName": "Jhanaydah"
      },
      {
        "regionId": 266,
        "regionName": "Khagrachhari"
      },
      {
        "regionId": 267,
        "regionName": "Khulna"
      },
      {
        "regionId": 268,
        "regionName": "Kishorganj"
      },
      {
        "regionId": 269,
        "regionName": "Koks Bazar"
      },
      {
        "regionId": 270,
        "regionName": "Komilla"
      },
      {
        "regionId": 271,
        "regionName": "Kurigram"
      },
      {
        "regionId": 272,
        "regionName": "Kushtiya"
      },
      {
        "regionId": 273,
        "regionName": "Lakshmipur"
      },
      {
        "regionId": 274,
        "regionName": "Lalmanir Hat"
      },
      {
        "regionId": 275,
        "regionName": "Madaripur"
      },
      {
        "regionId": 276,
        "regionName": "Magura"
      },
      {
        "regionId": 277,
        "regionName": "Maimansingh"
      },
      {
        "regionId": 278,
        "regionName": "Manikganj"
      },
      {
        "regionId": 279,
        "regionName": "Maulvi Bazar"
      },
      {
        "regionId": 280,
        "regionName": "Meherpur"
      },
      {
        "regionId": 281,
        "regionName": "Munshiganj"
      },
      {
        "regionId": 282,
        "regionName": "Naral"
      },
      {
        "regionId": 283,
        "regionName": "Narayanganj"
      },
      {
        "regionId": 284,
        "regionName": "Narsingdi"
      },
      {
        "regionId": 285,
        "regionName": "Nator"
      },
      {
        "regionId": 286,
        "regionName": "Naugaon"
      },
      {
        "regionId": 287,
        "regionName": "Nawabganj"
      },
      {
        "regionId": 288,
        "regionName": "Netrakona"
      },
      {
        "regionId": 289,
        "regionName": "Nilphamari"
      },
      {
        "regionId": 290,
        "regionName": "Noakhali"
      },
      {
        "regionId": 291,
        "regionName": "Pabna"
      },
      {
        "regionId": 292,
        "regionName": "Panchagarh"
      },
      {
        "regionId": 293,
        "regionName": "Patuakhali"
      },
      {
        "regionId": 294,
        "regionName": "Pirojpur"
      },
      {
        "regionId": 295,
        "regionName": "Rajbari"
      },
      {
        "regionId": 296,
        "regionName": "Rajshahi"
      },
      {
        "regionId": 297,
        "regionName": "Rangamati"
      },
      {
        "regionId": 298,
        "regionName": "Rangpur"
      },
      {
        "regionId": 299,
        "regionName": "Satkhira"
      },
      {
        "regionId": 300,
        "regionName": "Shariatpur"
      },
      {
        "regionId": 301,
        "regionName": "Sherpur"
      },
      {
        "regionId": 302,
        "regionName": "Silhat"
      },
      {
        "regionId": 303,
        "regionName": "Sirajganj"
      },
      {
        "regionId": 304,
        "regionName": "Sunamganj"
      },
      {
        "regionId": 305,
        "regionName": "Tangayal"
      },
      {
        "regionId": 306,
        "regionName": "Thakurgaon"
      }
    ]
  },
  {
    "countryId": 21,
    "countryCode": "BB",
    "countryName": "Barbados",
    "regions": [
      {
        "regionId": 231,
        "regionName": "Christ Church"
      },
      {
        "regionId": 232,
        "regionName": "Saint Andrew"
      },
      {
        "regionId": 233,
        "regionName": "Saint George"
      },
      {
        "regionId": 234,
        "regionName": "Saint James"
      },
      {
        "regionId": 235,
        "regionName": "Saint John"
      },
      {
        "regionId": 236,
        "regionName": "Saint Joseph"
      },
      {
        "regionId": 237,
        "regionName": "Saint Lucy"
      },
      {
        "regionId": 238,
        "regionName": "Saint Michael"
      },
      {
        "regionId": 239,
        "regionName": "Saint Peter"
      },
      {
        "regionId": 240,
        "regionName": "Saint Philip"
      },
      {
        "regionId": 241,
        "regionName": "Saint Thomas"
      }
    ]
  },
  {
    "countryId": 22,
    "countryCode": "BY",
    "countryName": "Belarus",
    "regions": [
      {
        "regionId": 532,
        "regionName": "Brest"
      },
      {
        "regionId": 533,
        "regionName": "Homjel'"
      },
      {
        "regionId": 534,
        "regionName": "Hrodna"
      },
      {
        "regionId": 535,
        "regionName": "Mahiljow"
      },
      {
        "regionId": 536,
        "regionName": "Mahilyowskaya Voblasts"
      },
      {
        "regionId": 537,
        "regionName": "Minsk"
      },
      {
        "regionId": 538,
        "regionName": "Minskaja Voblasts'"
      },
      {
        "regionId": 539,
        "regionName": "Petrik"
      },
      {
        "regionId": 540,
        "regionName": "Vicebsk"
      }
    ]
  },
  {
    "countryId": 23,
    "countryCode": "BE",
    "countryName": "Belgium",
    "regions": [
      {
        "regionId": 307,
        "regionName": "Antwerpen"
      },
      {
        "regionId": 308,
        "regionName": "Berchem"
      },
      {
        "regionId": 309,
        "regionName": "Brabant"
      },
      {
        "regionId": 310,
        "regionName": "Brabant Wallon"
      },
      {
        "regionId": 311,
        "regionName": "Brussel"
      },
      {
        "regionId": 312,
        "regionName": "East Flanders"
      },
      {
        "regionId": 313,
        "regionCode": "03",
        "regionName": "Hainaut"
      },
      {
        "regionId": 314,
        "regionCode": "04",
        "regionName": "Liege"
      },
      {
        "regionId": 315,
        "regionCode": "05",
        "regionName": "Limburg"
      },
      {
        "regionId": 316,
        "regionCode": "06",
        "regionName": "Luxembourg"
      },
      {
        "regionId": 317,
        "regionCode": "07",
        "regionName": "Namur"
      },
      {
        "regionId": 318,
        "regionName": "Ontario"
      },
      {
        "regionId": 319,
        "regionCode": "08",
        "regionName": "Oost-Vlaanderen"
      },
      {
        "regionId": 320,
        "regionName": "Provincie Brabant"
      },
      {
        "regionId": 321,
        "regionName": "Vlaams-Brabant"
      },
      {
        "regionId": 322,
        "regionName": "Wallonne"
      },
      {
        "regionId": 323,
        "regionCode": "09",
        "regionName": "West-Vlaanderen"
      }
    ]
  },
  {
    "countryId": 24,
    "countryCode": "BZ",
    "countryName": "Belize",
    "regions": [
      {
        "regionId": 541,
        "regionCode": "BZ",
        "regionName": "Belize"
      },
      {
        "regionId": 542,
        "regionCode": "CY",
        "regionName": "Cayo"
      },
      {
        "regionId": 543,
        "regionCode": "CZ",
        "regionName": "Corozal"
      },
      {
        "regionId": 544,
        "regionCode": "OW",
        "regionName": "Orange Walk"
      },
      {
        "regionId": 545,
        "regionCode": "SC",
        "regionName": "Stann Creek"
      },
      {
        "regionId": 546,
        "regionCode": "TO",
        "regionName": "Toledo"
      }
    ]
  },
  {
    "countryId": 25,
    "countryCode": "BJ",
    "countryName": "Benin",
    "regions": [
      {
        "regionId": 418,
        "regionName": "Alibori"
      },
      {
        "regionId": 419,
        "regionName": "Atacora"
      },
      {
        "regionId": 420,
        "regionName": "Atlantique"
      },
      {
        "regionId": 421,
        "regionName": "Borgou"
      },
      {
        "regionId": 422,
        "regionName": "Collines"
      },
      {
        "regionId": 423,
        "regionName": "Couffo"
      },
      {
        "regionId": 424,
        "regionName": "Donga"
      },
      {
        "regionId": 425,
        "regionName": "Littoral"
      },
      {
        "regionId": 426,
        "regionName": "Mono"
      },
      {
        "regionId": 427,
        "regionName": "Oueme"
      },
      {
        "regionId": 428,
        "regionName": "Plateau"
      },
      {
        "regionId": 429,
        "regionName": "Zou"
      }
    ]
  },
  {
    "countryId": 26,
    "countryCode": "BM",
    "countryName": "Bermuda",
    "regions": [
      {
        "regionId": 430,
        "regionCode": "HA",
        "regionName": "Hamilton"
      },
      {
        "regionId": 431,
        "regionCode": "SC",
        "regionName": "Saint George"
      },
      {
        "regionId": 4068,
        "regionCode": "DE",
        "regionName": "Devonshire"
      },
      {
        "regionId": 4069,
        "regionCode": "PA",
        "regionName": "Paget"
      },
      {
        "regionId": 4070,
        "regionCode": "PE",
        "regionName": "Pembroke"
      },
      {
        "regionId": 4071,
        "regionCode": "SA",
        "regionName": "Sandys"
      },
      {
        "regionId": 4072,
        "regionCode": "SM",
        "regionName": "Smith's"
      },
      {
        "regionId": 4073,
        "regionCode": "SO",
        "regionName": "Southampton"
      },
      {
        "regionId": 4074,
        "regionCode": "WA",
        "regionName": "Warwick"
      }
    ]
  },
  {
    "countryId": 27,
    "countryCode": "BT",
    "countryName": "Bhutan",
    "regions": [
      {
        "regionId": 490,
        "regionName": "Bumthang"
      },
      {
        "regionId": 491,
        "regionName": "Chhukha"
      },
      {
        "regionId": 492,
        "regionName": "Chirang"
      },
      {
        "regionId": 493,
        "regionName": "Daga"
      },
      {
        "regionId": 494,
        "regionName": "Geylegphug"
      },
      {
        "regionId": 495,
        "regionName": "Ha"
      },
      {
        "regionId": 496,
        "regionName": "Lhuntshi"
      },
      {
        "regionId": 497,
        "regionName": "Mongar"
      },
      {
        "regionId": 498,
        "regionName": "Pemagatsel"
      },
      {
        "regionId": 499,
        "regionName": "Punakha"
      },
      {
        "regionId": 500,
        "regionName": "Rinpung"
      },
      {
        "regionId": 501,
        "regionName": "Samchi"
      },
      {
        "regionId": 502,
        "regionName": "Samdrup Jongkhar"
      },
      {
        "regionId": 503,
        "regionName": "Shemgang"
      },
      {
        "regionId": 504,
        "regionName": "Tashigang"
      },
      {
        "regionId": 505,
        "regionName": "Timphu"
      },
      {
        "regionId": 506,
        "regionName": "Tongsa"
      },
      {
        "regionId": 507,
        "regionName": "Wangdiphodrang"
      }
    ]
  },
  {
    "countryId": 28,
    "countryCode": "BO",
    "countryName": "Bolivia",
    "regions": [
      {
        "regionId": 436,
        "regionName": "Beni"
      },
      {
        "regionId": 437,
        "regionName": "Chuquisaca"
      },
      {
        "regionId": 438,
        "regionName": "Cochabamba"
      },
      {
        "regionId": 439,
        "regionName": "La Paz"
      },
      {
        "regionId": 440,
        "regionName": "Oruro"
      },
      {
        "regionId": 441,
        "regionName": "Pando"
      },
      {
        "regionId": 442,
        "regionName": "Potosi"
      },
      {
        "regionId": 443,
        "regionName": "Santa Cruz"
      },
      {
        "regionId": 444,
        "regionName": "Tarija"
      }
    ]
  },
  {
    "countryId": 29,
    "countryCode": "BA",
    "countryName": "Bosnia and Herzegovina",
    "regions": [
      {
        "regionId": 229,
        "regionName": "Federacija Bosna i Hercegovina"
      },
      {
        "regionId": 230,
        "regionName": "Republika Srpska"
      }
    ]
  },
  {
    "countryId": 30,
    "countryCode": "BW",
    "countryName": "Botswana",
    "regions": [
      {
        "regionId": 509,
        "regionName": "Central Bobonong"
      },
      {
        "regionId": 510,
        "regionName": "Central Boteti"
      },
      {
        "regionId": 511,
        "regionName": "Central Mahalapye"
      },
      {
        "regionId": 512,
        "regionName": "Central Serowe-Palapye"
      },
      {
        "regionId": 513,
        "regionName": "Central Tutume"
      },
      {
        "regionId": 514,
        "regionName": "Chobe"
      },
      {
        "regionId": 515,
        "regionName": "Francistown"
      },
      {
        "regionId": 516,
        "regionName": "Gaborone"
      },
      {
        "regionId": 517,
        "regionName": "Ghanzi"
      },
      {
        "regionId": 518,
        "regionName": "Jwaneng"
      },
      {
        "regionId": 519,
        "regionName": "Kgalagadi North"
      },
      {
        "regionId": 520,
        "regionName": "Kgalagadi South"
      },
      {
        "regionId": 521,
        "regionName": "Kgatleng"
      },
      {
        "regionId": 522,
        "regionName": "Kweneng"
      },
      {
        "regionId": 523,
        "regionName": "Lobatse"
      },
      {
        "regionId": 524,
        "regionName": "Ngamiland"
      },
      {
        "regionId": 525,
        "regionName": "Ngwaketse"
      },
      {
        "regionId": 526,
        "regionName": "North East"
      },
      {
        "regionId": 527,
        "regionName": "Okavango"
      },
      {
        "regionId": 528,
        "regionName": "Orapa"
      },
      {
        "regionId": 529,
        "regionName": "Selibe Phikwe"
      },
      {
        "regionId": 530,
        "regionName": "South East"
      },
      {
        "regionId": 531,
        "regionName": "Sowa"
      }
    ]
  },
  {
    "countryId": 31,
    "countryCode": "BV",
    "countryName": "Bouvet Island",
    "regions": [
      {
        "regionId": 508,
        "regionName": "Bouvet Island"
      }
    ]
  },
  {
    "countryId": 32,
    "countryCode": "BR",
    "countryName": "Brazil",
    "regions": [
      {
        "regionId": 445,
        "regionCode": "AC",
        "regionName": "Acre"
      },
      {
        "regionId": 446,
        "regionCode": "AL",
        "regionName": "Alagoas"
      },
      {
        "regionId": 447,
        "regionCode": "AP",
        "regionName": "Amapa"
      },
      {
        "regionId": 448,
        "regionCode": "AM",
        "regionName": "Amazonas"
      },
      {
        "regionId": 449,
        "regionCode": "BA",
        "regionName": "Bahia"
      },
      {
        "regionId": 450,
        "regionCode": "CE",
        "regionName": "Ceara"
      },
      {
        "regionId": 451,
        "regionCode": "DF",
        "regionName": "Distrito Federal"
      },
      {
        "regionId": 452,
        "regionCode": "ES",
        "regionName": "Espirito Santo"
      },
      {
        "regionId": 454,
        "regionCode": "GO",
        "regionName": "Goias"
      },
      {
        "regionId": 455,
        "regionCode": "MA",
        "regionName": "Maranhao"
      },
      {
        "regionId": 456,
        "regionCode": "MT",
        "regionName": "Mato Grosso"
      },
      {
        "regionId": 457,
        "regionCode": "MS",
        "regionName": "Mato Grosso do Sul"
      },
      {
        "regionId": 458,
        "regionCode": "MG",
        "regionName": "Minas Gerais"
      },
      {
        "regionId": 459,
        "regionCode": "PA",
        "regionName": "Para"
      },
      {
        "regionId": 460,
        "regionCode": "PB",
        "regionName": "Paraiba"
      },
      {
        "regionId": 461,
        "regionCode": "PR",
        "regionName": "Parana"
      },
      {
        "regionId": 462,
        "regionCode": "PE",
        "regionName": "Pernambuco"
      },
      {
        "regionId": 463,
        "regionCode": "PI",
        "regionName": "Piaui"
      },
      {
        "regionId": 464,
        "regionCode": "RJ",
        "regionName": "Rio de Janeiro"
      },
      {
        "regionId": 465,
        "regionCode": "RN",
        "regionName": "Rio Grande do Norte"
      },
      {
        "regionId": 466,
        "regionCode": "RS",
        "regionName": "Rio Grande do Sul"
      },
      {
        "regionId": 467,
        "regionCode": "RO",
        "regionName": "Rondonia"
      },
      {
        "regionId": 468,
        "regionCode": "RR",
        "regionName": "Roraima"
      },
      {
        "regionId": 469,
        "regionCode": "SC",
        "regionName": "Santa Catarina"
      },
      {
        "regionId": 470,
        "regionCode": "SP",
        "regionName": "Sao Paulo"
      },
      {
        "regionId": 471,
        "regionCode": "SE",
        "regionName": "Sergipe"
      },
      {
        "regionId": 472,
        "regionCode": "TO",
        "regionName": "Tocantins"
      }
    ]
  },
  {
    "countryId": 33,
    "countryCode": "VG",
    "countryName": "British Virgin Islands",
    "regions": [
      {
        "regionId": 3912,
        "regionName": "Anegada"
      },
      {
        "regionId": 3913,
        "regionName": "Jost van Dyke"
      },
      {
        "regionId": 3914,
        "regionName": "Tortola"
      }
    ]
  },
  {
    "countryId": 34,
    "countryCode": "BN",
    "countryName": "Brunei",
    "regions": [
      {
        "regionId": 432,
        "regionName": "Belait"
      },
      {
        "regionId": 433,
        "regionName": "Brunei-Muara"
      },
      {
        "regionId": 434,
        "regionName": "Temburong"
      },
      {
        "regionId": 435,
        "regionName": "Tutong"
      }
    ]
  },
  {
    "countryId": 35,
    "countryCode": "BG",
    "countryName": "Bulgaria",
    "regions": [
      {
        "regionId": 363,
        "regionName": "Blagoevgrad"
      },
      {
        "regionId": 364,
        "regionCode": "01",
        "regionName": "Burgas"
      },
      {
        "regionId": 365,
        "regionName": "Dobrich"
      },
      {
        "regionId": 366,
        "regionName": "Gabrovo"
      },
      {
        "regionId": 367,
        "regionName": "Haskovo"
      },
      {
        "regionId": 368,
        "regionName": "Jambol"
      },
      {
        "regionId": 369,
        "regionName": "Kardzhali"
      },
      {
        "regionId": 370,
        "regionName": "Kjustendil"
      },
      {
        "regionId": 371,
        "regionCode": "04",
        "regionName": "Lovech"
      },
      {
        "regionId": 372,
        "regionCode": "05",
        "regionName": "Montana"
      },
      {
        "regionId": 373,
        "regionName": "Oblast Sofiya-Grad"
      },
      {
        "regionId": 374,
        "regionName": "Pazardzhik"
      },
      {
        "regionId": 375,
        "regionName": "Pernik"
      },
      {
        "regionId": 376,
        "regionName": "Pleven"
      },
      {
        "regionId": 377,
        "regionCode": "06",
        "regionName": "Plovdiv"
      },
      {
        "regionId": 378,
        "regionName": "Razgrad"
      },
      {
        "regionId": 379,
        "regionCode": "07",
        "regionName": "Ruse"
      },
      {
        "regionId": 380,
        "regionName": "Shumen"
      },
      {
        "regionId": 381,
        "regionName": "Silistra"
      },
      {
        "regionId": 382,
        "regionName": "Sliven"
      },
      {
        "regionId": 383,
        "regionName": "Smoljan"
      },
      {
        "regionId": 384,
        "regionName": "Sofija grad"
      },
      {
        "regionId": 385,
        "regionName": "Sofijska oblast"
      },
      {
        "regionId": 386,
        "regionName": "Stara Zagora"
      },
      {
        "regionId": 387,
        "regionName": "Targovishte"
      },
      {
        "regionId": 388,
        "regionCode": "09",
        "regionName": "Varna"
      },
      {
        "regionId": 389,
        "regionName": "Veliko Tarnovo"
      },
      {
        "regionId": 390,
        "regionName": "Vidin"
      },
      {
        "regionId": 391,
        "regionName": "Vraca"
      },
      {
        "regionId": 392,
        "regionName": "Yablaniza"
      }
    ]
  },
  {
    "countryId": 36,
    "countryCode": "BF",
    "countryName": "Burkina Faso",
    "regions": [
      {
        "regionId": 324,
        "regionName": "Bale"
      },
      {
        "regionId": 325,
        "regionName": "Bam"
      },
      {
        "regionId": 326,
        "regionName": "Bazega"
      },
      {
        "regionId": 327,
        "regionName": "Bougouriba"
      },
      {
        "regionId": 328,
        "regionName": "Boulgou"
      },
      {
        "regionId": 329,
        "regionName": "Boulkiemde"
      },
      {
        "regionId": 330,
        "regionName": "Comoe"
      },
      {
        "regionId": 331,
        "regionName": "Ganzourgou"
      },
      {
        "regionId": 332,
        "regionName": "Gnagna"
      },
      {
        "regionId": 333,
        "regionName": "Gourma"
      },
      {
        "regionId": 334,
        "regionName": "Houet"
      },
      {
        "regionId": 335,
        "regionName": "Ioba"
      },
      {
        "regionId": 336,
        "regionName": "Kadiogo"
      },
      {
        "regionId": 337,
        "regionName": "Kenedougou"
      },
      {
        "regionId": 338,
        "regionName": "Komandjari"
      },
      {
        "regionId": 339,
        "regionName": "Kompienga"
      },
      {
        "regionId": 340,
        "regionName": "Kossi"
      },
      {
        "regionId": 341,
        "regionName": "Kouritenga"
      },
      {
        "regionId": 342,
        "regionName": "Kourweogo"
      },
      {
        "regionId": 343,
        "regionName": "Leraba"
      },
      {
        "regionId": 344,
        "regionName": "Mouhoun"
      },
      {
        "regionId": 345,
        "regionName": "Nahouri"
      },
      {
        "regionId": 346,
        "regionName": "Namentenga"
      },
      {
        "regionId": 347,
        "regionName": "Noumbiel"
      },
      {
        "regionId": 348,
        "regionName": "Oubritenga"
      },
      {
        "regionId": 349,
        "regionName": "Oudalan"
      },
      {
        "regionId": 350,
        "regionName": "Passore"
      },
      {
        "regionId": 351,
        "regionName": "Poni"
      },
      {
        "regionId": 352,
        "regionName": "Sanguie"
      },
      {
        "regionId": 353,
        "regionName": "Sanmatenga"
      },
      {
        "regionId": 354,
        "regionName": "Seno"
      },
      {
        "regionId": 355,
        "regionName": "Sissili"
      },
      {
        "regionId": 356,
        "regionName": "Soum"
      },
      {
        "regionId": 357,
        "regionName": "Sourou"
      },
      {
        "regionId": 358,
        "regionName": "Tapoa"
      },
      {
        "regionId": 359,
        "regionName": "Tuy"
      },
      {
        "regionId": 360,
        "regionName": "Yatenga"
      },
      {
        "regionId": 361,
        "regionName": "Zondoma"
      },
      {
        "regionId": 362,
        "regionName": "Zoundweogo"
      }
    ]
  },
  {
    "countryId": 37,
    "countryCode": "BI",
    "countryName": "Burundi",
    "regions": [
      {
        "regionId": 403,
        "regionName": "Bubanza"
      },
      {
        "regionId": 404,
        "regionName": "Bujumbura"
      },
      {
        "regionId": 405,
        "regionName": "Bururi"
      },
      {
        "regionId": 406,
        "regionName": "Cankuzo"
      },
      {
        "regionId": 407,
        "regionName": "Cibitoke"
      },
      {
        "regionId": 408,
        "regionName": "Gitega"
      },
      {
        "regionId": 409,
        "regionName": "Karuzi"
      },
      {
        "regionId": 410,
        "regionName": "Kayanza"
      },
      {
        "regionId": 411,
        "regionName": "Kirundo"
      },
      {
        "regionId": 412,
        "regionName": "Makamba"
      },
      {
        "regionId": 413,
        "regionName": "Muramvya"
      },
      {
        "regionId": 414,
        "regionName": "Muyinga"
      },
      {
        "regionId": 415,
        "regionName": "Ngozi"
      },
      {
        "regionId": 416,
        "regionName": "Rutana"
      },
      {
        "regionId": 417,
        "regionName": "Ruyigi"
      }
    ]
  },
  {
    "countryId": 38,
    "countryCode": "KH",
    "countryName": "Cambodia",
    "regions": [
      {
        "regionId": 2116,
        "regionName": "Banteay Mean Chey"
      },
      {
        "regionId": 2117,
        "regionName": "Bat Dambang"
      },
      {
        "regionId": 2118,
        "regionName": "Kampong Cham"
      },
      {
        "regionId": 2119,
        "regionName": "Kampong Chhnang"
      },
      {
        "regionId": 2120,
        "regionName": "Kampong Spoeu"
      },
      {
        "regionId": 2121,
        "regionName": "Kampong Thum"
      },
      {
        "regionId": 2122,
        "regionName": "Kampot"
      },
      {
        "regionId": 2123,
        "regionName": "Kandal"
      },
      {
        "regionId": 2124,
        "regionName": "Kaoh Kong"
      },
      {
        "regionId": 2125,
        "regionName": "Kracheh"
      },
      {
        "regionId": 2126,
        "regionName": "Krong Kaeb"
      },
      {
        "regionId": 2127,
        "regionName": "Krong Pailin"
      },
      {
        "regionId": 2128,
        "regionName": "Krong Preah Sihanouk"
      },
      {
        "regionId": 2129,
        "regionName": "Mondol Kiri"
      },
      {
        "regionId": 2130,
        "regionName": "Otdar Mean Chey"
      },
      {
        "regionId": 2131,
        "regionName": "Phnum Penh"
      },
      {
        "regionId": 2132,
        "regionName": "Pousat"
      },
      {
        "regionId": 2133,
        "regionName": "Preah Vihear"
      },
      {
        "regionId": 2134,
        "regionName": "Prey Veaeng"
      },
      {
        "regionId": 2135,
        "regionName": "Rotanak Kiri"
      },
      {
        "regionId": 2136,
        "regionName": "Siem Reab"
      },
      {
        "regionId": 2137,
        "regionName": "Stueng Traeng"
      },
      {
        "regionId": 2138,
        "regionName": "Svay Rieng"
      },
      {
        "regionId": 2139,
        "regionName": "Takaev"
      }
    ]
  },
  {
    "countryId": 39,
    "countryCode": "CM",
    "countryName": "Cameroon",
    "regions": [
      {
        "regionId": 675,
        "regionName": "Adamaoua"
      },
      {
        "regionId": 676,
        "regionName": "Centre"
      },
      {
        "regionId": 677,
        "regionName": "Est"
      },
      {
        "regionId": 678,
        "regionName": "Littoral"
      },
      {
        "regionId": 679,
        "regionName": "Nord"
      },
      {
        "regionId": 680,
        "regionName": "Nord Extreme"
      },
      {
        "regionId": 681,
        "regionName": "Nordouest"
      },
      {
        "regionId": 682,
        "regionName": "Ouest"
      },
      {
        "regionId": 683,
        "regionName": "Sud"
      },
      {
        "regionId": 684,
        "regionName": "Sudouest"
      }
    ]
  },
  {
    "countryId": 40,
    "countryCode": "CA",
    "countryName": "Canada",
    "regions": [
      {
        "regionId": 547,
        "regionCode": "AB",
        "regionName": "Alberta"
      },
      {
        "regionId": 548,
        "regionCode": "BC",
        "regionName": "British Columbia"
      },
      {
        "regionId": 549,
        "regionCode": "MB",
        "regionName": "Manitoba"
      },
      {
        "regionId": 550,
        "regionCode": "NB",
        "regionName": "New Brunswick"
      },
      {
        "regionId": 551,
        "regionCode": "NL",
        "regionName": "Newfoundland and Labrador"
      },
      {
        "regionId": 552,
        "regionCode": "NT",
        "regionName": "Northwest Territories"
      },
      {
        "regionId": 553,
        "regionCode": "NS",
        "regionName": "Nova Scotia"
      },
      {
        "regionId": 554,
        "regionCode": "NU",
        "regionName": "Nunavut"
      },
      {
        "regionId": 555,
        "regionCode": "ON",
        "regionName": "Ontario"
      },
      {
        "regionId": 556,
        "regionCode": "PE",
        "regionName": "Prince Edward Island"
      },
      {
        "regionId": 557,
        "regionCode": "QC",
        "regionName": "Quebec"
      },
      {
        "regionId": 558,
        "regionCode": "SK",
        "regionName": "Saskatchewan"
      },
      {
        "regionId": 559,
        "regionCode": "YT",
        "regionName": "Yukon"
      }
    ]
  },
  {
    "countryId": 41,
    "countryCode": "KY",
    "countryName": "Cayman Islands",
    "regions": [
      {
        "regionId": 2236,
        "regionName": "Grand Cayman"
      }
    ]
  },
  {
    "countryId": 42,
    "countryCode": "CF",
    "countryName": "Central African Republic",
    "regions": [
      {
        "regionId": 571,
        "regionName": "Bamingui-Bangoran"
      },
      {
        "regionId": 572,
        "regionName": "Bangui"
      },
      {
        "regionId": 573,
        "regionName": "Basse-Kotto"
      },
      {
        "regionId": 574,
        "regionName": "Haute-Kotto"
      },
      {
        "regionId": 575,
        "regionName": "Haut-Mbomou"
      },
      {
        "regionId": 576,
        "regionName": "Kemo"
      },
      {
        "regionId": 577,
        "regionName": "Lobaye"
      },
      {
        "regionId": 578,
        "regionName": "Mambere-Kadei"
      },
      {
        "regionId": 579,
        "regionName": "Mbomou"
      },
      {
        "regionId": 580,
        "regionName": "Nana-Gribizi"
      },
      {
        "regionId": 581,
        "regionName": "Nana-Mambere"
      },
      {
        "regionId": 582,
        "regionName": "Ombella Mpoko"
      },
      {
        "regionId": 583,
        "regionName": "Ouaka"
      },
      {
        "regionId": 584,
        "regionName": "Ouham"
      },
      {
        "regionId": 585,
        "regionName": "Ouham-Pende"
      },
      {
        "regionId": 586,
        "regionName": "Sangha-Mbaere"
      },
      {
        "regionId": 587,
        "regionName": "Vakaga"
      }
    ]
  },
  {
    "countryId": 43,
    "countryCode": "TD",
    "countryName": "Chad",
    "regions": [
      {
        "regionId": 3466,
        "regionName": "Batha"
      },
      {
        "regionId": 3467,
        "regionName": "Biltine"
      },
      {
        "regionId": 3468,
        "regionName": "Bourkou-Ennedi-Tibesti"
      },
      {
        "regionId": 3469,
        "regionName": "Chari-Baguirmi"
      },
      {
        "regionId": 3470,
        "regionName": "Guera"
      },
      {
        "regionId": 3471,
        "regionName": "Kanem"
      },
      {
        "regionId": 3472,
        "regionName": "Lac"
      },
      {
        "regionId": 3473,
        "regionName": "Logone Occidental"
      },
      {
        "regionId": 3474,
        "regionName": "Logone Oriental"
      },
      {
        "regionId": 3475,
        "regionName": "Mayo-Kebbi"
      },
      {
        "regionId": 3476,
        "regionName": "Moyen-Chari"
      },
      {
        "regionId": 3477,
        "regionName": "Ouaddai"
      },
      {
        "regionId": 3478,
        "regionName": "Salamat"
      },
      {
        "regionId": 3479,
        "regionName": "Tandjile"
      }
    ]
  },
  {
    "countryId": 44,
    "countryCode": "CL",
    "countryName": "Chile",
    "regions": [
      {
        "regionId": 661,
        "regionName": "Aisen"
      },
      {
        "regionId": 662,
        "regionCode": "AN",
        "regionName": "Antofagasta"
      },
      {
        "regionId": 663,
        "regionCode": "AR",
        "regionName": "Araucania"
      },
      {
        "regionId": 664,
        "regionCode": "AT",
        "regionName": "Atacama"
      },
      {
        "regionId": 665,
        "regionName": "Bio Bio"
      },
      {
        "regionId": 666,
        "regionCode": "CO",
        "regionName": "Coquimbo"
      },
      {
        "regionId": 667,
        "regionName": "Libertador General Bernardo O'"
      },
      {
        "regionId": 668,
        "regionCode": "LL",
        "regionName": "Los Lagos"
      },
      {
        "regionId": 669,
        "regionName": "Magellanes"
      },
      {
        "regionId": 670,
        "regionCode": "ML",
        "regionName": "Maule"
      },
      {
        "regionId": 671,
        "regionName": "Metropolitana"
      },
      {
        "regionId": 672,
        "regionCode": "RM",
        "regionName": "Metropolitana de Santiago"
      },
      {
        "regionId": 673,
        "regionCode": "TA",
        "regionName": "Tarapaca"
      },
      {
        "regionId": 674,
        "regionCode": "VS",
        "regionName": "Valparaiso"
      }
    ]
  },
  {
    "countryId": 45,
    "countryCode": "CN",
    "countryName": "China",
    "regions": [
      {
        "regionId": 685,
        "regionCode": "110",
        "regionName": "Anhui"
      },
      {
        "regionId": 686,
        "regionName": "Anhui Province"
      },
      {
        "regionId": 687,
        "regionCode": "34",
        "regionName": "Anhui Sheng"
      },
      {
        "regionId": 688,
        "regionName": "Aomen"
      },
      {
        "regionId": 689,
        "regionCode": "010",
        "regionName": "Beijing"
      },
      {
        "regionId": 690,
        "regionCode": "11",
        "regionName": "Beijing Shi"
      },
      {
        "regionId": 691,
        "regionName": "Chongqing"
      },
      {
        "regionId": 692,
        "regionCode": "150",
        "regionName": "Fujian"
      },
      {
        "regionId": 693,
        "regionCode": "35",
        "regionName": "Fujian Sheng"
      },
      {
        "regionId": 694,
        "regionCode": "260",
        "regionName": "Gansu"
      },
      {
        "regionId": 695,
        "regionCode": "190",
        "regionName": "Guangdong"
      },
      {
        "regionId": 696,
        "regionCode": "44",
        "regionName": "Guangdong Sheng"
      },
      {
        "regionId": 697,
        "regionCode": "210",
        "regionName": "Guangxi"
      },
      {
        "regionId": 698,
        "regionCode": "220",
        "regionName": "Guizhou"
      },
      {
        "regionId": 699,
        "regionCode": "200",
        "regionName": "Hainan"
      },
      {
        "regionId": 700,
        "regionCode": "060",
        "regionName": "Hebei"
      },
      {
        "regionId": 701,
        "regionCode": "090",
        "regionName": "Heilongjiang"
      },
      {
        "regionId": 702,
        "regionCode": "180",
        "regionName": "Henan"
      },
      {
        "regionId": 703,
        "regionCode": "170",
        "regionName": "Hubei"
      },
      {
        "regionId": 704,
        "regionCode": "160",
        "regionName": "Hunan"
      },
      {
        "regionId": 705,
        "regionCode": "100",
        "regionName": "Jiangsu"
      },
      {
        "regionId": 706,
        "regionCode": "32",
        "regionName": "Jiangsu Sheng"
      },
      {
        "regionId": 707,
        "regionCode": "140",
        "regionName": "Jiangxi"
      },
      {
        "regionId": 708,
        "regionCode": "080",
        "regionName": "Jilin"
      },
      {
        "regionId": 709,
        "regionCode": "070",
        "regionName": "Liaoning"
      },
      {
        "regionId": 710,
        "regionCode": "21",
        "regionName": "Liaoning Sheng"
      },
      {
        "regionId": 711,
        "regionName": "Nei Monggol"
      },
      {
        "regionId": 712,
        "regionName": "Ningxia Hui"
      },
      {
        "regionId": 713,
        "regionCode": "280",
        "regionName": "Qinghai"
      },
      {
        "regionId": 714,
        "regionCode": "250",
        "regionName": "Shaanxi"
      },
      {
        "regionId": 715,
        "regionCode": "120",
        "regionName": "Shandong"
      },
      {
        "regionId": 716,
        "regionCode": "37",
        "regionName": "Shandong Sheng"
      },
      {
        "regionId": 717,
        "regionCode": "020",
        "regionName": "Shanghai"
      },
      {
        "regionId": 718,
        "regionCode": "050",
        "regionName": "Shanxi"
      },
      {
        "regionId": 719,
        "regionCode": "230",
        "regionName": "Sichuan"
      },
      {
        "regionId": 720,
        "regionCode": "030",
        "regionName": "Tianjin"
      },
      {
        "regionId": 721,
        "regionName": "Xianggang"
      },
      {
        "regionId": 722,
        "regionCode": "290",
        "regionName": "Xinjiang"
      },
      {
        "regionId": 723,
        "regionCode": "300",
        "regionName": "Xizang"
      },
      {
        "regionId": 724,
        "regionCode": "240",
        "regionName": "Yunnan"
      },
      {
        "regionId": 725,
        "regionCode": "130",
        "regionName": "Zhejiang"
      },
      {
        "regionId": 726,
        "regionCode": "33",
        "regionName": "Zhejiang Sheng"
      }
    ]
  },
  {
    "countryId": 46,
    "countryCode": "CX",
    "countryName": "Christmas Island",
    "regions": [
      {
        "regionId": 783,
        "regionName": "Christmas Island"
      }
    ]
  },
  {
    "countryId": 47,
    "countryCode": "CO",
    "countryName": "Colombia",
    "regions": [
      {
        "regionId": 727,
        "regionCode": "91",
        "regionName": "Amazonas"
      },
      {
        "regionId": 728,
        "regionCode": "05",
        "regionName": "Antioquia"
      },
      {
        "regionId": 729,
        "regionCode": "81",
        "regionName": "Arauca"
      },
      {
        "regionId": 730,
        "regionCode": "08",
        "regionName": "Atlantico"
      },
      {
        "regionId": 731,
        "regionCode": "11",
        "regionName": "Bogota"
      },
      {
        "regionId": 732,
        "regionCode": "13",
        "regionName": "Bolivar"
      },
      {
        "regionId": 733,
        "regionCode": "15",
        "regionName": "Boyaca"
      },
      {
        "regionId": 734,
        "regionCode": "17",
        "regionName": "Caldas"
      },
      {
        "regionId": 735,
        "regionCode": "18",
        "regionName": "Caqueta"
      },
      {
        "regionId": 736,
        "regionCode": "85",
        "regionName": "Casanare"
      },
      {
        "regionId": 737,
        "regionCode": "19",
        "regionName": "Cauca"
      },
      {
        "regionId": 738,
        "regionCode": "20",
        "regionName": "Cesar"
      },
      {
        "regionId": 739,
        "regionCode": "27",
        "regionName": "Choco"
      },
      {
        "regionId": 740,
        "regionCode": "23",
        "regionName": "Cordoba"
      },
      {
        "regionId": 741,
        "regionCode": "25",
        "regionName": "Cundinamarca"
      },
      {
        "regionId": 742,
        "regionCode": "94",
        "regionName": "Guainia"
      },
      {
        "regionId": 743,
        "regionCode": "95",
        "regionName": "Guaviare"
      },
      {
        "regionId": 744,
        "regionCode": "41",
        "regionName": "Huila"
      },
      {
        "regionId": 745,
        "regionCode": "44",
        "regionName": "La Guajira"
      },
      {
        "regionId": 746,
        "regionCode": "47",
        "regionName": "Magdalena"
      },
      {
        "regionId": 747,
        "regionCode": "50",
        "regionName": "Meta"
      },
      {
        "regionId": 748,
        "regionName": "Narino"
      },
      {
        "regionId": 749,
        "regionName": "Norte de Santander"
      },
      {
        "regionId": 750,
        "regionCode": "86",
        "regionName": "Putumayo"
      },
      {
        "regionId": 751,
        "regionCode": "63",
        "regionName": "Quindio"
      },
      {
        "regionId": 752,
        "regionCode": "66",
        "regionName": "Risaralda"
      },
      {
        "regionId": 753,
        "regionName": "San Andres y Providencia"
      },
      {
        "regionId": 754,
        "regionCode": "68",
        "regionName": "Santander"
      },
      {
        "regionId": 755,
        "regionCode": "70",
        "regionName": "Sucre"
      },
      {
        "regionId": 756,
        "regionCode": "73",
        "regionName": "Tolima"
      },
      {
        "regionId": 757,
        "regionName": "Valle del Cauca"
      },
      {
        "regionId": 758,
        "regionCode": "97",
        "regionName": "Vaupes"
      },
      {
        "regionId": 759,
        "regionCode": "99",
        "regionName": "Vichada"
      }
    ]
  },
  {
    "countryId": 48,
    "countryCode": "KM",
    "countryName": "Comoros",
    "regions": [
      {
        "regionId": 2163,
        "regionName": "Mwali"
      },
      {
        "regionId": 2164,
        "regionName": "Njazidja"
      },
      {
        "regionId": 2165,
        "regionName": "Nzwani"
      }
    ]
  },
  {
    "countryId": 49,
    "countryCode": "CD",
    "countryName": "Congo, Democratic Republic of the",
    "regions": [
      {
        "regionId": 560,
        "regionName": "Bandundu"
      },
      {
        "regionId": 561,
        "regionName": "Bas-Congo"
      },
      {
        "regionId": 562,
        "regionName": "Equateur"
      },
      {
        "regionId": 563,
        "regionName": "Haut-Congo"
      },
      {
        "regionId": 564,
        "regionName": "Kasai-Occidental"
      },
      {
        "regionId": 565,
        "regionName": "Kasai-Oriental"
      },
      {
        "regionId": 566,
        "regionName": "Katanga"
      },
      {
        "regionId": 567,
        "regionName": "Kinshasa"
      },
      {
        "regionId": 568,
        "regionName": "Maniema"
      },
      {
        "regionId": 569,
        "regionName": "Nord-Kivu"
      },
      {
        "regionId": 570,
        "regionName": "Sud-Kivu"
      }
    ]
  },
  {
    "countryId": 50,
    "countryCode": "CG",
    "countryName": "Congo, Republic of the",
    "regions": [
      {
        "regionId": 588,
        "regionName": "Bouenza"
      },
      {
        "regionId": 589,
        "regionName": "Brazzaville"
      },
      {
        "regionId": 590,
        "regionName": "Cuvette"
      },
      {
        "regionId": 591,
        "regionName": "Kouilou"
      },
      {
        "regionId": 592,
        "regionName": "Lekoumou"
      },
      {
        "regionId": 593,
        "regionName": "Likouala"
      },
      {
        "regionId": 594,
        "regionName": "Niari"
      },
      {
        "regionId": 595,
        "regionName": "Plateaux"
      },
      {
        "regionId": 596,
        "regionName": "Pool"
      },
      {
        "regionId": 597,
        "regionName": "Sangha"
      }
    ]
  },
  {
    "countryId": 51,
    "countryCode": "CK",
    "countryName": "Cook Islands",
    "regions": [
      {
        "regionId": 650,
        "regionName": "Aitutaki"
      },
      {
        "regionId": 651,
        "regionName": "Atiu"
      },
      {
        "regionId": 652,
        "regionName": "Mangaia"
      },
      {
        "regionId": 653,
        "regionName": "Manihiki"
      },
      {
        "regionId": 654,
        "regionName": "Mauke"
      },
      {
        "regionId": 655,
        "regionName": "Mitiaro"
      },
      {
        "regionId": 656,
        "regionName": "Nassau"
      },
      {
        "regionId": 657,
        "regionName": "Pukapuka"
      },
      {
        "regionId": 658,
        "regionName": "Rakahanga"
      },
      {
        "regionId": 659,
        "regionName": "Rarotonga"
      },
      {
        "regionId": 660,
        "regionName": "Tongareva"
      }
    ]
  },
  {
    "countryId": 52,
    "countryCode": "CR",
    "countryName": "Costa Rica",
    "regions": [
      {
        "regionId": 760,
        "regionCode": "A",
        "regionName": "Alajuela"
      },
      {
        "regionId": 761,
        "regionCode": "C",
        "regionName": "Cartago"
      },
      {
        "regionId": 762,
        "regionCode": "G",
        "regionName": "Guanacaste"
      },
      {
        "regionId": 763,
        "regionCode": "H",
        "regionName": "Heredia"
      },
      {
        "regionId": 764,
        "regionCode": "L",
        "regionName": "Limon"
      },
      {
        "regionId": 765,
        "regionCode": "P",
        "regionName": "Puntarenas"
      },
      {
        "regionId": 766,
        "regionCode": "SJ",
        "regionName": "San Jose"
      }
    ]
  },
  {
    "countryId": 53,
    "countryCode": "HR",
    "countryName": "Croatia",
    "regions": [
      {
        "regionId": 1686,
        "regionCode": "01",
        "regionName": "Bjelovar-Bilogora"
      },
      {
        "regionId": 1687,
        "regionCode": "03",
        "regionName": "Dubrovnik-Neretva"
      },
      {
        "regionId": 1688,
        "regionName": "Grad Zagreb"
      },
      {
        "regionId": 1689,
        "regionCode": "04",
        "regionName": "Istra"
      },
      {
        "regionId": 1690,
        "regionCode": "05",
        "regionName": "Karlovac"
      },
      {
        "regionId": 1691,
        "regionName": "Koprivnica-Krizhevci"
      },
      {
        "regionId": 1692,
        "regionName": "Krapina-Zagorje"
      },
      {
        "regionId": 1693,
        "regionCode": "07",
        "regionName": "Lika-Senj"
      },
      {
        "regionId": 1694,
        "regionName": "Medhimurje"
      },
      {
        "regionId": 1695,
        "regionName": "Medimurska Zupanija"
      },
      {
        "regionId": 1696,
        "regionCode": "09",
        "regionName": "Osijek-Baranja"
      },
      {
        "regionId": 1697,
        "regionName": "Osjecko-Baranjska Zupanija"
      },
      {
        "regionId": 1698,
        "regionName": "Pozhega-Slavonija"
      },
      {
        "regionId": 1699,
        "regionName": "Primorje-Gorski Kotar"
      },
      {
        "regionId": 1700,
        "regionName": "Shibenik-Knin"
      },
      {
        "regionId": 1701,
        "regionCode": "13",
        "regionName": "Sisak-Moslavina"
      },
      {
        "regionId": 1702,
        "regionName": "Slavonski Brod-Posavina"
      },
      {
        "regionId": 1703,
        "regionName": "Split-Dalmacija"
      },
      {
        "regionId": 1704,
        "regionName": "Varazhdin"
      },
      {
        "regionId": 1705,
        "regionCode": "18",
        "regionName": "Virovitica-Podravina"
      },
      {
        "regionId": 1706,
        "regionCode": "19",
        "regionName": "Vukovar-Srijem"
      },
      {
        "regionId": 1707,
        "regionName": "Zadar"
      },
      {
        "regionId": 1708,
        "regionCode": "21",
        "regionName": "Zagreb"
      }
    ]
  },
  {
    "countryId": 54,
    "countryCode": "CU",
    "countryName": "Cuba",
    "regions": [
      {
        "regionId": 767,
        "regionCode": "09",
        "regionName": "Camaguey"
      },
      {
        "regionId": 768,
        "regionCode": "08",
        "regionName": "Ciego de Avila"
      },
      {
        "regionId": 769,
        "regionCode": "06",
        "regionName": "Cienfuegos"
      },
      {
        "regionId": 770,
        "regionName": "Ciudad de la Habana"
      },
      {
        "regionId": 771,
        "regionCode": "12",
        "regionName": "Granma"
      },
      {
        "regionId": 772,
        "regionCode": "14",
        "regionName": "Guantanamo"
      },
      {
        "regionId": 773,
        "regionName": "Habana"
      },
      {
        "regionId": 774,
        "regionCode": "11",
        "regionName": "Holguin"
      },
      {
        "regionId": 775,
        "regionCode": "99",
        "regionName": "Isla de la Juventud"
      },
      {
        "regionId": 776,
        "regionCode": "03",
        "regionName": "La Habana"
      },
      {
        "regionId": 777,
        "regionCode": "10",
        "regionName": "Las Tunas"
      },
      {
        "regionId": 778,
        "regionCode": "04",
        "regionName": "Matanzas"
      },
      {
        "regionId": 779,
        "regionCode": "01",
        "regionName": "Pinar del Rio"
      },
      {
        "regionId": 780,
        "regionCode": "07",
        "regionName": "Sancti Spiritus"
      },
      {
        "regionId": 781,
        "regionCode": "13",
        "regionName": "Santiago de Cuba"
      },
      {
        "regionId": 782,
        "regionCode": "05",
        "regionName": "Villa Clara"
      }
    ]
  },
  {
    "countryId": 55,
    "countryCode": "CY",
    "countryName": "Cyprus",
    "regions": [
      {
        "regionId": 784,
        "regionName": "Government controlled area"
      },
      {
        "regionId": 785,
        "regionName": "Limassol"
      },
      {
        "regionId": 786,
        "regionName": "Nicosia District"
      },
      {
        "regionId": 787,
        "regionName": "Paphos"
      },
      {
        "regionId": 788,
        "regionName": "Turkish controlled area"
      }
    ]
  },
  {
    "countryId": 56,
    "countryCode": "CZ",
    "countryName": "Czech Republic",
    "regions": [
      {
        "regionId": 789,
        "regionName": "Central Bohemian"
      },
      {
        "regionId": 790,
        "regionName": "Frycovice"
      },
      {
        "regionId": 791,
        "regionName": "Jihocesky Kraj"
      },
      {
        "regionId": 792,
        "regionName": "Jihochesky"
      },
      {
        "regionId": 793,
        "regionCode": "02",
        "regionName": "Jihomoravsky"
      },
      {
        "regionId": 794,
        "regionName": "Karlovarsky"
      },
      {
        "regionId": 795,
        "regionName": "Klecany"
      },
      {
        "regionId": 796,
        "regionName": "Kralovehradecky"
      },
      {
        "regionId": 797,
        "regionName": "Liberecky"
      },
      {
        "regionId": 798,
        "regionName": "Lipov"
      },
      {
        "regionId": 799,
        "regionName": "Moravskoslezsky"
      },
      {
        "regionId": 800,
        "regionName": "Olomoucky"
      },
      {
        "regionId": 801,
        "regionName": "Olomoucky Kraj"
      },
      {
        "regionId": 802,
        "regionName": "Pardubicky"
      },
      {
        "regionId": 803,
        "regionName": "Plzensky"
      },
      {
        "regionId": 804,
        "regionCode": "03",
        "regionName": "Praha"
      },
      {
        "regionId": 805,
        "regionName": "Rajhrad"
      },
      {
        "regionId": 806,
        "regionName": "Smirice"
      },
      {
        "regionId": 807,
        "regionName": "South Moravian"
      },
      {
        "regionId": 808,
        "regionName": "Straz nad Nisou"
      },
      {
        "regionId": 809,
        "regionName": "Stredochesky"
      },
      {
        "regionId": 810,
        "regionName": "Unicov"
      },
      {
        "regionId": 811,
        "regionName": "Ustecky"
      },
      {
        "regionId": 812,
        "regionName": "Valletta"
      },
      {
        "regionId": 813,
        "regionName": "Velesin"
      },
      {
        "regionId": 814,
        "regionName": "Vysochina"
      },
      {
        "regionId": 815,
        "regionName": "Zlinsky"
      }
    ]
  },
  {
    "countryId": 57,
    "countryCode": "DK",
    "countryName": "Denmark",
    "regions": [
      {
        "regionId": 868,
        "regionName": "Arhus"
      },
      {
        "regionId": 869,
        "regionName": "Bornholm"
      },
      {
        "regionId": 870,
        "regionName": "Frederiksborg"
      },
      {
        "regionId": 871,
        "regionName": "Fyn"
      },
      {
        "regionId": 872,
        "regionName": "Hovedstaden"
      },
      {
        "regionId": 873,
        "regionName": "Kobenhavn"
      },
      {
        "regionId": 874,
        "regionName": "Kobenhavns Amt"
      },
      {
        "regionId": 875,
        "regionName": "Kobenhavns Kommune"
      },
      {
        "regionId": 876,
        "regionName": "Nordjylland"
      },
      {
        "regionId": 877,
        "regionName": "Ribe"
      },
      {
        "regionId": 878,
        "regionName": "Ringkobing"
      },
      {
        "regionId": 879,
        "regionName": "Roervig"
      },
      {
        "regionId": 880,
        "regionName": "Roskilde"
      },
      {
        "regionId": 881,
        "regionName": "Roslev"
      },
      {
        "regionId": 882,
        "regionName": "Sjaelland"
      },
      {
        "regionId": 883,
        "regionName": "Soeborg"
      },
      {
        "regionId": 884,
        "regionName": "Sonderjylland"
      },
      {
        "regionId": 885,
        "regionName": "Storstrom"
      },
      {
        "regionId": 886,
        "regionName": "Syddanmark"
      },
      {
        "regionId": 887,
        "regionName": "Toelloese"
      },
      {
        "regionId": 888,
        "regionName": "Vejle"
      },
      {
        "regionId": 889,
        "regionName": "Vestsjalland"
      },
      {
        "regionId": 890,
        "regionName": "Viborg"
      }
    ]
  },
  {
    "countryId": 58,
    "countryCode": "DJ",
    "countryName": "Djibouti",
    "regions": [
      {
        "regionId": 863,
        "regionName": "'Ali Sabih"
      },
      {
        "regionId": 864,
        "regionName": "Dikhil"
      },
      {
        "regionId": 865,
        "regionName": "Jibuti"
      },
      {
        "regionId": 866,
        "regionName": "Tajurah"
      },
      {
        "regionId": 867,
        "regionName": "Ubuk"
      }
    ]
  },
  {
    "countryId": 59,
    "countryCode": "DM",
    "countryName": "Dominica",
    "regions": [
      {
        "regionId": 891,
        "regionName": "Saint Andrew"
      },
      {
        "regionId": 892,
        "regionName": "Saint David"
      },
      {
        "regionId": 893,
        "regionName": "Saint George"
      },
      {
        "regionId": 894,
        "regionName": "Saint John"
      },
      {
        "regionId": 895,
        "regionName": "Saint Joseph"
      },
      {
        "regionId": 896,
        "regionName": "Saint Luke"
      },
      {
        "regionId": 897,
        "regionName": "Saint Mark"
      },
      {
        "regionId": 898,
        "regionName": "Saint Patrick"
      },
      {
        "regionId": 899,
        "regionName": "Saint Paul"
      },
      {
        "regionId": 900,
        "regionName": "Saint Peter"
      }
    ]
  },
  {
    "countryId": 60,
    "countryCode": "DO",
    "countryName": "Dominican Republic",
    "regions": [
      {
        "regionId": 4003,
        "regionCode": "06",
        "regionName": "Duarte"
      },
      {
        "regionId": 4004,
        "regionCode": "14",
        "regionName": "María Trinidad Sánchez"
      },
      {
        "regionId": 4005,
        "regionCode": "19",
        "regionName": "Hermanas Mirabal"
      },
      {
        "regionId": 4006,
        "regionCode": "20",
        "regionName": "Samaná"
      },
      {
        "regionId": 4007,
        "regionCode": "05",
        "regionName": "Dajabón"
      },
      {
        "regionId": 4008,
        "regionCode": "15",
        "regionName": "Monte Cristi"
      },
      {
        "regionId": 4009,
        "regionCode": "12",
        "regionName": "La Romana"
      },
      {
        "regionId": 4010,
        "regionCode": "02",
        "regionName": "Azua"
      },
      {
        "regionId": 4011,
        "regionCode": "17",
        "regionName": "Peravia"
      },
      {
        "regionId": 4012,
        "regionCode": "21",
        "regionName": "San Cristóbal"
      },
      {
        "regionId": 4013,
        "regionCode": "31",
        "regionName": "San José de Ocoa"
      },
      {
        "regionId": 4014,
        "regionCode": "08",
        "regionName": "El Seibo"
      },
      {
        "regionId": 4015,
        "regionCode": "11",
        "regionName": "La Altagracia"
      },
      {
        "regionId": 4016,
        "regionCode": "10",
        "regionName": "Independencia"
      },
      {
        "regionId": 4017,
        "regionCode": "16",
        "regionName": "Pedernales"
      },
      {
        "regionId": 4018,
        "regionCode": "23",
        "regionName": "San Pedro de Macorís"
      },
      {
        "regionId": 4019,
        "regionCode": "29",
        "regionName": "Monte Plata"
      },
      {
        "regionId": 4020,
        "regionCode": "30",
        "regionName": "Hato Mayor"
      },
      {
        "regionId": 4021,
        "regionCode": "32",
        "regionName": "Santo Domingo"
      },
      {
        "regionId": 4022,
        "regionCode": "24",
        "regionName": "Sánchez Ramírez"
      },
      {
        "regionId": 4023,
        "regionCode": "28",
        "regionName": "Monseñor Nouel"
      },
      {
        "regionId": 4024,
        "regionCode": "07",
        "regionName": "Elías Piña"
      },
      {
        "regionId": 4025,
        "regionCode": "22",
        "regionName": "San Juan"
      },
      {
        "regionId": 4026,
        "regionCode": "03",
        "regionName": "Baoruco"
      },
      {
        "regionId": 4027,
        "regionCode": "04",
        "regionName": "Barahona"
      },
      {
        "regionId": 4028,
        "regionCode": "26",
        "regionName": "Santiago Rodríguez"
      },
      {
        "regionId": 4029,
        "regionCode": "27",
        "regionName": "Valverde"
      },
      {
        "regionId": 4030,
        "regionCode": "09",
        "regionName": "Espaillat"
      },
      {
        "regionId": 4031,
        "regionCode": "18",
        "regionName": "Puerto Plata"
      },
      {
        "regionId": 4032,
        "regionCode": "25",
        "regionName": "Santiago"
      },
      {
        "regionId": 4033,
        "regionCode": "13",
        "regionName": "La Vega"
      }
    ]
  },
  {
    "countryId": 61,
    "countryCode": "EC",
    "countryName": "Ecuador",
    "regions": [
      {
        "regionId": 985,
        "regionName": "Azuay"
      },
      {
        "regionId": 986,
        "regionName": "Bolivar"
      },
      {
        "regionId": 987,
        "regionName": "Canar"
      },
      {
        "regionId": 988,
        "regionName": "Carchi"
      },
      {
        "regionId": 989,
        "regionName": "Chimborazo"
      },
      {
        "regionId": 990,
        "regionName": "Cotopaxi"
      },
      {
        "regionId": 991,
        "regionName": "El Oro"
      },
      {
        "regionId": 992,
        "regionName": "Esmeraldas"
      },
      {
        "regionId": 993,
        "regionName": "Galapagos"
      },
      {
        "regionId": 994,
        "regionName": "Guayas"
      },
      {
        "regionId": 995,
        "regionName": "Imbabura"
      },
      {
        "regionId": 996,
        "regionName": "Loja"
      },
      {
        "regionId": 997,
        "regionName": "Los Rios"
      },
      {
        "regionId": 998,
        "regionName": "Manabi"
      },
      {
        "regionId": 999,
        "regionName": "Morona Santiago"
      },
      {
        "regionId": 1000,
        "regionName": "Napo"
      },
      {
        "regionId": 1001,
        "regionName": "Orellana"
      },
      {
        "regionId": 1002,
        "regionName": "Pastaza"
      },
      {
        "regionId": 1003,
        "regionName": "Pichincha"
      },
      {
        "regionId": 1004,
        "regionName": "Sucumbios"
      },
      {
        "regionId": 1005,
        "regionName": "Tungurahua"
      },
      {
        "regionId": 1006,
        "regionName": "Zamora Chinchipe"
      }
    ]
  },
  {
    "countryId": 62,
    "countryCode": "EG",
    "countryName": "Egypt",
    "regions": [
      {
        "regionId": 1022,
        "regionName": "ad-Daqahliyah"
      },
      {
        "regionId": 1023,
        "regionName": "al-Bahr-al-Ahmar"
      },
      {
        "regionId": 1024,
        "regionName": "al-Buhayrah"
      },
      {
        "regionId": 1025,
        "regionName": "al-Fayyum"
      },
      {
        "regionId": 1026,
        "regionName": "al-Gharbiyah"
      },
      {
        "regionId": 1027,
        "regionName": "al-Iskandariyah"
      },
      {
        "regionId": 1028,
        "regionName": "al-Ismailiyah"
      },
      {
        "regionId": 1029,
        "regionName": "al-Jizah"
      },
      {
        "regionId": 1030,
        "regionName": "al-Minufiyah"
      },
      {
        "regionId": 1031,
        "regionName": "al-Minya"
      },
      {
        "regionId": 1032,
        "regionName": "al-Qahira"
      },
      {
        "regionId": 1033,
        "regionName": "al-Qalyubiyah"
      },
      {
        "regionId": 1034,
        "regionName": "al-Uqsur"
      },
      {
        "regionId": 1035,
        "regionName": "al-Wadi al-Jadid"
      },
      {
        "regionId": 1036,
        "regionName": "ash-Sharqiyah"
      },
      {
        "regionId": 1037,
        "regionName": "as-Suways"
      },
      {
        "regionId": 1038,
        "regionName": "Aswan"
      },
      {
        "regionId": 1039,
        "regionName": "Asyut"
      },
      {
        "regionId": 1040,
        "regionName": "Bani Suwayf"
      },
      {
        "regionId": 1041,
        "regionName": "Bur Sa'id"
      },
      {
        "regionId": 1042,
        "regionName": "Cairo"
      },
      {
        "regionId": 1043,
        "regionName": "Dumyat"
      },
      {
        "regionId": 1044,
        "regionName": "Kafr-ash-Shaykh"
      },
      {
        "regionId": 1045,
        "regionName": "Matruh"
      },
      {
        "regionId": 1046,
        "regionName": "Muhafazat ad Daqahliyah"
      },
      {
        "regionId": 1047,
        "regionName": "Muhafazat al Fayyum"
      },
      {
        "regionId": 1048,
        "regionName": "Muhafazat al Gharbiyah"
      },
      {
        "regionId": 1049,
        "regionName": "Muhafazat al Iskandariyah"
      },
      {
        "regionId": 1050,
        "regionName": "Muhafazat al Qahirah"
      },
      {
        "regionId": 1051,
        "regionName": "Qina"
      },
      {
        "regionId": 1052,
        "regionName": "Sawhaj"
      },
      {
        "regionId": 1053,
        "regionName": "Sina al-Janubiyah"
      },
      {
        "regionId": 1054,
        "regionName": "Sina ash-Shamaliyah"
      }
    ]
  },
  {
    "countryId": 63,
    "countryCode": "SV",
    "countryName": "El Salvador",
    "regions": [
      {
        "regionId": 3431,
        "regionCode": "AH",
        "regionName": "Ahuachapan"
      },
      {
        "regionId": 3432,
        "regionCode": "CA",
        "regionName": "Cabanas"
      },
      {
        "regionId": 3433,
        "regionCode": "CH",
        "regionName": "Chalatenango"
      },
      {
        "regionId": 3434,
        "regionCode": "CU",
        "regionName": "Cuscatlan"
      },
      {
        "regionId": 3435,
        "regionCode": "LI",
        "regionName": "La Libertad"
      },
      {
        "regionId": 3436,
        "regionCode": "PA",
        "regionName": "La Paz"
      },
      {
        "regionId": 3437,
        "regionCode": "UN",
        "regionName": "La Union"
      },
      {
        "regionId": 3438,
        "regionCode": "MO",
        "regionName": "Morazan"
      },
      {
        "regionId": 3439,
        "regionCode": "SM",
        "regionName": "San Miguel"
      },
      {
        "regionId": 3440,
        "regionCode": "SS",
        "regionName": "San Salvador"
      },
      {
        "regionId": 3441,
        "regionCode": "SV",
        "regionName": "San Vicente"
      },
      {
        "regionId": 3442,
        "regionCode": "SA",
        "regionName": "Santa Ana"
      },
      {
        "regionId": 3443,
        "regionCode": "SO",
        "regionName": "Sonsonate"
      },
      {
        "regionId": 3444,
        "regionCode": "US",
        "regionName": "Usulutan"
      }
    ]
  },
  {
    "countryId": 64,
    "countryCode": "GQ",
    "countryName": "Equatorial Guinea",
    "regions": [
      {
        "regionId": 1541,
        "regionName": "Annobon"
      },
      {
        "regionId": 1542,
        "regionName": "Bioko Norte"
      },
      {
        "regionId": 1543,
        "regionName": "Bioko Sur"
      },
      {
        "regionId": 1544,
        "regionName": "Centro Sur"
      },
      {
        "regionId": 1545,
        "regionName": "Kie-Ntem"
      },
      {
        "regionId": 1546,
        "regionName": "Litoral"
      },
      {
        "regionId": 1547,
        "regionName": "Wele-Nzas"
      }
    ]
  },
  {
    "countryId": 65,
    "countryCode": "ER",
    "countryName": "Eritrea",
    "regions": [
      {
        "regionId": 1059,
        "regionName": "Anseba"
      },
      {
        "regionId": 1060,
        "regionName": "Debub"
      },
      {
        "regionId": 1061,
        "regionName": "Debub-Keih-Bahri"
      },
      {
        "regionId": 1062,
        "regionName": "Gash-Barka"
      },
      {
        "regionId": 1063,
        "regionName": "Maekel"
      },
      {
        "regionId": 1064,
        "regionName": "Semien-Keih-Bahri"
      }
    ]
  },
  {
    "countryId": 66,
    "countryCode": "EE",
    "countryName": "Estonia",
    "regions": [
      {
        "regionId": 1007,
        "regionName": "Harju"
      },
      {
        "regionId": 1008,
        "regionName": "Hiiu"
      },
      {
        "regionId": 1009,
        "regionName": "Ida-Viru"
      },
      {
        "regionId": 1010,
        "regionName": "Jarva"
      },
      {
        "regionId": 1011,
        "regionName": "Jogeva"
      },
      {
        "regionId": 1012,
        "regionName": "Laane"
      },
      {
        "regionId": 1013,
        "regionName": "Laane-Viru"
      },
      {
        "regionId": 1014,
        "regionName": "Parnu"
      },
      {
        "regionId": 1015,
        "regionName": "Polva"
      },
      {
        "regionId": 1016,
        "regionName": "Rapla"
      },
      {
        "regionId": 1017,
        "regionName": "Saare"
      },
      {
        "regionId": 1018,
        "regionName": "Tartu"
      },
      {
        "regionId": 1019,
        "regionName": "Valga"
      },
      {
        "regionId": 1020,
        "regionName": "Viljandi"
      },
      {
        "regionId": 1021,
        "regionName": "Voru"
      }
    ]
  },
  {
    "countryId": 67,
    "countryCode": "ET",
    "countryName": "Ethiopia",
    "regions": [
      {
        "regionId": 1131,
        "regionName": "Addis Abeba"
      },
      {
        "regionId": 1132,
        "regionName": "Afar"
      },
      {
        "regionId": 1133,
        "regionName": "Amhara"
      },
      {
        "regionId": 1134,
        "regionName": "Benishangul"
      },
      {
        "regionId": 1135,
        "regionName": "Diredawa"
      },
      {
        "regionId": 1136,
        "regionName": "Gambella"
      },
      {
        "regionId": 1137,
        "regionName": "Harar"
      },
      {
        "regionId": 1138,
        "regionName": "Jigjiga"
      },
      {
        "regionId": 1139,
        "regionName": "Mekele"
      },
      {
        "regionId": 1140,
        "regionName": "Oromia"
      },
      {
        "regionId": 1141,
        "regionName": "Somali"
      },
      {
        "regionId": 1142,
        "regionName": "Southern"
      },
      {
        "regionId": 1143,
        "regionName": "Tigray"
      }
    ]
  },
  {
    "countryId": 68,
    "countryCode": "FK",
    "countryName": "Falkland Islands (Islas Malvinas)",
    "regions": [
      {
        "regionId": 1179,
        "regionName": "Falkland Islands"
      },
      {
        "regionId": 1180,
        "regionName": "South Georgia"
      }
    ]
  },
  {
    "countryId": 69,
    "countryCode": "FO",
    "countryName": "Faroe Islands",
    "regions": [
      {
        "regionId": 1185,
        "regionName": "Klaksvik"
      },
      {
        "regionId": 1186,
        "regionName": "Nor ara Eysturoy"
      },
      {
        "regionId": 1187,
        "regionName": "Nor oy"
      },
      {
        "regionId": 1188,
        "regionName": "Sandoy"
      },
      {
        "regionId": 1189,
        "regionName": "Streymoy"
      },
      {
        "regionId": 1190,
        "regionName": "Su uroy"
      },
      {
        "regionId": 1191,
        "regionName": "Sy ra Eysturoy"
      },
      {
        "regionId": 1192,
        "regionName": "Torshavn"
      },
      {
        "regionId": 1193,
        "regionName": "Vaga"
      }
    ]
  },
  {
    "countryId": 70,
    "countryCode": "FJ",
    "countryName": "Fiji",
    "regions": [
      {
        "regionId": 1174,
        "regionName": "Central"
      },
      {
        "regionId": 1175,
        "regionName": "Eastern"
      },
      {
        "regionId": 1176,
        "regionName": "Northern"
      },
      {
        "regionId": 1177,
        "regionName": "South Pacific"
      },
      {
        "regionId": 1178,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 71,
    "countryCode": "FI",
    "countryName": "Finland",
    "regions": [
      {
        "regionId": 1144,
        "regionCode": "001",
        "regionName": "Ahvenanmaa"
      },
      {
        "regionId": 1145,
        "regionName": "Etela-Karjala"
      },
      {
        "regionId": 1146,
        "regionName": "Etela-Pohjanmaa"
      },
      {
        "regionId": 1147,
        "regionName": "Etela-Savo"
      },
      {
        "regionId": 1148,
        "regionName": "Etela-Suomen Laani"
      },
      {
        "regionId": 1149,
        "regionName": "Ita-Suomen Laani"
      },
      {
        "regionId": 1150,
        "regionName": "Ita-Uusimaa"
      },
      {
        "regionId": 1151,
        "regionName": "Kainuu"
      },
      {
        "regionId": 1152,
        "regionName": "Kanta-Hame"
      },
      {
        "regionId": 1153,
        "regionName": "Keski-Pohjanmaa"
      },
      {
        "regionId": 1154,
        "regionName": "Keski-Suomi"
      },
      {
        "regionId": 1155,
        "regionName": "Kymenlaakso"
      },
      {
        "regionId": 1156,
        "regionName": "Lansi-Suomen Laani"
      },
      {
        "regionId": 1157,
        "regionCode": "004",
        "regionName": "Lappi"
      },
      {
        "regionId": 1158,
        "regionName": "Northern Savonia"
      },
      {
        "regionId": 1159,
        "regionName": "Ostrobothnia"
      },
      {
        "regionId": 1160,
        "regionName": "Oulun Laani"
      },
      {
        "regionId": 1161,
        "regionName": "Paijat-Hame"
      },
      {
        "regionId": 1162,
        "regionName": "Pirkanmaa"
      },
      {
        "regionId": 1163,
        "regionName": "Pohjanmaa"
      },
      {
        "regionId": 1164,
        "regionName": "Pohjois-Karjala"
      },
      {
        "regionId": 1165,
        "regionName": "Pohjois-Pohjanmaa"
      },
      {
        "regionId": 1166,
        "regionName": "Pohjois-Savo"
      },
      {
        "regionId": 1167,
        "regionName": "Saarijarvi"
      },
      {
        "regionId": 1168,
        "regionName": "Satakunta"
      },
      {
        "regionId": 1169,
        "regionName": "Southern Savonia"
      },
      {
        "regionId": 1170,
        "regionName": "Tavastia Proper"
      },
      {
        "regionId": 1171,
        "regionName": "Uleaborgs Lan"
      },
      {
        "regionId": 1172,
        "regionName": "Uusimaa"
      },
      {
        "regionId": 1173,
        "regionName": "Varsinais-Suomi"
      }
    ]
  },
  {
    "countryId": 72,
    "countryCode": "FR",
    "countryName": "France",
    "regions": [
      {
        "regionId": 1194,
        "regionCode": "01",
        "regionName": "Ain"
      },
      {
        "regionId": 1195,
        "regionCode": "02",
        "regionName": "Aisne"
      },
      {
        "regionId": 1196,
        "regionName": "Albi Le Sequestre"
      },
      {
        "regionId": 1197,
        "regionCode": "03",
        "regionName": "Allier"
      },
      {
        "regionId": 1198,
        "regionName": "Alpes-Cote dAzur"
      },
      {
        "regionId": 1199,
        "regionName": "Alpes-de-Haute-Provence"
      },
      {
        "regionId": 1200,
        "regionCode": "06",
        "regionName": "Alpes-Maritimes"
      },
      {
        "regionId": 1201,
        "regionName": "Alsace"
      },
      {
        "regionId": 1202,
        "regionName": "Aquitaine"
      },
      {
        "regionId": 1203,
        "regionName": "Ardeche"
      },
      {
        "regionId": 1204,
        "regionCode": "08",
        "regionName": "Ardennes"
      },
      {
        "regionId": 1205,
        "regionName": "Ariege"
      },
      {
        "regionId": 1206,
        "regionCode": "10",
        "regionName": "Aube"
      },
      {
        "regionId": 1207,
        "regionCode": "11",
        "regionName": "Aude"
      },
      {
        "regionId": 1208,
        "regionName": "Auvergne"
      },
      {
        "regionId": 1209,
        "regionCode": "12",
        "regionName": "Aveyron"
      },
      {
        "regionId": 1210,
        "regionCode": "67",
        "regionName": "Bas-Rhin"
      },
      {
        "regionId": 1211,
        "regionName": "Basse-Normandie"
      },
      {
        "regionId": 1212,
        "regionName": "Bouches-du-Rhone"
      },
      {
        "regionId": 1213,
        "regionName": "Bourgogne"
      },
      {
        "regionId": 1214,
        "regionName": "Bretagne"
      },
      {
        "regionId": 1215,
        "regionName": "Brittany"
      },
      {
        "regionId": 1216,
        "regionName": "Burgundy"
      },
      {
        "regionId": 1217,
        "regionCode": "14",
        "regionName": "Calvados"
      },
      {
        "regionId": 1218,
        "regionCode": "15",
        "regionName": "Cantal"
      },
      {
        "regionId": 1219,
        "regionName": "Cedex"
      },
      {
        "regionId": 1220,
        "regionName": "Centre"
      },
      {
        "regionId": 1221,
        "regionCode": "16",
        "regionName": "Charente"
      },
      {
        "regionId": 1222,
        "regionCode": "17",
        "regionName": "Charente-Maritime"
      },
      {
        "regionId": 1223,
        "regionCode": "18",
        "regionName": "Cher"
      },
      {
        "regionId": 1224,
        "regionName": "Correze"
      },
      {
        "regionId": 1225,
        "regionCode": "2A",
        "regionName": "Corse-du-Sud"
      },
      {
        "regionId": 1226,
        "regionName": "Cote-d'Or"
      },
      {
        "regionId": 1227,
        "regionName": "Cotes-d'Armor"
      },
      {
        "regionId": 1228,
        "regionCode": "23",
        "regionName": "Creuse"
      },
      {
        "regionId": 1229,
        "regionName": "Crolles"
      },
      {
        "regionId": 1230,
        "regionName": "Deux-Sevres"
      },
      {
        "regionId": 1231,
        "regionCode": "24",
        "regionName": "Dordogne"
      },
      {
        "regionId": 1232,
        "regionCode": "25",
        "regionName": "Doubs"
      },
      {
        "regionId": 1233,
        "regionName": "Drome"
      },
      {
        "regionId": 1234,
        "regionCode": "91",
        "regionName": "Essonne"
      },
      {
        "regionId": 1235,
        "regionCode": "27",
        "regionName": "Eure"
      },
      {
        "regionId": 1236,
        "regionCode": "28",
        "regionName": "Eure-et-Loir"
      },
      {
        "regionId": 1237,
        "regionName": "Feucherolles"
      },
      {
        "regionId": 1238,
        "regionName": "Finistere"
      },
      {
        "regionId": 1239,
        "regionName": "Franche-Comte"
      },
      {
        "regionId": 1240,
        "regionCode": "30",
        "regionName": "Gard"
      },
      {
        "regionId": 1241,
        "regionCode": "32",
        "regionName": "Gers"
      },
      {
        "regionId": 1242,
        "regionCode": "33",
        "regionName": "Gironde"
      },
      {
        "regionId": 1243,
        "regionName": "Haute-Corse"
      },
      {
        "regionId": 1244,
        "regionName": "Haute-Garonne"
      },
      {
        "regionId": 1245,
        "regionName": "Haute-Loire"
      },
      {
        "regionId": 1246,
        "regionName": "Haute-Marne"
      },
      {
        "regionId": 1247,
        "regionName": "Hautes-Alpes"
      },
      {
        "regionId": 1248,
        "regionName": "Haute-Saone"
      },
      {
        "regionId": 1249,
        "regionName": "Haute-Savoie"
      },
      {
        "regionId": 1250,
        "regionName": "Hautes-Pyrenees"
      },
      {
        "regionId": 1251,
        "regionName": "Haute-Vienne"
      },
      {
        "regionId": 1252,
        "regionCode": "68",
        "regionName": "Haut-Rhin"
      },
      {
        "regionId": 1253,
        "regionCode": "92",
        "regionName": "Hauts-de-Seine"
      },
      {
        "regionId": 1254,
        "regionName": "Herault"
      },
      {
        "regionId": 1255,
        "regionName": "Ile-de-France"
      },
      {
        "regionId": 1256,
        "regionCode": "35",
        "regionName": "Ille-et-Vilaine"
      },
      {
        "regionId": 1257,
        "regionCode": "36",
        "regionName": "Indre"
      },
      {
        "regionId": 1258,
        "regionCode": "37",
        "regionName": "Indre-et-Loire"
      },
      {
        "regionId": 1259,
        "regionName": "Isere"
      },
      {
        "regionId": 1260,
        "regionCode": "39",
        "regionName": "Jura"
      },
      {
        "regionId": 1261,
        "regionName": "Klagenfurt"
      },
      {
        "regionId": 1262,
        "regionCode": "40",
        "regionName": "Landes"
      },
      {
        "regionId": 1263,
        "regionName": "Languedoc-Roussillon"
      },
      {
        "regionId": 1264,
        "regionName": "Larcay"
      },
      {
        "regionId": 1265,
        "regionName": "Le Castellet"
      },
      {
        "regionId": 1266,
        "regionName": "Le Creusot"
      },
      {
        "regionId": 1267,
        "regionName": "Limousin"
      },
      {
        "regionId": 1268,
        "regionCode": "42",
        "regionName": "Loire"
      },
      {
        "regionId": 1269,
        "regionCode": "44",
        "regionName": "Loire-Atlantique"
      },
      {
        "regionId": 1270,
        "regionCode": "45",
        "regionName": "Loiret"
      },
      {
        "regionId": 1271,
        "regionCode": "41",
        "regionName": "Loir-et-Cher"
      },
      {
        "regionId": 1272,
        "regionName": "Lorraine"
      },
      {
        "regionId": 1273,
        "regionCode": "46",
        "regionName": "Lot"
      },
      {
        "regionId": 1274,
        "regionCode": "47",
        "regionName": "Lot-et-Garonne"
      },
      {
        "regionId": 1275,
        "regionName": "Lower Normandy"
      },
      {
        "regionId": 1276,
        "regionName": "Lozere"
      },
      {
        "regionId": 1277,
        "regionCode": "49",
        "regionName": "Maine-et-Loire"
      },
      {
        "regionId": 1278,
        "regionCode": "50",
        "regionName": "Manche"
      },
      {
        "regionId": 1279,
        "regionCode": "51",
        "regionName": "Marne"
      },
      {
        "regionId": 1280,
        "regionCode": "53",
        "regionName": "Mayenne"
      },
      {
        "regionId": 1281,
        "regionCode": "54",
        "regionName": "Meurthe-et-Moselle"
      },
      {
        "regionId": 1282,
        "regionCode": "55",
        "regionName": "Meuse"
      },
      {
        "regionId": 1283,
        "regionName": "Midi-Pyrenees"
      },
      {
        "regionId": 1284,
        "regionCode": "56",
        "regionName": "Morbihan"
      },
      {
        "regionId": 1285,
        "regionCode": "57",
        "regionName": "Moselle"
      },
      {
        "regionId": 1286,
        "regionName": "Nievre"
      },
      {
        "regionId": 1287,
        "regionCode": "59",
        "regionName": "Nord"
      },
      {
        "regionId": 1288,
        "regionName": "Nord-Pas-de-Calais"
      },
      {
        "regionId": 1289,
        "regionCode": "60",
        "regionName": "Oise"
      },
      {
        "regionId": 1290,
        "regionCode": "61",
        "regionName": "Orne"
      },
      {
        "regionId": 1291,
        "regionCode": "75",
        "regionName": "Paris"
      },
      {
        "regionId": 1292,
        "regionCode": "62",
        "regionName": "Pas-de-Calais"
      },
      {
        "regionId": 1293,
        "regionName": "Pays de la Loire"
      },
      {
        "regionId": 1294,
        "regionName": "Pays-de-la-Loire"
      },
      {
        "regionId": 1295,
        "regionName": "Picardy"
      },
      {
        "regionId": 1296,
        "regionName": "Puy-de-Dome"
      },
      {
        "regionId": 1297,
        "regionName": "Pyrenees-Atlantiques"
      },
      {
        "regionId": 1298,
        "regionName": "Pyrenees-Orientales"
      },
      {
        "regionId": 1299,
        "regionName": "Quelmes"
      },
      {
        "regionId": 1300,
        "regionName": "Rhone"
      },
      {
        "regionId": 1301,
        "regionName": "Rhone-Alpes"
      },
      {
        "regionId": 1302,
        "regionName": "Saint Ouen"
      },
      {
        "regionId": 1303,
        "regionName": "Saint Viatre"
      },
      {
        "regionId": 1304,
        "regionName": "Saone-et-Loire"
      },
      {
        "regionId": 1305,
        "regionCode": "72",
        "regionName": "Sarthe"
      },
      {
        "regionId": 1306,
        "regionCode": "73",
        "regionName": "Savoie"
      },
      {
        "regionId": 1307,
        "regionCode": "77",
        "regionName": "Seine-et-Marne"
      },
      {
        "regionId": 1308,
        "regionCode": "76",
        "regionName": "Seine-Maritime"
      },
      {
        "regionId": 1309,
        "regionCode": "93",
        "regionName": "Seine-Saint-Denis"
      },
      {
        "regionId": 1310,
        "regionCode": "80",
        "regionName": "Somme"
      },
      {
        "regionId": 1311,
        "regionName": "Sophia Antipolis"
      },
      {
        "regionId": 1312,
        "regionName": "Souvans"
      },
      {
        "regionId": 1313,
        "regionCode": "81",
        "regionName": "Tarn"
      },
      {
        "regionId": 1314,
        "regionCode": "82",
        "regionName": "Tarn-et-Garonne"
      },
      {
        "regionId": 1315,
        "regionName": "Territoire de Belfort"
      },
      {
        "regionId": 1316,
        "regionName": "Treignac"
      },
      {
        "regionId": 1317,
        "regionName": "Upper Normandy"
      },
      {
        "regionId": 1318,
        "regionCode": "94",
        "regionName": "Val-de-Marne"
      },
      {
        "regionId": 1319,
        "regionCode": "95",
        "regionName": "Val-d'Oise"
      },
      {
        "regionId": 1320,
        "regionCode": "83",
        "regionName": "Var"
      },
      {
        "regionId": 1321,
        "regionCode": "84",
        "regionName": "Vaucluse"
      },
      {
        "regionId": 1322,
        "regionName": "Vellise"
      },
      {
        "regionId": 1323,
        "regionName": "Vendee"
      },
      {
        "regionId": 1324,
        "regionCode": "86",
        "regionName": "Vienne"
      },
      {
        "regionId": 1325,
        "regionCode": "88",
        "regionName": "Vosges"
      },
      {
        "regionId": 1326,
        "regionCode": "89",
        "regionName": "Yonne"
      },
      {
        "regionId": 1327,
        "regionCode": "78",
        "regionName": "Yvelines"
      }
    ]
  },
  {
    "countryId": 73,
    "countryCode": "GF",
    "countryName": "French Guiana",
    "regions": [
      {
        "regionId": 1469,
        "regionName": "Cayenne"
      },
      {
        "regionId": 1470,
        "regionName": "Saint-Laurent-du-Maroni"
      }
    ]
  },
  {
    "countryId": 74,
    "countryCode": "PF",
    "countryName": "French Polynesia",
    "regions": [
      {
        "regionId": 2930,
        "regionName": "Iles du Vent"
      },
      {
        "regionId": 2931,
        "regionName": "Iles sous le Vent"
      },
      {
        "regionId": 2932,
        "regionName": "Marquesas"
      },
      {
        "regionId": 2933,
        "regionName": "Tuamotu"
      },
      {
        "regionId": 2934,
        "regionName": "Tubuai"
      }
    ]
  },
  {
    "countryId": 75,
    "countryCode": "GA",
    "countryName": "Gabon",
    "regions": [
      {
        "regionId": 1328,
        "regionName": "Estuaire"
      },
      {
        "regionId": 1329,
        "regionName": "Haut-Ogooue"
      },
      {
        "regionId": 1330,
        "regionName": "Moyen-Ogooue"
      },
      {
        "regionId": 1331,
        "regionName": "Ngounie"
      },
      {
        "regionId": 1332,
        "regionName": "Nyanga"
      },
      {
        "regionId": 1333,
        "regionName": "Ogooue-Ivindo"
      },
      {
        "regionId": 1334,
        "regionName": "Ogooue-Lolo"
      },
      {
        "regionId": 1335,
        "regionName": "Ogooue-Maritime"
      },
      {
        "regionId": 1336,
        "regionName": "Woleu-Ntem"
      }
    ]
  },
  {
    "countryId": 76,
    "countryCode": "GM",
    "countryName": "Gambia",
    "regions": [
      {
        "regionId": 1501,
        "regionName": "Banjul"
      },
      {
        "regionId": 1502,
        "regionName": "Basse"
      },
      {
        "regionId": 1503,
        "regionName": "Brikama"
      },
      {
        "regionId": 1504,
        "regionName": "Janjanbureh"
      },
      {
        "regionId": 1505,
        "regionName": "Kanifing"
      },
      {
        "regionId": 1506,
        "regionName": "Kerewan"
      },
      {
        "regionId": 1507,
        "regionName": "Kuntaur"
      },
      {
        "regionId": 1508,
        "regionName": "Mansakonko"
      }
    ]
  },
  {
    "countryId": 77,
    "countryCode": "GE",
    "countryName": "Georgia",
    "regions": [
      {
        "regionId": 1457,
        "regionName": "Abhasia"
      },
      {
        "regionId": 1458,
        "regionName": "Ajaria"
      },
      {
        "regionId": 1459,
        "regionName": "Guria"
      },
      {
        "regionId": 1460,
        "regionName": "Imereti"
      },
      {
        "regionId": 1461,
        "regionName": "Kaheti"
      },
      {
        "regionId": 1462,
        "regionName": "Kvemo Kartli"
      },
      {
        "regionId": 1463,
        "regionName": "Mcheta-Mtianeti"
      },
      {
        "regionId": 1464,
        "regionName": "Racha"
      },
      {
        "regionId": 1465,
        "regionName": "Samagrelo-Zemo Svaneti"
      },
      {
        "regionId": 1466,
        "regionName": "Samche-Zhavaheti"
      },
      {
        "regionId": 1467,
        "regionName": "Shida Kartli"
      },
      {
        "regionId": 1468,
        "regionName": "Tbilisi"
      }
    ]
  },
  {
    "countryId": 78,
    "countryCode": "DE",
    "countryName": "Germany",
    "regions": [
      {
        "regionId": 816,
        "regionName": "Auvergne"
      },
      {
        "regionId": 817,
        "regionCode": "08",
        "regionName": "Baden-Wurttemberg"
      },
      {
        "regionId": 818,
        "regionCode": "09",
        "regionName": "Bavaria"
      },
      {
        "regionId": 819,
        "regionName": "Bayern"
      },
      {
        "regionId": 820,
        "regionName": "Beilstein Wurtt"
      },
      {
        "regionId": 821,
        "regionCode": "11",
        "regionName": "Berlin"
      },
      {
        "regionId": 822,
        "regionCode": "12",
        "regionName": "Brandenburg"
      },
      {
        "regionId": 823,
        "regionCode": "04",
        "regionName": "Bremen"
      },
      {
        "regionId": 824,
        "regionName": "Dreisbach"
      },
      {
        "regionId": 825,
        "regionName": "Freistaat Bayern"
      },
      {
        "regionId": 826,
        "regionCode": "02",
        "regionName": "Hamburg"
      },
      {
        "regionId": 827,
        "regionName": "Hannover"
      },
      {
        "regionId": 828,
        "regionName": "Heroldstatt"
      },
      {
        "regionId": 829,
        "regionName": "Hessen"
      },
      {
        "regionId": 830,
        "regionName": "Kortenberg"
      },
      {
        "regionId": 831,
        "regionName": "Laasdorf"
      },
      {
        "regionId": 832,
        "regionName": "Land Baden-Wurttemberg"
      },
      {
        "regionId": 833,
        "regionName": "Land Bayern"
      },
      {
        "regionId": 834,
        "regionName": "Land Brandenburg"
      },
      {
        "regionId": 835,
        "regionName": "Land Hessen"
      },
      {
        "regionId": 836,
        "regionName": "Land Mecklenburg-Vorpommern"
      },
      {
        "regionId": 837,
        "regionName": "Land Nordrhein-Westfalen"
      },
      {
        "regionId": 838,
        "regionName": "Land Rheinland-Pfalz"
      },
      {
        "regionId": 839,
        "regionName": "Land Sachsen"
      },
      {
        "regionId": 840,
        "regionName": "Land Sachsen-Anhalt"
      },
      {
        "regionId": 841,
        "regionName": "Land Thuringen"
      },
      {
        "regionId": 842,
        "regionCode": "03",
        "regionName": "Lower Saxony"
      },
      {
        "regionId": 843,
        "regionName": "Mecklenburg-Vorpommern"
      },
      {
        "regionId": 844,
        "regionName": "Mulfingen"
      },
      {
        "regionId": 845,
        "regionName": "Munich"
      },
      {
        "regionId": 846,
        "regionName": "Neubeuern"
      },
      {
        "regionId": 847,
        "regionName": "Niedersachsen"
      },
      {
        "regionId": 848,
        "regionName": "Noord-Holland"
      },
      {
        "regionId": 849,
        "regionName": "Nordrhein-Westfalen"
      },
      {
        "regionId": 850,
        "regionName": "North Rhine-Westphalia"
      },
      {
        "regionId": 851,
        "regionName": "Osterode"
      },
      {
        "regionId": 852,
        "regionName": "Rheinland-Pfalz"
      },
      {
        "regionId": 853,
        "regionName": "Rhineland-Palatinate"
      },
      {
        "regionId": 854,
        "regionCode": "10",
        "regionName": "Saarland"
      },
      {
        "regionId": 855,
        "regionName": "Sachsen"
      },
      {
        "regionId": 856,
        "regionName": "Sachsen-Anhalt"
      },
      {
        "regionId": 857,
        "regionCode": "14",
        "regionName": "Saxony"
      },
      {
        "regionId": 858,
        "regionCode": "01",
        "regionName": "Schleswig-Holstein"
      },
      {
        "regionId": 859,
        "regionName": "schlobborn"
      },
      {
        "regionId": 860,
        "regionCode": "16",
        "regionName": "Thuringia"
      },
      {
        "regionId": 861,
        "regionName": "Webling"
      },
      {
        "regionId": 862,
        "regionName": "Weinstrabe"
      }
    ]
  },
  {
    "countryId": 79,
    "countryCode": "GH",
    "countryName": "Ghana",
    "regions": [
      {
        "regionId": 1471,
        "regionName": "Ashanti"
      },
      {
        "regionId": 1472,
        "regionName": "Brong-Ahafo"
      },
      {
        "regionId": 1473,
        "regionName": "Central"
      },
      {
        "regionId": 1474,
        "regionName": "Eastern"
      },
      {
        "regionId": 1475,
        "regionName": "Greater Accra"
      },
      {
        "regionId": 1476,
        "regionName": "Northern"
      },
      {
        "regionId": 1477,
        "regionName": "Upper East"
      },
      {
        "regionId": 1478,
        "regionName": "Upper West"
      },
      {
        "regionId": 1479,
        "regionName": "Volta"
      },
      {
        "regionId": 1480,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 80,
    "countryCode": "GI",
    "countryName": "Gibraltar",
    "regions": [
      {
        "regionId": 1481,
        "regionName": "Gibraltar"
      }
    ]
  },
  {
    "countryId": 81,
    "countryCode": "GR",
    "countryName": "Greece",
    "regions": [
      {
        "regionId": 1548,
        "regionName": "Acharnes"
      },
      {
        "regionId": 1549,
        "regionName": "Ahaia"
      },
      {
        "regionId": 1550,
        "regionName": "Aitolia kai Akarnania"
      },
      {
        "regionId": 1551,
        "regionCode": "03",
        "regionName": "Argolis"
      },
      {
        "regionId": 1552,
        "regionName": "Arkadia"
      },
      {
        "regionId": 1553,
        "regionCode": "05",
        "regionName": "Arta"
      },
      {
        "regionId": 1554,
        "regionName": "Attica"
      },
      {
        "regionId": 1555,
        "regionCode": "06",
        "regionName": "Attiki"
      },
      {
        "regionId": 1556,
        "regionName": "Ayion Oros"
      },
      {
        "regionId": 1557,
        "regionName": "Crete"
      },
      {
        "regionId": 1558,
        "regionName": "Dodekanisos"
      },
      {
        "regionId": 1559,
        "regionName": "Drama"
      },
      {
        "regionId": 1560,
        "regionName": "Evia"
      },
      {
        "regionId": 1561,
        "regionCode": "09",
        "regionName": "Evritania"
      },
      {
        "regionId": 1562,
        "regionCode": "10",
        "regionName": "Evros"
      },
      {
        "regionId": 1563,
        "regionCode": "11",
        "regionName": "Evvoia"
      },
      {
        "regionId": 1564,
        "regionCode": "12",
        "regionName": "Florina"
      },
      {
        "regionId": 1565,
        "regionCode": "13",
        "regionName": "Fokis"
      },
      {
        "regionId": 1566,
        "regionCode": "14",
        "regionName": "Fthiotis"
      },
      {
        "regionId": 1567,
        "regionCode": "15",
        "regionName": "Grevena"
      },
      {
        "regionId": 1568,
        "regionName": "Halandri"
      },
      {
        "regionId": 1569,
        "regionName": "Halkidiki"
      },
      {
        "regionId": 1570,
        "regionName": "Hania"
      },
      {
        "regionId": 1571,
        "regionName": "Heraklion"
      },
      {
        "regionId": 1572,
        "regionName": "Hios"
      },
      {
        "regionId": 1573,
        "regionCode": "16",
        "regionName": "Ilia"
      },
      {
        "regionId": 1574,
        "regionCode": "17",
        "regionName": "Imathia"
      },
      {
        "regionId": 1575,
        "regionCode": "18",
        "regionName": "Ioannina"
      },
      {
        "regionId": 1576,
        "regionCode": "19",
        "regionName": "Iraklion"
      },
      {
        "regionId": 1577,
        "regionName": "Karditsa"
      },
      {
        "regionId": 1578,
        "regionCode": "21",
        "regionName": "Kastoria"
      },
      {
        "regionId": 1579,
        "regionCode": "22",
        "regionName": "Kavala"
      },
      {
        "regionId": 1580,
        "regionCode": "23",
        "regionName": "Kefallinia"
      },
      {
        "regionId": 1581,
        "regionCode": "24",
        "regionName": "Kerkira"
      },
      {
        "regionId": 1582,
        "regionName": "Kiklades"
      },
      {
        "regionId": 1583,
        "regionCode": "29",
        "regionName": "Kilkis"
      },
      {
        "regionId": 1584,
        "regionCode": "30",
        "regionName": "Korinthia"
      },
      {
        "regionId": 1585,
        "regionCode": "31",
        "regionName": "Kozani"
      },
      {
        "regionId": 1586,
        "regionCode": "32",
        "regionName": "Lakonia"
      },
      {
        "regionId": 1587,
        "regionCode": "33",
        "regionName": "Larisa"
      },
      {
        "regionId": 1588,
        "regionCode": "34",
        "regionName": "Lasithi"
      },
      {
        "regionId": 1589,
        "regionCode": "35",
        "regionName": "Lesvos"
      },
      {
        "regionId": 1590,
        "regionCode": "36",
        "regionName": "Levkas"
      },
      {
        "regionId": 1591,
        "regionCode": "37",
        "regionName": "Magnisia"
      },
      {
        "regionId": 1592,
        "regionCode": "38",
        "regionName": "Messinia"
      },
      {
        "regionId": 1593,
        "regionName": "Nomos Attikis"
      },
      {
        "regionId": 1594,
        "regionName": "Nomos Zakynthou"
      },
      {
        "regionId": 1595,
        "regionCode": "39",
        "regionName": "Pella"
      },
      {
        "regionId": 1596,
        "regionCode": "40",
        "regionName": "Pieria"
      },
      {
        "regionId": 1597,
        "regionName": "Piraios"
      },
      {
        "regionId": 1598,
        "regionCode": "42",
        "regionName": "Preveza"
      },
      {
        "regionId": 1599,
        "regionCode": "43",
        "regionName": "Rethimni"
      },
      {
        "regionId": 1600,
        "regionName": "Rodopi"
      },
      {
        "regionId": 1601,
        "regionCode": "45",
        "regionName": "Samos"
      },
      {
        "regionId": 1602,
        "regionCode": "46",
        "regionName": "Serrai"
      },
      {
        "regionId": 1603,
        "regionCode": "47",
        "regionName": "Thesprotia"
      },
      {
        "regionId": 1604,
        "regionCode": "48",
        "regionName": "Thessaloniki"
      },
      {
        "regionId": 1605,
        "regionCode": "49",
        "regionName": "Trikala"
      },
      {
        "regionId": 1606,
        "regionCode": "50",
        "regionName": "Voiotia"
      },
      {
        "regionId": 1607,
        "regionName": "West Greece"
      },
      {
        "regionId": 1608,
        "regionCode": "51",
        "regionName": "Xanthi"
      },
      {
        "regionId": 1609,
        "regionCode": "52",
        "regionName": "Zakinthos"
      }
    ]
  },
  {
    "countryId": 82,
    "countryCode": "GL",
    "countryName": "Greenland",
    "regions": [
      {
        "regionId": 1482,
        "regionName": "Aasiaat"
      },
      {
        "regionId": 1483,
        "regionName": "Ammassalik"
      },
      {
        "regionId": 1484,
        "regionName": "Illoqqortoormiut"
      },
      {
        "regionId": 1485,
        "regionName": "Ilulissat"
      },
      {
        "regionId": 1486,
        "regionName": "Ivittuut"
      },
      {
        "regionId": 1487,
        "regionName": "Kangaatsiaq"
      },
      {
        "regionId": 1488,
        "regionName": "Maniitsoq"
      },
      {
        "regionId": 1489,
        "regionName": "Nanortalik"
      },
      {
        "regionId": 1490,
        "regionName": "Narsaq"
      },
      {
        "regionId": 1491,
        "regionName": "Nuuk"
      },
      {
        "regionId": 1492,
        "regionName": "Paamiut"
      },
      {
        "regionId": 1493,
        "regionName": "Qaanaaq"
      },
      {
        "regionId": 1494,
        "regionName": "Qaqortoq"
      },
      {
        "regionId": 1495,
        "regionName": "Qasigiannguit"
      },
      {
        "regionId": 1496,
        "regionName": "Qeqertarsuaq"
      },
      {
        "regionId": 1497,
        "regionName": "Sisimiut"
      },
      {
        "regionId": 1498,
        "regionName": "Udenfor kommunal inddeling"
      },
      {
        "regionId": 1499,
        "regionName": "Upernavik"
      },
      {
        "regionId": 1500,
        "regionName": "Uummannaq"
      }
    ]
  },
  {
    "countryId": 83,
    "countryCode": "GD",
    "countryName": "Grenada",
    "regions": [
      {
        "regionId": 1450,
        "regionName": "Carriacou-Petite Martinique"
      },
      {
        "regionId": 1451,
        "regionName": "Saint Andrew"
      },
      {
        "regionId": 1452,
        "regionName": "Saint Davids"
      },
      {
        "regionId": 1453,
        "regionName": "Saint George's"
      },
      {
        "regionId": 1454,
        "regionName": "Saint John"
      },
      {
        "regionId": 1455,
        "regionName": "Saint Mark"
      },
      {
        "regionId": 1456,
        "regionName": "Saint Patrick"
      }
    ]
  },
  {
    "countryId": 84,
    "countryCode": "GU",
    "countryName": "Guam",
    "regions": [
      {
        "regionId": 1632,
        "regionName": "Agana Heights"
      },
      {
        "regionId": 1633,
        "regionName": "Agat"
      },
      {
        "regionId": 1634,
        "regionName": "Barrigada"
      },
      {
        "regionId": 1635,
        "regionName": "Chalan-Pago-Ordot"
      },
      {
        "regionId": 1636,
        "regionName": "Dededo"
      },
      {
        "regionId": 1637,
        "regionName": "Hagatna"
      },
      {
        "regionId": 1638,
        "regionName": "Inarajan"
      },
      {
        "regionId": 1639,
        "regionName": "Mangilao"
      },
      {
        "regionId": 1640,
        "regionName": "Merizo"
      },
      {
        "regionId": 1641,
        "regionName": "Mongmong-Toto-Maite"
      },
      {
        "regionId": 1642,
        "regionName": "Santa Rita"
      },
      {
        "regionId": 1643,
        "regionName": "Sinajana"
      },
      {
        "regionId": 1644,
        "regionName": "Talofofo"
      },
      {
        "regionId": 1645,
        "regionName": "Tamuning"
      },
      {
        "regionId": 1646,
        "regionName": "Yigo"
      },
      {
        "regionId": 1647,
        "regionName": "Yona"
      }
    ]
  },
  {
    "countryId": 85,
    "countryCode": "GT",
    "countryName": "Guatemala",
    "regions": [
      {
        "regionId": 1610,
        "regionName": "Alta Verapaz"
      },
      {
        "regionId": 1611,
        "regionName": "Baja Verapaz"
      },
      {
        "regionId": 1612,
        "regionName": "Chimaltenango"
      },
      {
        "regionId": 1613,
        "regionName": "Chiquimula"
      },
      {
        "regionId": 1614,
        "regionName": "El Progreso"
      },
      {
        "regionId": 1615,
        "regionName": "Escuintla"
      },
      {
        "regionId": 1616,
        "regionName": "Guatemala"
      },
      {
        "regionId": 1617,
        "regionName": "Huehuetenango"
      },
      {
        "regionId": 1618,
        "regionName": "Izabal"
      },
      {
        "regionId": 1619,
        "regionName": "Jalapa"
      },
      {
        "regionId": 1620,
        "regionName": "Jutiapa"
      },
      {
        "regionId": 1621,
        "regionName": "Peten"
      },
      {
        "regionId": 1622,
        "regionName": "Quezaltenango"
      },
      {
        "regionId": 1623,
        "regionName": "Quiche"
      },
      {
        "regionId": 1624,
        "regionName": "Retalhuleu"
      },
      {
        "regionId": 1625,
        "regionName": "Sacatepequez"
      },
      {
        "regionId": 1626,
        "regionName": "San Marcos"
      },
      {
        "regionId": 1627,
        "regionName": "Santa Rosa"
      },
      {
        "regionId": 1628,
        "regionName": "Solola"
      },
      {
        "regionId": 1629,
        "regionName": "Suchitepequez"
      },
      {
        "regionId": 1630,
        "regionName": "Totonicapan"
      },
      {
        "regionId": 1631,
        "regionName": "Zacapa"
      }
    ]
  },
  {
    "countryId": 86,
    "countryCode": "GG",
    "countryName": "Guernsey",
    "regions": []
  },
  {
    "countryId": 87,
    "countryCode": "GN",
    "countryName": "Guinea",
    "regions": [
      {
        "regionId": 1509,
        "regionName": "Beyla"
      },
      {
        "regionId": 1510,
        "regionName": "Boffa"
      },
      {
        "regionId": 1511,
        "regionName": "Boke"
      },
      {
        "regionId": 1512,
        "regionName": "Conakry"
      },
      {
        "regionId": 1513,
        "regionName": "Coyah"
      },
      {
        "regionId": 1514,
        "regionName": "Dabola"
      },
      {
        "regionId": 1515,
        "regionName": "Dalaba"
      },
      {
        "regionId": 1516,
        "regionName": "Dinguiraye"
      },
      {
        "regionId": 1517,
        "regionName": "Faranah"
      },
      {
        "regionId": 1518,
        "regionName": "Forecariah"
      },
      {
        "regionId": 1519,
        "regionName": "Fria"
      },
      {
        "regionId": 1520,
        "regionName": "Gaoual"
      },
      {
        "regionId": 1521,
        "regionName": "Gueckedou"
      },
      {
        "regionId": 1522,
        "regionName": "Kankan"
      },
      {
        "regionId": 1523,
        "regionName": "Kerouane"
      },
      {
        "regionId": 1524,
        "regionName": "Kindia"
      },
      {
        "regionId": 1525,
        "regionName": "Kissidougou"
      },
      {
        "regionId": 1526,
        "regionName": "Koubia"
      },
      {
        "regionId": 1527,
        "regionName": "Koundara"
      },
      {
        "regionId": 1528,
        "regionName": "Kouroussa"
      },
      {
        "regionId": 1529,
        "regionName": "Labe"
      },
      {
        "regionId": 1530,
        "regionName": "Lola"
      },
      {
        "regionId": 1531,
        "regionName": "Macenta"
      },
      {
        "regionId": 1532,
        "regionName": "Mali"
      },
      {
        "regionId": 1533,
        "regionName": "Mamou"
      },
      {
        "regionId": 1534,
        "regionName": "Mandiana"
      },
      {
        "regionId": 1535,
        "regionName": "Nzerekore"
      },
      {
        "regionId": 1536,
        "regionName": "Pita"
      },
      {
        "regionId": 1537,
        "regionName": "Siguiri"
      },
      {
        "regionId": 1538,
        "regionName": "Telimele"
      },
      {
        "regionId": 1539,
        "regionName": "Tougue"
      },
      {
        "regionId": 1540,
        "regionName": "Yomou"
      }
    ]
  },
  {
    "countryId": 88,
    "countryCode": "GW",
    "countryName": "Guinea-Bissau",
    "regions": [
      {
        "regionId": 1648,
        "regionName": "Bafata"
      },
      {
        "regionId": 1649,
        "regionName": "Bissau"
      },
      {
        "regionId": 1650,
        "regionName": "Bolama"
      },
      {
        "regionId": 1651,
        "regionName": "Cacheu"
      },
      {
        "regionId": 1652,
        "regionName": "Gabu"
      },
      {
        "regionId": 1653,
        "regionName": "Oio"
      },
      {
        "regionId": 1654,
        "regionName": "Quinara"
      },
      {
        "regionId": 1655,
        "regionName": "Tombali"
      }
    ]
  },
  {
    "countryId": 89,
    "countryCode": "GY",
    "countryName": "Guyana",
    "regions": [
      {
        "regionId": 1656,
        "regionName": "Barima-Waini"
      },
      {
        "regionId": 1657,
        "regionName": "Cuyuni-Mazaruni"
      },
      {
        "regionId": 1658,
        "regionName": "Demerara-Mahaica"
      },
      {
        "regionId": 1659,
        "regionName": "East Berbice-Corentyne"
      },
      {
        "regionId": 1660,
        "regionName": "Essequibo Islands-West Demerar"
      },
      {
        "regionId": 1661,
        "regionName": "Mahaica-Berbice"
      },
      {
        "regionId": 1662,
        "regionName": "Pomeroon-Supenaam"
      },
      {
        "regionId": 1663,
        "regionName": "Potaro-Siparuni"
      },
      {
        "regionId": 1664,
        "regionName": "Upper Demerara-Berbice"
      },
      {
        "regionId": 1665,
        "regionName": "Upper Takutu-Upper Essequibo"
      }
    ]
  },
  {
    "countryId": 90,
    "countryCode": "HT",
    "countryName": "Haiti",
    "regions": [
      {
        "regionId": 1709,
        "regionName": "Artibonite"
      },
      {
        "regionId": 1710,
        "regionName": "Centre"
      },
      {
        "regionId": 1711,
        "regionName": "Grand'Anse"
      },
      {
        "regionId": 1712,
        "regionName": "Nord"
      },
      {
        "regionId": 1713,
        "regionName": "Nord-Est"
      },
      {
        "regionId": 1714,
        "regionName": "Nord-Ouest"
      },
      {
        "regionId": 1715,
        "regionName": "Ouest"
      },
      {
        "regionId": 1716,
        "regionName": "Sud"
      },
      {
        "regionId": 1717,
        "regionName": "Sud-Est"
      }
    ]
  },
  {
    "countryId": 91,
    "countryCode": "HN",
    "countryName": "Honduras",
    "regions": [
      {
        "regionId": 1667,
        "regionName": "Atlantida"
      },
      {
        "regionId": 1668,
        "regionCode": "CH",
        "regionName": "Choluteca"
      },
      {
        "regionId": 1669,
        "regionName": "Colon"
      },
      {
        "regionId": 1670,
        "regionCode": "CM",
        "regionName": "Comayagua"
      },
      {
        "regionId": 1671,
        "regionName": "Copan"
      },
      {
        "regionId": 1672,
        "regionName": "Cortes"
      },
      {
        "regionId": 1673,
        "regionName": "Distrito Central"
      },
      {
        "regionId": 1674,
        "regionName": "El Paraiso"
      },
      {
        "regionId": 1675,
        "regionName": "Francisco Morazan"
      },
      {
        "regionId": 1676,
        "regionCode": "GD",
        "regionName": "Gracias a Dios"
      },
      {
        "regionId": 1677,
        "regionName": "Intibuca"
      },
      {
        "regionId": 1678,
        "regionName": "Islas de la Bahia"
      },
      {
        "regionId": 1679,
        "regionCode": "LP",
        "regionName": "La Paz"
      },
      {
        "regionId": 1680,
        "regionCode": "LE",
        "regionName": "Lempira"
      },
      {
        "regionId": 1681,
        "regionCode": "OC",
        "regionName": "Ocotepeque"
      },
      {
        "regionId": 1682,
        "regionCode": "OL",
        "regionName": "Olancho"
      },
      {
        "regionId": 1683,
        "regionName": "Santa Barbara"
      },
      {
        "regionId": 1684,
        "regionCode": "VA",
        "regionName": "Valle"
      },
      {
        "regionId": 1685,
        "regionCode": "YO",
        "regionName": "Yoro"
      }
    ]
  },
  {
    "countryId": 92,
    "countryCode": "HK",
    "countryName": "Hong Kong",
    "regions": [
      {
        "regionId": 1666,
        "regionName": "Hong Kong"
      }
    ]
  },
  {
    "countryId": 93,
    "countryCode": "HU",
    "countryName": "Hungary",
    "regions": [
      {
        "regionId": 1718,
        "regionCode": "01",
        "regionName": "Bács-Kiskun"
      },
      {
        "regionId": 1719,
        "regionCode": "02",
        "regionName": "Baranya"
      },
      {
        "regionId": 1720,
        "regionCode": "03",
        "regionName": "Békés"
      },
      {
        "regionId": 1721,
        "regionCode": "05",
        "regionName": "Borsod-Abaúj-Zemplén"
      },
      {
        "regionId": 1722,
        "regionCode": "06",
        "regionName": "Budapest"
      },
      {
        "regionId": 1723,
        "regionCode": "07",
        "regionName": "Csongrád"
      },
      {
        "regionId": 1724,
        "regionCode": "11",
        "regionName": "Fejér"
      },
      {
        "regionId": 1725,
        "regionCode": "13",
        "regionName": "Gyor-Moson-Sopron"
      },
      {
        "regionId": 1726,
        "regionCode": "14",
        "regionName": "Hajdú-Bihar"
      },
      {
        "regionId": 1727,
        "regionCode": "15",
        "regionName": "Heves"
      },
      {
        "regionId": 1728,
        "regionCode": "17",
        "regionName": "Jász-Nagykun-Szolnok"
      },
      {
        "regionId": 1729,
        "regionCode": "20",
        "regionName": "Komárom-Esztergom"
      },
      {
        "regionId": 1730,
        "regionCode": "23",
        "regionName": "Nógrád"
      },
      {
        "regionId": 1731,
        "regionCode": "26",
        "regionName": "Pest"
      },
      {
        "regionId": 1732,
        "regionCode": "27",
        "regionName": "Somogy"
      },
      {
        "regionId": 1733,
        "regionName": "Szabolcs-Szatmár-Bereg"
      },
      {
        "regionId": 1734,
        "regionCode": "35",
        "regionName": "Tolna"
      },
      {
        "regionId": 1735,
        "regionCode": "36",
        "regionName": "Vas"
      },
      {
        "regionId": 1736,
        "regionCode": "37",
        "regionName": "Veszprém"
      },
      {
        "regionId": 1737,
        "regionCode": "38",
        "regionName": "Zala"
      }
    ]
  },
  {
    "countryId": 94,
    "countryCode": "IS",
    "countryName": "Iceland",
    "regions": [
      {
        "regionId": 1915,
        "regionName": "Austurland"
      },
      {
        "regionId": 1916,
        "regionName": "Gullbringusysla"
      },
      {
        "regionId": 1917,
        "regionName": "Hofu borgarsva i"
      },
      {
        "regionId": 1918,
        "regionName": "Nor urland eystra"
      },
      {
        "regionId": 1919,
        "regionName": "Nor urland vestra"
      },
      {
        "regionId": 1920,
        "regionName": "Su urland"
      },
      {
        "regionId": 1921,
        "regionName": "Su urnes"
      },
      {
        "regionId": 1922,
        "regionName": "Vestfir ir"
      },
      {
        "regionId": 1923,
        "regionName": "Vesturland"
      }
    ]
  },
  {
    "countryId": 95,
    "countryCode": "IN",
    "countryName": "India",
    "regions": [
      {
        "regionId": 1825,
        "regionCode": "AN",
        "regionName": "Andaman and Nicobar Islands"
      },
      {
        "regionId": 1826,
        "regionCode": "AP",
        "regionName": "Andhra Pradesh"
      },
      {
        "regionId": 1827,
        "regionCode": "02",
        "regionName": "Arunachal Pradesh"
      },
      {
        "regionId": 1828,
        "regionCode": "03",
        "regionName": "Assam"
      },
      {
        "regionId": 1829,
        "regionCode": "04",
        "regionName": "Bihar"
      },
      {
        "regionId": 1830,
        "regionCode": "27",
        "regionName": "Chandigarh"
      },
      {
        "regionId": 1831,
        "regionCode": "CT",
        "regionName": "Chhattisgarh"
      },
      {
        "regionId": 1832,
        "regionName": "Dadra and Nagar Haveli"
      },
      {
        "regionId": 1833,
        "regionName": "Daman and Diu"
      },
      {
        "regionId": 1834,
        "regionCode": "30",
        "regionName": "Delhi"
      },
      {
        "regionId": 1835,
        "regionCode": "05",
        "regionName": "Goa"
      },
      {
        "regionId": 1836,
        "regionCode": "06",
        "regionName": "Gujarat"
      },
      {
        "regionId": 1837,
        "regionCode": "07",
        "regionName": "Haryana"
      },
      {
        "regionId": 1838,
        "regionCode": "08",
        "regionName": "Himachal Pradesh"
      },
      {
        "regionId": 1839,
        "regionCode": "JK",
        "regionName": "Jammu and Kashmir"
      },
      {
        "regionId": 1840,
        "regionCode": "JH",
        "regionName": "Jharkhand"
      },
      {
        "regionId": 1841,
        "regionCode": "10",
        "regionName": "Karnataka"
      },
      {
        "regionId": 1842,
        "regionName": "Kenmore"
      },
      {
        "regionId": 1843,
        "regionCode": "11",
        "regionName": "Kerala"
      },
      {
        "regionId": 1844,
        "regionCode": "31",
        "regionName": "Lakshadweep"
      },
      {
        "regionId": 1845,
        "regionCode": "12",
        "regionName": "Madhya Pradesh"
      },
      {
        "regionId": 1846,
        "regionCode": "13",
        "regionName": "Maharashtra"
      },
      {
        "regionId": 1847,
        "regionCode": "14",
        "regionName": "Manipur"
      },
      {
        "regionId": 1848,
        "regionCode": "ML",
        "regionName": "Meghalaya"
      },
      {
        "regionId": 1849,
        "regionCode": "16",
        "regionName": "Mizoram"
      },
      {
        "regionId": 1850,
        "regionCode": "17",
        "regionName": "Nagaland"
      },
      {
        "regionId": 1851,
        "regionName": "Narora"
      },
      {
        "regionId": 1852,
        "regionName": "Natwar"
      },
      {
        "regionId": 1853,
        "regionCode": "OR",
        "regionName": "Odisha"
      },
      {
        "regionId": 1854,
        "regionName": "Paschim Medinipur"
      },
      {
        "regionId": 1855,
        "regionCode": "32",
        "regionName": "Pondicherry"
      },
      {
        "regionId": 1856,
        "regionCode": "19",
        "regionName": "Punjab"
      },
      {
        "regionId": 1857,
        "regionCode": "20",
        "regionName": "Rajasthan"
      },
      {
        "regionId": 1858,
        "regionCode": "21",
        "regionName": "Sikkim"
      },
      {
        "regionId": 1859,
        "regionCode": "22",
        "regionName": "Tamil Nadu"
      },
      {
        "regionId": 1860,
        "regionCode": "TG",
        "regionName": "Telangana"
      },
      {
        "regionId": 1861,
        "regionCode": "23",
        "regionName": "Tripura"
      },
      {
        "regionId": 1862,
        "regionCode": "24",
        "regionName": "Uttar Pradesh"
      },
      {
        "regionId": 1863,
        "regionCode": "UT",
        "regionName": "Uttarakhand"
      },
      {
        "regionId": 1864,
        "regionName": "Vaishali"
      },
      {
        "regionId": 1865,
        "regionCode": "25",
        "regionName": "West Bengal"
      }
    ]
  },
  {
    "countryId": 96,
    "countryCode": "ID",
    "countryName": "Indonesia",
    "regions": [
      {
        "regionId": 1738,
        "regionCode": "AC",
        "regionName": "Aceh"
      },
      {
        "regionId": 1739,
        "regionCode": "BA",
        "regionName": "Bali"
      },
      {
        "regionId": 1740,
        "regionName": "Bangka-Belitung"
      },
      {
        "regionId": 1741,
        "regionCode": "BT",
        "regionName": "Banten"
      },
      {
        "regionId": 1742,
        "regionCode": "BE",
        "regionName": "Bengkulu"
      },
      {
        "regionId": 1743,
        "regionName": "Gandaria"
      },
      {
        "regionId": 1744,
        "regionCode": "GO",
        "regionName": "Gorontalo"
      },
      {
        "regionId": 1745,
        "regionCode": "JK",
        "regionName": "Jakarta"
      },
      {
        "regionId": 1746,
        "regionCode": "JA",
        "regionName": "Jambi"
      },
      {
        "regionId": 1747,
        "regionCode": "JB",
        "regionName": "Jawa Barat"
      },
      {
        "regionId": 1748,
        "regionCode": "JT",
        "regionName": "Jawa Tengah"
      },
      {
        "regionId": 1749,
        "regionCode": "JI",
        "regionName": "Jawa Timur"
      },
      {
        "regionId": 1750,
        "regionCode": "KB",
        "regionName": "Kalimantan Barat"
      },
      {
        "regionId": 1751,
        "regionCode": "KS",
        "regionName": "Kalimantan Selatan"
      },
      {
        "regionId": 1752,
        "regionCode": "KT",
        "regionName": "Kalimantan Tengah"
      },
      {
        "regionId": 1753,
        "regionCode": "KI",
        "regionName": "Kalimantan Timur"
      },
      {
        "regionId": 1754,
        "regionName": "Kendal"
      },
      {
        "regionId": 1755,
        "regionCode": "LA",
        "regionName": "Lampung"
      },
      {
        "regionId": 1756,
        "regionCode": "MA",
        "regionName": "Maluku"
      },
      {
        "regionId": 1757,
        "regionCode": "MU",
        "regionName": "Maluku Utara"
      },
      {
        "regionId": 1758,
        "regionCode": "23",
        "regionName": "Nusa Tenggara Barat"
      },
      {
        "regionId": 1759,
        "regionCode": "24",
        "regionName": "Nusa Tenggara Timur"
      },
      {
        "regionId": 1760,
        "regionCode": "PA",
        "regionName": "Papua"
      },
      {
        "regionId": 1761,
        "regionCode": "RI",
        "regionName": "Riau"
      },
      {
        "regionId": 1762,
        "regionName": "Riau Kepulauan"
      },
      {
        "regionId": 1763,
        "regionName": "Solo"
      },
      {
        "regionId": 1764,
        "regionCode": "SN",
        "regionName": "Sulawesi Selatan"
      },
      {
        "regionId": 1765,
        "regionCode": "ST",
        "regionName": "Sulawesi Tengah"
      },
      {
        "regionId": 1766,
        "regionCode": "SG",
        "regionName": "Sulawesi Tenggara"
      },
      {
        "regionId": 1767,
        "regionCode": "SA",
        "regionName": "Sulawesi Utara"
      },
      {
        "regionId": 1768,
        "regionCode": "SB",
        "regionName": "Sumatera Barat"
      },
      {
        "regionId": 1769,
        "regionCode": "SS",
        "regionName": "Sumatera Selatan"
      },
      {
        "regionId": 1770,
        "regionCode": "SU",
        "regionName": "Sumatera Utara"
      },
      {
        "regionId": 1771,
        "regionName": "Yogyakarta"
      }
    ]
  },
  {
    "countryId": 97,
    "countryCode": "IR",
    "countryName": "Iran",
    "regions": [
      {
        "regionId": 1886,
        "regionName": "Ardabil"
      },
      {
        "regionId": 1887,
        "regionName": "Azarbayjan-e Bakhtari"
      },
      {
        "regionId": 1888,
        "regionName": "Azarbayjan-e Khavari"
      },
      {
        "regionId": 1889,
        "regionName": "Bushehr"
      },
      {
        "regionId": 1890,
        "regionName": "Chahar Mahal-e Bakhtiari"
      },
      {
        "regionId": 1891,
        "regionName": "Esfahan"
      },
      {
        "regionId": 1892,
        "regionName": "Fars"
      },
      {
        "regionId": 1893,
        "regionName": "Gilan"
      },
      {
        "regionId": 1894,
        "regionName": "Golestan"
      },
      {
        "regionId": 1895,
        "regionName": "Hamadan"
      },
      {
        "regionId": 1896,
        "regionName": "Hormozgan"
      },
      {
        "regionId": 1897,
        "regionName": "Ilam"
      },
      {
        "regionId": 1898,
        "regionName": "Kerman"
      },
      {
        "regionId": 1899,
        "regionName": "Kermanshah"
      },
      {
        "regionId": 1900,
        "regionName": "Khorasan"
      },
      {
        "regionId": 1901,
        "regionName": "Khuzestan"
      },
      {
        "regionId": 1902,
        "regionName": "Kohgiluyeh-e Boyerahmad"
      },
      {
        "regionId": 1903,
        "regionName": "Kordestan"
      },
      {
        "regionId": 1904,
        "regionName": "Lorestan"
      },
      {
        "regionId": 1905,
        "regionName": "Markazi"
      },
      {
        "regionId": 1906,
        "regionName": "Mazandaran"
      },
      {
        "regionId": 1907,
        "regionName": "Ostan-e Esfahan"
      },
      {
        "regionId": 1908,
        "regionName": "Qazvin"
      },
      {
        "regionId": 1909,
        "regionName": "Qom"
      },
      {
        "regionId": 1910,
        "regionName": "Semnan"
      },
      {
        "regionId": 1911,
        "regionName": "Sistan-e Baluchestan"
      },
      {
        "regionId": 1912,
        "regionName": "Tehran"
      },
      {
        "regionId": 1913,
        "regionName": "Yazd"
      },
      {
        "regionId": 1914,
        "regionName": "Zanjan"
      }
    ]
  },
  {
    "countryId": 98,
    "countryCode": "IQ",
    "countryName": "Iraq",
    "regions": [
      {
        "regionId": 1866,
        "regionName": "al-Anbar"
      },
      {
        "regionId": 1867,
        "regionName": "al-Basrah"
      },
      {
        "regionId": 1868,
        "regionName": "al-Muthanna"
      },
      {
        "regionId": 1869,
        "regionName": "al-Qadisiyah"
      },
      {
        "regionId": 1870,
        "regionName": "an-Najaf"
      },
      {
        "regionId": 1871,
        "regionName": "as-Sulaymaniyah"
      },
      {
        "regionId": 1872,
        "regionName": "at-Ta'mim"
      },
      {
        "regionId": 1873,
        "regionName": "Babil"
      },
      {
        "regionId": 1874,
        "regionName": "Baghdad"
      },
      {
        "regionId": 1875,
        "regionName": "Dahuk"
      },
      {
        "regionId": 1876,
        "regionName": "Dhi Qar"
      },
      {
        "regionId": 1877,
        "regionName": "Diyala"
      },
      {
        "regionId": 1878,
        "regionName": "Erbil"
      },
      {
        "regionId": 1879,
        "regionName": "Irbil"
      },
      {
        "regionId": 1880,
        "regionName": "Karbala"
      },
      {
        "regionId": 1881,
        "regionName": "Kurdistan"
      },
      {
        "regionId": 1882,
        "regionName": "Maysan"
      },
      {
        "regionId": 1883,
        "regionName": "Ninawa"
      },
      {
        "regionId": 1884,
        "regionName": "Salah-ad-Din"
      },
      {
        "regionId": 1885,
        "regionName": "Wasit"
      }
    ]
  },
  {
    "countryId": 99,
    "countryCode": "IE",
    "countryName": "Ireland",
    "regions": [
      {
        "regionId": 1772,
        "regionName": "Armagh"
      },
      {
        "regionId": 1773,
        "regionCode": "CW",
        "regionName": "Carlow"
      },
      {
        "regionId": 1774,
        "regionCode": "CV",
        "regionName": "Cavan"
      },
      {
        "regionId": 1775,
        "regionCode": "CL",
        "regionName": "Clare"
      },
      {
        "regionId": 1776,
        "regionCode": "CK",
        "regionName": "Cork"
      },
      {
        "regionId": 1777,
        "regionCode": "DG",
        "regionName": "Donegal"
      },
      {
        "regionId": 1778,
        "regionCode": "DB",
        "regionName": "Dublin"
      },
      {
        "regionId": 1779,
        "regionCode": "GW",
        "regionName": "Galway"
      },
      {
        "regionId": 1780,
        "regionCode": "KY",
        "regionName": "Kerry"
      },
      {
        "regionId": 1781,
        "regionCode": "KD",
        "regionName": "Kildare"
      },
      {
        "regionId": 1782,
        "regionCode": "KK",
        "regionName": "Kilkenny"
      },
      {
        "regionId": 1783,
        "regionCode": "LS",
        "regionName": "Laois"
      },
      {
        "regionId": 1784,
        "regionName": "Leinster"
      },
      {
        "regionId": 1785,
        "regionCode": "LM",
        "regionName": "Leitrim"
      },
      {
        "regionId": 1786,
        "regionCode": "LI",
        "regionName": "Limerick"
      },
      {
        "regionId": 1787,
        "regionName": "Loch Garman"
      },
      {
        "regionId": 1788,
        "regionCode": "LF",
        "regionName": "Longford"
      },
      {
        "regionId": 1789,
        "regionCode": "LT",
        "regionName": "Louth"
      },
      {
        "regionId": 1790,
        "regionCode": "MY",
        "regionName": "Mayo"
      },
      {
        "regionId": 1791,
        "regionCode": "MT",
        "regionName": "Meath"
      },
      {
        "regionId": 1792,
        "regionCode": "MH",
        "regionName": "Monaghan"
      },
      {
        "regionId": 1793,
        "regionCode": "OF",
        "regionName": "Offaly"
      },
      {
        "regionId": 1794,
        "regionName": "Roscommon"
      },
      {
        "regionId": 1795,
        "regionCode": "SG",
        "regionName": "Sligo"
      },
      {
        "regionId": 1796,
        "regionName": "Tipperary North Riding"
      },
      {
        "regionId": 1797,
        "regionName": "Tipperary South Riding"
      },
      {
        "regionId": 1798,
        "regionName": "Ulster"
      },
      {
        "regionId": 1799,
        "regionCode": "WF",
        "regionName": "Waterford"
      },
      {
        "regionId": 1800,
        "regionCode": "WM",
        "regionName": "Westmeath"
      },
      {
        "regionId": 1801,
        "regionCode": "WX",
        "regionName": "Wexford"
      },
      {
        "regionId": 1802,
        "regionCode": "WK",
        "regionName": "Wicklow"
      }
    ]
  },
  {
    "countryId": 100,
    "countryCode": "IM",
    "countryName": "Isle of Man",
    "regions": []
  },
  {
    "countryId": 101,
    "countryCode": "IL",
    "countryName": "Israel",
    "regions": [
      {
        "regionId": 1803,
        "regionName": "Beit Hanania"
      },
      {
        "regionId": 1804,
        "regionName": "Ben Gurion Airport"
      },
      {
        "regionId": 1805,
        "regionName": "Bethlehem"
      },
      {
        "regionId": 1806,
        "regionName": "Caesarea"
      },
      {
        "regionId": 1807,
        "regionName": "Centre"
      },
      {
        "regionId": 1808,
        "regionName": "Gaza"
      },
      {
        "regionId": 1809,
        "regionName": "Hadaron"
      },
      {
        "regionId": 1810,
        "regionName": "Haifa District"
      },
      {
        "regionId": 1811,
        "regionName": "Hamerkaz"
      },
      {
        "regionId": 1812,
        "regionName": "Hazafon"
      },
      {
        "regionId": 1813,
        "regionName": "Hebron"
      },
      {
        "regionId": 1814,
        "regionName": "Jaffa"
      },
      {
        "regionId": 1815,
        "regionCode": "03",
        "regionName": "Jerusalem"
      },
      {
        "regionId": 1816,
        "regionName": "Khefa"
      },
      {
        "regionId": 1817,
        "regionName": "Kiryat Yam"
      },
      {
        "regionId": 1818,
        "regionName": "Lower Galilee"
      },
      {
        "regionId": 1819,
        "regionName": "Qalqilya"
      },
      {
        "regionId": 1820,
        "regionName": "Talme Elazar"
      },
      {
        "regionId": 1821,
        "regionCode": "06",
        "regionName": "Tel Aviv"
      },
      {
        "regionId": 1822,
        "regionName": "Tsafon"
      },
      {
        "regionId": 1823,
        "regionName": "Umm El Fahem"
      },
      {
        "regionId": 1824,
        "regionName": "Yerushalayim"
      }
    ]
  },
  {
    "countryId": 102,
    "countryCode": "IT",
    "countryName": "Italy",
    "regions": [
      {
        "regionId": 1924,
        "regionName": "Abruzzi"
      },
      {
        "regionId": 1925,
        "regionName": "Abruzzo"
      },
      {
        "regionId": 1926,
        "regionCode": "AG",
        "regionName": "Agrigento"
      },
      {
        "regionId": 1927,
        "regionCode": "AL",
        "regionName": "Alessandria"
      },
      {
        "regionId": 1928,
        "regionCode": "AN",
        "regionName": "Ancona"
      },
      {
        "regionId": 1929,
        "regionCode": "AR",
        "regionName": "Arezzo"
      },
      {
        "regionId": 1930,
        "regionCode": "AP",
        "regionName": "Ascoli Piceno"
      },
      {
        "regionId": 1931,
        "regionCode": "AT",
        "regionName": "Asti"
      },
      {
        "regionId": 1932,
        "regionCode": "AV",
        "regionName": "Avellino"
      },
      {
        "regionId": 1933,
        "regionCode": "BA",
        "regionName": "Bari"
      },
      {
        "regionId": 1934,
        "regionName": "Basilicata"
      },
      {
        "regionId": 1935,
        "regionCode": "BL",
        "regionName": "Belluno"
      },
      {
        "regionId": 1936,
        "regionCode": "BN",
        "regionName": "Benevento"
      },
      {
        "regionId": 1937,
        "regionCode": "BG",
        "regionName": "Bergamo"
      },
      {
        "regionId": 1938,
        "regionCode": "BI",
        "regionName": "Biella"
      },
      {
        "regionId": 1939,
        "regionCode": "BO",
        "regionName": "Bologna"
      },
      {
        "regionId": 1940,
        "regionCode": "BZ",
        "regionName": "Bolzano"
      },
      {
        "regionId": 1941,
        "regionCode": "BS",
        "regionName": "Brescia"
      },
      {
        "regionId": 1942,
        "regionCode": "BR",
        "regionName": "Brindisi"
      },
      {
        "regionId": 1943,
        "regionName": "Calabria"
      },
      {
        "regionId": 1944,
        "regionName": "Campania"
      },
      {
        "regionId": 1945,
        "regionName": "Cartoceto"
      },
      {
        "regionId": 1946,
        "regionCode": "CE",
        "regionName": "Caserta"
      },
      {
        "regionId": 1947,
        "regionCode": "CT",
        "regionName": "Catania"
      },
      {
        "regionId": 1948,
        "regionCode": "CH",
        "regionName": "Chieti"
      },
      {
        "regionId": 1949,
        "regionCode": "CO",
        "regionName": "Como"
      },
      {
        "regionId": 1950,
        "regionCode": "CS",
        "regionName": "Cosenza"
      },
      {
        "regionId": 1951,
        "regionCode": "CR",
        "regionName": "Cremona"
      },
      {
        "regionId": 1952,
        "regionCode": "CN",
        "regionName": "Cuneo"
      },
      {
        "regionId": 1953,
        "regionName": "Emilia-Romagna"
      },
      {
        "regionId": 1954,
        "regionCode": "FE",
        "regionName": "Ferrara"
      },
      {
        "regionId": 1955,
        "regionCode": "FI",
        "regionName": "Firenze"
      },
      {
        "regionId": 1956,
        "regionCode": "FI",
        "regionName": "Florence"
      },
      {
        "regionId": 1957,
        "regionCode": "FC",
        "regionName": "Forli-Cesena"
      },
      {
        "regionId": 1958,
        "regionName": "Friuli-Venezia Giulia"
      },
      {
        "regionId": 1959,
        "regionCode": "FR",
        "regionName": "Frosinone"
      },
      {
        "regionId": 1960,
        "regionName": "Genoa"
      },
      {
        "regionId": 1961,
        "regionCode": "GO",
        "regionName": "Gorizia"
      },
      {
        "regionId": 1962,
        "regionCode": "AQ",
        "regionName": "L'Aquila"
      },
      {
        "regionId": 1963,
        "regionName": "Lazio"
      },
      {
        "regionId": 1964,
        "regionCode": "LE",
        "regionName": "Lecce"
      },
      {
        "regionId": 1965,
        "regionCode": "LC",
        "regionName": "Lecco"
      },
      {
        "regionId": 1966,
        "regionName": "Lecco Province"
      },
      {
        "regionId": 1967,
        "regionName": "Liguria"
      },
      {
        "regionId": 1968,
        "regionCode": "LO",
        "regionName": "Lodi"
      },
      {
        "regionId": 1969,
        "regionName": "Lombardia"
      },
      {
        "regionId": 1970,
        "regionName": "Lombardy"
      },
      {
        "regionId": 1971,
        "regionCode": "MC",
        "regionName": "Macerata"
      },
      {
        "regionId": 1972,
        "regionCode": "MN",
        "regionName": "Mantova"
      },
      {
        "regionId": 1973,
        "regionName": "Marche"
      },
      {
        "regionId": 1974,
        "regionCode": "ME",
        "regionName": "Messina"
      },
      {
        "regionId": 1975,
        "regionCode": "MI",
        "regionName": "Milan"
      },
      {
        "regionId": 1976,
        "regionCode": "MO",
        "regionName": "Modena"
      },
      {
        "regionId": 1977,
        "regionName": "Molise"
      },
      {
        "regionId": 1978,
        "regionName": "Molteno"
      },
      {
        "regionId": 1979,
        "regionName": "Montenegro"
      },
      {
        "regionId": 1980,
        "regionName": "Monza and Brianza"
      },
      {
        "regionId": 1981,
        "regionCode": "NA",
        "regionName": "Naples"
      },
      {
        "regionId": 1982,
        "regionCode": "NO",
        "regionName": "Novara"
      },
      {
        "regionId": 1983,
        "regionCode": "PD",
        "regionName": "Padova"
      },
      {
        "regionId": 1984,
        "regionCode": "PR",
        "regionName": "Parma"
      },
      {
        "regionId": 1985,
        "regionCode": "PV",
        "regionName": "Pavia"
      },
      {
        "regionId": 1986,
        "regionCode": "PG",
        "regionName": "Perugia"
      },
      {
        "regionId": 1987,
        "regionCode": "PU",
        "regionName": "Pesaro-Urbino"
      },
      {
        "regionId": 1988,
        "regionCode": "PC",
        "regionName": "Piacenza"
      },
      {
        "regionId": 1989,
        "regionName": "Piedmont"
      },
      {
        "regionId": 1990,
        "regionName": "Piemonte"
      },
      {
        "regionId": 1991,
        "regionCode": "PI",
        "regionName": "Pisa"
      },
      {
        "regionId": 1992,
        "regionCode": "PN",
        "regionName": "Pordenone"
      },
      {
        "regionId": 1993,
        "regionCode": "PZ",
        "regionName": "Potenza"
      },
      {
        "regionId": 1994,
        "regionName": "Puglia"
      },
      {
        "regionId": 1995,
        "regionCode": "RE",
        "regionName": "Reggio Emilia"
      },
      {
        "regionId": 1996,
        "regionCode": "RN",
        "regionName": "Rimini"
      },
      {
        "regionId": 1997,
        "regionCode": "RM",
        "regionName": "Roma"
      },
      {
        "regionId": 1998,
        "regionCode": "SA",
        "regionName": "Salerno"
      },
      {
        "regionId": 1999,
        "regionName": "Sardegna"
      },
      {
        "regionId": 2000,
        "regionCode": "SS",
        "regionName": "Sassari"
      },
      {
        "regionId": 2001,
        "regionCode": "SV",
        "regionName": "Savona"
      },
      {
        "regionId": 2002,
        "regionName": "Sicilia"
      },
      {
        "regionId": 2003,
        "regionCode": "SI",
        "regionName": "Siena"
      },
      {
        "regionId": 2004,
        "regionCode": "SO",
        "regionName": "Sondrio"
      },
      {
        "regionId": 2005,
        "regionName": "South Tyrol"
      },
      {
        "regionId": 2006,
        "regionCode": "TA",
        "regionName": "Taranto"
      },
      {
        "regionId": 2007,
        "regionCode": "TE",
        "regionName": "Teramo"
      },
      {
        "regionId": 2008,
        "regionCode": "TO",
        "regionName": "Torino"
      },
      {
        "regionId": 2009,
        "regionName": "Toscana"
      },
      {
        "regionId": 2010,
        "regionCode": "TP",
        "regionName": "Trapani"
      },
      {
        "regionId": 2011,
        "regionName": "Trentino-Alto Adige"
      },
      {
        "regionId": 2012,
        "regionCode": "TN",
        "regionName": "Trento"
      },
      {
        "regionId": 2013,
        "regionCode": "TV",
        "regionName": "Treviso"
      },
      {
        "regionId": 2014,
        "regionCode": "UD",
        "regionName": "Udine"
      },
      {
        "regionId": 2015,
        "regionName": "Umbria"
      },
      {
        "regionId": 2016,
        "regionName": "Valle d'Aosta"
      },
      {
        "regionId": 2017,
        "regionCode": "VA",
        "regionName": "Varese"
      },
      {
        "regionId": 2018,
        "regionName": "Veneto"
      },
      {
        "regionId": 2019,
        "regionCode": "VE",
        "regionName": "Venezia"
      },
      {
        "regionId": 2020,
        "regionCode": "VB",
        "regionName": "Verbano-Cusio-Ossola"
      },
      {
        "regionId": 2021,
        "regionCode": "VC",
        "regionName": "Vercelli"
      },
      {
        "regionId": 2022,
        "regionCode": "VR",
        "regionName": "Verona"
      },
      {
        "regionId": 2023,
        "regionCode": "VI",
        "regionName": "Vicenza"
      },
      {
        "regionId": 2024,
        "regionCode": "VT",
        "regionName": "Viterbo"
      }
    ]
  },
  {
    "countryId": 103,
    "countryCode": "CI",
    "countryName": "Ivory Coast",
    "regions": [
      {
        "regionId": 630,
        "regionName": "Abidjan"
      },
      {
        "regionId": 631,
        "regionName": "Agneby"
      },
      {
        "regionId": 632,
        "regionName": "Bafing"
      },
      {
        "regionId": 633,
        "regionName": "Denguele"
      },
      {
        "regionId": 634,
        "regionName": "Dix-huit Montagnes"
      },
      {
        "regionId": 635,
        "regionName": "Fromager"
      },
      {
        "regionId": 636,
        "regionName": "Haut-Sassandra"
      },
      {
        "regionId": 637,
        "regionName": "Lacs"
      },
      {
        "regionId": 638,
        "regionName": "Lagunes"
      },
      {
        "regionId": 639,
        "regionName": "Marahoue"
      },
      {
        "regionId": 640,
        "regionName": "Moyen-Cavally"
      },
      {
        "regionId": 641,
        "regionName": "Moyen-Comoe"
      },
      {
        "regionId": 642,
        "regionName": "N'zi-Comoe"
      },
      {
        "regionId": 643,
        "regionName": "Sassandra"
      },
      {
        "regionId": 644,
        "regionName": "Savanes"
      },
      {
        "regionId": 645,
        "regionName": "Sud-Bandama"
      },
      {
        "regionId": 646,
        "regionName": "Sud-Comoe"
      },
      {
        "regionId": 647,
        "regionName": "Vallee du Bandama"
      },
      {
        "regionId": 648,
        "regionName": "Worodougou"
      },
      {
        "regionId": 649,
        "regionName": "Zanzan"
      }
    ]
  },
  {
    "countryId": 104,
    "countryCode": "JM",
    "countryName": "Jamaica",
    "regions": [
      {
        "regionId": 2026,
        "regionCode": "13",
        "regionName": "Clarendon"
      },
      {
        "regionId": 2027,
        "regionCode": "09",
        "regionName": "Hanover"
      },
      {
        "regionId": 2028,
        "regionCode": "01",
        "regionName": "Kingston"
      },
      {
        "regionId": 2029,
        "regionCode": "12",
        "regionName": "Manchester"
      },
      {
        "regionId": 2030,
        "regionCode": "04",
        "regionName": "Portland"
      },
      {
        "regionId": 2031,
        "regionCode": "02",
        "regionName": "Saint Andrew"
      },
      {
        "regionId": 2032,
        "regionCode": "06",
        "regionName": "Saint Ann"
      },
      {
        "regionId": 2033,
        "regionCode": "14",
        "regionName": "Saint Catherine"
      },
      {
        "regionId": 2034,
        "regionCode": "11",
        "regionName": "Saint Elizabeth"
      },
      {
        "regionId": 2035,
        "regionCode": "08",
        "regionName": "Saint James"
      },
      {
        "regionId": 2036,
        "regionCode": "05",
        "regionName": "Saint Mary"
      },
      {
        "regionId": 2037,
        "regionCode": "03",
        "regionName": "Saint Thomas"
      },
      {
        "regionId": 2038,
        "regionCode": "07",
        "regionName": "Trelawny"
      },
      {
        "regionId": 2039,
        "regionCode": "10",
        "regionName": "Westmoreland"
      }
    ]
  },
  {
    "countryId": 105,
    "countryCode": "JP",
    "countryName": "Japan",
    "regions": [
      {
        "regionId": 2052,
        "regionCode": "23",
        "regionName": "Aichi"
      },
      {
        "regionId": 2053,
        "regionCode": "05",
        "regionName": "Akita"
      },
      {
        "regionId": 2054,
        "regionCode": "02",
        "regionName": "Aomori"
      },
      {
        "regionId": 2055,
        "regionCode": "12",
        "regionName": "Chiba"
      },
      {
        "regionId": 2056,
        "regionCode": "38",
        "regionName": "Ehime"
      },
      {
        "regionId": 2057,
        "regionCode": "18",
        "regionName": "Fukui"
      },
      {
        "regionId": 2058,
        "regionCode": "40",
        "regionName": "Fukuoka"
      },
      {
        "regionId": 2059,
        "regionCode": "07",
        "regionName": "Fukushima"
      },
      {
        "regionId": 2060,
        "regionCode": "21",
        "regionName": "Gifu"
      },
      {
        "regionId": 2061,
        "regionName": "Gumma"
      },
      {
        "regionId": 2062,
        "regionCode": "34",
        "regionName": "Hiroshima"
      },
      {
        "regionId": 2063,
        "regionCode": "01",
        "regionName": "Hokkaido"
      },
      {
        "regionId": 2064,
        "regionCode": "28",
        "regionName": "Hyogo"
      },
      {
        "regionId": 2065,
        "regionCode": "08",
        "regionName": "Ibaraki"
      },
      {
        "regionId": 2066,
        "regionCode": "17",
        "regionName": "Ishikawa"
      },
      {
        "regionId": 2067,
        "regionCode": "03",
        "regionName": "Iwate"
      },
      {
        "regionId": 2068,
        "regionCode": "37",
        "regionName": "Kagawa"
      },
      {
        "regionId": 2069,
        "regionCode": "46",
        "regionName": "Kagoshima"
      },
      {
        "regionId": 2070,
        "regionCode": "14",
        "regionName": "Kanagawa"
      },
      {
        "regionId": 2071,
        "regionName": "Kanto"
      },
      {
        "regionId": 2072,
        "regionCode": "39",
        "regionName": "Kochi"
      },
      {
        "regionId": 2073,
        "regionCode": "43",
        "regionName": "Kumamoto"
      },
      {
        "regionId": 2074,
        "regionCode": "26",
        "regionName": "Kyoto"
      },
      {
        "regionId": 2075,
        "regionCode": "24",
        "regionName": "Mie"
      },
      {
        "regionId": 2076,
        "regionCode": "04",
        "regionName": "Miyagi"
      },
      {
        "regionId": 2077,
        "regionCode": "45",
        "regionName": "Miyazaki"
      },
      {
        "regionId": 2078,
        "regionCode": "20",
        "regionName": "Nagano"
      },
      {
        "regionId": 2079,
        "regionCode": "42",
        "regionName": "Nagasaki"
      },
      {
        "regionId": 2080,
        "regionCode": "29",
        "regionName": "Nara"
      },
      {
        "regionId": 2081,
        "regionCode": "15",
        "regionName": "Niigata"
      },
      {
        "regionId": 2082,
        "regionCode": "44",
        "regionName": "Oita"
      },
      {
        "regionId": 2083,
        "regionCode": "33",
        "regionName": "Okayama"
      },
      {
        "regionId": 2084,
        "regionCode": "47",
        "regionName": "Okinawa"
      },
      {
        "regionId": 2085,
        "regionCode": "27",
        "regionName": "Osaka"
      },
      {
        "regionId": 2086,
        "regionCode": "41",
        "regionName": "Saga"
      },
      {
        "regionId": 2087,
        "regionCode": "11",
        "regionName": "Saitama"
      },
      {
        "regionId": 2088,
        "regionCode": "25",
        "regionName": "Shiga"
      },
      {
        "regionId": 2089,
        "regionCode": "32",
        "regionName": "Shimane"
      },
      {
        "regionId": 2090,
        "regionCode": "22",
        "regionName": "Shizuoka"
      },
      {
        "regionId": 2091,
        "regionCode": "09",
        "regionName": "Tochigi"
      },
      {
        "regionId": 2092,
        "regionCode": "36",
        "regionName": "Tokushima"
      },
      {
        "regionId": 2093,
        "regionCode": "13",
        "regionName": "Tokyo"
      },
      {
        "regionId": 2094,
        "regionCode": "31",
        "regionName": "Tottori"
      },
      {
        "regionId": 2095,
        "regionCode": "16",
        "regionName": "Toyama"
      },
      {
        "regionId": 2096,
        "regionCode": "30",
        "regionName": "Wakayama"
      },
      {
        "regionId": 2097,
        "regionCode": "06",
        "regionName": "Yamagata"
      },
      {
        "regionId": 2098,
        "regionCode": "35",
        "regionName": "Yamaguchi"
      },
      {
        "regionId": 2099,
        "regionCode": "19",
        "regionName": "Yamanashi"
      }
    ]
  },
  {
    "countryId": 106,
    "countryCode": "JE",
    "countryName": "Jersey",
    "regions": []
  },
  {
    "countryId": 107,
    "countryCode": "JO",
    "countryName": "Jordan",
    "regions": [
      {
        "regionId": 2040,
        "regionName": "'Ajlun"
      },
      {
        "regionId": 2041,
        "regionName": "al-'Aqabah"
      },
      {
        "regionId": 2042,
        "regionName": "al-Balqa'"
      },
      {
        "regionId": 2043,
        "regionName": "al-Karak"
      },
      {
        "regionId": 2044,
        "regionName": "al-Mafraq"
      },
      {
        "regionId": 2045,
        "regionName": "Amman"
      },
      {
        "regionId": 2046,
        "regionName": "at-Tafilah"
      },
      {
        "regionId": 2047,
        "regionName": "az-Zarqa'"
      },
      {
        "regionId": 2048,
        "regionName": "Irbid"
      },
      {
        "regionId": 2049,
        "regionName": "Jarash"
      },
      {
        "regionId": 2050,
        "regionName": "Ma'an"
      },
      {
        "regionId": 2051,
        "regionName": "Madaba"
      }
    ]
  },
  {
    "countryId": 108,
    "countryCode": "KZ",
    "countryName": "Kazakhstan",
    "regions": [
      {
        "regionId": 2237,
        "regionName": "Akmecet"
      },
      {
        "regionId": 2238,
        "regionName": "Akmola"
      },
      {
        "regionId": 2239,
        "regionName": "Aktobe"
      },
      {
        "regionId": 2240,
        "regionName": "Almati"
      },
      {
        "regionId": 2241,
        "regionName": "Atirau"
      },
      {
        "regionId": 2242,
        "regionName": "Batis Kazakstan"
      },
      {
        "regionId": 2243,
        "regionName": "Burlinsky Region"
      },
      {
        "regionId": 2244,
        "regionName": "Karagandi"
      },
      {
        "regionId": 2245,
        "regionName": "Kostanay"
      },
      {
        "regionId": 2246,
        "regionName": "Mankistau"
      },
      {
        "regionId": 2247,
        "regionName": "Ontustik Kazakstan"
      },
      {
        "regionId": 2248,
        "regionName": "Pavlodar"
      },
      {
        "regionId": 2249,
        "regionName": "Sigis Kazakstan"
      },
      {
        "regionId": 2250,
        "regionName": "Soltustik Kazakstan"
      },
      {
        "regionId": 2251,
        "regionName": "Taraz"
      }
    ]
  },
  {
    "countryId": 109,
    "countryCode": "KE",
    "countryName": "Kenya",
    "regions": [
      {
        "regionId": 2100,
        "regionName": "Central"
      },
      {
        "regionId": 2101,
        "regionName": "Coast"
      },
      {
        "regionId": 2102,
        "regionName": "Eastern"
      },
      {
        "regionId": 2103,
        "regionName": "Nairobi"
      },
      {
        "regionId": 2104,
        "regionName": "North Eastern"
      },
      {
        "regionId": 2105,
        "regionName": "Nyanza"
      },
      {
        "regionId": 2106,
        "regionName": "Rift Valley"
      },
      {
        "regionId": 2107,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 110,
    "countryCode": "KI",
    "countryName": "Kiribati",
    "regions": [
      {
        "regionId": 2140,
        "regionName": "Abaiang"
      },
      {
        "regionId": 2141,
        "regionName": "Abemana"
      },
      {
        "regionId": 2142,
        "regionName": "Aranuka"
      },
      {
        "regionId": 2143,
        "regionName": "Arorae"
      },
      {
        "regionId": 2144,
        "regionName": "Banaba"
      },
      {
        "regionId": 2145,
        "regionName": "Beru"
      },
      {
        "regionId": 2146,
        "regionName": "Butaritari"
      },
      {
        "regionId": 2147,
        "regionName": "Kiritimati"
      },
      {
        "regionId": 2148,
        "regionName": "Kuria"
      },
      {
        "regionId": 2149,
        "regionName": "Maiana"
      },
      {
        "regionId": 2150,
        "regionName": "Makin"
      },
      {
        "regionId": 2151,
        "regionName": "Marakei"
      },
      {
        "regionId": 2152,
        "regionName": "Nikunau"
      },
      {
        "regionId": 2153,
        "regionName": "Nonouti"
      },
      {
        "regionId": 2154,
        "regionName": "Onotoa"
      },
      {
        "regionId": 2155,
        "regionName": "Phoenix Islands"
      },
      {
        "regionId": 2156,
        "regionName": "Tabiteuea North"
      },
      {
        "regionId": 2157,
        "regionName": "Tabiteuea South"
      },
      {
        "regionId": 2158,
        "regionName": "Tabuaeran"
      },
      {
        "regionId": 2159,
        "regionName": "Tamana"
      },
      {
        "regionId": 2160,
        "regionName": "Tarawa North"
      },
      {
        "regionId": 2161,
        "regionName": "Tarawa South"
      },
      {
        "regionId": 2162,
        "regionName": "Teraina"
      }
    ]
  },
  {
    "countryId": 111,
    "countryCode": "KR",
    "countryName": "Korea, Republic of",
    "regions": [
      {
        "regionId": 2192,
        "regionCode": "26",
        "regionName": "Busan"
      },
      {
        "regionId": 2193,
        "regionName": "Cheju"
      },
      {
        "regionId": 2194,
        "regionName": "Chollabuk"
      },
      {
        "regionId": 2195,
        "regionName": "Chollanam"
      },
      {
        "regionId": 2196,
        "regionName": "Chungbuk"
      },
      {
        "regionId": 2197,
        "regionName": "Chungcheongbuk"
      },
      {
        "regionId": 2198,
        "regionName": "Chungcheongnam"
      },
      {
        "regionId": 2199,
        "regionName": "Chungnam"
      },
      {
        "regionId": 2200,
        "regionCode": "27",
        "regionName": "Daegu"
      },
      {
        "regionId": 2201,
        "regionCode": "42",
        "regionName": "Gangwon-do"
      },
      {
        "regionId": 2202,
        "regionName": "Goyang-si"
      },
      {
        "regionId": 2203,
        "regionName": "gwangyoksi"
      },
      {
        "regionId": 2204,
        "regionCode": "41",
        "regionName": "Gyeonggi-do"
      },
      {
        "regionId": 2205,
        "regionName": "Gyeongsang"
      },
      {
        "regionId": 2206,
        "regionCode": "48",
        "regionName": "Gyeongsangnam-do"
      },
      {
        "regionId": 2207,
        "regionCode": "28",
        "regionName": "Incheon"
      },
      {
        "regionId": 2208,
        "regionName": "Jeju-Si"
      },
      {
        "regionId": 2209,
        "regionName": "Jeonbuk"
      },
      {
        "regionId": 2210,
        "regionName": "Kangweon"
      },
      {
        "regionId": 2211,
        "regionName": "Kwangju"
      },
      {
        "regionId": 2212,
        "regionName": "Kyeonggi"
      },
      {
        "regionId": 2213,
        "regionName": "Kyeongsangbuk"
      },
      {
        "regionId": 2214,
        "regionName": "Kyeongsangnam"
      },
      {
        "regionId": 2215,
        "regionCode": "09",
        "regionName": "Kyonggi-do"
      },
      {
        "regionId": 2216,
        "regionName": "Kyungbuk-do"
      },
      {
        "regionId": 2217,
        "regionName": "Kyunggi-do"
      },
      {
        "regionId": 2218,
        "regionName": "Pusan"
      },
      {
        "regionId": 2219,
        "regionCode": "11",
        "regionName": "Seoul"
      },
      {
        "regionId": 2220,
        "regionName": "Sudogwon"
      },
      {
        "regionId": 2221,
        "regionName": "Taegu"
      },
      {
        "regionId": 2222,
        "regionName": "Taejeon"
      },
      {
        "regionId": 2223,
        "regionName": "Taejon-gwangyoksi"
      },
      {
        "regionId": 2224,
        "regionCode": "31",
        "regionName": "Ulsan"
      },
      {
        "regionId": 2225,
        "regionName": "Wonju"
      }
    ]
  },
  {
    "countryId": 112,
    "countryCode": "CS",
    "countryName": "Kosovo",
    "regions": []
  },
  {
    "countryId": 113,
    "countryCode": "KW",
    "countryName": "Kuwait",
    "regions": [
      {
        "regionId": 2226,
        "regionName": "Al Asimah"
      },
      {
        "regionId": 2227,
        "regionName": "al-Ahmadi"
      },
      {
        "regionId": 2228,
        "regionName": "al-Farwaniyah"
      },
      {
        "regionId": 2229,
        "regionName": "al-Jahra"
      },
      {
        "regionId": 2230,
        "regionName": "al-Kuwayt"
      },
      {
        "regionId": 2231,
        "regionName": "Hawalli"
      },
      {
        "regionId": 2232,
        "regionName": "Mishref"
      },
      {
        "regionId": 2233,
        "regionName": "Qadesiya"
      },
      {
        "regionId": 2234,
        "regionName": "Safat"
      },
      {
        "regionId": 2235,
        "regionName": "Salmiya"
      }
    ]
  },
  {
    "countryId": 114,
    "countryCode": "KG",
    "countryName": "Kyrgyzstan",
    "regions": [
      {
        "regionId": 2108,
        "regionName": "Batken"
      },
      {
        "regionId": 2109,
        "regionName": "Bishkek"
      },
      {
        "regionId": 2110,
        "regionName": "Chui"
      },
      {
        "regionId": 2111,
        "regionName": "Issyk-Kul"
      },
      {
        "regionId": 2112,
        "regionName": "Jalal-Abad"
      },
      {
        "regionId": 2113,
        "regionName": "Naryn"
      },
      {
        "regionId": 2114,
        "regionName": "Osh"
      },
      {
        "regionId": 2115,
        "regionName": "Talas"
      }
    ]
  },
  {
    "countryId": 115,
    "countryCode": "LA",
    "countryName": "Lao People's Democratic Republic",
    "regions": [
      {
        "regionId": 2252,
        "regionName": "Attopu"
      },
      {
        "regionId": 2253,
        "regionName": "Bokeo"
      },
      {
        "regionId": 2254,
        "regionName": "Bolikhamsay"
      },
      {
        "regionId": 2255,
        "regionName": "Champasak"
      },
      {
        "regionId": 2256,
        "regionName": "Houaphanh"
      },
      {
        "regionId": 2257,
        "regionName": "Khammouane"
      },
      {
        "regionId": 2258,
        "regionName": "Luang Nam Tha"
      },
      {
        "regionId": 2259,
        "regionName": "Luang Prabang"
      },
      {
        "regionId": 2260,
        "regionName": "Oudomxay"
      },
      {
        "regionId": 2261,
        "regionName": "Phongsaly"
      },
      {
        "regionId": 2262,
        "regionName": "Saravan"
      },
      {
        "regionId": 2263,
        "regionName": "Savannakhet"
      },
      {
        "regionId": 2264,
        "regionName": "Sekong"
      },
      {
        "regionId": 2265,
        "regionName": "Viangchan Prefecture"
      },
      {
        "regionId": 2266,
        "regionName": "Viangchan Province"
      },
      {
        "regionId": 2267,
        "regionName": "Xaignabury"
      },
      {
        "regionId": 2268,
        "regionName": "Xiang Khuang"
      }
    ]
  },
  {
    "countryId": 116,
    "countryCode": "LV",
    "countryName": "Latvia",
    "regions": [
      {
        "regionId": 2379,
        "regionName": "Aizkraukles"
      },
      {
        "regionId": 2380,
        "regionName": "Aluksnes"
      },
      {
        "regionId": 2381,
        "regionName": "Balvu"
      },
      {
        "regionId": 2382,
        "regionName": "Bauskas"
      },
      {
        "regionId": 2383,
        "regionName": "Cesu"
      },
      {
        "regionId": 2384,
        "regionName": "Daugavpils"
      },
      {
        "regionId": 2385,
        "regionName": "Daugavpils City"
      },
      {
        "regionId": 2386,
        "regionName": "Dobeles"
      },
      {
        "regionId": 2387,
        "regionName": "Gulbenes"
      },
      {
        "regionId": 2388,
        "regionName": "Jekabspils"
      },
      {
        "regionId": 2389,
        "regionName": "Jelgava"
      },
      {
        "regionId": 2390,
        "regionName": "Jelgavas"
      },
      {
        "regionId": 2391,
        "regionName": "Jurmala City"
      },
      {
        "regionId": 2392,
        "regionName": "Kraslavas"
      },
      {
        "regionId": 2393,
        "regionName": "Kuldigas"
      },
      {
        "regionId": 2394,
        "regionName": "Liepaja"
      },
      {
        "regionId": 2395,
        "regionName": "Liepajas"
      },
      {
        "regionId": 2396,
        "regionName": "Limbazhu"
      },
      {
        "regionId": 2397,
        "regionName": "Ludzas"
      },
      {
        "regionId": 2398,
        "regionName": "Madonas"
      },
      {
        "regionId": 2399,
        "regionName": "Ogres"
      },
      {
        "regionId": 2400,
        "regionName": "Preilu"
      },
      {
        "regionId": 2401,
        "regionName": "Rezekne"
      },
      {
        "regionId": 2402,
        "regionName": "Rezeknes"
      },
      {
        "regionId": 2403,
        "regionName": "Riga"
      },
      {
        "regionId": 2404,
        "regionName": "Rigas"
      },
      {
        "regionId": 2405,
        "regionName": "Saldus"
      },
      {
        "regionId": 2406,
        "regionName": "Talsu"
      },
      {
        "regionId": 2407,
        "regionName": "Tukuma"
      },
      {
        "regionId": 2408,
        "regionName": "Valkas"
      },
      {
        "regionId": 2409,
        "regionName": "Valmieras"
      },
      {
        "regionId": 2410,
        "regionName": "Ventspils"
      },
      {
        "regionId": 2411,
        "regionName": "Ventspils City"
      }
    ]
  },
  {
    "countryId": 117,
    "countryCode": "LB",
    "countryName": "Lebanon",
    "regions": [
      {
        "regionId": 2269,
        "regionName": "al-Biqa"
      },
      {
        "regionId": 2270,
        "regionName": "al-Janub"
      },
      {
        "regionId": 2271,
        "regionName": "an-Nabatiyah"
      },
      {
        "regionId": 2272,
        "regionName": "ash-Shamal"
      },
      {
        "regionId": 2273,
        "regionName": "Beirut"
      },
      {
        "regionId": 2274,
        "regionName": "Jabal Lubnan"
      },
      {
        "regionId": 2275,
        "regionName": "Mohafazat Liban-Nord"
      },
      {
        "regionId": 2276,
        "regionName": "Mohafazat Mont-Liban"
      },
      {
        "regionId": 2277,
        "regionName": "Sidon"
      }
    ]
  },
  {
    "countryId": 118,
    "countryCode": "LS",
    "countryName": "Lesotho",
    "regions": [
      {
        "regionId": 2344,
        "regionName": "Berea"
      },
      {
        "regionId": 2345,
        "regionName": "Butha-Buthe"
      },
      {
        "regionId": 2346,
        "regionName": "Leribe"
      },
      {
        "regionId": 2347,
        "regionName": "Mafeteng"
      },
      {
        "regionId": 2348,
        "regionName": "Maseru"
      },
      {
        "regionId": 2349,
        "regionName": "Mohale's Hoek"
      },
      {
        "regionId": 2350,
        "regionName": "Mokhotlong"
      },
      {
        "regionId": 2351,
        "regionName": "Qacha's Nek"
      },
      {
        "regionId": 2352,
        "regionName": "Quthing"
      },
      {
        "regionId": 2353,
        "regionName": "Thaba-Tseka"
      }
    ]
  },
  {
    "countryId": 119,
    "countryCode": "LR",
    "countryName": "Liberia",
    "regions": [
      {
        "regionId": 2332,
        "regionName": "Bomi"
      },
      {
        "regionId": 2333,
        "regionName": "Bong"
      },
      {
        "regionId": 2334,
        "regionName": "Grand Bassa"
      },
      {
        "regionId": 2335,
        "regionName": "Grand Cape Mount"
      },
      {
        "regionId": 2336,
        "regionName": "Grand Gedeh"
      },
      {
        "regionId": 2337,
        "regionName": "Loffa"
      },
      {
        "regionId": 2338,
        "regionName": "Margibi"
      },
      {
        "regionId": 2339,
        "regionName": "Maryland and Grand Kru"
      },
      {
        "regionId": 2340,
        "regionName": "Montserrado"
      },
      {
        "regionId": 2341,
        "regionName": "Nimba"
      },
      {
        "regionId": 2342,
        "regionName": "Rivercess"
      },
      {
        "regionId": 2343,
        "regionName": "Sinoe"
      }
    ]
  },
  {
    "countryId": 120,
    "countryCode": "LY",
    "countryName": "Libya",
    "regions": [
      {
        "regionId": 2412,
        "regionName": "Ajdabiya"
      },
      {
        "regionId": 2413,
        "regionName": "al-'Aziziyah"
      },
      {
        "regionId": 2414,
        "regionName": "al-Fatih"
      },
      {
        "regionId": 2415,
        "regionName": "al-Jabal al Akhdar"
      },
      {
        "regionId": 2416,
        "regionName": "al-Jufrah"
      },
      {
        "regionId": 2417,
        "regionName": "al-Khums"
      },
      {
        "regionId": 2418,
        "regionName": "al-Kufrah"
      },
      {
        "regionId": 2419,
        "regionName": "an-Nuqat al-Khams"
      },
      {
        "regionId": 2420,
        "regionName": "ash-Shati'"
      },
      {
        "regionId": 2421,
        "regionName": "az-Zawiyah"
      },
      {
        "regionId": 2422,
        "regionName": "Banghazi"
      },
      {
        "regionId": 2423,
        "regionName": "Darnah"
      },
      {
        "regionId": 2424,
        "regionName": "Ghadamis"
      },
      {
        "regionId": 2425,
        "regionName": "Gharyan"
      },
      {
        "regionId": 2426,
        "regionName": "Misratah"
      },
      {
        "regionId": 2427,
        "regionName": "Murzuq"
      },
      {
        "regionId": 2428,
        "regionName": "Sabha"
      },
      {
        "regionId": 2429,
        "regionName": "Sawfajjin"
      },
      {
        "regionId": 2430,
        "regionName": "Surt"
      },
      {
        "regionId": 2431,
        "regionName": "Tarabulus"
      },
      {
        "regionId": 2432,
        "regionName": "Tarhunah"
      },
      {
        "regionId": 2433,
        "regionName": "Tripolitania"
      },
      {
        "regionId": 2434,
        "regionName": "Tubruq"
      },
      {
        "regionId": 2435,
        "regionName": "Yafran"
      },
      {
        "regionId": 2436,
        "regionName": "Zlitan"
      }
    ]
  },
  {
    "countryId": 121,
    "countryCode": "LI",
    "countryName": "Liechtenstein",
    "regions": [
      {
        "regionId": 2288,
        "regionName": "Balzers"
      },
      {
        "regionId": 2289,
        "regionName": "Eschen"
      },
      {
        "regionId": 2290,
        "regionName": "Gamprin"
      },
      {
        "regionId": 2291,
        "regionName": "Mauren"
      },
      {
        "regionId": 2292,
        "regionName": "Planken"
      },
      {
        "regionId": 2293,
        "regionName": "Ruggell"
      },
      {
        "regionId": 2294,
        "regionName": "Schaan"
      },
      {
        "regionId": 2295,
        "regionName": "Schellenberg"
      },
      {
        "regionId": 2296,
        "regionName": "Triesen"
      },
      {
        "regionId": 2297,
        "regionName": "Triesenberg"
      },
      {
        "regionId": 2298,
        "regionName": "Vaduz"
      }
    ]
  },
  {
    "countryId": 122,
    "countryCode": "LT",
    "countryName": "Lithuania",
    "regions": [
      {
        "regionId": 2354,
        "regionName": "Alytaus"
      },
      {
        "regionId": 2355,
        "regionName": "Anyksciai"
      },
      {
        "regionId": 2356,
        "regionName": "Kauno"
      },
      {
        "regionId": 2357,
        "regionName": "Klaipedos"
      },
      {
        "regionId": 2358,
        "regionName": "Marijampoles"
      },
      {
        "regionId": 2359,
        "regionName": "Panevezhio"
      },
      {
        "regionId": 2360,
        "regionName": "Panevezys"
      },
      {
        "regionId": 2361,
        "regionName": "Shiauliu"
      },
      {
        "regionId": 2362,
        "regionName": "Taurages"
      },
      {
        "regionId": 2363,
        "regionName": "Telshiu"
      },
      {
        "regionId": 2364,
        "regionName": "Telsiai"
      },
      {
        "regionId": 2365,
        "regionName": "Utenos"
      },
      {
        "regionId": 2366,
        "regionName": "Vilniaus"
      }
    ]
  },
  {
    "countryId": 123,
    "countryCode": "LU",
    "countryName": "Luxembourg",
    "regions": [
      {
        "regionId": 2367,
        "regionName": "Capellen"
      },
      {
        "regionId": 2368,
        "regionName": "Clervaux"
      },
      {
        "regionId": 2369,
        "regionName": "Diekirch"
      },
      {
        "regionId": 2370,
        "regionName": "Echternach"
      },
      {
        "regionId": 2371,
        "regionName": "Esch-sur-Alzette"
      },
      {
        "regionId": 2372,
        "regionName": "Grevenmacher"
      },
      {
        "regionId": 2373,
        "regionName": "Luxembourg"
      },
      {
        "regionId": 2374,
        "regionName": "Mersch"
      },
      {
        "regionId": 2375,
        "regionName": "Redange"
      },
      {
        "regionId": 2376,
        "regionName": "Remich"
      },
      {
        "regionId": 2377,
        "regionName": "Vianden"
      },
      {
        "regionId": 2378,
        "regionName": "Wiltz"
      }
    ]
  },
  {
    "countryId": 124,
    "countryCode": "MO",
    "countryName": "Macau",
    "regions": [
      {
        "regionId": 2583,
        "regionName": "Macau"
      }
    ]
  },
  {
    "countryId": 125,
    "countryCode": "MK",
    "countryName": "Macedonia, Republic of",
    "regions": [
      {
        "regionId": 2507,
        "regionName": "Berovo"
      },
      {
        "regionId": 2508,
        "regionName": "Bitola"
      },
      {
        "regionId": 2509,
        "regionName": "Brod"
      },
      {
        "regionId": 2510,
        "regionName": "Debar"
      },
      {
        "regionId": 2511,
        "regionName": "Delchevo"
      },
      {
        "regionId": 2512,
        "regionName": "Demir Hisar"
      },
      {
        "regionId": 2513,
        "regionName": "Gevgelija"
      },
      {
        "regionId": 2514,
        "regionName": "Gostivar"
      },
      {
        "regionId": 2515,
        "regionName": "Kavadarci"
      },
      {
        "regionId": 2516,
        "regionName": "Kichevo"
      },
      {
        "regionId": 2517,
        "regionName": "Kochani"
      },
      {
        "regionId": 2518,
        "regionName": "Kratovo"
      },
      {
        "regionId": 2519,
        "regionName": "Kriva Palanka"
      },
      {
        "regionId": 2520,
        "regionName": "Krushevo"
      },
      {
        "regionId": 2521,
        "regionName": "Kumanovo"
      },
      {
        "regionId": 2522,
        "regionName": "Negotino"
      },
      {
        "regionId": 2523,
        "regionName": "Ohrid"
      },
      {
        "regionId": 2524,
        "regionName": "Prilep"
      },
      {
        "regionId": 2525,
        "regionName": "Probishtip"
      },
      {
        "regionId": 2526,
        "regionName": "Radovish"
      },
      {
        "regionId": 2527,
        "regionName": "Resen"
      },
      {
        "regionId": 2528,
        "regionName": "Shtip"
      },
      {
        "regionId": 2529,
        "regionName": "Skopje"
      },
      {
        "regionId": 2530,
        "regionName": "Struga"
      },
      {
        "regionId": 2531,
        "regionName": "Strumica"
      },
      {
        "regionId": 2532,
        "regionName": "Sveti Nikole"
      },
      {
        "regionId": 2533,
        "regionName": "Tetovo"
      },
      {
        "regionId": 2534,
        "regionName": "Valandovo"
      },
      {
        "regionId": 2535,
        "regionName": "Veles"
      },
      {
        "regionId": 2536,
        "regionName": "Vinica"
      }
    ]
  },
  {
    "countryId": 126,
    "countryCode": "MG",
    "countryName": "Madagascar",
    "regions": [
      {
        "regionId": 2476,
        "regionName": "Antananarivo"
      },
      {
        "regionId": 2477,
        "regionName": "Antsiranana"
      },
      {
        "regionId": 2478,
        "regionName": "Fianarantsoa"
      },
      {
        "regionId": 2479,
        "regionName": "Mahajanga"
      },
      {
        "regionId": 2480,
        "regionName": "Toamasina"
      },
      {
        "regionId": 2481,
        "regionName": "Toliary"
      }
    ]
  },
  {
    "countryId": 127,
    "countryCode": "MW",
    "countryName": "Malawi",
    "regions": [
      {
        "regionId": 2643,
        "regionName": "Balaka"
      },
      {
        "regionId": 2644,
        "regionName": "Blantyre City"
      },
      {
        "regionId": 2645,
        "regionName": "Chikwawa"
      },
      {
        "regionId": 2646,
        "regionName": "Chiradzulu"
      },
      {
        "regionId": 2647,
        "regionName": "Chitipa"
      },
      {
        "regionId": 2648,
        "regionName": "Dedza"
      },
      {
        "regionId": 2649,
        "regionName": "Dowa"
      },
      {
        "regionId": 2650,
        "regionName": "Karonga"
      },
      {
        "regionId": 2651,
        "regionName": "Kasungu"
      },
      {
        "regionId": 2652,
        "regionName": "Lilongwe City"
      },
      {
        "regionId": 2653,
        "regionName": "Machinga"
      },
      {
        "regionId": 2654,
        "regionName": "Mangochi"
      },
      {
        "regionId": 2655,
        "regionName": "Mchinji"
      },
      {
        "regionId": 2656,
        "regionName": "Mulanje"
      },
      {
        "regionId": 2657,
        "regionName": "Mwanza"
      },
      {
        "regionId": 2658,
        "regionName": "Mzimba"
      },
      {
        "regionId": 2659,
        "regionName": "Mzuzu City"
      },
      {
        "regionId": 2660,
        "regionName": "Nkhata Bay"
      },
      {
        "regionId": 2661,
        "regionName": "Nkhotakota"
      },
      {
        "regionId": 2662,
        "regionName": "Nsanje"
      },
      {
        "regionId": 2663,
        "regionName": "Ntcheu"
      },
      {
        "regionId": 2664,
        "regionName": "Ntchisi"
      },
      {
        "regionId": 2665,
        "regionName": "Phalombe"
      },
      {
        "regionId": 2666,
        "regionName": "Rumphi"
      },
      {
        "regionId": 2667,
        "regionName": "Salima"
      },
      {
        "regionId": 2668,
        "regionName": "Thyolo"
      },
      {
        "regionId": 2669,
        "regionName": "Zomba Municipality"
      }
    ]
  },
  {
    "countryId": 128,
    "countryCode": "MY",
    "countryName": "Malaysia",
    "regions": [
      {
        "regionId": 2703,
        "regionCode": "JOH",
        "regionName": "Johor"
      },
      {
        "regionId": 2704,
        "regionCode": "KED",
        "regionName": "Kedah"
      },
      {
        "regionId": 2705,
        "regionCode": "KEL",
        "regionName": "Kelantan"
      },
      {
        "regionId": 2706,
        "regionCode": "KUL",
        "regionName": "Kuala Lumpur"
      },
      {
        "regionId": 2707,
        "regionCode": "LAB",
        "regionName": "Labuan"
      },
      {
        "regionId": 2708,
        "regionCode": "MEL",
        "regionName": "Melaka"
      },
      {
        "regionId": 2709,
        "regionName": "Negeri Johor"
      },
      {
        "regionId": 2710,
        "regionCode": "SER",
        "regionName": "Negeri Sembilan"
      },
      {
        "regionId": 2711,
        "regionCode": "PAH",
        "regionName": "Pahang"
      },
      {
        "regionId": 2712,
        "regionName": "Penang"
      },
      {
        "regionId": 2713,
        "regionCode": "PER",
        "regionName": "Perak"
      },
      {
        "regionId": 2714,
        "regionCode": "PEL",
        "regionName": "Perlis"
      },
      {
        "regionId": 2715,
        "regionCode": "PIN",
        "regionName": "Pulau Pinang"
      },
      {
        "regionId": 2716,
        "regionCode": "SAB",
        "regionName": "Sabah"
      },
      {
        "regionId": 2717,
        "regionCode": "SAR",
        "regionName": "Sarawak"
      },
      {
        "regionId": 2718,
        "regionCode": "SEL",
        "regionName": "Selangor"
      },
      {
        "regionId": 2719,
        "regionName": "Sembilan"
      },
      {
        "regionId": 2720,
        "regionCode": "11",
        "regionName": "Terengganu"
      }
    ]
  },
  {
    "countryId": 129,
    "countryCode": "MV",
    "countryName": "Maldives",
    "regions": [
      {
        "regionId": 2622,
        "regionName": "Alif Alif"
      },
      {
        "regionId": 2623,
        "regionName": "Alif Dhaal"
      },
      {
        "regionId": 2624,
        "regionName": "Baa"
      },
      {
        "regionId": 2625,
        "regionName": "Dhaal"
      },
      {
        "regionId": 2626,
        "regionName": "Faaf"
      },
      {
        "regionId": 2627,
        "regionName": "Gaaf Alif"
      },
      {
        "regionId": 2628,
        "regionName": "Gaaf Dhaal"
      },
      {
        "regionId": 2629,
        "regionName": "Ghaviyani"
      },
      {
        "regionId": 2630,
        "regionName": "Haa Alif"
      },
      {
        "regionId": 2631,
        "regionName": "Haa Dhaal"
      },
      {
        "regionId": 2632,
        "regionName": "Kaaf"
      },
      {
        "regionId": 2633,
        "regionName": "Laam"
      },
      {
        "regionId": 2634,
        "regionName": "Lhaviyani"
      },
      {
        "regionId": 2635,
        "regionName": "Male"
      },
      {
        "regionId": 2636,
        "regionName": "Miim"
      },
      {
        "regionId": 2637,
        "regionName": "Nuun"
      },
      {
        "regionId": 2638,
        "regionName": "Raa"
      },
      {
        "regionId": 2639,
        "regionName": "Shaviyani"
      },
      {
        "regionId": 2640,
        "regionName": "Siin"
      },
      {
        "regionId": 2641,
        "regionName": "Thaa"
      },
      {
        "regionId": 2642,
        "regionName": "Vaav"
      }
    ]
  },
  {
    "countryId": 130,
    "countryCode": "ML",
    "countryName": "Mali",
    "regions": [
      {
        "regionId": 2537,
        "regionName": "Bamako"
      },
      {
        "regionId": 2538,
        "regionName": "Gao"
      },
      {
        "regionId": 2539,
        "regionName": "Kayes"
      },
      {
        "regionId": 2540,
        "regionName": "Kidal"
      },
      {
        "regionId": 2541,
        "regionName": "Koulikoro"
      },
      {
        "regionId": 2542,
        "regionName": "Mopti"
      },
      {
        "regionId": 2543,
        "regionName": "Segou"
      },
      {
        "regionId": 2544,
        "regionName": "Sikasso"
      },
      {
        "regionId": 2545,
        "regionName": "Tombouctou"
      }
    ]
  },
  {
    "countryId": 131,
    "countryCode": "MT",
    "countryName": "Malta",
    "regions": [
      {
        "regionId": 2602,
        "regionName": "Gozo and Comino"
      },
      {
        "regionId": 2603,
        "regionName": "Inner Harbour"
      },
      {
        "regionId": 2604,
        "regionName": "Northern"
      },
      {
        "regionId": 2605,
        "regionName": "Outer Harbour"
      },
      {
        "regionId": 2606,
        "regionName": "South Eastern"
      },
      {
        "regionId": 2607,
        "regionName": "Valletta"
      },
      {
        "regionId": 2608,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 132,
    "countryCode": "MH",
    "countryName": "Marshall Islands",
    "regions": [
      {
        "regionId": 2482,
        "regionName": "Ailinlaplap"
      },
      {
        "regionId": 2483,
        "regionName": "Ailuk"
      },
      {
        "regionId": 2484,
        "regionName": "Arno"
      },
      {
        "regionId": 2485,
        "regionName": "Aur"
      },
      {
        "regionId": 2486,
        "regionName": "Bikini"
      },
      {
        "regionId": 2487,
        "regionName": "Ebon"
      },
      {
        "regionId": 2488,
        "regionName": "Enewetak"
      },
      {
        "regionId": 2489,
        "regionName": "Jabat"
      },
      {
        "regionId": 2490,
        "regionName": "Jaluit"
      },
      {
        "regionId": 2491,
        "regionName": "Kili"
      },
      {
        "regionId": 2492,
        "regionName": "Kwajalein"
      },
      {
        "regionId": 2493,
        "regionName": "Lae"
      },
      {
        "regionId": 2494,
        "regionName": "Lib"
      },
      {
        "regionId": 2495,
        "regionName": "Likiep"
      },
      {
        "regionId": 2496,
        "regionName": "Majuro"
      },
      {
        "regionId": 2497,
        "regionName": "Maloelap"
      },
      {
        "regionId": 2498,
        "regionName": "Mejit"
      },
      {
        "regionId": 2499,
        "regionName": "Mili"
      },
      {
        "regionId": 2500,
        "regionName": "Namorik"
      },
      {
        "regionId": 2501,
        "regionName": "Namu"
      },
      {
        "regionId": 2502,
        "regionName": "Rongelap"
      },
      {
        "regionId": 2503,
        "regionName": "Ujae"
      },
      {
        "regionId": 2504,
        "regionName": "Utrik"
      },
      {
        "regionId": 2505,
        "regionName": "Wotho"
      },
      {
        "regionId": 2506,
        "regionName": "Wotje"
      }
    ]
  },
  {
    "countryId": 133,
    "countryCode": "MQ",
    "countryName": "Martinique",
    "regions": [
      {
        "regionId": 2584,
        "regionName": "Fort-de-France"
      },
      {
        "regionId": 2585,
        "regionName": "La Trinite"
      },
      {
        "regionId": 2586,
        "regionName": "Le Marin"
      },
      {
        "regionId": 2587,
        "regionName": "Saint-Pierre"
      }
    ]
  },
  {
    "countryId": 134,
    "countryCode": "MR",
    "countryName": "Mauritania",
    "regions": [
      {
        "regionId": 2588,
        "regionName": "Adrar"
      },
      {
        "regionId": 2589,
        "regionName": "Assaba"
      },
      {
        "regionId": 2590,
        "regionName": "Brakna"
      },
      {
        "regionId": 2591,
        "regionName": "Dhakhlat Nawadibu"
      },
      {
        "regionId": 2592,
        "regionName": "Hudh-al-Gharbi"
      },
      {
        "regionId": 2593,
        "regionName": "Hudh-ash-Sharqi"
      },
      {
        "regionId": 2594,
        "regionName": "Inshiri"
      },
      {
        "regionId": 2595,
        "regionName": "Nawakshut"
      },
      {
        "regionId": 2596,
        "regionName": "Qidimagha"
      },
      {
        "regionId": 2597,
        "regionName": "Qurqul"
      },
      {
        "regionId": 2598,
        "regionName": "Taqant"
      },
      {
        "regionId": 2599,
        "regionName": "Tiris Zammur"
      },
      {
        "regionId": 2600,
        "regionName": "Trarza"
      }
    ]
  },
  {
    "countryId": 135,
    "countryCode": "MU",
    "countryName": "Mauritius",
    "regions": [
      {
        "regionId": 2609,
        "regionName": "Black River"
      },
      {
        "regionId": 2610,
        "regionName": "Eau Coulee"
      },
      {
        "regionId": 2611,
        "regionName": "Flacq"
      },
      {
        "regionId": 2612,
        "regionName": "Floreal"
      },
      {
        "regionId": 2613,
        "regionName": "Grand Port"
      },
      {
        "regionId": 2614,
        "regionName": "Moka"
      },
      {
        "regionId": 2615,
        "regionName": "Pamplempousses"
      },
      {
        "regionId": 2616,
        "regionName": "Plaines Wilhelm"
      },
      {
        "regionId": 2617,
        "regionName": "Port Louis"
      },
      {
        "regionId": 2618,
        "regionName": "Riviere du Rempart"
      },
      {
        "regionId": 2619,
        "regionName": "Rodrigues"
      },
      {
        "regionId": 2620,
        "regionName": "Rose Hill"
      },
      {
        "regionId": 2621,
        "regionName": "Savanne"
      }
    ]
  },
  {
    "countryId": 136,
    "countryCode": "YT",
    "countryName": "Mayotte",
    "regions": [
      {
        "regionId": 3968,
        "regionName": "Mayotte"
      },
      {
        "regionId": 3969,
        "regionName": "Pamanzi"
      }
    ]
  },
  {
    "countryId": 137,
    "countryCode": "MX",
    "countryName": "Mexico",
    "regions": [
      {
        "regionId": 4036,
        "regionCode": "AGS",
        "regionName": "Aguascalientes"
      },
      {
        "regionId": 4037,
        "regionCode": "BCN",
        "regionName": "Baja California (N)"
      },
      {
        "regionId": 4038,
        "regionCode": "BCS",
        "regionName": "Baja California Sur"
      },
      {
        "regionId": 4039,
        "regionCode": "CAM",
        "regionName": "Campeche"
      },
      {
        "regionId": 4040,
        "regionCode": "CHH",
        "regionName": "Chihuahua"
      },
      {
        "regionId": 4041,
        "regionCode": "CHP",
        "regionName": "Chiapas"
      },
      {
        "regionId": 4042,
        "regionCode": "COA",
        "regionName": "Coahuila"
      },
      {
        "regionId": 4043,
        "regionCode": "COL",
        "regionName": "Colima"
      },
      {
        "regionId": 4044,
        "regionCode": "DGO",
        "regionName": "Durango"
      },
      {
        "regionId": 4045,
        "regionCode": "DIF",
        "regionName": "Distrito Federal"
      },
      {
        "regionId": 4046,
        "regionCode": "GRO",
        "regionName": "Guerrero"
      },
      {
        "regionId": 4047,
        "regionCode": "GTO",
        "regionName": "Guanajuato"
      },
      {
        "regionId": 4048,
        "regionCode": "HGO",
        "regionName": "Hidalgo"
      },
      {
        "regionId": 4049,
        "regionCode": "JAL",
        "regionName": "Jalisco"
      },
      {
        "regionId": 4050,
        "regionCode": "MCH",
        "regionName": "Michoacán"
      },
      {
        "regionId": 4051,
        "regionCode": "MEX",
        "regionName": "Estado de México"
      },
      {
        "regionId": 4052,
        "regionCode": "MOR",
        "regionName": "Morelos"
      },
      {
        "regionId": 4053,
        "regionCode": "NAY",
        "regionName": "Nayarit"
      },
      {
        "regionId": 4054,
        "regionCode": "NLE",
        "regionName": "Nuevo Léon"
      },
      {
        "regionId": 4055,
        "regionCode": "OAX",
        "regionName": "Oaxaca"
      },
      {
        "regionId": 4056,
        "regionCode": "PUE",
        "regionName": "Puebla"
      },
      {
        "regionId": 4057,
        "regionCode": "QRO",
        "regionName": "Querétaro"
      },
      {
        "regionId": 4058,
        "regionCode": "ROO",
        "regionName": "Quintana Roo"
      },
      {
        "regionId": 4059,
        "regionCode": "SIN",
        "regionName": "Sinaloa"
      },
      {
        "regionId": 4060,
        "regionCode": "SLP",
        "regionName": "San Luis Potosí"
      },
      {
        "regionId": 4061,
        "regionCode": "SON",
        "regionName": "Sonora"
      },
      {
        "regionId": 4062,
        "regionCode": "TAB",
        "regionName": "Tabasco"
      },
      {
        "regionId": 4063,
        "regionCode": "TAM",
        "regionName": "Tamaulipas"
      },
      {
        "regionId": 4064,
        "regionCode": "TLA",
        "regionName": "Tlaxcala"
      },
      {
        "regionId": 4065,
        "regionCode": "VER",
        "regionName": "Veracruz"
      },
      {
        "regionId": 4066,
        "regionCode": "YUC",
        "regionName": "Yucatán"
      },
      {
        "regionId": 4067,
        "regionCode": "ZAC",
        "regionName": "Zacatecas"
      }
    ]
  },
  {
    "countryId": 138,
    "countryCode": "FM",
    "countryName": "Micronesia, Federated States of",
    "regions": [
      {
        "regionId": 1181,
        "regionName": "Chuuk"
      },
      {
        "regionId": 1182,
        "regionName": "Kusaie"
      },
      {
        "regionId": 1183,
        "regionName": "Pohnpei"
      },
      {
        "regionId": 1184,
        "regionName": "Yap"
      }
    ]
  },
  {
    "countryId": 139,
    "countryCode": "MD",
    "countryName": "Moldova, Republic of",
    "regions": [
      {
        "regionId": 2463,
        "regionName": "Balti"
      },
      {
        "regionId": 2464,
        "regionName": "Cahul"
      },
      {
        "regionId": 2465,
        "regionName": "Chisinau"
      },
      {
        "regionId": 2466,
        "regionName": "Chisinau Oras"
      },
      {
        "regionId": 2467,
        "regionName": "Edinet"
      },
      {
        "regionId": 2468,
        "regionName": "Gagauzia"
      },
      {
        "regionId": 2469,
        "regionName": "Lapusna"
      },
      {
        "regionId": 2470,
        "regionName": "Orhei"
      },
      {
        "regionId": 2471,
        "regionName": "Soroca"
      },
      {
        "regionId": 2472,
        "regionName": "Taraclia"
      },
      {
        "regionId": 2473,
        "regionName": "Tighina"
      },
      {
        "regionId": 2474,
        "regionName": "Transnistria"
      },
      {
        "regionId": 2475,
        "regionName": "Ungheni"
      }
    ]
  },
  {
    "countryId": 140,
    "countryCode": "MC",
    "countryName": "Monaco",
    "regions": [
      {
        "regionId": 2459,
        "regionName": "Fontvieille"
      },
      {
        "regionId": 2460,
        "regionName": "La Condamine"
      },
      {
        "regionId": 2461,
        "regionName": "Monaco-Ville"
      },
      {
        "regionId": 2462,
        "regionName": "Monte Carlo"
      }
    ]
  },
  {
    "countryId": 141,
    "countryCode": "MN",
    "countryName": "Mongolia",
    "regions": [
      {
        "regionId": 2561,
        "regionName": "Arhangaj"
      },
      {
        "regionId": 2562,
        "regionName": "Bajanhongor"
      },
      {
        "regionId": 2563,
        "regionName": "Bajan-Olgij"
      },
      {
        "regionId": 2564,
        "regionName": "Bulgan"
      },
      {
        "regionId": 2565,
        "regionName": "Darhan-Uul"
      },
      {
        "regionId": 2566,
        "regionName": "Dornod"
      },
      {
        "regionId": 2567,
        "regionName": "Dornogovi"
      },
      {
        "regionId": 2568,
        "regionName": "Dundgovi"
      },
      {
        "regionId": 2569,
        "regionName": "Govi-Altaj"
      },
      {
        "regionId": 2570,
        "regionName": "Govisumber"
      },
      {
        "regionId": 2571,
        "regionName": "Hentij"
      },
      {
        "regionId": 2572,
        "regionName": "Hovd"
      },
      {
        "regionId": 2573,
        "regionName": "Hovsgol"
      },
      {
        "regionId": 2574,
        "regionName": "Omnogovi"
      },
      {
        "regionId": 2575,
        "regionName": "Orhon"
      },
      {
        "regionId": 2576,
        "regionName": "Ovorhangaj"
      },
      {
        "regionId": 2577,
        "regionName": "Selenge"
      },
      {
        "regionId": 2578,
        "regionName": "Suhbaatar"
      },
      {
        "regionId": 2579,
        "regionName": "Tov"
      },
      {
        "regionId": 2580,
        "regionName": "Ulaanbaatar"
      },
      {
        "regionId": 2581,
        "regionName": "Uvs"
      },
      {
        "regionId": 2582,
        "regionName": "Zavhan"
      }
    ]
  },
  {
    "countryId": 142,
    "countryCode": "ME",
    "countryName": "Montenegro",
    "regions": []
  },
  {
    "countryId": 143,
    "countryCode": "MS",
    "countryName": "Montserrat",
    "regions": [
      {
        "regionId": 2601,
        "regionName": "Montserrat"
      }
    ]
  },
  {
    "countryId": 144,
    "countryCode": "MA",
    "countryName": "Morocco",
    "regions": [
      {
        "regionId": 2437,
        "regionName": "Agadir"
      },
      {
        "regionId": 2438,
        "regionName": "Casablanca"
      },
      {
        "regionId": 2439,
        "regionName": "Chaouia-Ouardigha"
      },
      {
        "regionId": 2440,
        "regionName": "Doukkala-Abda"
      },
      {
        "regionId": 2441,
        "regionName": "Fes-Boulemane"
      },
      {
        "regionId": 2442,
        "regionName": "Gharb-Chrarda-Beni Hssen"
      },
      {
        "regionId": 2443,
        "regionName": "Guelmim"
      },
      {
        "regionId": 2444,
        "regionName": "Kenitra"
      },
      {
        "regionId": 2445,
        "regionName": "Marrakech-Tensift-Al Haouz"
      },
      {
        "regionId": 2446,
        "regionName": "Meknes-Tafilalet"
      },
      {
        "regionId": 2447,
        "regionName": "Oriental"
      },
      {
        "regionId": 2448,
        "regionName": "Oujda"
      },
      {
        "regionId": 2449,
        "regionName": "Province de Tanger"
      },
      {
        "regionId": 2450,
        "regionName": "Rabat-Sale-Zammour-Zaer"
      },
      {
        "regionId": 2451,
        "regionName": "Sala Al Jadida"
      },
      {
        "regionId": 2452,
        "regionName": "Settat"
      },
      {
        "regionId": 2453,
        "regionName": "Souss Massa-Draa"
      },
      {
        "regionId": 2454,
        "regionName": "Tadla-Azilal"
      },
      {
        "regionId": 2455,
        "regionName": "Tangier-Tetouan"
      },
      {
        "regionId": 2456,
        "regionName": "Taza-Al Hoceima-Taounate"
      },
      {
        "regionId": 2457,
        "regionName": "Wilaya de Casablanca"
      },
      {
        "regionId": 2458,
        "regionName": "Wilaya de Rabat-Sale"
      }
    ]
  },
  {
    "countryId": 145,
    "countryCode": "MZ",
    "countryName": "Mozambique",
    "regions": [
      {
        "regionId": 2721,
        "regionCode": "P",
        "regionName": "Cabo Delgado"
      },
      {
        "regionId": 2722,
        "regionCode": "G",
        "regionName": "Gaza"
      },
      {
        "regionId": 2723,
        "regionCode": "I",
        "regionName": "Inhambane"
      },
      {
        "regionId": 2724,
        "regionCode": "B",
        "regionName": "Manica"
      },
      {
        "regionId": 2725,
        "regionCode": "L",
        "regionName": "Maputo"
      },
      {
        "regionId": 2726,
        "regionName": "Maputo Provincia"
      },
      {
        "regionId": 2727,
        "regionCode": "N",
        "regionName": "Nampula"
      },
      {
        "regionId": 2728,
        "regionCode": "A",
        "regionName": "Niassa"
      },
      {
        "regionId": 2729,
        "regionCode": "S",
        "regionName": "Sofala"
      },
      {
        "regionId": 2730,
        "regionCode": "T",
        "regionName": "Tete"
      },
      {
        "regionId": 2731,
        "regionCode": "Q",
        "regionName": "Zambezia"
      }
    ]
  },
  {
    "countryId": 146,
    "countryCode": "MM",
    "countryName": "Myanmar",
    "regions": [
      {
        "regionId": 2546,
        "regionName": "Ayeyarwady"
      },
      {
        "regionId": 2547,
        "regionName": "Bago"
      },
      {
        "regionId": 2548,
        "regionName": "Chin"
      },
      {
        "regionId": 2549,
        "regionName": "Kachin"
      },
      {
        "regionId": 2550,
        "regionName": "Kayah"
      },
      {
        "regionId": 2551,
        "regionName": "Kayin"
      },
      {
        "regionId": 2552,
        "regionName": "Magway"
      },
      {
        "regionId": 2553,
        "regionName": "Mandalay"
      },
      {
        "regionId": 2554,
        "regionName": "Mon"
      },
      {
        "regionId": 2555,
        "regionName": "Nay Pyi Taw"
      },
      {
        "regionId": 2556,
        "regionName": "Rakhine"
      },
      {
        "regionId": 2557,
        "regionName": "Sagaing"
      },
      {
        "regionId": 2558,
        "regionName": "Shan"
      },
      {
        "regionId": 2559,
        "regionName": "Tanintharyi"
      },
      {
        "regionId": 2560,
        "regionName": "Yangon"
      }
    ]
  },
  {
    "countryId": 147,
    "countryCode": "NA",
    "countryName": "Namibia",
    "regions": [
      {
        "regionId": 2732,
        "regionName": "Caprivi"
      },
      {
        "regionId": 2733,
        "regionName": "Erongo"
      },
      {
        "regionId": 2734,
        "regionName": "Hardap"
      },
      {
        "regionId": 2735,
        "regionName": "Karas"
      },
      {
        "regionId": 2736,
        "regionName": "Kavango"
      },
      {
        "regionId": 2737,
        "regionName": "Khomas"
      },
      {
        "regionId": 2738,
        "regionName": "Kunene"
      },
      {
        "regionId": 2739,
        "regionName": "Ohangwena"
      },
      {
        "regionId": 2740,
        "regionName": "Omaheke"
      },
      {
        "regionId": 2741,
        "regionName": "Omusati"
      },
      {
        "regionId": 2742,
        "regionName": "Oshana"
      },
      {
        "regionId": 2743,
        "regionName": "Oshikoto"
      },
      {
        "regionId": 2744,
        "regionName": "Otjozondjupa"
      }
    ]
  },
  {
    "countryId": 148,
    "countryCode": "NR",
    "countryName": "Nauru",
    "regions": [
      {
        "regionId": 2863,
        "regionName": "Yaren"
      }
    ]
  },
  {
    "countryId": 149,
    "countryCode": "NP",
    "countryName": "Nepal",
    "regions": [
      {
        "regionId": 2849,
        "regionName": "Bagmati"
      },
      {
        "regionId": 2850,
        "regionName": "Bheri"
      },
      {
        "regionId": 2851,
        "regionName": "Dhawalagiri"
      },
      {
        "regionId": 2852,
        "regionName": "Gandaki"
      },
      {
        "regionId": 2853,
        "regionName": "Janakpur"
      },
      {
        "regionId": 2854,
        "regionName": "Karnali"
      },
      {
        "regionId": 2855,
        "regionName": "Koshi"
      },
      {
        "regionId": 2856,
        "regionName": "Lumbini"
      },
      {
        "regionId": 2857,
        "regionName": "Mahakali"
      },
      {
        "regionId": 2858,
        "regionName": "Mechi"
      },
      {
        "regionId": 2859,
        "regionName": "Narayani"
      },
      {
        "regionId": 2860,
        "regionName": "Rapti"
      },
      {
        "regionId": 2861,
        "regionName": "Sagarmatha"
      },
      {
        "regionId": 2862,
        "regionName": "Seti"
      }
    ]
  },
  {
    "countryId": 150,
    "countryCode": "NL",
    "countryName": "Netherlands",
    "regions": [
      {
        "regionId": 2811,
        "regionName": "Amsterdam"
      },
      {
        "regionId": 2812,
        "regionName": "Benelux"
      },
      {
        "regionId": 2813,
        "regionCode": "01",
        "regionName": "Drenthe"
      },
      {
        "regionId": 2814,
        "regionCode": "02",
        "regionName": "Flevoland"
      },
      {
        "regionId": 2815,
        "regionCode": "03",
        "regionName": "Friesland"
      },
      {
        "regionId": 2816,
        "regionCode": "04",
        "regionName": "Gelderland"
      },
      {
        "regionId": 2817,
        "regionCode": "05",
        "regionName": "Groningen"
      },
      {
        "regionId": 2818,
        "regionCode": "06",
        "regionName": "Limburg"
      },
      {
        "regionId": 2819,
        "regionCode": "07",
        "regionName": "Noord-Brabant"
      },
      {
        "regionId": 2820,
        "regionCode": "08",
        "regionName": "Noord-Holland"
      },
      {
        "regionId": 2821,
        "regionCode": "09",
        "regionName": "Overijssel"
      },
      {
        "regionId": 2822,
        "regionName": "South Holland"
      },
      {
        "regionId": 2823,
        "regionCode": "10",
        "regionName": "Utrecht"
      },
      {
        "regionId": 2824,
        "regionCode": "11",
        "regionName": "Zeeland"
      },
      {
        "regionId": 2825,
        "regionCode": "12",
        "regionName": "Zuid-Holland"
      }
    ]
  },
  {
    "countryId": 151,
    "countryCode": "AN",
    "countryName": "Netherlands Antilles",
    "regions": [
      {
        "regionId": 105,
        "regionName": "Bonaire"
      },
      {
        "regionId": 106,
        "regionName": "Curacao"
      },
      {
        "regionId": 107,
        "regionName": "Saba"
      },
      {
        "regionId": 108,
        "regionName": "Sint Eustatius"
      },
      {
        "regionId": 109,
        "regionName": "Sint Maarten"
      }
    ]
  },
  {
    "countryId": 152,
    "countryCode": "NC",
    "countryName": "New Caledonia",
    "regions": [
      {
        "regionId": 2745,
        "regionName": "Iles"
      },
      {
        "regionId": 2746,
        "regionName": "Nord"
      },
      {
        "regionId": 2747,
        "regionName": "Sud"
      }
    ]
  },
  {
    "countryId": 153,
    "countryCode": "NZ",
    "countryName": "New Zealand",
    "regions": [
      {
        "regionId": 2865,
        "regionName": "Area Outside Region"
      },
      {
        "regionId": 2866,
        "regionCode": "NZ-AUK",
        "regionName": "Auckland"
      },
      {
        "regionId": 2867,
        "regionCode": "BOP",
        "regionName": "Bay of Plenty"
      },
      {
        "regionId": 2868,
        "regionCode": "CAN",
        "regionName": "Canterbury"
      },
      {
        "regionId": 2869,
        "regionName": "Christchurch"
      },
      {
        "regionId": 2870,
        "regionName": "Gisborne"
      },
      {
        "regionId": 2871,
        "regionName": "Hawke's Bay"
      },
      {
        "regionId": 2872,
        "regionCode": "MAN",
        "regionName": "Manawatu-Wanganui"
      },
      {
        "regionId": 2873,
        "regionName": "Marlborough"
      },
      {
        "regionId": 2874,
        "regionName": "Nelson"
      },
      {
        "regionId": 2875,
        "regionCode": "NTL",
        "regionName": "Northland"
      },
      {
        "regionId": 2876,
        "regionCode": "OTA",
        "regionName": "Otago"
      },
      {
        "regionId": 2877,
        "regionName": "Rodney"
      },
      {
        "regionId": 2878,
        "regionCode": "STL",
        "regionName": "Southland"
      },
      {
        "regionId": 2879,
        "regionCode": "TAR",
        "regionName": "Taranaki"
      },
      {
        "regionId": 2880,
        "regionName": "Tasman"
      },
      {
        "regionId": 2881,
        "regionCode": "WAI",
        "regionName": "Waikato"
      },
      {
        "regionId": 2882,
        "regionCode": "WLG",
        "regionName": "Wellington"
      },
      {
        "regionId": 2883,
        "regionCode": "WEC",
        "regionName": "West Coast"
      }
    ]
  },
  {
    "countryId": 154,
    "countryCode": "NI",
    "countryName": "Nicaragua",
    "regions": [
      {
        "regionId": 2794,
        "regionName": "Atlantico Norte"
      },
      {
        "regionId": 2795,
        "regionName": "Atlantico Sur"
      },
      {
        "regionId": 2796,
        "regionCode": "BO",
        "regionName": "Boaco"
      },
      {
        "regionId": 2797,
        "regionCode": "CA",
        "regionName": "Carazo"
      },
      {
        "regionId": 2798,
        "regionCode": "CI",
        "regionName": "Chinandega"
      },
      {
        "regionId": 2799,
        "regionCode": "CO",
        "regionName": "Chontales"
      },
      {
        "regionId": 2800,
        "regionCode": "ES",
        "regionName": "Esteli"
      },
      {
        "regionId": 2801,
        "regionCode": "GR",
        "regionName": "Granada"
      },
      {
        "regionId": 2802,
        "regionCode": "JI",
        "regionName": "Jinotega"
      },
      {
        "regionId": 2803,
        "regionCode": "LE",
        "regionName": "Leon"
      },
      {
        "regionId": 2804,
        "regionCode": "MD",
        "regionName": "Madriz"
      },
      {
        "regionId": 2805,
        "regionCode": "MN",
        "regionName": "Managua"
      },
      {
        "regionId": 2806,
        "regionCode": "MS",
        "regionName": "Masaya"
      },
      {
        "regionId": 2807,
        "regionCode": "MT",
        "regionName": "Matagalpa"
      },
      {
        "regionId": 2808,
        "regionCode": "NS",
        "regionName": "Nueva Segovia"
      },
      {
        "regionId": 2809,
        "regionCode": "SJ",
        "regionName": "Rio San Juan"
      },
      {
        "regionId": 2810,
        "regionCode": "RI",
        "regionName": "Rivas"
      }
    ]
  },
  {
    "countryId": 155,
    "countryCode": "NE",
    "countryName": "Niger",
    "regions": [
      {
        "regionId": 2748,
        "regionName": "Agadez"
      },
      {
        "regionId": 2749,
        "regionName": "Diffa"
      },
      {
        "regionId": 2750,
        "regionName": "Dosso"
      },
      {
        "regionId": 2751,
        "regionName": "Maradi"
      },
      {
        "regionId": 2752,
        "regionName": "Niamey"
      },
      {
        "regionId": 2753,
        "regionName": "Tahoua"
      },
      {
        "regionId": 2754,
        "regionName": "Tillabery"
      },
      {
        "regionId": 2755,
        "regionName": "Zinder"
      }
    ]
  },
  {
    "countryId": 156,
    "countryCode": "NG",
    "countryName": "Nigeria",
    "regions": [
      {
        "regionId": 2757,
        "regionCode": "AB",
        "regionName": "Abia"
      },
      {
        "regionId": 2758,
        "regionName": "Abuja Federal Capital Territor"
      },
      {
        "regionId": 2759,
        "regionCode": "AD",
        "regionName": "Adamawa"
      },
      {
        "regionId": 2760,
        "regionCode": "AK",
        "regionName": "Akwa Ibom"
      },
      {
        "regionId": 2761,
        "regionCode": "AN",
        "regionName": "Anambra"
      },
      {
        "regionId": 2762,
        "regionCode": "BA",
        "regionName": "Bauchi"
      },
      {
        "regionId": 2763,
        "regionCode": "BY",
        "regionName": "Bayelsa"
      },
      {
        "regionId": 2764,
        "regionCode": "BE",
        "regionName": "Benue"
      },
      {
        "regionId": 2765,
        "regionCode": "BO",
        "regionName": "Borno"
      },
      {
        "regionId": 2766,
        "regionCode": "CR",
        "regionName": "Cross River"
      },
      {
        "regionId": 2767,
        "regionCode": "DE",
        "regionName": "Delta"
      },
      {
        "regionId": 2768,
        "regionCode": "EB",
        "regionName": "Ebonyi"
      },
      {
        "regionId": 2769,
        "regionCode": "ED",
        "regionName": "Edo"
      },
      {
        "regionId": 2770,
        "regionCode": "EK",
        "regionName": "Ekiti"
      },
      {
        "regionId": 2771,
        "regionCode": "EN",
        "regionName": "Enugu"
      },
      {
        "regionId": 2772,
        "regionCode": "GO",
        "regionName": "Gombe"
      },
      {
        "regionId": 2773,
        "regionCode": "IM",
        "regionName": "Imo"
      },
      {
        "regionId": 2774,
        "regionCode": "JI",
        "regionName": "Jigawa"
      },
      {
        "regionId": 2775,
        "regionCode": "KD",
        "regionName": "Kaduna"
      },
      {
        "regionId": 2776,
        "regionCode": "KN",
        "regionName": "Kano"
      },
      {
        "regionId": 2777,
        "regionCode": "KT",
        "regionName": "Katsina"
      },
      {
        "regionId": 2778,
        "regionCode": "KE",
        "regionName": "Kebbi"
      },
      {
        "regionId": 2779,
        "regionCode": "KO",
        "regionName": "Kogi"
      },
      {
        "regionId": 2780,
        "regionCode": "KW",
        "regionName": "Kwara"
      },
      {
        "regionId": 2781,
        "regionCode": "LA",
        "regionName": "Lagos"
      },
      {
        "regionId": 2782,
        "regionName": "Nassarawa"
      },
      {
        "regionId": 2783,
        "regionCode": "NI",
        "regionName": "Niger"
      },
      {
        "regionId": 2784,
        "regionName": "Ogun"
      },
      {
        "regionId": 2785,
        "regionCode": "ON",
        "regionName": "Ondo"
      },
      {
        "regionId": 2786,
        "regionCode": "OS",
        "regionName": "Osun"
      },
      {
        "regionId": 2787,
        "regionCode": "OY",
        "regionName": "Oyo"
      },
      {
        "regionId": 2788,
        "regionCode": "PL",
        "regionName": "Plateau"
      },
      {
        "regionId": 2789,
        "regionCode": "RI",
        "regionName": "Rivers"
      },
      {
        "regionId": 2790,
        "regionCode": "SO",
        "regionName": "Sokoto"
      },
      {
        "regionId": 2791,
        "regionCode": "TA",
        "regionName": "Taraba"
      },
      {
        "regionId": 2792,
        "regionCode": "YO",
        "regionName": "Yobe"
      },
      {
        "regionId": 2793,
        "regionCode": "ZA",
        "regionName": "Zamfara"
      }
    ]
  },
  {
    "countryId": 157,
    "countryCode": "NU",
    "countryName": "Niue",
    "regions": [
      {
        "regionId": 2864,
        "regionName": "Niue"
      }
    ]
  },
  {
    "countryId": 158,
    "countryCode": "NF",
    "countryName": "Norfolk Island",
    "regions": [
      {
        "regionId": 2756,
        "regionName": "Norfolk Island"
      }
    ]
  },
  {
    "countryId": 159,
    "countryCode": "KP",
    "countryName": "North Korea",
    "regions": [
      {
        "regionId": 2180,
        "regionName": "Chagangdo"
      },
      {
        "regionId": 2181,
        "regionName": "Hamgyeongbukto"
      },
      {
        "regionId": 2182,
        "regionName": "Hamgyeongnamdo"
      },
      {
        "regionId": 2183,
        "regionName": "Hwanghaebukto"
      },
      {
        "regionId": 2184,
        "regionName": "Hwanghaenamdo"
      },
      {
        "regionId": 2185,
        "regionName": "Kaeseong"
      },
      {
        "regionId": 2186,
        "regionName": "Kangweon"
      },
      {
        "regionId": 2187,
        "regionName": "Nampo"
      },
      {
        "regionId": 2188,
        "regionName": "Pyeonganbukto"
      },
      {
        "regionId": 2189,
        "regionName": "Pyeongannamdo"
      },
      {
        "regionId": 2190,
        "regionName": "Pyeongyang"
      },
      {
        "regionId": 2191,
        "regionName": "Yanggang"
      }
    ]
  },
  {
    "countryId": 160,
    "countryCode": "NO",
    "countryName": "Norway",
    "regions": [
      {
        "regionId": 2826,
        "regionName": "Ã?stfold"
      },
      {
        "regionId": 2827,
        "regionName": "Akershus"
      },
      {
        "regionId": 2828,
        "regionName": "Aust Agder"
      },
      {
        "regionId": 2829,
        "regionName": "Bergen"
      },
      {
        "regionId": 2830,
        "regionName": "Buskerud"
      },
      {
        "regionId": 2831,
        "regionName": "Finnmark"
      },
      {
        "regionId": 2832,
        "regionName": "Hedmark"
      },
      {
        "regionId": 2833,
        "regionName": "Hordaland"
      },
      {
        "regionId": 2834,
        "regionName": "Moere og Romsdal"
      },
      {
        "regionId": 2835,
        "regionName": "Nord Trondelag"
      },
      {
        "regionId": 2836,
        "regionName": "Nordland"
      },
      {
        "regionId": 2837,
        "regionName": "Oestfold"
      },
      {
        "regionId": 2838,
        "regionName": "Oppland"
      },
      {
        "regionId": 2839,
        "regionCode": "03",
        "regionName": "Oslo"
      },
      {
        "regionId": 2840,
        "regionName": "Rogaland"
      },
      {
        "regionId": 2841,
        "regionName": "Soer Troendelag"
      },
      {
        "regionId": 2842,
        "regionName": "Sogn og Fjordane"
      },
      {
        "regionId": 2843,
        "regionName": "Stavern"
      },
      {
        "regionId": 2844,
        "regionName": "Sykkylven"
      },
      {
        "regionId": 2845,
        "regionName": "Telemark"
      },
      {
        "regionId": 2846,
        "regionName": "Troms"
      },
      {
        "regionId": 2847,
        "regionName": "Vest Agder"
      },
      {
        "regionId": 2848,
        "regionName": "Vestfold"
      }
    ]
  },
  {
    "countryId": 161,
    "countryCode": "OM",
    "countryName": "Oman",
    "regions": [
      {
        "regionId": 2884,
        "regionName": "ad-Dakhiliyah"
      },
      {
        "regionId": 2885,
        "regionName": "adh-Dhahirah"
      },
      {
        "regionId": 2886,
        "regionName": "Al Buraimi"
      },
      {
        "regionId": 2887,
        "regionName": "al-Batinah"
      },
      {
        "regionId": 2888,
        "regionName": "ash-Sharqiyah"
      },
      {
        "regionId": 2889,
        "regionName": "Dhufar"
      },
      {
        "regionId": 2890,
        "regionName": "Masqat"
      },
      {
        "regionId": 2891,
        "regionName": "Musandam"
      },
      {
        "regionId": 2892,
        "regionName": "Rusayl"
      },
      {
        "regionId": 2893,
        "regionName": "Wadi Kabir"
      }
    ]
  },
  {
    "countryId": 162,
    "countryCode": "PK",
    "countryName": "Pakistan",
    "regions": [
      {
        "regionId": 2977,
        "regionName": "Baluchistan"
      },
      {
        "regionId": 2978,
        "regionName": "Federal Capital Area"
      },
      {
        "regionId": 2979,
        "regionName": "Federally administered Tribal"
      },
      {
        "regionId": 2980,
        "regionName": "Northern Areas"
      },
      {
        "regionId": 2981,
        "regionName": "North-West Frontier"
      },
      {
        "regionId": 2982,
        "regionName": "Punjab"
      },
      {
        "regionId": 2983,
        "regionName": "Sind"
      }
    ]
  },
  {
    "countryId": 163,
    "countryCode": "PW",
    "countryName": "Palau",
    "regions": [
      {
        "regionId": 3062,
        "regionName": "Aimeliik"
      },
      {
        "regionId": 3063,
        "regionName": "Airai"
      },
      {
        "regionId": 3064,
        "regionName": "Angaur"
      },
      {
        "regionId": 3065,
        "regionName": "Hatobohei"
      },
      {
        "regionId": 3066,
        "regionName": "Kayangel"
      },
      {
        "regionId": 3067,
        "regionName": "Koror"
      },
      {
        "regionId": 3068,
        "regionName": "Melekeok"
      },
      {
        "regionId": 3069,
        "regionName": "Ngaraard"
      },
      {
        "regionId": 3070,
        "regionName": "Ngardmau"
      },
      {
        "regionId": 3071,
        "regionName": "Ngaremlengui"
      },
      {
        "regionId": 3072,
        "regionName": "Ngatpang"
      },
      {
        "regionId": 3073,
        "regionName": "Ngchesar"
      },
      {
        "regionId": 3074,
        "regionName": "Ngerchelong"
      },
      {
        "regionId": 3075,
        "regionName": "Ngiwal"
      },
      {
        "regionId": 3076,
        "regionName": "Peleliu"
      },
      {
        "regionId": 3077,
        "regionName": "Sonsorol"
      }
    ]
  },
  {
    "countryId": 164,
    "countryCode": "PS",
    "countryName": "Palestinian Territories",
    "regions": [
      {
        "regionId": 3031,
        "regionName": "al-Khalil"
      },
      {
        "regionId": 3032,
        "regionName": "al-Quds"
      },
      {
        "regionId": 3033,
        "regionName": "Ariha"
      },
      {
        "regionId": 3034,
        "regionName": "Bayt Lahm"
      },
      {
        "regionId": 3035,
        "regionName": "Bethlehem"
      },
      {
        "regionId": 3036,
        "regionName": "Dayr-al-Balah"
      },
      {
        "regionId": 3037,
        "regionName": "Ghazzah"
      },
      {
        "regionId": 3038,
        "regionName": "Ghazzah ash-Shamaliyah"
      },
      {
        "regionId": 3039,
        "regionName": "Janin"
      },
      {
        "regionId": 3040,
        "regionName": "Khan Yunis"
      },
      {
        "regionId": 3041,
        "regionName": "Nabulus"
      },
      {
        "regionId": 3042,
        "regionName": "Qalqilyah"
      },
      {
        "regionId": 3043,
        "regionName": "Rafah"
      },
      {
        "regionId": 3044,
        "regionName": "Ram Allah wal-Birah"
      },
      {
        "regionId": 3045,
        "regionName": "Salfit"
      },
      {
        "regionId": 3046,
        "regionName": "Tubas"
      },
      {
        "regionId": 3047,
        "regionName": "Tulkarm"
      }
    ]
  },
  {
    "countryId": 165,
    "countryCode": "PA",
    "countryName": "Panama",
    "regions": [
      {
        "regionId": 2894,
        "regionName": "Bocas del Toro"
      },
      {
        "regionId": 2895,
        "regionName": "Chiriqui"
      },
      {
        "regionId": 2896,
        "regionName": "Cocle"
      },
      {
        "regionId": 2897,
        "regionName": "Colon"
      },
      {
        "regionId": 2898,
        "regionName": "Darien"
      },
      {
        "regionId": 2899,
        "regionName": "Embera"
      },
      {
        "regionId": 2900,
        "regionName": "Herrera"
      },
      {
        "regionId": 2901,
        "regionName": "Kuna Yala"
      },
      {
        "regionId": 2902,
        "regionName": "Los Santos"
      },
      {
        "regionId": 2903,
        "regionName": "Ngobe Bugle"
      },
      {
        "regionId": 2904,
        "regionName": "Panama"
      },
      {
        "regionId": 2905,
        "regionName": "Veraguas"
      }
    ]
  },
  {
    "countryId": 166,
    "countryCode": "PG",
    "countryName": "Papua New Guinea",
    "regions": [
      {
        "regionId": 2935,
        "regionName": "East New Britain"
      },
      {
        "regionId": 2936,
        "regionName": "East Sepik"
      },
      {
        "regionId": 2937,
        "regionName": "Eastern Highlands"
      },
      {
        "regionId": 2938,
        "regionName": "Enga"
      },
      {
        "regionId": 2939,
        "regionName": "Fly River"
      },
      {
        "regionId": 2940,
        "regionName": "Gulf"
      },
      {
        "regionId": 2941,
        "regionName": "Madang"
      },
      {
        "regionId": 2942,
        "regionName": "Manus"
      },
      {
        "regionId": 2943,
        "regionName": "Milne Bay"
      },
      {
        "regionId": 2944,
        "regionName": "Morobe"
      },
      {
        "regionId": 2945,
        "regionName": "National Capital District"
      },
      {
        "regionId": 2946,
        "regionName": "New Ireland"
      },
      {
        "regionId": 2947,
        "regionName": "North Solomons"
      },
      {
        "regionId": 2948,
        "regionName": "Oro"
      },
      {
        "regionId": 2949,
        "regionName": "Sandaun"
      },
      {
        "regionId": 2950,
        "regionName": "Simbu"
      },
      {
        "regionId": 2951,
        "regionName": "Southern Highlands"
      },
      {
        "regionId": 2952,
        "regionName": "West New Britain"
      },
      {
        "regionId": 2953,
        "regionName": "Western Highlands"
      }
    ]
  },
  {
    "countryId": 167,
    "countryCode": "PY",
    "countryName": "Paraguay",
    "regions": [
      {
        "regionId": 3078,
        "regionName": "Alto Paraguay"
      },
      {
        "regionId": 3079,
        "regionName": "Alto Parana"
      },
      {
        "regionId": 3080,
        "regionName": "Amambay"
      },
      {
        "regionId": 3081,
        "regionName": "Asuncion"
      },
      {
        "regionId": 3082,
        "regionName": "Boqueron"
      },
      {
        "regionId": 3083,
        "regionName": "Caaguazu"
      },
      {
        "regionId": 3084,
        "regionName": "Caazapa"
      },
      {
        "regionId": 3085,
        "regionName": "Canendiyu"
      },
      {
        "regionId": 3086,
        "regionName": "Central"
      },
      {
        "regionId": 3087,
        "regionName": "Concepcion"
      },
      {
        "regionId": 3088,
        "regionName": "Cordillera"
      },
      {
        "regionId": 3089,
        "regionName": "Guaira"
      },
      {
        "regionId": 3090,
        "regionName": "Itapua"
      },
      {
        "regionId": 3091,
        "regionName": "Misiones"
      },
      {
        "regionId": 3092,
        "regionName": "Neembucu"
      },
      {
        "regionId": 3093,
        "regionName": "Paraguari"
      },
      {
        "regionId": 3094,
        "regionName": "Presidente Hayes"
      },
      {
        "regionId": 3095,
        "regionName": "San Pedro"
      }
    ]
  },
  {
    "countryId": 168,
    "countryCode": "PE",
    "countryName": "Peru",
    "regions": [
      {
        "regionId": 2906,
        "regionCode": "AMA",
        "regionName": "Amazonas"
      },
      {
        "regionId": 2907,
        "regionCode": "05",
        "regionName": "Ancash"
      },
      {
        "regionId": 2908,
        "regionCode": "19",
        "regionName": "Apurimac"
      },
      {
        "regionId": 2909,
        "regionCode": "08",
        "regionName": "Arequipa"
      },
      {
        "regionId": 2910,
        "regionCode": "18",
        "regionName": "Ayacucho"
      },
      {
        "regionId": 2911,
        "regionCode": "12",
        "regionName": "Cajamarca"
      },
      {
        "regionId": 2912,
        "regionName": "Cusco"
      },
      {
        "regionId": 2913,
        "regionCode": "17",
        "regionName": "Huancavelica"
      },
      {
        "regionId": 2914,
        "regionCode": "HUC",
        "regionName": "Huanuco"
      },
      {
        "regionId": 2915,
        "regionCode": "07",
        "regionName": "Ica"
      },
      {
        "regionId": 2916,
        "regionCode": "JUN",
        "regionName": "Junin"
      },
      {
        "regionId": 2917,
        "regionCode": "04",
        "regionName": "La Libertad"
      },
      {
        "regionId": 2918,
        "regionCode": "03",
        "regionName": "Lambayeque"
      },
      {
        "regionId": 2919,
        "regionCode": "06",
        "regionName": "Lima y Callao"
      },
      {
        "regionId": 2920,
        "regionCode": "22",
        "regionName": "Loreto"
      },
      {
        "regionId": 2921,
        "regionCode": "MDD",
        "regionName": "Madre de Dios"
      },
      {
        "regionId": 2922,
        "regionCode": "09",
        "regionName": "Moquegua"
      },
      {
        "regionId": 2923,
        "regionCode": "15",
        "regionName": "Pasco"
      },
      {
        "regionId": 2924,
        "regionCode": "02",
        "regionName": "Piura"
      },
      {
        "regionId": 2925,
        "regionCode": "21",
        "regionName": "Puno"
      },
      {
        "regionId": 2926,
        "regionCode": "SAM",
        "regionName": "San Martin"
      },
      {
        "regionId": 2927,
        "regionCode": "10",
        "regionName": "Tacna"
      },
      {
        "regionId": 2928,
        "regionCode": "01",
        "regionName": "Tumbes"
      },
      {
        "regionId": 2929,
        "regionCode": "23",
        "regionName": "Ucayali"
      }
    ]
  },
  {
    "countryId": 169,
    "countryCode": "PH",
    "countryName": "Philippines",
    "regions": [
      {
        "regionId": 2954,
        "regionCode": "BTG",
        "regionName": "Batangas"
      },
      {
        "regionId": 2955,
        "regionCode": "05",
        "regionName": "Bicol"
      },
      {
        "regionId": 2956,
        "regionCode": "BUL",
        "regionName": "Bulacan"
      },
      {
        "regionId": 2957,
        "regionCode": "CAG",
        "regionName": "Cagayan"
      },
      {
        "regionId": 2958,
        "regionName": "Caraga"
      },
      {
        "regionId": 2959,
        "regionCode": "03",
        "regionName": "Central Luzon"
      },
      {
        "regionId": 2960,
        "regionCode": "11",
        "regionName": "Central Mindanao"
      },
      {
        "regionId": 2961,
        "regionCode": "07",
        "regionName": "Central Visayas"
      },
      {
        "regionId": 2962,
        "regionName": "Cordillera"
      },
      {
        "regionId": 2963,
        "regionName": "Davao"
      },
      {
        "regionId": 2964,
        "regionCode": "08",
        "regionName": "Eastern Visayas"
      },
      {
        "regionId": 2965,
        "regionName": "Greater Metropolitan Area"
      },
      {
        "regionId": 2966,
        "regionCode": "01",
        "regionName": "Ilocos"
      },
      {
        "regionId": 2967,
        "regionCode": "LAG",
        "regionName": "Laguna"
      },
      {
        "regionId": 2968,
        "regionName": "Luzon"
      },
      {
        "regionId": 2969,
        "regionName": "Mactan"
      },
      {
        "regionId": 2970,
        "regionName": "Metropolitan Manila Area"
      },
      {
        "regionId": 2971,
        "regionName": "Muslim Mindanao"
      },
      {
        "regionId": 2972,
        "regionCode": "10",
        "regionName": "Northern Mindanao"
      },
      {
        "regionId": 2973,
        "regionName": "Southern Mindanao"
      },
      {
        "regionId": 2974,
        "regionName": "Southern Tagalog"
      },
      {
        "regionId": 2975,
        "regionCode": "09",
        "regionName": "Western Mindanao"
      },
      {
        "regionId": 2976,
        "regionName": "Western Visayas"
      }
    ]
  },
  {
    "countryId": 170,
    "countryCode": "PN",
    "countryName": "Pitcairn Islands",
    "regions": [
      {
        "regionId": 3020,
        "regionName": "Pitcairn Island"
      }
    ]
  },
  {
    "countryId": 171,
    "countryCode": "PL",
    "countryName": "Poland",
    "regions": [
      {
        "regionId": 2984,
        "regionName": "Biale Blota"
      },
      {
        "regionId": 2985,
        "regionName": "Dobroszyce"
      },
      {
        "regionId": 2986,
        "regionName": "Dolnoslaskie"
      },
      {
        "regionId": 2987,
        "regionName": "Dziekanow Lesny"
      },
      {
        "regionId": 2988,
        "regionName": "Hopowo"
      },
      {
        "regionId": 2989,
        "regionName": "Kartuzy"
      },
      {
        "regionId": 2990,
        "regionName": "Koscian"
      },
      {
        "regionId": 2991,
        "regionCode": "17",
        "regionName": "Krakow"
      },
      {
        "regionId": 2992,
        "regionName": "Kujawsko-Pomorskie"
      },
      {
        "regionId": 2993,
        "regionName": "Lodzkie"
      },
      {
        "regionId": 2994,
        "regionName": "Lubelskie"
      },
      {
        "regionId": 2995,
        "regionName": "Lubuskie"
      },
      {
        "regionId": 2996,
        "regionName": "Malomice"
      },
      {
        "regionId": 2997,
        "regionName": "Malopolskie"
      },
      {
        "regionId": 2998,
        "regionName": "Mazowieckie"
      },
      {
        "regionId": 2999,
        "regionName": "Mirkow"
      },
      {
        "regionId": 3000,
        "regionName": "Opolskie"
      },
      {
        "regionId": 3001,
        "regionName": "Ostrowiec"
      },
      {
        "regionId": 3002,
        "regionName": "Podkarpackie"
      },
      {
        "regionId": 3003,
        "regionName": "Podlaskie"
      },
      {
        "regionId": 3004,
        "regionName": "Polska"
      },
      {
        "regionId": 3005,
        "regionName": "Pomorskie"
      },
      {
        "regionId": 3006,
        "regionCode": "31",
        "regionName": "Poznan"
      },
      {
        "regionId": 3007,
        "regionName": "Pruszkow"
      },
      {
        "regionId": 3008,
        "regionName": "Rymanowska"
      },
      {
        "regionId": 3009,
        "regionCode": "34",
        "regionName": "Rzeszow"
      },
      {
        "regionId": 3010,
        "regionName": "Slaskie"
      },
      {
        "regionId": 3011,
        "regionName": "Stare Pole"
      },
      {
        "regionId": 3012,
        "regionName": "Swietokrzyskie"
      },
      {
        "regionId": 3013,
        "regionName": "Warminsko-Mazurskie"
      },
      {
        "regionId": 3014,
        "regionName": "Warsaw"
      },
      {
        "regionId": 3015,
        "regionName": "Wejherowo"
      },
      {
        "regionId": 3016,
        "regionName": "Wielkopolskie"
      },
      {
        "regionId": 3017,
        "regionCode": "47",
        "regionName": "Wroclaw"
      },
      {
        "regionId": 3018,
        "regionName": "Zachodnio-Pomorskie"
      },
      {
        "regionId": 3019,
        "regionName": "Zukowo"
      }
    ]
  },
  {
    "countryId": 172,
    "countryCode": "PT",
    "countryName": "Portugal",
    "regions": [
      {
        "regionId": 3048,
        "regionName": "Abrantes"
      },
      {
        "regionId": 3049,
        "regionName": "Acores"
      },
      {
        "regionId": 3050,
        "regionName": "Alentejo"
      },
      {
        "regionId": 3051,
        "regionCode": "50",
        "regionName": "Algarve"
      },
      {
        "regionId": 3052,
        "regionName": "Braga"
      },
      {
        "regionId": 3053,
        "regionName": "Centro"
      },
      {
        "regionId": 3054,
        "regionName": "Distrito de Leiria"
      },
      {
        "regionId": 3055,
        "regionName": "Distrito de Viana do Castelo"
      },
      {
        "regionId": 3056,
        "regionName": "Distrito de Vila Real"
      },
      {
        "regionId": 3057,
        "regionName": "Distrito do Porto"
      },
      {
        "regionId": 3058,
        "regionName": "Lisboa e Vale do Tejo"
      },
      {
        "regionId": 3059,
        "regionName": "Madeira"
      },
      {
        "regionId": 3060,
        "regionName": "Norte"
      },
      {
        "regionId": 3061,
        "regionName": "Paivas"
      }
    ]
  },
  {
    "countryId": 173,
    "countryCode": "PR",
    "countryName": "Puerto Rico",
    "regions": [
      {
        "regionId": 3021,
        "regionName": "Arecibo"
      },
      {
        "regionId": 3022,
        "regionName": "Bayamon"
      },
      {
        "regionId": 3023,
        "regionName": "Carolina"
      },
      {
        "regionId": 3024,
        "regionName": "Florida"
      },
      {
        "regionId": 3025,
        "regionName": "Guayama"
      },
      {
        "regionId": 3026,
        "regionName": "Humacao"
      },
      {
        "regionId": 3027,
        "regionName": "Mayaguez-Aguadilla"
      },
      {
        "regionId": 3028,
        "regionName": "Ponce"
      },
      {
        "regionId": 3029,
        "regionName": "Salinas"
      },
      {
        "regionId": 3030,
        "regionName": "San Juan"
      },
      {
        "regionId": 4075,
        "regionName": "Cayey"
      }
    ]
  },
  {
    "countryId": 174,
    "countryCode": "QA",
    "countryName": "Qatar",
    "regions": [
      {
        "regionId": 3096,
        "regionName": "ad-Dawhah"
      },
      {
        "regionId": 3097,
        "regionName": "al-Ghuwayriyah"
      },
      {
        "regionId": 3098,
        "regionName": "al-Jumayliyah"
      },
      {
        "regionId": 3099,
        "regionName": "al-Khawr"
      },
      {
        "regionId": 3100,
        "regionName": "al-Wakrah"
      },
      {
        "regionId": 3101,
        "regionName": "ar-Rayyan"
      },
      {
        "regionId": 3102,
        "regionName": "ash-Shamal"
      },
      {
        "regionId": 3103,
        "regionName": "Doha"
      },
      {
        "regionId": 3104,
        "regionName": "Jarian-al-Batnah"
      },
      {
        "regionId": 3105,
        "regionName": "Umm Salal"
      }
    ]
  },
  {
    "countryId": 175,
    "countryCode": "RE",
    "countryName": "Reunion",
    "regions": [
      {
        "regionId": 3106,
        "regionName": "Saint-Benoit"
      },
      {
        "regionId": 3107,
        "regionName": "Saint-Denis"
      },
      {
        "regionId": 3108,
        "regionName": "Saint-Paul"
      },
      {
        "regionId": 3109,
        "regionName": "Saint-Pierre"
      }
    ]
  },
  {
    "countryId": 176,
    "countryCode": "RO",
    "countryName": "Romania",
    "regions": [
      {
        "regionId": 3110,
        "regionCode": "01",
        "regionName": "Alba"
      },
      {
        "regionId": 3111,
        "regionCode": "02",
        "regionName": "Arad"
      },
      {
        "regionId": 3112,
        "regionCode": "03",
        "regionName": "Arges"
      },
      {
        "regionId": 3113,
        "regionCode": "04",
        "regionName": "Bacau"
      },
      {
        "regionId": 3114,
        "regionCode": "05",
        "regionName": "Bihor"
      },
      {
        "regionId": 3115,
        "regionCode": "06",
        "regionName": "Bistrita-Nasaud"
      },
      {
        "regionId": 3116,
        "regionCode": "07",
        "regionName": "Botosani"
      },
      {
        "regionId": 3117,
        "regionCode": "08",
        "regionName": "Braila"
      },
      {
        "regionId": 3118,
        "regionCode": "09",
        "regionName": "Brasov"
      },
      {
        "regionId": 3119,
        "regionCode": "10",
        "regionName": "Bucuresti"
      },
      {
        "regionId": 3120,
        "regionCode": "11",
        "regionName": "Buzau"
      },
      {
        "regionId": 3121,
        "regionCode": "12",
        "regionName": "Calarasi"
      },
      {
        "regionId": 3122,
        "regionCode": "13",
        "regionName": "Caras-Severin"
      },
      {
        "regionId": 3123,
        "regionCode": "14",
        "regionName": "Cluj"
      },
      {
        "regionId": 3124,
        "regionCode": "15",
        "regionName": "Constanta"
      },
      {
        "regionId": 3125,
        "regionCode": "16",
        "regionName": "Covasna"
      },
      {
        "regionId": 3126,
        "regionName": "Dambovita"
      },
      {
        "regionId": 3127,
        "regionCode": "18",
        "regionName": "Dolj"
      },
      {
        "regionId": 3128,
        "regionCode": "19",
        "regionName": "Galati"
      },
      {
        "regionId": 3129,
        "regionCode": "21",
        "regionName": "Giurgiu"
      },
      {
        "regionId": 3130,
        "regionCode": "20",
        "regionName": "Gorj"
      },
      {
        "regionId": 3131,
        "regionCode": "22",
        "regionName": "Harghita"
      },
      {
        "regionId": 3132,
        "regionCode": "23",
        "regionName": "Hunedoara"
      },
      {
        "regionId": 3133,
        "regionCode": "24",
        "regionName": "Ialomita"
      },
      {
        "regionId": 3134,
        "regionCode": "25",
        "regionName": "Iasi"
      },
      {
        "regionId": 3135,
        "regionName": "Ilfov"
      },
      {
        "regionId": 3136,
        "regionCode": "26",
        "regionName": "Maramures"
      },
      {
        "regionId": 3137,
        "regionCode": "27",
        "regionName": "Mehedinti"
      },
      {
        "regionId": 3138,
        "regionCode": "28",
        "regionName": "Mures"
      },
      {
        "regionId": 3139,
        "regionCode": "29",
        "regionName": "Neamt"
      },
      {
        "regionId": 3140,
        "regionCode": "30",
        "regionName": "Olt"
      },
      {
        "regionId": 3141,
        "regionCode": "31",
        "regionName": "Prahova"
      },
      {
        "regionId": 3142,
        "regionCode": "32",
        "regionName": "Salaj"
      },
      {
        "regionId": 3143,
        "regionCode": "33",
        "regionName": "Satu Mare"
      },
      {
        "regionId": 3144,
        "regionCode": "34",
        "regionName": "Sibiu"
      },
      {
        "regionId": 3145,
        "regionName": "Sondelor"
      },
      {
        "regionId": 3146,
        "regionCode": "35",
        "regionName": "Suceava"
      },
      {
        "regionId": 3147,
        "regionCode": "36",
        "regionName": "Teleorman"
      },
      {
        "regionId": 3148,
        "regionCode": "37",
        "regionName": "Timis"
      },
      {
        "regionId": 3149,
        "regionCode": "38",
        "regionName": "Tulcea"
      },
      {
        "regionId": 3150,
        "regionName": "Valcea"
      },
      {
        "regionId": 3151,
        "regionCode": "39",
        "regionName": "Vaslui"
      },
      {
        "regionId": 3152,
        "regionCode": "41",
        "regionName": "Vrancea"
      }
    ]
  },
  {
    "countryId": 177,
    "countryCode": "RU",
    "countryName": "Russian Federation",
    "regions": [
      {
        "regionId": 3156,
        "regionName": "Adygeja"
      },
      {
        "regionId": 3157,
        "regionName": "Aga"
      },
      {
        "regionId": 3158,
        "regionName": "Alanija"
      },
      {
        "regionId": 3159,
        "regionName": "Altaj"
      },
      {
        "regionId": 3160,
        "regionName": "Amur"
      },
      {
        "regionId": 3161,
        "regionName": "Arhangelsk"
      },
      {
        "regionId": 3162,
        "regionName": "Astrahan"
      },
      {
        "regionId": 3163,
        "regionName": "Bashkortostan"
      },
      {
        "regionId": 3164,
        "regionName": "Belgorod"
      },
      {
        "regionId": 3165,
        "regionName": "Brjansk"
      },
      {
        "regionId": 3166,
        "regionName": "Burjatija"
      },
      {
        "regionId": 3167,
        "regionName": "Chechenija"
      },
      {
        "regionId": 3168,
        "regionName": "Cheljabinsk"
      },
      {
        "regionId": 3169,
        "regionName": "Chita"
      },
      {
        "regionId": 3170,
        "regionName": "Chukotka"
      },
      {
        "regionId": 3171,
        "regionName": "Chuvashija"
      },
      {
        "regionId": 3172,
        "regionName": "Dagestan"
      },
      {
        "regionId": 3173,
        "regionName": "Evenkija"
      },
      {
        "regionId": 3174,
        "regionName": "Gorno-Altaj"
      },
      {
        "regionId": 3175,
        "regionName": "Habarovsk"
      },
      {
        "regionId": 3176,
        "regionName": "Hakasija"
      },
      {
        "regionId": 3177,
        "regionName": "Hanty-Mansija"
      },
      {
        "regionId": 3178,
        "regionName": "Ingusetija"
      },
      {
        "regionId": 3179,
        "regionName": "Irkutsk"
      },
      {
        "regionId": 3180,
        "regionName": "Ivanovo"
      },
      {
        "regionId": 3181,
        "regionName": "Jamalo-Nenets"
      },
      {
        "regionId": 3182,
        "regionName": "Jaroslavl"
      },
      {
        "regionId": 3183,
        "regionName": "Jevrej"
      },
      {
        "regionId": 3184,
        "regionName": "Kabardino-Balkarija"
      },
      {
        "regionId": 3185,
        "regionName": "Kaliningrad"
      },
      {
        "regionId": 3186,
        "regionName": "Kalmykija"
      },
      {
        "regionId": 3187,
        "regionName": "Kaluga"
      },
      {
        "regionId": 3188,
        "regionName": "Kamchatka"
      },
      {
        "regionId": 3189,
        "regionName": "Karachaj-Cherkessija"
      },
      {
        "regionId": 3190,
        "regionName": "Karelija"
      },
      {
        "regionId": 3191,
        "regionName": "Kemerovo"
      },
      {
        "regionId": 3192,
        "regionCode": "KHA",
        "regionName": "Khabarovskiy Kray"
      },
      {
        "regionId": 3193,
        "regionName": "Kirov"
      },
      {
        "regionId": 3194,
        "regionName": "Komi"
      },
      {
        "regionId": 3195,
        "regionName": "Komi-Permjakija"
      },
      {
        "regionId": 3196,
        "regionName": "Korjakija"
      },
      {
        "regionId": 3197,
        "regionName": "Kostroma"
      },
      {
        "regionId": 3198,
        "regionName": "Krasnodar"
      },
      {
        "regionId": 3199,
        "regionName": "Krasnojarsk"
      },
      {
        "regionId": 3200,
        "regionCode": "KYA",
        "regionName": "Krasnoyarskiy Kray"
      },
      {
        "regionId": 3201,
        "regionName": "Kurgan"
      },
      {
        "regionId": 3202,
        "regionName": "Kursk"
      },
      {
        "regionId": 3203,
        "regionName": "Leningrad"
      },
      {
        "regionId": 3204,
        "regionName": "Lipeck"
      },
      {
        "regionId": 3205,
        "regionName": "Magadan"
      },
      {
        "regionId": 3206,
        "regionName": "Marij El"
      },
      {
        "regionId": 3207,
        "regionName": "Mordovija"
      },
      {
        "regionId": 3208,
        "regionName": "Moscow"
      },
      {
        "regionId": 3209,
        "regionName": "Moskovskaja Oblast"
      },
      {
        "regionId": 3210,
        "regionName": "Moskovskaya Oblast"
      },
      {
        "regionId": 3211,
        "regionCode": "MOW",
        "regionName": "Moskva"
      },
      {
        "regionId": 3212,
        "regionName": "Murmansk"
      },
      {
        "regionId": 3213,
        "regionName": "Nenets"
      },
      {
        "regionId": 3214,
        "regionName": "Nizhnij Novgorod"
      },
      {
        "regionId": 3215,
        "regionName": "Novgorod"
      },
      {
        "regionId": 3216,
        "regionName": "Novokusnezk"
      },
      {
        "regionId": 3217,
        "regionName": "Novosibirsk"
      },
      {
        "regionId": 3218,
        "regionName": "Omsk"
      },
      {
        "regionId": 3219,
        "regionName": "Orenburg"
      },
      {
        "regionId": 3220,
        "regionName": "Orjol"
      },
      {
        "regionId": 3221,
        "regionName": "Penza"
      },
      {
        "regionId": 3222,
        "regionName": "Perm"
      },
      {
        "regionId": 3223,
        "regionName": "Primorje"
      },
      {
        "regionId": 3224,
        "regionName": "Pskov"
      },
      {
        "regionId": 3225,
        "regionName": "Pskovskaya Oblast"
      },
      {
        "regionId": 3226,
        "regionName": "Rjazan"
      },
      {
        "regionId": 3227,
        "regionName": "Rostov"
      },
      {
        "regionId": 3228,
        "regionName": "Saha"
      },
      {
        "regionId": 3229,
        "regionName": "Sahalin"
      },
      {
        "regionId": 3230,
        "regionName": "Samara"
      },
      {
        "regionId": 3231,
        "regionName": "Samarskaya"
      },
      {
        "regionId": 3232,
        "regionCode": "SPE",
        "regionName": "Sankt-Peterburg"
      },
      {
        "regionId": 3233,
        "regionName": "Saratov"
      },
      {
        "regionId": 3234,
        "regionName": "Smolensk"
      },
      {
        "regionId": 3235,
        "regionName": "Stavropol"
      },
      {
        "regionId": 3236,
        "regionName": "Sverdlovsk"
      },
      {
        "regionId": 3237,
        "regionName": "Tajmyrija"
      },
      {
        "regionId": 3238,
        "regionName": "Tambov"
      },
      {
        "regionId": 3239,
        "regionName": "Tatarstan"
      },
      {
        "regionId": 3240,
        "regionName": "Tjumen"
      },
      {
        "regionId": 3241,
        "regionName": "Tomsk"
      },
      {
        "regionId": 3242,
        "regionName": "Tula"
      },
      {
        "regionId": 3243,
        "regionName": "Tver"
      },
      {
        "regionId": 3244,
        "regionName": "Tyva"
      },
      {
        "regionId": 3245,
        "regionName": "Udmurtija"
      },
      {
        "regionId": 3246,
        "regionName": "Uljanovsk"
      },
      {
        "regionId": 3247,
        "regionName": "Ulyanovskaya Oblast"
      },
      {
        "regionId": 3248,
        "regionName": "Ust-Orda"
      },
      {
        "regionId": 3249,
        "regionName": "Vladimir"
      },
      {
        "regionId": 3250,
        "regionName": "Volgograd"
      },
      {
        "regionId": 3251,
        "regionName": "Vologda"
      },
      {
        "regionId": 3252,
        "regionName": "Voronezh"
      }
    ]
  },
  {
    "countryId": 178,
    "countryCode": "RW",
    "countryName": "Rwanda",
    "regions": [
      {
        "regionId": 3253,
        "regionName": "Butare"
      },
      {
        "regionId": 3254,
        "regionName": "Byumba"
      },
      {
        "regionId": 3255,
        "regionName": "Cyangugu"
      },
      {
        "regionId": 3256,
        "regionName": "Gikongoro"
      },
      {
        "regionId": 3257,
        "regionName": "Gisenyi"
      },
      {
        "regionId": 3258,
        "regionName": "Gitarama"
      },
      {
        "regionId": 3259,
        "regionName": "Kibungo"
      },
      {
        "regionId": 3260,
        "regionName": "Kibuye"
      },
      {
        "regionId": 3261,
        "regionName": "Kigali-ngali"
      },
      {
        "regionId": 3262,
        "regionName": "Ruhengeri"
      }
    ]
  },
  {
    "countryId": 179,
    "countryCode": "SH",
    "countryName": "Saint Helena",
    "regions": [
      {
        "regionId": 3353,
        "regionName": "Ascension"
      },
      {
        "regionId": 3354,
        "regionName": "Gough Island"
      },
      {
        "regionId": 3355,
        "regionName": "Saint Helena"
      },
      {
        "regionId": 3356,
        "regionName": "Tristan da Cunha"
      }
    ]
  },
  {
    "countryId": 180,
    "countryCode": "KN",
    "countryName": "Saint Kitts and Nevis",
    "regions": [
      {
        "regionId": 2166,
        "regionName": "Christ Church Nichola Town"
      },
      {
        "regionId": 2167,
        "regionName": "Saint Anne Sandy Point"
      },
      {
        "regionId": 2168,
        "regionName": "Saint George Basseterre"
      },
      {
        "regionId": 2169,
        "regionName": "Saint George Gingerland"
      },
      {
        "regionId": 2170,
        "regionName": "Saint James Windward"
      },
      {
        "regionId": 2171,
        "regionName": "Saint John Capesterre"
      },
      {
        "regionId": 2172,
        "regionName": "Saint John Figtree"
      },
      {
        "regionId": 2173,
        "regionName": "Saint Mary Cayon"
      },
      {
        "regionId": 2174,
        "regionName": "Saint Paul Capesterre"
      },
      {
        "regionId": 2175,
        "regionName": "Saint Paul Charlestown"
      },
      {
        "regionId": 2176,
        "regionName": "Saint Peter Basseterre"
      },
      {
        "regionId": 2177,
        "regionName": "Saint Thomas Lowland"
      },
      {
        "regionId": 2178,
        "regionName": "Saint Thomas Middle Island"
      },
      {
        "regionId": 2179,
        "regionName": "Trinity Palmetto Point"
      }
    ]
  },
  {
    "countryId": 181,
    "countryCode": "LC",
    "countryName": "Saint Lucia",
    "regions": [
      {
        "regionId": 2278,
        "regionName": "Anse-la-Raye"
      },
      {
        "regionId": 2279,
        "regionName": "Canaries"
      },
      {
        "regionId": 2280,
        "regionName": "Castries"
      },
      {
        "regionId": 2281,
        "regionName": "Choiseul"
      },
      {
        "regionId": 2282,
        "regionName": "Dennery"
      },
      {
        "regionId": 2283,
        "regionName": "Gros Inlet"
      },
      {
        "regionId": 2284,
        "regionName": "Laborie"
      },
      {
        "regionId": 2285,
        "regionName": "Micoud"
      },
      {
        "regionId": 2286,
        "regionName": "Soufriere"
      },
      {
        "regionId": 2287,
        "regionName": "Vieux Fort"
      }
    ]
  },
  {
    "countryId": 182,
    "countryCode": "WS",
    "countryName": "Samoa",
    "regions": [
      {
        "regionId": 3938,
        "regionName": "A'ana"
      },
      {
        "regionId": 3939,
        "regionName": "Aiga-i-le-Tai"
      },
      {
        "regionId": 3940,
        "regionName": "Atua"
      },
      {
        "regionId": 3941,
        "regionName": "Fa'asaleleaga"
      },
      {
        "regionId": 3942,
        "regionName": "Gaga'emauga"
      },
      {
        "regionId": 3943,
        "regionName": "Gagaifomauga"
      },
      {
        "regionId": 3944,
        "regionName": "Palauli"
      },
      {
        "regionId": 3945,
        "regionName": "Satupa'itea"
      },
      {
        "regionId": 3946,
        "regionName": "Tuamasaga"
      },
      {
        "regionId": 3947,
        "regionName": "Va'a-o-Fonoti"
      },
      {
        "regionId": 3948,
        "regionName": "Vaisigano"
      }
    ]
  },
  {
    "countryId": 183,
    "countryCode": "SM",
    "countryName": "San Marino",
    "regions": [
      {
        "regionId": 3386,
        "regionName": "Acquaviva"
      },
      {
        "regionId": 3387,
        "regionName": "Borgo Maggiore"
      },
      {
        "regionId": 3388,
        "regionName": "Chiesanuova"
      },
      {
        "regionId": 3389,
        "regionName": "Domagnano"
      },
      {
        "regionId": 3390,
        "regionName": "Faetano"
      },
      {
        "regionId": 3391,
        "regionName": "Fiorentino"
      },
      {
        "regionId": 3392,
        "regionName": "Montegiardino"
      },
      {
        "regionId": 3393,
        "regionName": "San Marino"
      },
      {
        "regionId": 3394,
        "regionName": "Serravalle"
      }
    ]
  },
  {
    "countryId": 184,
    "countryCode": "SA",
    "countryName": "Saudi Arabia",
    "regions": [
      {
        "regionId": 3263,
        "regionName": "Al Khobar"
      },
      {
        "regionId": 3264,
        "regionName": "al-Bahah"
      },
      {
        "regionId": 3265,
        "regionName": "al-Hudud-ash-Shamaliyah"
      },
      {
        "regionId": 3266,
        "regionName": "al-Madinah"
      },
      {
        "regionId": 3267,
        "regionName": "ar-Riyad"
      },
      {
        "regionId": 3268,
        "regionName": "Aseer"
      },
      {
        "regionId": 3269,
        "regionName": "Ash Sharqiyah"
      },
      {
        "regionId": 3270,
        "regionName": "Asir"
      },
      {
        "regionId": 3271,
        "regionName": "Central Province"
      },
      {
        "regionId": 3272,
        "regionName": "Eastern Province"
      },
      {
        "regionId": 3273,
        "regionName": "Ha'il"
      },
      {
        "regionId": 3274,
        "regionName": "Jawf"
      },
      {
        "regionId": 3275,
        "regionName": "Jizan"
      },
      {
        "regionId": 3276,
        "regionName": "Makkah"
      },
      {
        "regionId": 3277,
        "regionName": "Najran"
      },
      {
        "regionId": 3278,
        "regionName": "Qasim"
      },
      {
        "regionId": 3279,
        "regionName": "Tabuk"
      },
      {
        "regionId": 3280,
        "regionName": "Western Province"
      }
    ]
  },
  {
    "countryId": 185,
    "countryCode": "SN",
    "countryName": "Senegal",
    "regions": [
      {
        "regionId": 3395,
        "regionName": "Dakar"
      },
      {
        "regionId": 3396,
        "regionName": "Diourbel"
      },
      {
        "regionId": 3397,
        "regionName": "Fatick"
      },
      {
        "regionId": 3398,
        "regionName": "Kaolack"
      },
      {
        "regionId": 3399,
        "regionName": "Kolda"
      },
      {
        "regionId": 3400,
        "regionName": "Louga"
      },
      {
        "regionId": 3401,
        "regionName": "Saint-Louis"
      },
      {
        "regionId": 3402,
        "regionName": "Tambacounda"
      },
      {
        "regionId": 3403,
        "regionName": "Thies"
      },
      {
        "regionId": 3404,
        "regionName": "Ziguinchor"
      }
    ]
  },
  {
    "countryId": 186,
    "countryCode": "RS",
    "countryName": "Serbia",
    "regions": [
      {
        "regionId": 3153,
        "regionName": "Central Serbia"
      },
      {
        "regionId": 3154,
        "regionName": "Kosovo and Metohija"
      },
      {
        "regionId": 3155,
        "regionName": "Vojvodina"
      }
    ]
  },
  {
    "countryId": 187,
    "countryCode": "SC",
    "countryName": "Seychelles",
    "regions": [
      {
        "regionId": 3290,
        "regionName": "Anse Boileau"
      },
      {
        "regionId": 3291,
        "regionName": "Anse Royale"
      },
      {
        "regionId": 3292,
        "regionName": "Cascade"
      },
      {
        "regionId": 3293,
        "regionName": "Takamaka"
      },
      {
        "regionId": 3294,
        "regionName": "Victoria"
      }
    ]
  },
  {
    "countryId": 188,
    "countryCode": "SL",
    "countryName": "Sierra Leone",
    "regions": [
      {
        "regionId": 3382,
        "regionName": "Eastern"
      },
      {
        "regionId": 3383,
        "regionName": "Northern"
      },
      {
        "regionId": 3384,
        "regionName": "Southern"
      },
      {
        "regionId": 3385,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 189,
    "countryCode": "SG",
    "countryName": "Singapore",
    "regions": [
      {
        "regionId": 3352,
        "regionCode": "SG",
        "regionName": "Singapore"
      }
    ]
  },
  {
    "countryId": 190,
    "countryCode": "SK",
    "countryName": "Slovakia",
    "regions": [
      {
        "regionId": 3374,
        "regionName": "Banskobystricky"
      },
      {
        "regionId": 3375,
        "regionName": "Bratislavsky"
      },
      {
        "regionId": 3376,
        "regionName": "Kosicky"
      },
      {
        "regionId": 3377,
        "regionName": "Nitriansky"
      },
      {
        "regionId": 3378,
        "regionName": "Presovsky"
      },
      {
        "regionId": 3379,
        "regionName": "Trenciansky"
      },
      {
        "regionId": 3380,
        "regionName": "Trnavsky"
      },
      {
        "regionId": 3381,
        "regionName": "Zilinsky"
      }
    ]
  },
  {
    "countryId": 191,
    "countryCode": "SI",
    "countryName": "Slovenia",
    "regions": [
      {
        "regionId": 3357,
        "regionName": "Benedikt"
      },
      {
        "regionId": 3358,
        "regionName": "Gorenjska"
      },
      {
        "regionId": 3359,
        "regionName": "Gorishka"
      },
      {
        "regionId": 3360,
        "regionName": "Jugovzhodna Slovenija"
      },
      {
        "regionId": 3361,
        "regionName": "Koroshka"
      },
      {
        "regionId": 3362,
        "regionName": "Notranjsko-krashka"
      },
      {
        "regionId": 3363,
        "regionName": "Obalno-krashka"
      },
      {
        "regionId": 3364,
        "regionName": "Obcina Domzale"
      },
      {
        "regionId": 3365,
        "regionName": "Obcina Vitanje"
      },
      {
        "regionId": 3366,
        "regionName": "Osrednjeslovenska"
      },
      {
        "regionId": 3367,
        "regionName": "Podravska"
      },
      {
        "regionId": 3368,
        "regionName": "Pomurska"
      },
      {
        "regionId": 3369,
        "regionName": "Savinjska"
      },
      {
        "regionId": 3370,
        "regionName": "Slovenian Littoral"
      },
      {
        "regionId": 3371,
        "regionName": "Spodnjeposavska"
      },
      {
        "regionId": 3372,
        "regionName": "Zasavska"
      }
    ]
  },
  {
    "countryId": 192,
    "countryCode": "SB",
    "countryName": "Solomon Islands",
    "regions": [
      {
        "regionId": 3281,
        "regionName": "Central"
      },
      {
        "regionId": 3282,
        "regionName": "Choiseul"
      },
      {
        "regionId": 3283,
        "regionName": "Guadalcanal"
      },
      {
        "regionId": 3284,
        "regionName": "Isabel"
      },
      {
        "regionId": 3285,
        "regionName": "Makira and Ulawa"
      },
      {
        "regionId": 3286,
        "regionName": "Malaita"
      },
      {
        "regionId": 3287,
        "regionName": "Rennell and Bellona"
      },
      {
        "regionId": 3288,
        "regionName": "Temotu"
      },
      {
        "regionId": 3289,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 193,
    "countryCode": "SO",
    "countryName": "Somalia",
    "regions": [
      {
        "regionId": 3405,
        "regionCode": "AW",
        "regionName": "Awdal"
      },
      {
        "regionId": 3406,
        "regionName": "Bakol"
      },
      {
        "regionId": 3407,
        "regionName": "Banadir"
      },
      {
        "regionId": 3408,
        "regionCode": "BR",
        "regionName": "Bari"
      },
      {
        "regionId": 3409,
        "regionCode": "BY",
        "regionName": "Bay"
      },
      {
        "regionId": 3410,
        "regionName": "Galgudug"
      },
      {
        "regionId": 3411,
        "regionCode": "GE",
        "regionName": "Gedo"
      },
      {
        "regionId": 3412,
        "regionName": "Hiran"
      },
      {
        "regionId": 3413,
        "regionName": "Jubbada Hose"
      },
      {
        "regionId": 3414,
        "regionName": "Jubbadha Dexe"
      },
      {
        "regionId": 3415,
        "regionCode": "MU",
        "regionName": "Mudug"
      },
      {
        "regionId": 3416,
        "regionName": "Nugal"
      },
      {
        "regionId": 3417,
        "regionName": "Sanag"
      },
      {
        "regionId": 3418,
        "regionName": "Shabellaha Dhexe"
      },
      {
        "regionId": 3419,
        "regionName": "Shabellaha Hose"
      },
      {
        "regionId": 3420,
        "regionName": "Togdher"
      },
      {
        "regionId": 3421,
        "regionName": "Woqoyi Galbed"
      }
    ]
  },
  {
    "countryId": 194,
    "countryCode": "ZA",
    "countryName": "South Africa",
    "regions": [
      {
        "regionId": 3970,
        "regionCode": "EC",
        "regionName": "Eastern Cape"
      },
      {
        "regionId": 3971,
        "regionName": "Free State"
      },
      {
        "regionId": 3972,
        "regionCode": "GP",
        "regionName": "Gauteng"
      },
      {
        "regionId": 3973,
        "regionName": "Kempton Park"
      },
      {
        "regionId": 3974,
        "regionName": "Kramerville"
      },
      {
        "regionId": 3975,
        "regionName": "KwaZulu Natal"
      },
      {
        "regionId": 3976,
        "regionName": "Limpopo"
      },
      {
        "regionId": 3977,
        "regionCode": "MP",
        "regionName": "Mpumalanga"
      },
      {
        "regionId": 3978,
        "regionName": "North West"
      },
      {
        "regionId": 3979,
        "regionCode": "NC",
        "regionName": "Northern Cape"
      },
      {
        "regionId": 3980,
        "regionName": "Parow"
      },
      {
        "regionId": 3981,
        "regionName": "Table View"
      },
      {
        "regionId": 3982,
        "regionName": "Umtentweni"
      },
      {
        "regionId": 3983,
        "regionCode": "WC",
        "regionName": "Western Cape"
      }
    ]
  },
  {
    "countryId": 195,
    "countryCode": "ES",
    "countryName": "Spain",
    "regions": [
      {
        "regionId": 1065,
        "regionName": "A Coruna"
      },
      {
        "regionId": 1066,
        "regionName": "Alacant"
      },
      {
        "regionId": 1067,
        "regionCode": "01",
        "regionName": "Alava"
      },
      {
        "regionId": 1068,
        "regionCode": "02",
        "regionName": "Albacete"
      },
      {
        "regionId": 1069,
        "regionCode": "04",
        "regionName": "Almeria"
      },
      {
        "regionId": 1070,
        "regionName": "Andalucia"
      },
      {
        "regionId": 1071,
        "regionCode": "33",
        "regionName": "Asturias"
      },
      {
        "regionId": 1072,
        "regionCode": "05",
        "regionName": "Avila"
      },
      {
        "regionId": 1073,
        "regionCode": "06",
        "regionName": "Badajoz"
      },
      {
        "regionId": 1074,
        "regionName": "Balears"
      },
      {
        "regionId": 1075,
        "regionCode": "08",
        "regionName": "Barcelona"
      },
      {
        "regionId": 1076,
        "regionName": "Bertamirans"
      },
      {
        "regionId": 1077,
        "regionName": "Biscay"
      },
      {
        "regionId": 1078,
        "regionCode": "09",
        "regionName": "Burgos"
      },
      {
        "regionId": 1079,
        "regionCode": "10",
        "regionName": "Caceres"
      },
      {
        "regionId": 1080,
        "regionCode": "11",
        "regionName": "Cadiz"
      },
      {
        "regionId": 1081,
        "regionCode": "39",
        "regionName": "Cantabria"
      },
      {
        "regionId": 1082,
        "regionName": "Castello"
      },
      {
        "regionId": 1083,
        "regionName": "Catalunya"
      },
      {
        "regionId": 1084,
        "regionName": "Ceuta"
      },
      {
        "regionId": 1085,
        "regionCode": "13",
        "regionName": "Ciudad Real"
      },
      {
        "regionId": 1086,
        "regionName": "Comunidad Autonoma de Canarias"
      },
      {
        "regionId": 1087,
        "regionName": "Comunidad Autonoma de Cataluna"
      },
      {
        "regionId": 1088,
        "regionName": "Comunidad Autonoma de Galicia"
      },
      {
        "regionId": 1089,
        "regionName": "Comunidad Autonoma de las Isla"
      },
      {
        "regionId": 1090,
        "regionName": "Comunidad Autonoma del Princip"
      },
      {
        "regionId": 1091,
        "regionName": "Comunidad Valenciana"
      },
      {
        "regionId": 1092,
        "regionName": "Cordoba"
      },
      {
        "regionId": 1093,
        "regionCode": "16",
        "regionName": "Cuenca"
      },
      {
        "regionId": 1094,
        "regionName": "Gipuzkoa"
      },
      {
        "regionId": 1095,
        "regionName": "Girona"
      },
      {
        "regionId": 1096,
        "regionCode": "18",
        "regionName": "Granada"
      },
      {
        "regionId": 1097,
        "regionCode": "19",
        "regionName": "Guadalajara"
      },
      {
        "regionId": 1098,
        "regionCode": "20",
        "regionName": "Guipuzcoa"
      },
      {
        "regionId": 1099,
        "regionCode": "21",
        "regionName": "Huelva"
      },
      {
        "regionId": 1100,
        "regionCode": "22",
        "regionName": "Huesca"
      },
      {
        "regionId": 1101,
        "regionCode": "23",
        "regionName": "Jaen"
      },
      {
        "regionId": 1102,
        "regionCode": "26",
        "regionName": "La Rioja"
      },
      {
        "regionId": 1103,
        "regionCode": "35",
        "regionName": "Las Palmas"
      },
      {
        "regionId": 1104,
        "regionCode": "24",
        "regionName": "Leon"
      },
      {
        "regionId": 1105,
        "regionCode": "25",
        "regionName": "Lerida"
      },
      {
        "regionId": 1106,
        "regionName": "Lleida"
      },
      {
        "regionId": 1107,
        "regionCode": "27",
        "regionName": "Lugo"
      },
      {
        "regionId": 1108,
        "regionCode": "28",
        "regionName": "Madrid"
      },
      {
        "regionId": 1109,
        "regionCode": "29",
        "regionName": "Malaga"
      },
      {
        "regionId": 1110,
        "regionName": "Melilla"
      },
      {
        "regionId": 1111,
        "regionCode": "30",
        "regionName": "Murcia"
      },
      {
        "regionId": 1112,
        "regionCode": "31",
        "regionName": "Navarra"
      },
      {
        "regionId": 1113,
        "regionName": "Ourense"
      },
      {
        "regionId": 1114,
        "regionName": "Pais Vasco"
      },
      {
        "regionId": 1115,
        "regionCode": "34",
        "regionName": "Palencia"
      },
      {
        "regionId": 1116,
        "regionCode": "36",
        "regionName": "Pontevedra"
      },
      {
        "regionId": 1117,
        "regionCode": "37",
        "regionName": "Salamanca"
      },
      {
        "regionId": 1118,
        "regionName": "Santa Cruz de Tenerife"
      },
      {
        "regionId": 1119,
        "regionCode": "40",
        "regionName": "Segovia"
      },
      {
        "regionId": 1120,
        "regionCode": "41",
        "regionName": "Sevilla"
      },
      {
        "regionId": 1121,
        "regionCode": "42",
        "regionName": "Soria"
      },
      {
        "regionId": 1122,
        "regionCode": "43",
        "regionName": "Tarragona"
      },
      {
        "regionId": 1123,
        "regionName": "Tenerife"
      },
      {
        "regionId": 1124,
        "regionCode": "44",
        "regionName": "Teruel"
      },
      {
        "regionId": 1125,
        "regionCode": "45",
        "regionName": "Toledo"
      },
      {
        "regionId": 1126,
        "regionCode": "46",
        "regionName": "Valencia"
      },
      {
        "regionId": 1127,
        "regionCode": "47",
        "regionName": "Valladolid"
      },
      {
        "regionId": 1128,
        "regionCode": "48",
        "regionName": "Vizcaya"
      },
      {
        "regionId": 1129,
        "regionCode": "49",
        "regionName": "Zamora"
      },
      {
        "regionId": 1130,
        "regionCode": "50",
        "regionName": "Zaragoza"
      }
    ]
  },
  {
    "countryId": 196,
    "countryCode": "LK",
    "countryName": "Sri Lanka",
    "regions": [
      {
        "regionId": 2299,
        "regionName": "Amparai"
      },
      {
        "regionId": 2300,
        "regionName": "Anuradhapuraya"
      },
      {
        "regionId": 2301,
        "regionName": "Badulla"
      },
      {
        "regionId": 2302,
        "regionName": "Boralesgamuwa"
      },
      {
        "regionId": 2303,
        "regionName": "Colombo"
      },
      {
        "regionId": 2304,
        "regionName": "Galla"
      },
      {
        "regionId": 2305,
        "regionName": "Gampaha"
      },
      {
        "regionId": 2306,
        "regionName": "Hambantota"
      },
      {
        "regionId": 2307,
        "regionName": "kadawatha"
      },
      {
        "regionId": 2308,
        "regionName": "Kalatura"
      },
      {
        "regionId": 2309,
        "regionName": "Kegalla"
      },
      {
        "regionId": 2310,
        "regionName": "Kilinochchi"
      },
      {
        "regionId": 2311,
        "regionName": "Kurunegala"
      },
      {
        "regionId": 2312,
        "regionName": "Madakalpuwa"
      },
      {
        "regionId": 2313,
        "regionName": "Maha Nuwara"
      },
      {
        "regionId": 2314,
        "regionName": "Malwana"
      },
      {
        "regionId": 2315,
        "regionName": "Mannarama"
      },
      {
        "regionId": 2316,
        "regionName": "Matale"
      },
      {
        "regionId": 2317,
        "regionName": "Matara"
      },
      {
        "regionId": 2318,
        "regionName": "Monaragala"
      },
      {
        "regionId": 2319,
        "regionName": "Mullaitivu"
      },
      {
        "regionId": 2320,
        "regionName": "North Eastern Province"
      },
      {
        "regionId": 2321,
        "regionName": "North Western Province"
      },
      {
        "regionId": 2322,
        "regionName": "Nuwara Eliya"
      },
      {
        "regionId": 2323,
        "regionName": "Polonnaruwa"
      },
      {
        "regionId": 2324,
        "regionName": "Puttalama"
      },
      {
        "regionId": 2325,
        "regionName": "Ratnapuraya"
      },
      {
        "regionId": 2326,
        "regionName": "Southern Province"
      },
      {
        "regionId": 2327,
        "regionName": "Tirikunamalaya"
      },
      {
        "regionId": 2328,
        "regionName": "Tuscany"
      },
      {
        "regionId": 2329,
        "regionName": "Vavuniyawa"
      },
      {
        "regionId": 2330,
        "regionName": "Western Province"
      },
      {
        "regionId": 2331,
        "regionName": "Yapanaya"
      }
    ]
  },
  {
    "countryId": 197,
    "countryCode": "SD",
    "countryName": "Sudan",
    "regions": [
      {
        "regionId": 3295,
        "regionName": "A'ali-an-Nil"
      },
      {
        "regionId": 3296,
        "regionName": "al-Bahr-al-Ahmar"
      },
      {
        "regionId": 3297,
        "regionName": "al-Buhayrat"
      },
      {
        "regionId": 3298,
        "regionName": "al-Jazirah"
      },
      {
        "regionId": 3299,
        "regionName": "al-Khartum"
      },
      {
        "regionId": 3300,
        "regionName": "al-Qadarif"
      },
      {
        "regionId": 3301,
        "regionName": "al-Wahdah"
      },
      {
        "regionId": 3302,
        "regionName": "an-Nil-al-Abyad"
      },
      {
        "regionId": 3303,
        "regionName": "an-Nil-al-Azraq"
      },
      {
        "regionId": 3304,
        "regionName": "ash-Shamaliyah"
      },
      {
        "regionId": 3305,
        "regionName": "Bahr-al-Jabal"
      },
      {
        "regionId": 3306,
        "regionName": "Central Equatoria"
      },
      {
        "regionId": 3307,
        "regionName": "Gharb Bahr-al-Ghazal"
      },
      {
        "regionId": 3308,
        "regionName": "Gharb Darfur"
      },
      {
        "regionId": 3309,
        "regionName": "Gharb Kurdufan"
      },
      {
        "regionId": 3310,
        "regionName": "Gharb-al-Istiwa'iyah"
      },
      {
        "regionId": 3311,
        "regionName": "Janub Darfur"
      },
      {
        "regionId": 3312,
        "regionName": "Janub Kurdufan"
      },
      {
        "regionId": 3313,
        "regionName": "Junqali"
      },
      {
        "regionId": 3314,
        "regionName": "Kassala"
      },
      {
        "regionId": 3315,
        "regionName": "Nahr-an-Nil"
      },
      {
        "regionId": 3316,
        "regionName": "Shamal Bahr-al-Ghazal"
      },
      {
        "regionId": 3317,
        "regionName": "Shamal Darfur"
      },
      {
        "regionId": 3318,
        "regionName": "Shamal Kurdufan"
      },
      {
        "regionId": 3319,
        "regionName": "Sharq-al-Istiwa'iyah"
      },
      {
        "regionId": 3320,
        "regionName": "Sinnar"
      },
      {
        "regionId": 3321,
        "regionName": "Warab"
      },
      {
        "regionId": 3322,
        "regionName": "Wilayat al Khartum"
      }
    ]
  },
  {
    "countryId": 198,
    "countryCode": "SR",
    "countryName": "Suriname",
    "regions": [
      {
        "regionId": 3422,
        "regionCode": "BR",
        "regionName": "Brokopondo"
      },
      {
        "regionId": 3423,
        "regionCode": "CM",
        "regionName": "Commewijne"
      },
      {
        "regionId": 3424,
        "regionCode": "CR",
        "regionName": "Coronie"
      },
      {
        "regionId": 3425,
        "regionCode": "MA",
        "regionName": "Marowijne"
      },
      {
        "regionId": 3426,
        "regionCode": "NI",
        "regionName": "Nickerie"
      },
      {
        "regionId": 3427,
        "regionCode": "PR",
        "regionName": "Para"
      },
      {
        "regionId": 3428,
        "regionCode": "PM",
        "regionName": "Paramaribo"
      },
      {
        "regionId": 3429,
        "regionCode": "SA",
        "regionName": "Saramacca"
      },
      {
        "regionId": 3430,
        "regionCode": "WA",
        "regionName": "Wanica"
      }
    ]
  },
  {
    "countryId": 199,
    "countryCode": "SJ",
    "countryName": "Svalbard",
    "regions": [
      {
        "regionId": 3373,
        "regionName": "Svalbard"
      }
    ]
  },
  {
    "countryId": 200,
    "countryCode": "SZ",
    "countryName": "Swaziland",
    "regions": [
      {
        "regionId": 3460,
        "regionName": "Hhohho"
      },
      {
        "regionId": 3461,
        "regionName": "Lubombo"
      },
      {
        "regionId": 3462,
        "regionName": "Manzini"
      },
      {
        "regionId": 3463,
        "regionName": "Shiselweni"
      }
    ]
  },
  {
    "countryId": 201,
    "countryCode": "SE",
    "countryName": "Sweden",
    "regions": [
      {
        "regionId": 3323,
        "regionName": "Alvsborgs Lan"
      },
      {
        "regionId": 3324,
        "regionName": "Angermanland"
      },
      {
        "regionId": 3325,
        "regionName": "Blekinge"
      },
      {
        "regionId": 3326,
        "regionName": "Bohuslan"
      },
      {
        "regionId": 3327,
        "regionName": "Dalarna"
      },
      {
        "regionId": 3328,
        "regionName": "Gavleborg"
      },
      {
        "regionId": 3329,
        "regionName": "Gaza"
      },
      {
        "regionId": 3330,
        "regionName": "Gotland"
      },
      {
        "regionId": 3331,
        "regionName": "Halland"
      },
      {
        "regionId": 3332,
        "regionName": "Jamtland"
      },
      {
        "regionId": 3333,
        "regionName": "Jonkoping"
      },
      {
        "regionId": 3334,
        "regionName": "Kalmar"
      },
      {
        "regionId": 3335,
        "regionName": "Kristianstads"
      },
      {
        "regionId": 3336,
        "regionName": "Kronoberg"
      },
      {
        "regionId": 3337,
        "regionName": "Norrbotten"
      },
      {
        "regionId": 3338,
        "regionName": "Orebro"
      },
      {
        "regionId": 3339,
        "regionName": "Ostergotland"
      },
      {
        "regionId": 3340,
        "regionName": "Saltsjo-Boo"
      },
      {
        "regionId": 3341,
        "regionName": "Skane"
      },
      {
        "regionId": 3342,
        "regionName": "Smaland"
      },
      {
        "regionId": 3343,
        "regionName": "Sodermanland"
      },
      {
        "regionId": 3344,
        "regionName": "Stockholm"
      },
      {
        "regionId": 3345,
        "regionName": "Uppsala"
      },
      {
        "regionId": 3346,
        "regionName": "Varmland"
      },
      {
        "regionId": 3347,
        "regionName": "Vasterbotten"
      },
      {
        "regionId": 3348,
        "regionName": "Vastergotland"
      },
      {
        "regionId": 3349,
        "regionName": "Vasternorrland"
      },
      {
        "regionId": 3350,
        "regionName": "Vastmanland"
      },
      {
        "regionId": 3351,
        "regionName": "Vastra Gotaland"
      }
    ]
  },
  {
    "countryId": 202,
    "countryCode": "CH",
    "countryName": "Switzerland",
    "regions": [
      {
        "regionId": 598,
        "regionCode": "AG",
        "regionName": "Aargau"
      },
      {
        "regionId": 599,
        "regionName": "Appenzell Inner-Rhoden"
      },
      {
        "regionId": 600,
        "regionName": "Appenzell-Ausser Rhoden"
      },
      {
        "regionId": 601,
        "regionName": "Basel-Landschaft"
      },
      {
        "regionId": 602,
        "regionName": "Basel-Stadt"
      },
      {
        "regionId": 603,
        "regionCode": "BE",
        "regionName": "Bern"
      },
      {
        "regionId": 604,
        "regionName": "Canton Ticino"
      },
      {
        "regionId": 605,
        "regionCode": "FR",
        "regionName": "Fribourg"
      },
      {
        "regionId": 606,
        "regionName": "Geneve"
      },
      {
        "regionId": 607,
        "regionCode": "GL",
        "regionName": "Glarus"
      },
      {
        "regionId": 608,
        "regionName": "Graubunden"
      },
      {
        "regionId": 609,
        "regionName": "Heerbrugg"
      },
      {
        "regionId": 610,
        "regionCode": "JU",
        "regionName": "Jura"
      },
      {
        "regionId": 611,
        "regionName": "Kanton Aargau"
      },
      {
        "regionId": 612,
        "regionName": "Luzern"
      },
      {
        "regionId": 613,
        "regionName": "Morbio Inferiore"
      },
      {
        "regionId": 614,
        "regionName": "Muhen"
      },
      {
        "regionId": 615,
        "regionCode": "NE",
        "regionName": "Neuchatel"
      },
      {
        "regionId": 616,
        "regionCode": "NW",
        "regionName": "Nidwalden"
      },
      {
        "regionId": 617,
        "regionCode": "OW",
        "regionName": "Obwalden"
      },
      {
        "regionId": 618,
        "regionName": "Sankt Gallen"
      },
      {
        "regionId": 619,
        "regionCode": "SH",
        "regionName": "Schaffhausen"
      },
      {
        "regionId": 620,
        "regionCode": "SZ",
        "regionName": "Schwyz"
      },
      {
        "regionId": 621,
        "regionCode": "SO",
        "regionName": "Solothurn"
      },
      {
        "regionId": 622,
        "regionCode": "TG",
        "regionName": "Thurgau"
      },
      {
        "regionId": 623,
        "regionCode": "TI",
        "regionName": "Ticino"
      },
      {
        "regionId": 624,
        "regionCode": "UR",
        "regionName": "Uri"
      },
      {
        "regionId": 625,
        "regionCode": "VS",
        "regionName": "Valais"
      },
      {
        "regionId": 626,
        "regionCode": "VD",
        "regionName": "Vaud"
      },
      {
        "regionId": 627,
        "regionName": "Vauffelin"
      },
      {
        "regionId": 628,
        "regionCode": "ZG",
        "regionName": "Zug"
      },
      {
        "regionId": 629,
        "regionCode": "ZH",
        "regionName": "Zurich"
      }
    ]
  },
  {
    "countryId": 203,
    "countryCode": "SY",
    "countryName": "Syrian Arab Republic",
    "regions": [
      {
        "regionId": 3445,
        "regionName": "Aleppo"
      },
      {
        "regionId": 3446,
        "regionName": "al-Hasakah"
      },
      {
        "regionId": 3447,
        "regionName": "al-Ladhiqiyah"
      },
      {
        "regionId": 3448,
        "regionName": "al-Qunaytirah"
      },
      {
        "regionId": 3449,
        "regionName": "ar-Raqqah"
      },
      {
        "regionId": 3450,
        "regionName": "as-Suwayda"
      },
      {
        "regionId": 3451,
        "regionName": "Dar'a"
      },
      {
        "regionId": 3452,
        "regionName": "Dayr-az-Zawr"
      },
      {
        "regionId": 3453,
        "regionName": "Dimashq"
      },
      {
        "regionId": 3454,
        "regionName": "Halab"
      },
      {
        "regionId": 3455,
        "regionName": "Hamah"
      },
      {
        "regionId": 3456,
        "regionName": "Hims"
      },
      {
        "regionId": 3457,
        "regionName": "Idlib"
      },
      {
        "regionId": 3458,
        "regionName": "Madinat Dimashq"
      },
      {
        "regionId": 3459,
        "regionName": "Tartus"
      }
    ]
  },
  {
    "countryId": 204,
    "countryCode": "TW",
    "countryName": "Taiwan",
    "regions": [
      {
        "regionId": 3719,
        "regionName": "Changhwa"
      },
      {
        "regionId": 3720,
        "regionName": "Chiayi Hsien"
      },
      {
        "regionId": 3721,
        "regionName": "Chiayi Shih"
      },
      {
        "regionId": 3722,
        "regionName": "Eastern Taipei"
      },
      {
        "regionId": 3723,
        "regionName": "Hsinchu Hsien"
      },
      {
        "regionId": 3724,
        "regionName": "Hsinchu Shih"
      },
      {
        "regionId": 3725,
        "regionName": "Hualien"
      },
      {
        "regionId": 3726,
        "regionName": "Ilan"
      },
      {
        "regionId": 3727,
        "regionName": "Kaohsiung Hsien"
      },
      {
        "regionId": 3728,
        "regionName": "Kaohsiung Shih"
      },
      {
        "regionId": 3729,
        "regionName": "Keelung Shih"
      },
      {
        "regionId": 3730,
        "regionName": "Kinmen"
      },
      {
        "regionId": 3731,
        "regionName": "Miaoli"
      },
      {
        "regionId": 3732,
        "regionName": "Nantou"
      },
      {
        "regionId": 3733,
        "regionName": "Northern Taiwan"
      },
      {
        "regionId": 3734,
        "regionName": "Penghu"
      },
      {
        "regionId": 3735,
        "regionName": "Pingtung"
      },
      {
        "regionId": 3736,
        "regionName": "Taichung"
      },
      {
        "regionId": 3737,
        "regionName": "Taichung Hsien"
      },
      {
        "regionId": 3738,
        "regionName": "Taichung Shih"
      },
      {
        "regionId": 3739,
        "regionName": "Tainan Hsien"
      },
      {
        "regionId": 3740,
        "regionName": "Tainan Shih"
      },
      {
        "regionId": 3741,
        "regionName": "Taipei Hsien"
      },
      {
        "regionId": 3742,
        "regionName": "Taipei Shih / Taipei Hsien"
      },
      {
        "regionId": 3743,
        "regionName": "Taitung"
      },
      {
        "regionId": 3744,
        "regionName": "Taoyuan"
      },
      {
        "regionId": 3745,
        "regionName": "Yilan"
      },
      {
        "regionId": 3746,
        "regionName": "Yunlin"
      },
      {
        "regionId": 3747,
        "regionName": "Yun-Lin Hsien"
      }
    ]
  },
  {
    "countryId": 205,
    "countryCode": "TJ",
    "countryName": "Tajikistan",
    "regions": [
      {
        "regionId": 3563,
        "regionName": "Dushanbe"
      },
      {
        "regionId": 3564,
        "regionName": "Gorno-Badakhshan"
      },
      {
        "regionId": 3565,
        "regionName": "Karotegin"
      },
      {
        "regionId": 3566,
        "regionName": "Khatlon"
      },
      {
        "regionId": 3567,
        "regionName": "Sughd"
      }
    ]
  },
  {
    "countryId": 206,
    "countryCode": "TZ",
    "countryName": "Tanzania, United Republic of",
    "regions": [
      {
        "regionId": 3748,
        "regionName": "Arusha"
      },
      {
        "regionId": 3749,
        "regionName": "Dar es Salaam"
      },
      {
        "regionId": 3750,
        "regionName": "Dodoma"
      },
      {
        "regionId": 3751,
        "regionName": "Iringa"
      },
      {
        "regionId": 3752,
        "regionName": "Kagera"
      },
      {
        "regionId": 3753,
        "regionName": "Kigoma"
      },
      {
        "regionId": 3754,
        "regionName": "Kilimanjaro"
      },
      {
        "regionId": 3755,
        "regionName": "Lindi"
      },
      {
        "regionId": 3756,
        "regionName": "Mara"
      },
      {
        "regionId": 3757,
        "regionName": "Mbeya"
      },
      {
        "regionId": 3758,
        "regionName": "Morogoro"
      },
      {
        "regionId": 3759,
        "regionName": "Mtwara"
      },
      {
        "regionId": 3760,
        "regionName": "Mwanza"
      },
      {
        "regionId": 3761,
        "regionName": "Pwani"
      },
      {
        "regionId": 3762,
        "regionName": "Rukwa"
      },
      {
        "regionId": 3763,
        "regionName": "Ruvuma"
      },
      {
        "regionId": 3764,
        "regionName": "Shinyanga"
      },
      {
        "regionId": 3765,
        "regionName": "Singida"
      },
      {
        "regionId": 3766,
        "regionName": "Tabora"
      },
      {
        "regionId": 3767,
        "regionName": "Tanga"
      },
      {
        "regionId": 3768,
        "regionName": "Zanzibar and Pemba"
      }
    ]
  },
  {
    "countryId": 207,
    "countryCode": "TH",
    "countryName": "Thailand",
    "regions": [
      {
        "regionId": 3485,
        "regionCode": "01",
        "regionName": "Amnat Charoen"
      },
      {
        "regionId": 3486,
        "regionCode": "02",
        "regionName": "Ang Thong"
      },
      {
        "regionId": 3487,
        "regionCode": "10",
        "regionName": "Bangkok"
      },
      {
        "regionId": 3488,
        "regionCode": "31",
        "regionName": "Buri Ram"
      },
      {
        "regionId": 3489,
        "regionCode": "04",
        "regionName": "Chachoengsao"
      },
      {
        "regionId": 3490,
        "regionCode": "05",
        "regionName": "Chai Nat"
      },
      {
        "regionId": 3491,
        "regionCode": "06",
        "regionName": "Chaiyaphum"
      },
      {
        "regionId": 3492,
        "regionName": "Changwat Chaiyaphum"
      },
      {
        "regionId": 3493,
        "regionCode": "07",
        "regionName": "Chanthaburi"
      },
      {
        "regionId": 3494,
        "regionCode": "08",
        "regionName": "Chiang Mai"
      },
      {
        "regionId": 3495,
        "regionCode": "09",
        "regionName": "Chiang Rai"
      },
      {
        "regionId": 3496,
        "regionCode": "10",
        "regionName": "Chon Buri"
      },
      {
        "regionId": 3497,
        "regionCode": "11",
        "regionName": "Chumphon"
      },
      {
        "regionId": 3498,
        "regionCode": "12",
        "regionName": "Kalasin"
      },
      {
        "regionId": 3499,
        "regionCode": "13",
        "regionName": "Kamphaeng Phet"
      },
      {
        "regionId": 3500,
        "regionCode": "14",
        "regionName": "Kanchanaburi"
      },
      {
        "regionId": 3501,
        "regionCode": "15",
        "regionName": "Khon Kaen"
      },
      {
        "regionId": 3502,
        "regionCode": "16",
        "regionName": "Krabi"
      },
      {
        "regionId": 3503,
        "regionCode": "17",
        "regionName": "Krung Thep"
      },
      {
        "regionId": 3504,
        "regionCode": "19",
        "regionName": "Lampang"
      },
      {
        "regionId": 3505,
        "regionCode": "20",
        "regionName": "Lamphun"
      },
      {
        "regionId": 3506,
        "regionCode": "21",
        "regionName": "Loei"
      },
      {
        "regionId": 3507,
        "regionCode": "22",
        "regionName": "Lop Buri"
      },
      {
        "regionId": 3508,
        "regionCode": "23",
        "regionName": "Mae Hong Son"
      },
      {
        "regionId": 3509,
        "regionCode": "24",
        "regionName": "Maha Sarakham"
      },
      {
        "regionId": 3510,
        "regionCode": "25",
        "regionName": "Mukdahan"
      },
      {
        "regionId": 3511,
        "regionCode": "26",
        "regionName": "Nakhon Nayok"
      },
      {
        "regionId": 3512,
        "regionCode": "27",
        "regionName": "Nakhon Pathom"
      },
      {
        "regionId": 3513,
        "regionCode": "28",
        "regionName": "Nakhon Phanom"
      },
      {
        "regionId": 3514,
        "regionCode": "29",
        "regionName": "Nakhon Ratchasima"
      },
      {
        "regionId": 3515,
        "regionCode": "30",
        "regionName": "Nakhon Sawan"
      },
      {
        "regionId": 3516,
        "regionCode": "31",
        "regionName": "Nakhon Si Thammarat"
      },
      {
        "regionId": 3517,
        "regionCode": "32",
        "regionName": "Nan"
      },
      {
        "regionId": 3518,
        "regionCode": "33",
        "regionName": "Narathiwat"
      },
      {
        "regionId": 3519,
        "regionCode": "39",
        "regionName": "Nong Bua Lam Phu"
      },
      {
        "regionId": 3520,
        "regionCode": "35",
        "regionName": "Nong Khai"
      },
      {
        "regionId": 3521,
        "regionCode": "36",
        "regionName": "Nonthaburi"
      },
      {
        "regionId": 3522,
        "regionCode": "37",
        "regionName": "Pathum Thani"
      },
      {
        "regionId": 3523,
        "regionCode": "38",
        "regionName": "Pattani"
      },
      {
        "regionId": 3524,
        "regionCode": "39",
        "regionName": "Phangnga"
      },
      {
        "regionId": 3525,
        "regionCode": "40",
        "regionName": "Phatthalung"
      },
      {
        "regionId": 3526,
        "regionCode": "41",
        "regionName": "Phayao"
      },
      {
        "regionId": 3527,
        "regionCode": "42",
        "regionName": "Phetchabun"
      },
      {
        "regionId": 3528,
        "regionCode": "43",
        "regionName": "Phetchaburi"
      },
      {
        "regionId": 3529,
        "regionCode": "44",
        "regionName": "Phichit"
      },
      {
        "regionId": 3530,
        "regionCode": "45",
        "regionName": "Phitsanulok"
      },
      {
        "regionId": 3531,
        "regionCode": "14",
        "regionName": "Phra Nakhon Si Ayutthaya"
      },
      {
        "regionId": 3532,
        "regionCode": "47",
        "regionName": "Phrae"
      },
      {
        "regionId": 3533,
        "regionCode": "48",
        "regionName": "Phuket"
      },
      {
        "regionId": 3534,
        "regionCode": "49",
        "regionName": "Prachin Buri"
      },
      {
        "regionId": 3535,
        "regionCode": "77",
        "regionName": "Prachuap Khiri Khan"
      },
      {
        "regionId": 3536,
        "regionCode": "85",
        "regionName": "Ranong"
      },
      {
        "regionId": 3537,
        "regionCode": "70",
        "regionName": "Ratchaburi"
      },
      {
        "regionId": 3538,
        "regionCode": "21",
        "regionName": "Rayong"
      },
      {
        "regionId": 3539,
        "regionCode": "45",
        "regionName": "Roi Et"
      },
      {
        "regionId": 3540,
        "regionCode": "27",
        "regionName": "Sa Kaeo"
      },
      {
        "regionId": 3541,
        "regionCode": "47",
        "regionName": "Sakon Nakhon"
      },
      {
        "regionId": 3542,
        "regionCode": "11",
        "regionName": "Samut Prakan"
      },
      {
        "regionId": 3543,
        "regionCode": "74",
        "regionName": "Samut Sakhon"
      },
      {
        "regionId": 3544,
        "regionName": "Samut Songkhran"
      },
      {
        "regionId": 3545,
        "regionCode": "19",
        "regionName": "Saraburi"
      },
      {
        "regionId": 3546,
        "regionCode": "91",
        "regionName": "Satun"
      },
      {
        "regionId": 3547,
        "regionCode": "33",
        "regionName": "Si Sa Ket"
      },
      {
        "regionId": 3548,
        "regionCode": "17",
        "regionName": "Sing Buri"
      },
      {
        "regionId": 3549,
        "regionCode": "90",
        "regionName": "Songkhla"
      },
      {
        "regionId": 3550,
        "regionCode": "64",
        "regionName": "Sukhothai"
      },
      {
        "regionId": 3551,
        "regionName": "Suphan Buri"
      },
      {
        "regionId": 3552,
        "regionCode": "84",
        "regionName": "Surat Thani"
      },
      {
        "regionId": 3553,
        "regionCode": "32",
        "regionName": "Surin"
      },
      {
        "regionId": 3554,
        "regionCode": "63",
        "regionName": "Tak"
      },
      {
        "regionId": 3555,
        "regionCode": "92",
        "regionName": "Trang"
      },
      {
        "regionId": 3556,
        "regionCode": "23",
        "regionName": "Trat"
      },
      {
        "regionId": 3557,
        "regionCode": "34",
        "regionName": "Ubon Ratchathani"
      },
      {
        "regionId": 3558,
        "regionCode": "41",
        "regionName": "Udon Thani"
      },
      {
        "regionId": 3559,
        "regionCode": "61",
        "regionName": "Uthai Thani"
      },
      {
        "regionId": 3560,
        "regionCode": "53",
        "regionName": "Uttaradit"
      },
      {
        "regionId": 3561,
        "regionCode": "95",
        "regionName": "Yala"
      },
      {
        "regionId": 3562,
        "regionCode": "35",
        "regionName": "Yasothon"
      }
    ]
  },
  {
    "countryId": 208,
    "countryCode": "TL",
    "countryName": "Timor-Leste (East Timor)",
    "regions": []
  },
  {
    "countryId": 209,
    "countryCode": "TG",
    "countryName": "Togo",
    "regions": [
      {
        "regionId": 3480,
        "regionName": "Centre"
      },
      {
        "regionId": 3481,
        "regionName": "Kara"
      },
      {
        "regionId": 3482,
        "regionName": "Maritime"
      },
      {
        "regionId": 3483,
        "regionName": "Plateaux"
      },
      {
        "regionId": 3484,
        "regionName": "Savanes"
      }
    ]
  },
  {
    "countryId": 210,
    "countryCode": "TK",
    "countryName": "Tokelau",
    "regions": [
      {
        "regionId": 3568,
        "regionName": "Atafu"
      },
      {
        "regionId": 3569,
        "regionName": "Fakaofo"
      },
      {
        "regionId": 3570,
        "regionName": "Nukunonu"
      }
    ]
  },
  {
    "countryId": 211,
    "countryCode": "TO",
    "countryName": "Tonga",
    "regions": [
      {
        "regionId": 3607,
        "regionName": "Eua"
      },
      {
        "regionId": 3608,
        "regionName": "Ha'apai"
      },
      {
        "regionId": 3609,
        "regionName": "Niuas"
      },
      {
        "regionId": 3610,
        "regionName": "Tongatapu"
      },
      {
        "regionId": 3611,
        "regionName": "Vava'u"
      }
    ]
  },
  {
    "countryId": 212,
    "countryCode": "TT",
    "countryName": "Trinidad and Tobago",
    "regions": [
      {
        "regionId": 3695,
        "regionName": "Arima-Tunapuna-Piarco"
      },
      {
        "regionId": 3696,
        "regionName": "Caroni"
      },
      {
        "regionId": 3697,
        "regionName": "Chaguanas"
      },
      {
        "regionId": 3698,
        "regionName": "Couva-Tabaquite-Talparo"
      },
      {
        "regionId": 3699,
        "regionName": "Diego Martin"
      },
      {
        "regionId": 3700,
        "regionName": "Glencoe"
      },
      {
        "regionId": 3701,
        "regionName": "Penal Debe"
      },
      {
        "regionId": 3702,
        "regionName": "Point Fortin"
      },
      {
        "regionId": 3703,
        "regionName": "Port of Spain"
      },
      {
        "regionId": 3704,
        "regionName": "Princes Town"
      },
      {
        "regionId": 3705,
        "regionName": "Saint George"
      },
      {
        "regionId": 3706,
        "regionName": "San Fernando"
      },
      {
        "regionId": 3707,
        "regionName": "San Juan"
      },
      {
        "regionId": 3708,
        "regionName": "Sangre Grande"
      },
      {
        "regionId": 3709,
        "regionName": "Siparia"
      },
      {
        "regionId": 3710,
        "regionName": "Tobago"
      }
    ]
  },
  {
    "countryId": 213,
    "countryCode": "TA",
    "countryName": "Tristan da Cunha",
    "regions": []
  },
  {
    "countryId": 214,
    "countryCode": "TN",
    "countryName": "Tunisia",
    "regions": [
      {
        "regionId": 3577,
        "regionName": "al-Kaf"
      },
      {
        "regionId": 3578,
        "regionName": "al-Mahdiyah"
      },
      {
        "regionId": 3579,
        "regionName": "al-Munastir"
      },
      {
        "regionId": 3580,
        "regionName": "al-Qasrayn"
      },
      {
        "regionId": 3581,
        "regionName": "al-Qayrawan"
      },
      {
        "regionId": 3582,
        "regionName": "Aryanah"
      },
      {
        "regionId": 3583,
        "regionName": "Bajah"
      },
      {
        "regionId": 3584,
        "regionName": "Bin 'Arus"
      },
      {
        "regionId": 3585,
        "regionName": "Binzart"
      },
      {
        "regionId": 3586,
        "regionName": "Gouvernorat de Ariana"
      },
      {
        "regionId": 3587,
        "regionName": "Gouvernorat de Nabeul"
      },
      {
        "regionId": 3588,
        "regionName": "Gouvernorat de Sousse"
      },
      {
        "regionId": 3589,
        "regionName": "Hammamet Yasmine"
      },
      {
        "regionId": 3590,
        "regionName": "Jundubah"
      },
      {
        "regionId": 3591,
        "regionName": "Madaniyin"
      },
      {
        "regionId": 3592,
        "regionName": "Manubah"
      },
      {
        "regionId": 3593,
        "regionName": "Monastir"
      },
      {
        "regionId": 3594,
        "regionName": "Nabul"
      },
      {
        "regionId": 3595,
        "regionName": "Qabis"
      },
      {
        "regionId": 3596,
        "regionName": "Qafsah"
      },
      {
        "regionId": 3597,
        "regionName": "Qibili"
      },
      {
        "regionId": 3598,
        "regionName": "Safaqis"
      },
      {
        "regionId": 3599,
        "regionName": "Sfax"
      },
      {
        "regionId": 3600,
        "regionName": "Sidi Bu Zayd"
      },
      {
        "regionId": 3601,
        "regionName": "Silyanah"
      },
      {
        "regionId": 3602,
        "regionName": "Susah"
      },
      {
        "regionId": 3603,
        "regionName": "Tatawin"
      },
      {
        "regionId": 3604,
        "regionName": "Tawzar"
      },
      {
        "regionId": 3605,
        "regionName": "Tunis"
      },
      {
        "regionId": 3606,
        "regionName": "Zaghwan"
      }
    ]
  },
  {
    "countryId": 215,
    "countryCode": "TR",
    "countryName": "Turkey",
    "regions": [
      {
        "regionId": 3612,
        "regionCode": "01",
        "regionName": "Adana"
      },
      {
        "regionId": 3613,
        "regionCode": "02",
        "regionName": "Adiyaman"
      },
      {
        "regionId": 3614,
        "regionCode": "03",
        "regionName": "Afyon"
      },
      {
        "regionId": 3615,
        "regionCode": "04",
        "regionName": "Agri"
      },
      {
        "regionId": 3616,
        "regionCode": "68",
        "regionName": "Aksaray"
      },
      {
        "regionId": 3617,
        "regionCode": "05",
        "regionName": "Amasya"
      },
      {
        "regionId": 3618,
        "regionCode": "06",
        "regionName": "Ankara"
      },
      {
        "regionId": 3619,
        "regionCode": "07",
        "regionName": "Antalya"
      },
      {
        "regionId": 3620,
        "regionCode": "75",
        "regionName": "Ardahan"
      },
      {
        "regionId": 3621,
        "regionCode": "08",
        "regionName": "Artvin"
      },
      {
        "regionId": 3622,
        "regionCode": "09",
        "regionName": "Aydin"
      },
      {
        "regionId": 3623,
        "regionCode": "10",
        "regionName": "Balikesir"
      },
      {
        "regionId": 3624,
        "regionCode": "74",
        "regionName": "Bartin"
      },
      {
        "regionId": 3625,
        "regionCode": "72",
        "regionName": "Batman"
      },
      {
        "regionId": 3626,
        "regionCode": "69",
        "regionName": "Bayburt"
      },
      {
        "regionId": 3627,
        "regionCode": "11",
        "regionName": "Bilecik"
      },
      {
        "regionId": 3628,
        "regionCode": "12",
        "regionName": "Bingol"
      },
      {
        "regionId": 3629,
        "regionCode": "13",
        "regionName": "Bitlis"
      },
      {
        "regionId": 3630,
        "regionCode": "14",
        "regionName": "Bolu"
      },
      {
        "regionId": 3631,
        "regionCode": "15",
        "regionName": "Burdur"
      },
      {
        "regionId": 3632,
        "regionCode": "16",
        "regionName": "Bursa"
      },
      {
        "regionId": 3633,
        "regionCode": "17",
        "regionName": "Canakkale"
      },
      {
        "regionId": 3634,
        "regionCode": "18",
        "regionName": "Cankiri"
      },
      {
        "regionId": 3635,
        "regionCode": "19",
        "regionName": "Corum"
      },
      {
        "regionId": 3636,
        "regionCode": "20",
        "regionName": "Denizli"
      },
      {
        "regionId": 3637,
        "regionCode": "21",
        "regionName": "Diyarbakir"
      },
      {
        "regionId": 3638,
        "regionCode": "81",
        "regionName": "Duzce"
      },
      {
        "regionId": 3639,
        "regionCode": "22",
        "regionName": "Edirne"
      },
      {
        "regionId": 3640,
        "regionCode": "23",
        "regionName": "Elazig"
      },
      {
        "regionId": 3641,
        "regionCode": "24",
        "regionName": "Erzincan"
      },
      {
        "regionId": 3642,
        "regionCode": "25",
        "regionName": "Erzurum"
      },
      {
        "regionId": 3643,
        "regionCode": "26",
        "regionName": "Eskisehir"
      },
      {
        "regionId": 3644,
        "regionCode": "27",
        "regionName": "Gaziantep"
      },
      {
        "regionId": 3645,
        "regionCode": "28",
        "regionName": "Giresun"
      },
      {
        "regionId": 3646,
        "regionCode": "29",
        "regionName": "Gumushane"
      },
      {
        "regionId": 3647,
        "regionCode": "30",
        "regionName": "Hakkari"
      },
      {
        "regionId": 3648,
        "regionCode": "31",
        "regionName": "Hatay"
      },
      {
        "regionId": 3649,
        "regionCode": "33",
        "regionName": "Icel"
      },
      {
        "regionId": 3650,
        "regionCode": "76",
        "regionName": "Igdir"
      },
      {
        "regionId": 3651,
        "regionCode": "32",
        "regionName": "Isparta"
      },
      {
        "regionId": 3652,
        "regionCode": "34",
        "regionName": "Istanbul"
      },
      {
        "regionId": 3653,
        "regionCode": "35",
        "regionName": "Izmir"
      },
      {
        "regionId": 3654,
        "regionCode": "46",
        "regionName": "Kahramanmaras"
      },
      {
        "regionId": 3655,
        "regionCode": "78",
        "regionName": "Karabuk"
      },
      {
        "regionId": 3656,
        "regionCode": "70",
        "regionName": "Karaman"
      },
      {
        "regionId": 3657,
        "regionCode": "36",
        "regionName": "Kars"
      },
      {
        "regionId": 3658,
        "regionName": "Karsiyaka"
      },
      {
        "regionId": 3659,
        "regionCode": "37",
        "regionName": "Kastamonu"
      },
      {
        "regionId": 3660,
        "regionCode": "38",
        "regionName": "Kayseri"
      },
      {
        "regionId": 3661,
        "regionCode": "79",
        "regionName": "Kilis"
      },
      {
        "regionId": 3662,
        "regionCode": "71",
        "regionName": "Kirikkale"
      },
      {
        "regionId": 3663,
        "regionCode": "39",
        "regionName": "Kirklareli"
      },
      {
        "regionId": 3664,
        "regionCode": "40",
        "regionName": "Kirsehir"
      },
      {
        "regionId": 3665,
        "regionCode": "41",
        "regionName": "Kocaeli"
      },
      {
        "regionId": 3666,
        "regionCode": "42",
        "regionName": "Konya"
      },
      {
        "regionId": 3667,
        "regionCode": "43",
        "regionName": "Kutahya"
      },
      {
        "regionId": 3668,
        "regionName": "Lefkosa"
      },
      {
        "regionId": 3669,
        "regionCode": "44",
        "regionName": "Malatya"
      },
      {
        "regionId": 3670,
        "regionCode": "45",
        "regionName": "Manisa"
      },
      {
        "regionId": 3671,
        "regionCode": "47",
        "regionName": "Mardin"
      },
      {
        "regionId": 3672,
        "regionCode": "48",
        "regionName": "Mugla"
      },
      {
        "regionId": 3673,
        "regionCode": "49",
        "regionName": "Mus"
      },
      {
        "regionId": 3674,
        "regionCode": "50",
        "regionName": "Nevsehir"
      },
      {
        "regionId": 3675,
        "regionCode": "51",
        "regionName": "Nigde"
      },
      {
        "regionId": 3676,
        "regionCode": "52",
        "regionName": "Ordu"
      },
      {
        "regionId": 3677,
        "regionCode": "80",
        "regionName": "Osmaniye"
      },
      {
        "regionId": 3678,
        "regionCode": "53",
        "regionName": "Rize"
      },
      {
        "regionId": 3679,
        "regionCode": "54",
        "regionName": "Sakarya"
      },
      {
        "regionId": 3680,
        "regionCode": "55",
        "regionName": "Samsun"
      },
      {
        "regionId": 3681,
        "regionCode": "63",
        "regionName": "Sanliurfa"
      },
      {
        "regionId": 3682,
        "regionCode": "56",
        "regionName": "Siirt"
      },
      {
        "regionId": 3683,
        "regionCode": "57",
        "regionName": "Sinop"
      },
      {
        "regionId": 3684,
        "regionCode": "73",
        "regionName": "Sirnak"
      },
      {
        "regionId": 3685,
        "regionCode": "58",
        "regionName": "Sivas"
      },
      {
        "regionId": 3686,
        "regionCode": "59",
        "regionName": "Tekirdag"
      },
      {
        "regionId": 3687,
        "regionCode": "60",
        "regionName": "Tokat"
      },
      {
        "regionId": 3688,
        "regionCode": "61",
        "regionName": "Trabzon"
      },
      {
        "regionId": 3689,
        "regionCode": "62",
        "regionName": "Tunceli"
      },
      {
        "regionId": 3690,
        "regionCode": "64",
        "regionName": "Usak"
      },
      {
        "regionId": 3691,
        "regionCode": "65",
        "regionName": "Van"
      },
      {
        "regionId": 3692,
        "regionCode": "77",
        "regionName": "Yalova"
      },
      {
        "regionId": 3693,
        "regionCode": "66",
        "regionName": "Yozgat"
      },
      {
        "regionId": 3694,
        "regionCode": "67",
        "regionName": "Zonguldak"
      }
    ]
  },
  {
    "countryId": 216,
    "countryCode": "TM",
    "countryName": "Turkmenistan",
    "regions": [
      {
        "regionId": 3571,
        "regionName": "Ahal"
      },
      {
        "regionId": 3572,
        "regionName": "Asgabat"
      },
      {
        "regionId": 3573,
        "regionName": "Balkan"
      },
      {
        "regionId": 3574,
        "regionName": "Dasoguz"
      },
      {
        "regionId": 3575,
        "regionName": "Lebap"
      },
      {
        "regionId": 3576,
        "regionName": "Mari"
      }
    ]
  },
  {
    "countryId": 217,
    "countryCode": "TC",
    "countryName": "Turks and Caicos Islands",
    "regions": [
      {
        "regionId": 3464,
        "regionName": "Grand Turk"
      },
      {
        "regionId": 3465,
        "regionName": "South Caicos and East Caicos"
      }
    ]
  },
  {
    "countryId": 218,
    "countryCode": "TV",
    "countryName": "Tuvalu",
    "regions": [
      {
        "regionId": 3711,
        "regionCode": "FUN",
        "regionName": "Funafuti"
      },
      {
        "regionId": 3712,
        "regionCode": "NMG",
        "regionName": "Nanumanga"
      },
      {
        "regionId": 3713,
        "regionCode": "NMA",
        "regionName": "Nanumea"
      },
      {
        "regionId": 3714,
        "regionCode": "NIT",
        "regionName": "Niutao"
      },
      {
        "regionId": 3715,
        "regionCode": "NUI",
        "regionName": "Nui"
      },
      {
        "regionId": 3716,
        "regionCode": "NKF",
        "regionName": "Nukufetau"
      },
      {
        "regionId": 3717,
        "regionCode": "NKL",
        "regionName": "Nukulaelae"
      },
      {
        "regionId": 3718,
        "regionCode": "VAI",
        "regionName": "Vaitupu"
      }
    ]
  },
  {
    "countryId": 219,
    "countryCode": "UG",
    "countryName": "Uganda",
    "regions": [
      {
        "regionId": 3799,
        "regionName": "Central"
      },
      {
        "regionId": 3800,
        "regionName": "Eastern"
      },
      {
        "regionId": 3801,
        "regionName": "Northern"
      },
      {
        "regionId": 3802,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 220,
    "countryCode": "UA",
    "countryName": "Ukraine",
    "regions": [
      {
        "regionId": 3769,
        "regionCode": "CHR",
        "regionName": "Cherkas'ka"
      },
      {
        "regionId": 3770,
        "regionName": "Chernihivs'ka"
      },
      {
        "regionId": 3771,
        "regionName": "Chernivets'ka"
      },
      {
        "regionId": 3772,
        "regionCode": "43",
        "regionName": "Crimea"
      },
      {
        "regionId": 3773,
        "regionName": "Dnipropetrovska"
      },
      {
        "regionId": 3774,
        "regionCode": "DON",
        "regionName": "Donets'ka"
      },
      {
        "regionId": 3775,
        "regionCode": "IVF",
        "regionName": "Ivano-Frankivs'ka"
      },
      {
        "regionId": 3776,
        "regionName": "Kharkiv"
      },
      {
        "regionId": 3777,
        "regionName": "Kharkov"
      },
      {
        "regionId": 3778,
        "regionName": "Khersonska"
      },
      {
        "regionId": 3779,
        "regionName": "Khmel'nyts'ka"
      },
      {
        "regionId": 3780,
        "regionName": "Kirovohrad"
      },
      {
        "regionId": 3781,
        "regionName": "Krym"
      },
      {
        "regionId": 3782,
        "regionName": "Kyyiv"
      },
      {
        "regionId": 3783,
        "regionName": "Kyyivs'ka"
      },
      {
        "regionId": 3784,
        "regionName": "Luhans'ka"
      },
      {
        "regionId": 3785,
        "regionName": "L'vivs'ka"
      },
      {
        "regionId": 3786,
        "regionName": "Mykolayivs'ka"
      },
      {
        "regionId": 3787,
        "regionCode": "ODS",
        "regionName": "Odes'ka"
      },
      {
        "regionId": 3788,
        "regionName": "Odessa"
      },
      {
        "regionId": 3789,
        "regionCode": "POL",
        "regionName": "Poltavs'ka"
      },
      {
        "regionId": 3790,
        "regionCode": "RIV",
        "regionName": "Rivnens'ka"
      },
      {
        "regionId": 3791,
        "regionName": "Sevastopol'"
      },
      {
        "regionId": 3792,
        "regionCode": "SUM",
        "regionName": "Sums'ka"
      },
      {
        "regionId": 3793,
        "regionCode": "TER",
        "regionName": "Ternopil's'ka"
      },
      {
        "regionId": 3794,
        "regionName": "Volyns'ka"
      },
      {
        "regionId": 3795,
        "regionName": "Vynnyts'ka"
      },
      {
        "regionId": 3796,
        "regionCode": "ZAK",
        "regionName": "Zakarpats'ka"
      },
      {
        "regionId": 3797,
        "regionName": "Zaporizhia"
      },
      {
        "regionId": 3798,
        "regionName": "Zhytomyrs'ka"
      }
    ]
  },
  {
    "countryId": 221,
    "countryCode": "AE",
    "countryName": "United Arab Emirates",
    "regions": [
      {
        "regionId": 8,
        "regionName": "Abu Zabi"
      },
      {
        "regionId": 9,
        "regionCode": "AJ",
        "regionName": "Ajman"
      },
      {
        "regionId": 10,
        "regionName": "al-Fujayrah"
      },
      {
        "regionId": 11,
        "regionName": "ash-Shariqah"
      },
      {
        "regionId": 12,
        "regionCode": "DU",
        "regionName": "Dubai"
      },
      {
        "regionId": 13,
        "regionName": "Ras al-Khaymah"
      },
      {
        "regionId": 14,
        "regionCode": "SH",
        "regionName": "Sharjah"
      },
      {
        "regionId": 15,
        "regionName": "Sharjha"
      },
      {
        "regionId": 16,
        "regionName": "Umm al Qaywayn"
      }
    ]
  },
  {
    "countryId": 222,
    "countryCode": "UK",
    "countryName": "United Kingdom",
    "regions": [
      {
        "regionId": 1337,
        "regionCode": "AB",
        "regionName": "Aberdeen"
      },
      {
        "regionId": 1338,
        "regionName": "Aberdeenshire"
      },
      {
        "regionId": 1339,
        "regionName": "Argyll"
      },
      {
        "regionId": 1340,
        "regionCode": "AM",
        "regionName": "Armagh"
      },
      {
        "regionId": 1341,
        "regionCode": "BE",
        "regionName": "Bedfordshire"
      },
      {
        "regionId": 1342,
        "regionCode": "BL",
        "regionName": "Belfast"
      },
      {
        "regionId": 1343,
        "regionCode": "BK",
        "regionName": "Berkshire"
      },
      {
        "regionId": 1344,
        "regionCode": "B",
        "regionName": "Birmingham"
      },
      {
        "regionId": 1345,
        "regionName": "Brechin"
      },
      {
        "regionId": 1346,
        "regionName": "Bridgnorth"
      },
      {
        "regionId": 1347,
        "regionCode": "BR",
        "regionName": "Bristol"
      },
      {
        "regionId": 1348,
        "regionCode": "BU",
        "regionName": "Buckinghamshire"
      },
      {
        "regionId": 1349,
        "regionCode": "CB",
        "regionName": "Cambridge"
      },
      {
        "regionId": 1350,
        "regionCode": "CA",
        "regionName": "Cambridgeshire"
      },
      {
        "regionId": 1351,
        "regionName": "Channel Islands"
      },
      {
        "regionId": 1352,
        "regionCode": "CH",
        "regionName": "Cheshire"
      },
      {
        "regionId": 1353,
        "regionCode": "CV",
        "regionName": "Cleveland"
      },
      {
        "regionId": 1354,
        "regionName": "Co Fermanagh"
      },
      {
        "regionId": 1355,
        "regionName": "Conwy"
      },
      {
        "regionId": 1356,
        "regionCode": "CO",
        "regionName": "Cornwall"
      },
      {
        "regionId": 1357,
        "regionCode": "CV",
        "regionName": "Coventry"
      },
      {
        "regionId": 1358,
        "regionName": "Craven Arms"
      },
      {
        "regionId": 1359,
        "regionCode": "CU",
        "regionName": "Cumbria"
      },
      {
        "regionId": 1360,
        "regionName": "Denbighshire"
      },
      {
        "regionId": 1361,
        "regionCode": "DE",
        "regionName": "Derby"
      },
      {
        "regionId": 1362,
        "regionCode": "DB",
        "regionName": "Derbyshire"
      },
      {
        "regionId": 1363,
        "regionCode": "DV",
        "regionName": "Devon"
      },
      {
        "regionId": 1364,
        "regionName": "Dial Code Dungannon"
      },
      {
        "regionId": 1365,
        "regionName": "Didcot"
      },
      {
        "regionId": 1366,
        "regionCode": "DO",
        "regionName": "Dorset"
      },
      {
        "regionId": 1367,
        "regionName": "Dunbartonshire"
      },
      {
        "regionId": 1368,
        "regionCode": "DU",
        "regionName": "Durham"
      },
      {
        "regionId": 1369,
        "regionName": "East Dunbartonshire"
      },
      {
        "regionId": 1370,
        "regionName": "East Lothian"
      },
      {
        "regionId": 1371,
        "regionName": "East Midlands"
      },
      {
        "regionId": 1372,
        "regionCode": "SE",
        "regionName": "East Sussex"
      },
      {
        "regionId": 1373,
        "regionName": "East Yorkshire"
      },
      {
        "regionId": 1374,
        "regionName": "England"
      },
      {
        "regionId": 1375,
        "regionCode": "ES",
        "regionName": "Essex"
      },
      {
        "regionId": 1376,
        "regionCode": "FM",
        "regionName": "Fermanagh"
      },
      {
        "regionId": 1377,
        "regionCode": "FI",
        "regionName": "Fife"
      },
      {
        "regionId": 1378,
        "regionName": "Flintshire"
      },
      {
        "regionId": 1379,
        "regionName": "Fulham"
      },
      {
        "regionId": 1380,
        "regionName": "Gainsborough"
      },
      {
        "regionId": 1381,
        "regionName": "Glocestershire"
      },
      {
        "regionId": 1382,
        "regionCode": "GW",
        "regionName": "Gwent"
      },
      {
        "regionId": 1383,
        "regionCode": "HA",
        "regionName": "Hampshire"
      },
      {
        "regionId": 1384,
        "regionName": "Hants"
      },
      {
        "regionId": 1385,
        "regionName": "Herefordshire"
      },
      {
        "regionId": 1386,
        "regionCode": "HT",
        "regionName": "Hertfordshire"
      },
      {
        "regionId": 1387,
        "regionName": "Ireland"
      },
      {
        "regionId": 1388,
        "regionName": "Isle Of Man"
      },
      {
        "regionId": 1389,
        "regionCode": "IW",
        "regionName": "Isle of Wight"
      },
      {
        "regionId": 1390,
        "regionName": "Kenford"
      },
      {
        "regionId": 1391,
        "regionCode": "KE",
        "regionName": "Kent"
      },
      {
        "regionId": 1392,
        "regionCode": "KA",
        "regionName": "Kilmarnock"
      },
      {
        "regionId": 1393,
        "regionName": "Lanarkshire"
      },
      {
        "regionId": 1394,
        "regionCode": "LA",
        "regionName": "Lancashire"
      },
      {
        "regionId": 1395,
        "regionCode": "LE",
        "regionName": "Leicestershire"
      },
      {
        "regionId": 1396,
        "regionCode": "LI",
        "regionName": "Lincolnshire"
      },
      {
        "regionId": 1397,
        "regionName": "Llanymynech"
      },
      {
        "regionId": 1398,
        "regionName": "London"
      },
      {
        "regionId": 1399,
        "regionName": "Ludlow"
      },
      {
        "regionId": 1400,
        "regionCode": "M",
        "regionName": "Manchester"
      },
      {
        "regionId": 1401,
        "regionName": "Mayfair"
      },
      {
        "regionId": 1402,
        "regionCode": "MY",
        "regionName": "Merseyside"
      },
      {
        "regionId": 1403,
        "regionCode": "MG",
        "regionName": "Mid Glamorgan"
      },
      {
        "regionId": 1404,
        "regionName": "Middlesex"
      },
      {
        "regionId": 1405,
        "regionName": "Mildenhall"
      },
      {
        "regionId": 1406,
        "regionName": "Monmouthshire"
      },
      {
        "regionId": 1407,
        "regionName": "Newton Stewart"
      },
      {
        "regionId": 1408,
        "regionCode": "NK",
        "regionName": "Norfolk"
      },
      {
        "regionId": 1409,
        "regionName": "North Humberside"
      },
      {
        "regionId": 1410,
        "regionCode": "YN",
        "regionName": "North Yorkshire"
      },
      {
        "regionId": 1411,
        "regionCode": "NH",
        "regionName": "Northamptonshire"
      },
      {
        "regionId": 1412,
        "regionName": "Northants"
      },
      {
        "regionId": 1413,
        "regionCode": "BT",
        "regionName": "Northern Ireland"
      },
      {
        "regionId": 1414,
        "regionCode": "NU",
        "regionName": "Northumberland"
      },
      {
        "regionId": 1415,
        "regionCode": "NT",
        "regionName": "Nottinghamshire"
      },
      {
        "regionId": 1416,
        "regionCode": "OX",
        "regionName": "Oxford"
      },
      {
        "regionId": 1417,
        "regionCode": "PO",
        "regionName": "Powys"
      },
      {
        "regionId": 1418,
        "regionName": "Roos-shire"
      },
      {
        "regionId": 1419,
        "regionName": "Sark"
      },
      {
        "regionId": 1420,
        "regionName": "Scotland"
      },
      {
        "regionId": 1421,
        "regionName": "Scottish Borders"
      },
      {
        "regionId": 1422,
        "regionCode": "SH",
        "regionName": "Shropshire"
      },
      {
        "regionId": 1423,
        "regionCode": "SO",
        "regionName": "Somerset"
      },
      {
        "regionId": 1424,
        "regionCode": "SG",
        "regionName": "South Glamorgan"
      },
      {
        "regionId": 1425,
        "regionName": "South Wales"
      },
      {
        "regionId": 1426,
        "regionCode": "YS",
        "regionName": "South Yorkshire"
      },
      {
        "regionId": 1427,
        "regionName": "Southwell"
      },
      {
        "regionId": 1428,
        "regionCode": "ST",
        "regionName": "Staffordshire"
      },
      {
        "regionId": 1429,
        "regionCode": "SR",
        "regionName": "Strabane"
      },
      {
        "regionId": 1430,
        "regionCode": "SK",
        "regionName": "Suffolk"
      },
      {
        "regionId": 1431,
        "regionCode": "SY",
        "regionName": "Surrey"
      },
      {
        "regionId": 1432,
        "regionName": "Sussex"
      },
      {
        "regionId": 1433,
        "regionCode": "TW",
        "regionName": "Twickenham"
      },
      {
        "regionId": 1434,
        "regionCode": "TW",
        "regionName": "Tyne and Wear"
      },
      {
        "regionId": 1435,
        "regionName": "Tyrone"
      },
      {
        "regionId": 1436,
        "regionCode": "UT",
        "regionName": "Utah"
      },
      {
        "regionId": 1437,
        "regionName": "Wales"
      },
      {
        "regionId": 1438,
        "regionCode": "WA",
        "regionName": "Warwickshire"
      },
      {
        "regionId": 1439,
        "regionName": "West Lothian"
      },
      {
        "regionId": 1440,
        "regionCode": "WM",
        "regionName": "West Midlands"
      },
      {
        "regionId": 1441,
        "regionCode": "SW",
        "regionName": "West Sussex"
      },
      {
        "regionId": 1442,
        "regionCode": "YW",
        "regionName": "West Yorkshire"
      },
      {
        "regionId": 1443,
        "regionName": "Whissendine"
      },
      {
        "regionId": 1444,
        "regionCode": "WI",
        "regionName": "Wiltshire"
      },
      {
        "regionId": 1445,
        "regionCode": "WO",
        "regionName": "Wokingham"
      },
      {
        "regionId": 1446,
        "regionName": "Worcestershire"
      },
      {
        "regionId": 1447,
        "regionName": "Wrexham"
      },
      {
        "regionId": 1448,
        "regionName": "Wurttemberg"
      },
      {
        "regionId": 1449,
        "regionName": "Yorkshire"
      }
    ]
  },
  {
    "countryId": 223,
    "countryCode": "US",
    "countryName": "United States",
    "regions": [
      {
        "regionId": 3803,
        "regionCode": "AL",
        "regionName": "Alabama"
      },
      {
        "regionId": 3804,
        "regionCode": "AK",
        "regionName": "Alaska"
      },
      {
        "regionId": 3805,
        "regionCode": "AZ",
        "regionName": "Arizona"
      },
      {
        "regionId": 3806,
        "regionCode": "AR",
        "regionName": "Arkansas"
      },
      {
        "regionId": 3807,
        "regionCode": "CA",
        "regionName": "California"
      },
      {
        "regionId": 3808,
        "regionCode": "CO",
        "regionName": "Colorado"
      },
      {
        "regionId": 3809,
        "regionCode": "CT",
        "regionName": "Connecticut"
      },
      {
        "regionId": 3810,
        "regionCode": "DE",
        "regionName": "Delaware"
      },
      {
        "regionId": 3811,
        "regionCode": "DC",
        "regionName": "District of Columbia"
      },
      {
        "regionId": 3812,
        "regionCode": "FL",
        "regionName": "Florida"
      },
      {
        "regionId": 3813,
        "regionCode": "GA",
        "regionName": "Georgia"
      },
      {
        "regionId": 3814,
        "regionCode": "HI",
        "regionName": "Hawaii"
      },
      {
        "regionId": 3815,
        "regionCode": "ID",
        "regionName": "Idaho"
      },
      {
        "regionId": 3816,
        "regionCode": "IL",
        "regionName": "Illinois"
      },
      {
        "regionId": 3817,
        "regionCode": "IN",
        "regionName": "Indiana"
      },
      {
        "regionId": 3818,
        "regionCode": "IA",
        "regionName": "Iowa"
      },
      {
        "regionId": 3819,
        "regionCode": "KS",
        "regionName": "Kansas"
      },
      {
        "regionId": 3820,
        "regionCode": "KY",
        "regionName": "Kentucky"
      },
      {
        "regionId": 3821,
        "regionCode": "LA",
        "regionName": "Louisiana"
      },
      {
        "regionId": 3822,
        "regionCode": "ME",
        "regionName": "Maine"
      },
      {
        "regionId": 3823,
        "regionCode": "MD",
        "regionName": "Maryland"
      },
      {
        "regionId": 3824,
        "regionCode": "MA",
        "regionName": "Massachusetts"
      },
      {
        "regionId": 3825,
        "regionCode": "MI",
        "regionName": "Michigan"
      },
      {
        "regionId": 3826,
        "regionCode": "MN",
        "regionName": "Minnesota"
      },
      {
        "regionId": 3827,
        "regionCode": "MS",
        "regionName": "Mississippi"
      },
      {
        "regionId": 3828,
        "regionCode": "MO",
        "regionName": "Missouri"
      },
      {
        "regionId": 3829,
        "regionCode": "MT",
        "regionName": "Montana"
      },
      {
        "regionId": 3830,
        "regionCode": "NE",
        "regionName": "Nebraska"
      },
      {
        "regionId": 3831,
        "regionCode": "NV",
        "regionName": "Nevada"
      },
      {
        "regionId": 3832,
        "regionCode": "NH",
        "regionName": "New Hampshire"
      },
      {
        "regionId": 3833,
        "regionCode": "NJ",
        "regionName": "New Jersey"
      },
      {
        "regionId": 3834,
        "regionCode": "NM",
        "regionName": "New Mexico"
      },
      {
        "regionId": 3835,
        "regionCode": "NY",
        "regionName": "New York"
      },
      {
        "regionId": 3836,
        "regionCode": "NC",
        "regionName": "North Carolina"
      },
      {
        "regionId": 3837,
        "regionCode": "ND",
        "regionName": "North Dakota"
      },
      {
        "regionId": 3838,
        "regionCode": "OH",
        "regionName": "Ohio"
      },
      {
        "regionId": 3839,
        "regionCode": "OK",
        "regionName": "Oklahoma"
      },
      {
        "regionId": 3840,
        "regionCode": "OR",
        "regionName": "Oregon"
      },
      {
        "regionId": 3841,
        "regionCode": "PA",
        "regionName": "Pennsylvania"
      },
      {
        "regionId": 3842,
        "regionCode": "RI",
        "regionName": "Rhode Island"
      },
      {
        "regionId": 3843,
        "regionCode": "SC",
        "regionName": "South Carolina"
      },
      {
        "regionId": 3844,
        "regionCode": "SD",
        "regionName": "South Dakota"
      },
      {
        "regionId": 3845,
        "regionCode": "TN",
        "regionName": "Tennessee"
      },
      {
        "regionId": 3846,
        "regionCode": "TX",
        "regionName": "Texas"
      },
      {
        "regionId": 3847,
        "regionCode": "UT",
        "regionName": "Utah"
      },
      {
        "regionId": 3848,
        "regionCode": "VT",
        "regionName": "Vermont"
      },
      {
        "regionId": 3849,
        "regionCode": "VA",
        "regionName": "Virginia"
      },
      {
        "regionId": 3850,
        "regionCode": "WA",
        "regionName": "Washington"
      },
      {
        "regionId": 3851,
        "regionCode": "WV",
        "regionName": "West Virginia"
      },
      {
        "regionId": 3852,
        "regionCode": "WI",
        "regionName": "Wisconsin"
      },
      {
        "regionId": 3853,
        "regionCode": "WY",
        "regionName": "Wyoming"
      },
      {
        "regionId": 4082,
        "regionCode": "PR",
        "regionName": "American Samoa"
      },
      {
        "regionId": 4083,
        "regionCode": "GU",
        "regionName": "Guam"
      },
      {
        "regionId": 4084,
        "regionCode": "MH",
        "regionName": "Marshall Islands"
      },
      {
        "regionId": 4085,
        "regionCode": "MP",
        "regionName": "Northern Mariana Islands"
      },
      {
        "regionId": 4086,
        "regionCode": "FM",
        "regionName": "Federated States of Micronesia"
      },
      {
        "regionId": 4087,
        "regionCode": "PR",
        "regionName": "Puerto Rico"
      },
      {
        "regionId": 4088,
        "regionCode": "PW",
        "regionName": "Palau"
      },
      {
        "regionId": 4089,
        "regionCode": "VI",
        "regionName": "U.S. Virgin Islands"
      }
    ]
  },
  {
    "countryId": 224,
    "countryCode": "UY",
    "countryName": "Uruguay",
    "regions": [
      {
        "regionId": 3854,
        "regionCode": "AR",
        "regionName": "Artigas"
      },
      {
        "regionId": 3855,
        "regionCode": "CA",
        "regionName": "Canelones"
      },
      {
        "regionId": 3856,
        "regionCode": "CL",
        "regionName": "Cerro Largo"
      },
      {
        "regionId": 3857,
        "regionCode": "CO",
        "regionName": "Colonia"
      },
      {
        "regionId": 3858,
        "regionCode": "DU",
        "regionName": "Durazno"
      },
      {
        "regionId": 3859,
        "regionCode": "FS",
        "regionName": "Flores"
      },
      {
        "regionId": 3860,
        "regionCode": "FD",
        "regionName": "Florida"
      },
      {
        "regionId": 3861,
        "regionCode": "LA",
        "regionName": "Lavalleja"
      },
      {
        "regionId": 3862,
        "regionCode": "MA",
        "regionName": "Maldonado"
      },
      {
        "regionId": 3863,
        "regionCode": "MO",
        "regionName": "Montevideo"
      },
      {
        "regionId": 3864,
        "regionCode": "PA",
        "regionName": "Paysandu"
      },
      {
        "regionId": 3865,
        "regionCode": "RN",
        "regionName": "Rio Negro"
      },
      {
        "regionId": 3866,
        "regionCode": "RV",
        "regionName": "Rivera"
      },
      {
        "regionId": 3867,
        "regionCode": "RO",
        "regionName": "Rocha"
      },
      {
        "regionId": 3868,
        "regionCode": "SA",
        "regionName": "Salto"
      },
      {
        "regionId": 3869,
        "regionCode": "SJ",
        "regionName": "San Jose"
      },
      {
        "regionId": 3870,
        "regionCode": "SO",
        "regionName": "Soriano"
      },
      {
        "regionId": 3871,
        "regionCode": "TA",
        "regionName": "Tacuarembo"
      },
      {
        "regionId": 3872,
        "regionCode": "TT",
        "regionName": "Treinta y Tres"
      }
    ]
  },
  {
    "countryId": 225,
    "countryCode": "UZ",
    "countryName": "Uzbekistan",
    "regions": [
      {
        "regionId": 3873,
        "regionName": "Andijon"
      },
      {
        "regionId": 3874,
        "regionName": "Buhoro"
      },
      {
        "regionId": 3875,
        "regionName": "Buxoro Viloyati"
      },
      {
        "regionId": 3876,
        "regionName": "Cizah"
      },
      {
        "regionId": 3877,
        "regionName": "Fargona"
      },
      {
        "regionId": 3878,
        "regionName": "Horazm"
      },
      {
        "regionId": 3879,
        "regionName": "Kaskadar"
      },
      {
        "regionId": 3880,
        "regionName": "Korakalpogiston"
      },
      {
        "regionId": 3881,
        "regionName": "Namangan"
      },
      {
        "regionId": 3882,
        "regionName": "Navoi"
      },
      {
        "regionId": 3883,
        "regionName": "Samarkand"
      },
      {
        "regionId": 3884,
        "regionName": "Sirdare"
      },
      {
        "regionId": 3885,
        "regionName": "Surhondar"
      },
      {
        "regionId": 3886,
        "regionName": "Toskent"
      }
    ]
  },
  {
    "countryId": 226,
    "countryCode": "VA",
    "countryName": "Vatican City",
    "regions": [
      {
        "regionId": 3887,
        "regionName": "Vatican City State UNION ALL SELECT Holy See)"
      }
    ]
  },
  {
    "countryId": 227,
    "countryCode": "VE",
    "countryName": "Venezuela",
    "regions": [
      {
        "regionId": 3888,
        "regionCode": "Z",
        "regionName": "Amazonas"
      },
      {
        "regionId": 3889,
        "regionCode": "ANZ",
        "regionName": "Anzoategui"
      },
      {
        "regionId": 3890,
        "regionCode": "APU",
        "regionName": "Apure"
      },
      {
        "regionId": 3891,
        "regionCode": "ARA",
        "regionName": "Aragua"
      },
      {
        "regionId": 3892,
        "regionCode": "BAR",
        "regionName": "Barinas"
      },
      {
        "regionId": 3893,
        "regionCode": "BOL",
        "regionName": "Bolivar"
      },
      {
        "regionId": 3894,
        "regionCode": "CAR",
        "regionName": "Carabobo"
      },
      {
        "regionId": 3895,
        "regionCode": "COJ",
        "regionName": "Cojedes"
      },
      {
        "regionId": 3896,
        "regionCode": "DA",
        "regionName": "Delta Amacuro"
      },
      {
        "regionId": 3897,
        "regionCode": "DF",
        "regionName": "Distrito Federal"
      },
      {
        "regionId": 3898,
        "regionCode": "FAL",
        "regionName": "Falcon"
      },
      {
        "regionId": 3899,
        "regionCode": "GUA",
        "regionName": "Guarico"
      },
      {
        "regionId": 3900,
        "regionCode": "LAR",
        "regionName": "Lara"
      },
      {
        "regionId": 3901,
        "regionCode": "MER",
        "regionName": "Merida"
      },
      {
        "regionId": 3902,
        "regionCode": "MIR",
        "regionName": "Miranda"
      },
      {
        "regionId": 3903,
        "regionCode": "MON",
        "regionName": "Monagas"
      },
      {
        "regionId": 3904,
        "regionCode": "NE",
        "regionName": "Nueva Esparta"
      },
      {
        "regionId": 3905,
        "regionCode": "POR",
        "regionName": "Portuguesa"
      },
      {
        "regionId": 3906,
        "regionCode": "SUC",
        "regionName": "Sucre"
      },
      {
        "regionId": 3907,
        "regionCode": "TAC",
        "regionName": "Tachira"
      },
      {
        "regionId": 3908,
        "regionCode": "TRU",
        "regionName": "Trujillo"
      },
      {
        "regionId": 3909,
        "regionCode": "VAR",
        "regionName": "Vargas"
      },
      {
        "regionId": 3910,
        "regionCode": "YAR",
        "regionName": "Yaracuy"
      },
      {
        "regionId": 3911,
        "regionCode": "ZUL",
        "regionName": "Zulia"
      }
    ]
  },
  {
    "countryId": 228,
    "countryCode": "VN",
    "countryName": "Vietnam",
    "regions": [
      {
        "regionId": 3918,
        "regionCode": "54",
        "regionName": "Bac Giang"
      },
      {
        "regionId": 3919,
        "regionCode": "31",
        "regionName": "Binh Dinh"
      },
      {
        "regionId": 3920,
        "regionCode": "57",
        "regionName": "Binh Duong"
      },
      {
        "regionId": 3921,
        "regionCode": "DN",
        "regionName": "Da Nang"
      },
      {
        "regionId": 3922,
        "regionName": "Dong Bang Song Cuu Long"
      },
      {
        "regionId": 3923,
        "regionName": "Dong Bang Song Hong"
      },
      {
        "regionId": 3924,
        "regionCode": "39",
        "regionName": "Dong Nai"
      },
      {
        "regionId": 3925,
        "regionName": "Dong Nam Bo"
      },
      {
        "regionId": 3926,
        "regionName": "Duyen Hai Mien Trung"
      },
      {
        "regionId": 3927,
        "regionName": "Hanoi"
      },
      {
        "regionId": 3928,
        "regionCode": "66",
        "regionName": "Hung Yen"
      },
      {
        "regionId": 3929,
        "regionName": "Khu Bon Cu"
      },
      {
        "regionId": 3930,
        "regionCode": "41",
        "regionName": "Long An"
      },
      {
        "regionId": 3931,
        "regionName": "Mien Nui Va Trung Du"
      },
      {
        "regionId": 3932,
        "regionCode": "69",
        "regionName": "Thai Nguyen"
      },
      {
        "regionId": 3933,
        "regionCode": "SG",
        "regionName": "Thanh Pho Ho Chi Minh"
      },
      {
        "regionId": 3934,
        "regionName": "Thu Do Ha Noi"
      },
      {
        "regionId": 3935,
        "regionName": "Tinh Can Tho"
      },
      {
        "regionId": 3936,
        "regionName": "Tinh Da Nang"
      },
      {
        "regionId": 3937,
        "regionName": "Tinh Gia Lai"
      }
    ]
  },
  {
    "countryId": 229,
    "countryCode": "VI",
    "countryName": "Virgin Islands",
    "regions": [
      {
        "regionId": 3915,
        "regionName": "Saint Croix"
      },
      {
        "regionId": 3916,
        "regionName": "Saint John"
      },
      {
        "regionId": 3917,
        "regionName": "Saint Thomas"
      }
    ]
  },
  {
    "countryId": 230,
    "countryCode": "EH",
    "countryName": "Western Sahara",
    "regions": [
      {
        "regionId": 1055,
        "regionName": "al-'Ayun"
      },
      {
        "regionId": 1056,
        "regionName": "as-Samarah"
      },
      {
        "regionId": 1057,
        "regionName": "Bu Jaydur"
      },
      {
        "regionId": 1058,
        "regionName": "Wad-adh-Dhahab"
      }
    ]
  },
  {
    "countryId": 231,
    "countryCode": "YE",
    "countryName": "Yemen",
    "regions": [
      {
        "regionId": 3949,
        "regionName": "Abyan"
      },
      {
        "regionId": 3950,
        "regionName": "'Adan"
      },
      {
        "regionId": 3951,
        "regionName": "al-Bayda"
      },
      {
        "regionId": 3952,
        "regionName": "al-Hudaydah"
      },
      {
        "regionId": 3953,
        "regionName": "al-Jawf"
      },
      {
        "regionId": 3954,
        "regionName": "al-Mahrah"
      },
      {
        "regionId": 3955,
        "regionName": "al-Mahwit"
      },
      {
        "regionId": 3956,
        "regionName": "Dhamar"
      },
      {
        "regionId": 3957,
        "regionName": "Hadramaut"
      },
      {
        "regionId": 3958,
        "regionName": "Hajjah"
      },
      {
        "regionId": 3959,
        "regionName": "Hudaydah"
      },
      {
        "regionId": 3960,
        "regionName": "Ibb"
      },
      {
        "regionId": 3961,
        "regionName": "Lahij"
      },
      {
        "regionId": 3962,
        "regionName": "Madinat San'a"
      },
      {
        "regionId": 3963,
        "regionName": "Ma'rib"
      },
      {
        "regionId": 3964,
        "regionName": "Sa'dah"
      },
      {
        "regionId": 3965,
        "regionName": "Sana"
      },
      {
        "regionId": 3966,
        "regionName": "Shabwah"
      },
      {
        "regionId": 3967,
        "regionName": "Ta'izz"
      }
    ]
  },
  {
    "countryId": 232,
    "countryCode": "ZM",
    "countryName": "Zambia",
    "regions": [
      {
        "regionId": 3984,
        "regionName": "Central"
      },
      {
        "regionId": 3985,
        "regionName": "Copperbelt"
      },
      {
        "regionId": 3986,
        "regionName": "Eastern"
      },
      {
        "regionId": 3987,
        "regionName": "Luapala"
      },
      {
        "regionId": 3988,
        "regionName": "Lusaka"
      },
      {
        "regionId": 3989,
        "regionName": "Northern"
      },
      {
        "regionId": 3990,
        "regionName": "North-Western"
      },
      {
        "regionId": 3991,
        "regionName": "Southern"
      },
      {
        "regionId": 3992,
        "regionName": "Western"
      }
    ]
  },
  {
    "countryId": 233,
    "countryCode": "ZW",
    "countryName": "Zimbabwe",
    "regions": [
      {
        "regionId": 3993,
        "regionName": "Bulawayo"
      },
      {
        "regionId": 3994,
        "regionName": "Harare"
      },
      {
        "regionId": 3995,
        "regionName": "Manicaland"
      },
      {
        "regionId": 3996,
        "regionName": "Mashonaland Central"
      },
      {
        "regionId": 3997,
        "regionName": "Mashonaland East"
      },
      {
        "regionId": 3998,
        "regionName": "Mashonaland West"
      },
      {
        "regionId": 3999,
        "regionName": "Masvingo"
      },
      {
        "regionId": 4000,
        "regionName": "Matabeleland North"
      },
      {
        "regionId": 4001,
        "regionName": "Matabeleland South"
      },
      {
        "regionId": 4002,
        "regionName": "Midlands"
      }
    ]
  },
  {
    "countryId": 234,
    "countryCode": "MP",
    "countryName": "Northern Mariana Islands",
    "regions": []
  },
  {
    "countryId": 235,
    "countryCode": "VC",
    "countryName": "Saint Vincent and the Grenadines",
    "regions": []
  },
  {
    "countryId": 238,
    "countryCode": "VU",
    "countryName": "Vanuatu",
    "regions": [
      {
        "regionId": 4102,
        "regionCode": "MAP",
        "regionName": "Malampa"
      },
      {
        "regionId": 4103,
        "regionCode": "PAM",
        "regionName": "Pénama"
      },
      {
        "regionId": 4104,
        "regionCode": "SAM",
        "regionName": "Sanma"
      },
      {
        "regionId": 4105,
        "regionCode": "SEE",
        "regionName": "Shéfa"
      },
      {
        "regionId": 4106,
        "regionCode": "TAE",
        "regionName": "Taféa"
      },
      {
        "regionId": 4107,
        "regionCode": "TOB",
        "regionName": "Torba"
      }
    ]
  }
]