import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { UIExternalAPI } from '@core/typings/ui/external-api.typing';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

export type CompKey = (keyof FormDefinitionComponent)|(`validate.${keyof FormDefinitionComponent['validate']}`);

export enum ValidationTypes {
  HasSelectedItem = 1,
  QuantityGreaterThan = 2,
  QuantityLessThan = 3,
  QuantityEqualTo = 4,
  HasSelectedQuantity = 5
}

export enum IsValidTypes {
  Valid = 1,
  Invalid = 2
}

export const Label_Setting: ComponentTabSetting = {
  i18nKey: 'common:hdrLabel',
  i18nDefault: 'Label',
  key: 'label',
  type: 'text',
  selectOptions: [],
  required: true,
  hasCustomValidity: false
};
export const Placeholder_Setting: ComponentTabSetting = {
  i18nKey: 'common:textPlaceholder',
  i18nDefault: 'Placeholder',
  key: 'placeholder',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Tooltip_Setting: ComponentTabSetting = {
  i18nKey: 'common:textTooltip',
  i18nDefault: 'Tooltip',
  key: 'tooltip',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Hide_Label_Setting: ComponentTabSetting = {
  i18nKey: 'common:textHideLabel',
  i18nDefault: 'Hide label',
  key: 'hideLabel',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Description_Setting: ComponentTabSetting = {
  i18nKey: 'common:textDescription',
  i18nDefault: 'Description',
  key: 'description',
  type: 'textarea',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Tab_Index_Setting: ComponentTabSetting = {
  i18nKey: 'common:textTabIndex',
  i18nDefault: 'Tab index',
  key: 'tabIndex',
  type: 'number',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Disabled_Setting: ComponentTabSetting = {
  i18nKey: 'common:textDisabled',
  i18nDefault: 'Disabled',
  key: 'disabled',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Text_For_Approving_Setting: ComponentTabSetting = {
  i18nKey: 'common:textTextForApproving',
  i18nDefault: 'Text for approving',
  key: 'truthyValue',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Text_For_Declining_Setting: ComponentTabSetting = {
  i18nKey: 'common:textTextForDeclining',
  i18nDefault: 'Text for declining',
  key: 'falsyValue',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Allow_Recused_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: null,
  type: 'decisionRecusedWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const In_Kind_Data_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'inKindDataWidget',
  key: null,
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const In_Kind_Validation_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'inKindValidationWidget',
  key: null,
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Allow_Override_Calculated_Value: ComponentTabSetting = {
  i18nKey: 'common:textAllowOverrideCalcValue',
  i18nDefault: 'Allow manual override of calculated value',
  type: 'checkbox',
  key: 'allowCalculateOverride',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Old_Calculated_Value_Setting: ComponentTabSetting = {
  i18nKey: 'common:hdrCalculatedValue',
  i18nDefault: 'Calculated Value',
  key: 'calculateValue',
  type: 'customJavascript',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Default_Value_Setting: ComponentTabSetting = {
  i18nKey: 'common:textDefaultValue',
  i18nDefault: 'Default value',
  key: 'defaultVal',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Required_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRequired',
  i18nDefault: 'Required',
  key: 'validate.required',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Custom_Error_Setting: ComponentTabSetting = {
  i18nKey: 'common:textCustomErrorMessage',
  i18nDefault: 'Custom error message',
  key: 'validate.customMessage',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Old_Custom_Validation_Setting: ComponentTabSetting = {
  i18nKey: 'common:hdrCustomValidation',
  i18nDefault: 'Custom Validation',
  key: 'validate.custom',
  type: 'customJavascript',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Advanced_Validation_Setting: ComponentTabSetting = {
  i18nKey: 'common:textAdvancedValidation',
  i18nDefault: 'Advanced Validation',
  key: 'customValidation',
  type: 'validationBuilder',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Api_Key_Setting: ComponentTabSetting = {
  i18nKey: 'common:textPropertyName',
  i18nDefault: 'Property name',
  key: 'key',
  type: 'text',
  selectOptions: [],
  required: true,
  hasCustomValidity: false,
  customRegexPattern: new RegExp(/\./g) // No periods are allowed in the property name as it causes problem when used as a form control key
};
export const Old_Conditional_Logic_Setting: ComponentTabSetting = {
  i18nKey: 'common:hdrCustomConditionalLogic',
  i18nDefault: 'Custom Conditional Logic',
  key: 'customConditional',
  type: 'customJavascript',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Conditional_Logic_Setting: ComponentTabSetting = {
  i18nKey: 'common:hdrAdvancedConditionalLogic',
  i18nDefault: 'Advanced Conditional Logic',
  key: 'conditionalLogic',
  type: 'logicFormBuilderWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Set_Value_Logic_Setting: ComponentTabSetting = {
  i18nKey: 'common:textSetValueLogic',
  i18nDefault: 'Set value logic',
  key: 'conditionalValue',
  type: 'logicValueFormBuilderWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Calculated_Value_Setting: ComponentTabSetting = {
  i18nKey: 'common:textCalculatedValue',
  i18nDefault: 'Calculated value',
  key: 'formula',
  type: 'formulaBuilderManager',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Custom_Currency_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: null,
  type: 'customCurrencyWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Report_Field_Data_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: null,
  type: 'reportFieldDataWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const Display_Type_Setting: ComponentTabSetting = {
  i18nKey: 'common:textDisplayType2',
  i18nDefault: 'Display type',
  key: 'displayType',
  type: 'select',
  selectOptions: [{
    i18nKey: 'common:textTextField',
    i18nDefault: 'Text field',
    value: AdHocReportingUI.DisplayTypes.TextField
  }, {
    i18nKey: 'common:textTextArea',
    i18nDefault: 'Text area',
    value: AdHocReportingUI.DisplayTypes.TextArea
  }, {
    i18nKey: 'common:textRaw',
    i18nDefault: 'Raw',
    value: AdHocReportingUI.DisplayTypes.Raw
  }],
  required: true,
  hasCustomValidity: false
};
export const Require_Supporting_Document_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRequireSupportingDocument',
  i18nDefault: 'Require supporting document',
  key: 'requireFileUpload',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Require_Reason_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRequireReason',
  i18nDefault: 'Require reason',
  key: 'requireReason',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Column_Properties_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: 'columns',
  type: 'columnsWidget',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const Legend_Setting: ComponentTabSetting = {
  i18nKey: 'common:textLegend',
  i18nDefault: 'Legend',
  key: 'legend',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Title_Setting: ComponentTabSetting = {
  i18nKey: 'common:lblTitle',
  i18nDefault: 'Title',
  key: 'title',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Collapsible_Panel_Setting: ComponentTabSetting = {
  i18nKey: 'common:textCollapsible',
  i18nDefault: 'Collapsible',
  key: 'collapsible',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Collapsed_Panel_Setting: ComponentTabSetting = {
  i18nKey: 'common:textInitiallyCollapsed',
  i18nDefault: 'Initially collapsed',
  key: 'collapsed',
  type: 'checkbox',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Rows_Per_Page_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRowsPerPage',
  i18nDefault: 'Rows per page',
  type: 'number',
  key: 'rowsPerPage',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Prefix_Setting: ComponentTabSetting = {
  i18nKey: 'common:textPrefix',
  i18nDefault: 'Prefix',
  key: 'prefix',
  type: 'text',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Suffix_Setting: ComponentTabSetting = {
  i18nKey: 'common:textSuffix',
  i18nDefault: 'Suffix',
  type: 'text',
  key: 'suffix',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Text_Area_Rows_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRows',
  i18nDefault: 'Rows',
  type: 'number',
  key: 'rows',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Word_Counter_Setting: ComponentTabSetting = {
  i18nKey: 'common:textShowWordCounter',
  i18nDefault: 'Show word counter',
  type: 'checkbox',
  key: 'showWordCount',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Character_Counter_Setting: ComponentTabSetting = {
  i18nKey: 'common:textShowCharacterCount',
  i18nDefault: 'Show character count',
  type: 'checkbox',
  key: 'showCharCount',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Pull_From_Bbgm_Setting: ComponentTabSetting = {
  i18nKey: 'common:textDisplayValueFromBbgm',
  i18nDefault: 'Display value from BBGM',
  type: 'checkbox',
  key: 'pullFromBBGM',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Inline_Layout_Setting: ComponentTabSetting = {
  i18nKey: 'common:textInlineLayout',
  i18nDefault: 'Inline layout',
  type: 'checkbox',
  key: 'inline',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Num_Items_Before_Scroll_Setting: ComponentTabSetting = {
  i18nKey: 'common:textNumberOfItemsBeforeScroll',
  i18nDefault: 'Number of items shown before scroll',
  type: 'number',
  key: 'itemsShownBeforeScroll',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Decimal_Places_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMaxNumberDecimalPlaces',
  i18nDefault: 'Maximum number of decimal places',
  type: 'number',
  key: 'decimalLimit',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Data_Set_Validation_Total: ComponentTabSetting = {
  i18nKey: 'common:textResponsesMustAddUp',
  i18nDefault: 'Responses must add up to the following value',
  type: 'number',
  key: 'validationTotal',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Require_Decimal_Setting: ComponentTabSetting = {
  i18nKey: 'common:textRequireDecimal',
  i18nDefault: 'Require decimal',
  type: 'checkbox',
  key: 'requireDecimal',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Dependent_Picklist_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'dependentPicklistSettings',
  key: 'hideWithoutParentVal',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Min_Value_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMinimumValue',
  i18nDefault: 'Minimum value',
  type: 'number',
  key: 'validate.min',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Max_Value_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMaximumValue',
  i18nDefault: 'Maximum value',
  type: 'number',
  key: 'validate.max',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const All_Options_Have_Response_Setting: ComponentTabSetting = {
  i18nKey: 'common:textAllOptionsMustHaveResponse',
  i18nDefault: 'All options must have a response',
  type: 'checkbox',
  key: 'allOptionsMustHaveResponse',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Min_Length_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMinimumLength',
  i18nDefault: 'Minimum length',
  type: 'number',
  key: 'validate.minLength',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Max_Length_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMaximumLength',
  i18nDefault: 'Maximum length',
  type: 'number',
  key: 'validate.maxLength',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Min_Words_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMinimumWordLength',
  i18nDefault: 'Minimum word length',
  type: 'number',
  key: 'validate.minWords',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Max_Words_Setting: ComponentTabSetting = {
  i18nKey: 'common:textMaxWordLength',
  i18nDefault: 'Maximum word length',
  type: 'number',
  key: 'validate.maxWords',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Regular_Expression_Setting: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: 'validate.pattern',
  type: 'regularExpressionSetting',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Input_Mask_Setting: ComponentTabSetting = {
  i18nKey: 'common:textInputMask',
  i18nDefault: 'Input mask',
  i18nDescKey: 'common:textInputMaskDesc',
  i18nDescContext: {
    exampleText: `"(000) 000-0000"`
  },
  i18nDescDefault: 'Use "0" to represent any digit. Use "a" to represent any character. Example: __exampleText__',
  type: 'text',
  key: 'inputMask',
  selectOptions: [],
  required: false,
  hasCustomValidity: false,
  customRegexPattern: new RegExp(/[^0a\-\+\()\/: ]/g) // Only allow "0", "a", "+", "-", "(", ")", "/", ":", or " "
};
export const Clear_When_Hidden_Setting: ComponentTabSetting = {
  i18nKey: 'common:textClearValueWhenHidden',
  i18nDefault: 'Clear value when hidden',
  type: 'checkbox',
  key: 'clearOnHide',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const External_Api_Settings: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'externalApiSettings',
  key: 'apiConfig',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Require_External_Api_Response: ComponentTabSetting = {
  i18nKey: 'common:textRequireExternalApiResponseForSubmission',
  i18nDefault: 'Require an external API response for submission',
  type: 'checkbox',
  key: 'required',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Visible_To_Applicants: ComponentTabSetting = {
  i18nKey: 'common:textComponentVisibleToApplicants',
  i18nDefault: 'Component visible to applicant(s)',
  type: 'checkbox',
  key: 'visibleToApplicants',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const Visible_To_Managers: ComponentTabSetting = {
  i18nKey: 'common:textComponentVisibleToManagers ',
  i18nDefault: 'Component visible to grant manager(s)',
  type: 'checkbox',
  key: 'visibleToManagers',
  selectOptions: [],
  required: false,
  hasCustomValidity: false
};
export const External_Api_Form_Field_Source: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'externalApiFormFieldSource',
  key: 'relatedComponent',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const External_Api_Data_Updates: ComponentTabSetting = {
  i18nKey: 'common:textDataUpdates',
  i18nDefault: 'Data updates',
  type: 'select',
  key: 'dataUpdates',
  selectOptions: [{
    i18nKey: 'common:textCompFieldDataLockedIn',
    i18nDefault: 'Component field data will be locked in at form submission. Field data is available for reporting and conditional logic.',
    value: UIExternalAPI.DataUpdates.UntilSubmitted
  }, {
    i18nKey: 'common:textCompFieldDataUpdated',
    i18nDefault: 'Component field data will be updated when the form is viewed. Field data is not available for reporting or conditional logic.',
    value: UIExternalAPI.DataUpdates.Always
  }],
  required: true,
  hasCustomValidity: false
};
export const Table_Component_Visible_Columns: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'tableComponentVisibleColumns',
  key: 'hiddenTableColumnKeys',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const Hidden_Cdt_Keys: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  key: 'hiddenCdtKeys',
  type: 'cdtFieldVisibleOptions',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};
export const Max_File_Size: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'maxFileSizeInMb',
  key: 'maxFileSizeInMb',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};

export const Accepted_File_Types: ComponentTabSetting = {
  i18nKey: '',
  i18nDefault: '',
  type: 'acceptedFileTypes',
  key: 'acceptedFileTypes',
  selectOptions: [],
  required: false,
  hasCustomValidity: true
};

export const Empty_State_Tab: ComponentTabOptions = {
  components: []
};

export const Report_Field_Data_Tab: ComponentTabOptions = {
  components: [
    Report_Field_Data_Settings
  ]
};
export const Api_Tab: ComponentTabOptions = {
  components: [
    Api_Key_Setting
  ]
};
export const Set_Value_Tab: ComponentTabOptions = {
  components: [
    Set_Value_Logic_Setting
  ]
};
export const Validation_Tab_Number_Components = [
  Required_Setting,
  Min_Value_Setting,
  Max_Value_Setting,
  Custom_Error_Setting
];
export const Validation_Tab_File_Multi_Components = [
  Custom_Error_Setting,
  {
    ...Min_Value_Setting,
    i18nKey: 'common:texMinimumNumberOfFiles',
    i18nDefault: 'Minimum number of files'
  },
  {
    ...Max_Value_Setting,
    i18nKey: 'common:texMaximumNumberOfFiles',
    i18nDefault: 'Maximum number of files'
  },
  Max_File_Size,
  Accepted_File_Types
];
export const Validation_Tab_File_Single_Components = [
  Required_Setting,
  Custom_Error_Setting,
  Max_File_Size,
  Accepted_File_Types
];
const Min_Max_Response_Settings = [{
  ...Min_Value_Setting,
  i18nKey: 'common:textMinimumNumberResponses',
  i18nDefault: 'Minimum number of responses'
},
{
  ...Max_Value_Setting,
  i18nKey: 'common:textMaximumNumberOfResponses',
  i18nDefault: 'Maximum number of responses'
}];
const Min_Max_SelectBox_Settings = [{
  ...Min_Value_Setting,
  i18nKey: 'common:textMinimumNumberSelected',
  i18nDefault: 'Minimum number selected'
},
{
  ...Max_Value_Setting,
  i18nKey: 'common:textMaximumNumberOfSelected',
  i18nDefault: 'Maximum number selected'
}];
export const Validation_Tab_Subset_Components = [
  Custom_Error_Setting,
  All_Options_Have_Response_Setting,
  ...Min_Max_Response_Settings
];
export const Validation_Tab_Table_Components = [
  Custom_Error_Setting,
  ...Min_Max_Response_Settings
];
export const Validation_Tab_SelectBox_Components = [
  Required_Setting,
  Custom_Error_Setting,
  ...Min_Max_SelectBox_Settings
];

export interface ComponentTabSetting<T = any> {
  i18nKey: string;
  i18nDefault: string;
  i18nDescKey?: string;
  i18nDescContext?: Record<string, string>;
  i18nDescDefault?: string;
  key: CompKey;
  type: ComponentSettingTypes;
  selectOptions: ComponentSelectOption<T>[];
  required: boolean;
  hasCustomValidity: boolean;
  customRegexPattern?: RegExp; // Passed to input component. Regexp of valid characters for input.
}

export interface ComponentSelectOption<T> {
  i18nKey: string;
  i18nDefault: string;
  value: T;
}

export interface ComponentTabOptions {
  components: ComponentTabSetting[];
}

export interface ComponentTabOptionsWithValue {
  components: ComponentTabSettingWithValue[];
}

export interface ComponentTabSettingWithValue extends ComponentTabSetting {
  value: ComponentValueType;
  typeaheadOptions: TypeaheadSelectOption[];
}

export type ComponentValueType = string|number|boolean;

export enum ComponentConfigTabType {
  Display = 1,
  Data = 2,
  Validation = 3,
  Api = 4,
  Conditional_Visibility = 5,
  Set_Value = 6,
  Calculated_Value = 7,
  Columns = 8
}

export enum DefaultValType {
  None = 1,
  Text = 2,
  Date = 3
}
export type ComponentSettingTypes = 'text'|
  'number'|
  'textarea'|
  'select'|
  'checkbox'|
  'date'|
  'inKindValidationWidget'|
  'inKindDataWidget'|
  'decisionRecusedWidget'|
  'reportFieldDataWidget'|
  'formulaBuilderManager'|
  'customCurrencyWidget'|
  'logicFormBuilderWidget'|
  'logicValueFormBuilderWidget'|
  'columnsWidget'|
  'hiddenCdtKeys'|
  'customJavascript'|
  'regularExpressionSetting'|
  'dependentPicklistSettings'|
  'externalApiSettings'|
  'validationBuilder'|
  'externalApiFormFieldSource'|
  'tableComponentVisibleColumns'|
  'cdtFieldVisibleOptions'|
  'maxFileSizeInMb'|
  'acceptedFileTypes';

