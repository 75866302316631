import { StandardProductDashboard } from '@features/platform-admin/standard-product-configuration/standard-product-configuration.typing';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';
import { GCDashboards } from './dashboards.typing';


@RegisterYCState()
export class DashboardsState extends BaseYcState {
  readonly dashboardDetails: Record<number, GCDashboards.DashboardDetailForUI> = {};
  readonly widgetDisplayMap: Record<number, GCDashboards.Widget> = {};
  readonly widgetDetail: Record<number, GCDashboards.WidgetConfigFromApi> = {};
  readonly allWidgets: GCDashboards.SimpleWidgetConfig[];
  readonly visibleDashboards: GCDashboards.DashboardTab[];
  readonly allDashboards: GCDashboards.DashboardTab[];
  readonly dashboardManagerRows: (GCDashboards.DashboardTab|StandardProductDashboard)[];
  readonly homeRoute: string;
  readonly editing: boolean = false;
  readonly widgetEditMap: {
    [x: number]: GCDashboards.WidgetConfigFromApi;
  } = {};
  readonly lastRefreshMap: {
    [x: number]: string;
  } = {};
  readonly promptingForUnsavedChanges: boolean;
  readonly editingDashId: number;
}
