<div [formGroup]="formGroup">
  <yc-input
    name="maxFileSizeInMb"
    [isNumber]="true"
    [disabled]="isViewOnly"
    [label]="'common:textMaximumFileSize' | ycI18n: 'Maximum file size'"
    [help]="isMultiUploadField ?
      ('common:textMaxFileSizeDefaultsToMulti2' | ycI18n: 'Optionally choose a maximum file size for each file uploaded. If no size is entered, the default of __size__ MB will be enforced.' : {
        size: Default_Max_File_Size  
      }) :
      ('common:textMaxFileSizeDefaultsTo2' | ycI18n: 'Optionally choose a maximum file size. If no size is entered, the default of __size__ MB will be enforced.': {
      size: Default_Max_File_Size
    })"
    [suffix]="'MB'"
    [prefixSuffixFlexBasis]="'5%'"
    (onChange)="onChange.emit($event)">
  </yc-input>
</div>